import { localizedString } from '@languages';
import { defaultLettersAndSymbolsRegexMatch } from '@lib/validations/vefifyDetailsFormValidations';

export const short = {
  homeAddress: {
    isRequired: {
      message: localizedString('pleaseEnterYourHomeAddress')
    }
  }
};

export const detailed = {
  addressLine1: {
    isRegexMatch: defaultLettersAndSymbolsRegexMatch
  },
  street_number: {
    isRequired: {
      message: localizedString('streetNumberRequired')
    },
    isRegexMatch: defaultLettersAndSymbolsRegexMatch
  },
  street_name: {
    isRequired: {
      message: localizedString('streetNameRequired')
    },
    isRegexMatch: defaultLettersAndSymbolsRegexMatch
  },
  suburb: {
    isRequired: {
      message: localizedString('suburbRequired')
    },
    isRegexMatch: defaultLettersAndSymbolsRegexMatch
  },
  postcode: {
    isRequired: {
      message: localizedString('postcodeRequired')
    },
    isRegexMatch: {
      message: localizedString('lettersAndNumbersOnly'),
      regex: /^([a-zA-Z0-9]+)$/
    }
  },
  state_territory: {
    isRequired: {
      message: localizedString('stateRequired')
    }
  }
};
