const japaneseUnicodeRanges = {
  hiragana: '\u3041-\u3096',
  katakana: '\u30A0-\u30FF',
  kanji: '\u3400-\u4DB5\u4E00-\u9FCB\uF900-\uFA6A',
  kanjiRadicals: '\u2E80-\u2FD5',
  katakanaAndPunctuation: '\uFF5F-\uFF9F',
  japaneseSymbolsAndPunctuation: '\u3000-\u303F'
};

const turkishUnicodeRanges = [
  '\u00A7',
  '\u00A9',
  '\u00C7',
  '\u00D6',
  '\u00DC',
  '\u00E7',
  '\u00F6',
  '\u00FC',
  '\u011E-\u011F',
  '\u0130-\u0131',
  '\u015E-\u015F',
  '\u2010-\u2011',
  '\u2013-\u2014',
  '\u2018-\u2019',
  '\u201C-\u201D',
  '\u2020-\u2021',
  '\u2026',
  '\u2030',
  '\u2032-\u2033',
  '\u20AC'
];

export const noEmojies = new RegExp(
  `^([\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178\u2019${Object.values(japaneseUnicodeRanges).join(
    ''
  )}${turkishUnicodeRanges.join('')}]+)$`
);
