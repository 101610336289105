import axios from 'axios';

const BASE_URL = '/api/3.5';

export const checkDataOnlyDetails = (details) => {
  return axios.post(`${BASE_URL}/dataCheckForPortal`, details).then(({ data }) => {
    if (data.status === 'error') {
      throw new Error(data.message || 'Unexpected Error');
    }
    return data;
  });
};
