import React from 'react';
import parse from 'html-react-parser';
import { localizedString } from '@languages';
import { Message } from '../../components';

/**
 * If card blurry or crop etc.
 */
export const Failed = (props) => {
  const {
    FLOW_V2_FAILED_ALERT_TITLE = localizedString('weNeedYouToHaveOneMoreTry'),
    FLOW_V2_FAILED_ALERT_DESCRIPTION = localizedString('weNeedYouToHaveOneMoreTryDescription')
  } = process.env;

  return (
    <Message {...props} title={FLOW_V2_FAILED_ALERT_TITLE} issue>
      {parse(FLOW_V2_FAILED_ALERT_DESCRIPTION)}
    </Message>
  );
};

/**
 * Enable Camera Permission
 */
export const Permission = (props) => {
  const {
    FLOW_V2_ENABLE_CAMERA_ALERT_TITLE = localizedString('enableCamera'),
    FLOW_V2_ENABLE_CAMERA_ALERT_DESCRIPTION = localizedString('cameraAccessIsRequired')
  } = process.env;

  return (
    <Message {...props} title={FLOW_V2_ENABLE_CAMERA_ALERT_TITLE} issue>
      {FLOW_V2_ENABLE_CAMERA_ALERT_DESCRIPTION}
    </Message>
  );
};

/**
 * Enable camera settings
 */
export const CameraSettings = (props) => {
  const {
    FLOW_V2_CAMERA_SETTINGS_ALERT_TITLE = localizedString('cameraSettings'),
    FLOW_V2_CAMERA_SETTINGS_ALERT_DESCRIPTION = `${localizedString(
      'cameraSettings_Description1_FlowV2'
    )}<br/><br/>${localizedString('cameraSettings_Description2_FlowV2')}`
  } = process.env;

  return (
    <Message {...props} title={FLOW_V2_CAMERA_SETTINGS_ALERT_TITLE} issue>
      {parse(FLOW_V2_CAMERA_SETTINGS_ALERT_DESCRIPTION)}
    </Message>
  );
};

/**
 * Retry with alternative flow because of permission issue.
 */
export const PermissionIssueRequiresAlternativeFlow = (props) => {
  const {
    FLOW_V2_TRY_SOMETHING_ELSE_TITLE = localizedString('weWillTrySomethingElse'),
    FLOW_V2_TRY_SOMETHING_ELSE_DESCRIPTION = localizedString(
      'weWillTrySomethingElse_Description_FlowV2'
    )
  } = process.env;

  return (
    <Message {...props} title={FLOW_V2_TRY_SOMETHING_ELSE_TITLE} issue>
      {parse(FLOW_V2_TRY_SOMETHING_ELSE_DESCRIPTION)}
    </Message>
  );
};

/**
 * Device Incompatible
 */

export const Incompatible = (props) => {
  return (
    <Message {...props} issue title={localizedString('deviceIncompatibleContent')}>
      {localizedString('deviceIncompatibleDesc1')}
      <br />
      <br />
      {localizedString('deviceIncompatibleDesc2')}
    </Message>
  );
};

/**
 * Session Interrupted
 */

export const SessionInterrupted = (props) => {
  return (
    <Message {...props} issue title={localizedString('sessionInterrupted')}>
      {localizedString('afterAttemptingToReconnect')}
      <br />
      <br />
      {localizedString('tryAgainOtherCall')}
    </Message>
  );
};

/**
 * Out of Hours
 */

export const OutOFHours = (props) => {
  return (
    <Message {...props} issue title={localizedString('outOfHours')}>
      {localizedString('comeBackBusinessHours')}
      <br />
      <br />
      {localizedString('businessHours')}
      <br />
      {localizedString('businessDays')}
    </Message>
  );
};

/**
 * Unable to connect
 */

export const UnableToConnect = (props) => {
  return (
    <Message {...props} issue title={localizedString('unableToConnect')}>
      {localizedString('afterAttemptingToReconnect')}
      <br />
      <br />
      {localizedString('tryAgainOtherCall')}
    </Message>
  );
};
