import React from 'react';
import PropTypes from 'prop-types';
import classes from './Header.style.module.scss';

const Header = ({ title, onGoBack, fixed, onSelectLanguage }) => {
  const { MULTI_LANGUAGE } = process.env;

  return (
    <header className={classes.warpper} style={{ position: fixed && 'fixed' }}>
      <div className="b-container">
        {onGoBack && <button type="button" className={classes.backButton} onClick={onGoBack} />}
        <h3 className={classes.title}>{title}</h3>

        {MULTI_LANGUAGE === 'true' && onSelectLanguage && (
          <button
            type="button"
            className={classes['langButton-white']}
            onClick={onSelectLanguage}
          />
        )}
      </div>
    </header>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  onGoBack: PropTypes.func,
  /* If its `true` the header position will be fixed */
  fixed: PropTypes.bool,
  onSelectLanguage: PropTypes.func
};

Header.defaultProps = {
  fixed: false
};

export default Header;
