import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormValidation } from 'calidation';
import { Page, Modal } from '@components';
import { VerifyAddressContent } from '@components/Contents';
import { localizedString } from '@languages';
import InfoAction from '../../store/actions/information';
import FormModel from './VerifyAddress.form';
import APIs from '../../services/APIs';
import Address from '../../services/Address';
import { getQueryStringParams } from '../../lib/Utils';
import { Error500 } from '../../errors';

class VerifyAddress extends Component {
  static propTypes = {
    onNextStep: PropTypes.func,
    onExit: PropTypes.func,
    onGoBack: PropTypes.func,
    addresses: PropTypes.object,
    idDetails: PropTypes.object,
    appConfig: PropTypes.object,
    location: PropTypes.string,
    countryCode: PropTypes.string,
    setAddress: PropTypes.func,
    on500Error: PropTypes.func
  };

  static defaultProps = {
    onNextStep: () => null,
    onExit: () => null,
    countryCode: ''
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.handleConfirm = this.handleConfirm.bind(this);
    this.storeData = this.storeData.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  /**
   * Return the component's initial state
   * @return {Object}
   */
  getInitialState() {
    const { idDetails = {}, addresses = {} } = this.props;
    const { ADDRESS_CUSTOM_FIELDS = [] } = process.env;
    const { address } = idDetails;

    const {
      homeAddress,
      prevAddress,
      addressLine1,
      addressLine2,
      city,
      suburb,
      postcode,
      country,
      isMatch
    } = addresses;

    const state = {
      /* Form data */
      data: {
        homeAddress: homeAddress || address,
        prevAddress: prevAddress || '',

        addressLine1,
        addressLine2,
        city,
        suburb,
        postcode,
        country,
        isMatch
      },
      customFields: {},
      manually: false,
      loading: false,
      fatalError: null
    };

    if (ADDRESS_CUSTOM_FIELDS.length > 0) {
      ADDRESS_CUSTOM_FIELDS.forEach((field) => {
        state.data[field.id] = addresses[field.id] || '';
        state.customFields[field.id] = addresses[field.id] || '';
      });
    }

    return state;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { ENABLE_MANUAL_ADDRESS = false } = process.env;
    const { countryCode } = this.props;
    if (countryCode === 'OTHER' && ENABLE_MANUAL_ADDRESS) {
      this.setState({ manually: true });
    }
  }

  componentDidMount() {
    APIs.status('address');
  }

  /**
   * Handle go back button.
   * @return {Void}
   */
  handleGoBack() {
    const { onGoBack } = this.props;
    const { manually } = this.state;
    if (manually) {
      this.setState({ manually: false });
    } else {
      onGoBack();
    }
  }

  /**
   * Save addresses and go to the next step
   * @return {Void}
   */
  handleConfirm({ isValid, setError }) {
    // Just for testing
    let { ad_mode: adMode } = getQueryStringParams();
    adMode = adMode ? parseInt(adMode, 10) : 0;

    const { data, customFields, manually } = this.state;
    const { countryCode, appConfig, idDetails, location, addresses = {} } = this.props;
    const { addressApiCalls = 0 } = addresses;
    const {
      ADDRESS_CUSTOM_FIELDS = [],
      ADDRESS_VALIDATION = false,
      ENABLE_MANUAL_ADDRESS = false
    } = process.env;
    if (ADDRESS_CUSTOM_FIELDS.length > 0) {
      const temp = [];
      ADDRESS_CUSTOM_FIELDS.forEach((field) => {
        const { id, label } = field;
        if (!customFields[id]) {
          if (id !== 'citizenship') {
            setError({
              [id]: localizedString('isRequiredPrefix') + label + localizedString('isRequired')
            });
            temp.push(localizedString('isRequiredPrefix') + label + localizedString('isRequired'));
          }
        }
      });
      if (temp.length > 0) {
        return;
      }
    }

    this.setState({ loading: true });

    if (ADDRESS_VALIDATION || adMode === 1 || adMode === 2) {
      if ((ENABLE_MANUAL_ADDRESS && manually) || (adMode === 1 && manually)) {
        const isManualValid = data.addressLine1 && data.suburb && data.postcode && data.country;
        const fullAddress = `${data.addressLine1} ${data.addressLine2} ${data.city} ${data.suburb} ${data.postcode} ${data.country}`;
        if (isManualValid) {
          // No need to Cleanse
          const apiData = {
            ...idDetails,
            addressData: {
              ...data,
              fullAddress,
              addressApiCalls: addressApiCalls + data.addressApiCalls,
              manually
            },
            ...customFields,
            location,
            countryCode: countryCode === 'OTHER' ? 'NZ' : countryCode
          };
          const reduxAddressData = {
            ...data,
            fullAddress,
            addressApiCalls: addressApiCalls + data.addressApiCalls,
            manually
          };
          // store
          this.storeData(apiData, reduxAddressData);
        } else {
          this.setState({ loading: false });
        }
        return;
      }

      Address.verify(data.homeAddress, countryCode, appConfig.dataProvider).then(
        ({ matched, addressData, provider }) => {
          const datas = {
            ...data,
            ...addressData,
            addressApiCalls: addressApiCalls + data.addressApiCalls
          };
          if (
            Address.ALLOWED_COUNTRIES.includes(countryCode) &&
            (Address.API_KEY || Address.ADDY_API_KEY)
          ) {
            const addressApiCalls = datas.addressApiCalls || 1;
            datas.addressApiCalls =
              provider === 'experian' ? addressApiCalls + 1 : 2 * (addressApiCalls + 1);
          }

          this.setState({ loading: false });

          const showManual =
            !manually &&
            ((ENABLE_MANUAL_ADDRESS && countryCode === 'OTHER') ||
              (ENABLE_MANUAL_ADDRESS && !matched));
          if (showManual || (adMode === 1 && !matched)) {
            datas.homeAddress = '';
            datas.fullAddress = '';
            datas.isMatch = matched;
            this.setState({ manually: true });
            return;
          }
          if ((!ENABLE_MANUAL_ADDRESS && !matched) || (adMode === 2 && !matched)) {
            this.setState({
              data: { ...data, homeAddress: '', fullAddress: '', isMatch: matched },
              error: {
                issue: localizedString('addressNotFound'),
                buttons: [
                  {
                    label: localizedString('cancel'),
                    onClick: () =>
                      this.setState({
                        error: null
                      })
                  }
                ]
              }
            });
            return;
          }

          // Call API to Store
          if (isValid) {
            const apiData = {
              ...idDetails,
              addressData: datas,
              ...customFields,
              location,
              countryCode: countryCode === 'OTHER' ? 'NZ' : countryCode
            };
            const reduxAddressData = datas;
            this.setState({ loading: true });
            // store
            this.storeData(apiData, reduxAddressData);
          }
        }
      );
    } else {
      // Just Submit
      const datas = {
        ...data,
        fullAddress: data.homeAddress,
        addressApiCalls: addressApiCalls + data.addressApiCalls
      };
      const apiData = {
        ...idDetails,
        addressData: datas,
        ...customFields,
        location,
        countryCode: countryCode === 'OTHER' ? 'NZ' : countryCode
      };
      this.storeData(apiData, datas);
    }
  }

  /**
   * Helper function
   * @return {Void}
   */
  storeData(data, addressData) {
    const { onGoBack, setAddress, onNextStep, on500Error } = this.props;
    this.setState({ loading: true });
    APIs.store(data)
      .then(({ status, type, msg }) => {
        this.setState({ loading: false });
        if (status !== 'success') {
          if (type === 'cards') {
            this.setState({
              error: {
                issue: msg,
                buttons: [
                  {
                    label: localizedString('cancel'),
                    onClick: () => onGoBack()
                  }
                ]
              }
            });
          } else {
            if (type === 'address') {
              this.setState({
                data: { ...data, homeAddress: '' }
              });
            }
            this.setState({
              error: {
                issue: msg,
                buttons: [
                  {
                    label: localizedString('cancel'),
                    onClick: () =>
                      this.setState({
                        error: null
                      })
                  }
                ]
              }
            });
          }
          return;
        }
        setAddress(addressData);
        onNextStep();
      })
      .catch(({ message }) => {
        console.error(message);
        const fatalError = {
          component: Error500,
          props: {
            onTryAgain: () => {
              on500Error();
            }
          }
        };
        this.setState({ fatalError, loading: false });
      });
  }

  render() {
    const { data, error, loading, manually, fatalError } = this.state;
    const { component: Error, props: errorProps } = fatalError || {};

    const { onExit, countryCode } = this.props;
    const buttons = [
      {
        label: localizedString('exit'),
        variant: 'outline',
        onClick: onExit,
        dataTestId: 'address-exit'
      },
      { label: localizedString('confirm'), type: 'submit', loading, dataTestId: 'address-confirm' }
    ];

    return (
      <div>
        {Error && <Error {...errorProps} />}
        {error && <Modal isOpen {...error} />}
        <FormValidation
          onSubmit={this.handleConfirm}
          config={FormModel}
          initialValues={data}
          key={error}
        >
          {({ errors, setField }) => (
            <Page
              title={localizedString('verifyYourAddress')}
              buttons={buttons}
              onGoBack={this.handleGoBack}
            >
              <VerifyAddressContent
                data={data}
                setField={setField}
                errors={errors}
                loading={loading}
                manually={manually}
                countryCode={countryCode}
                onChange={(data) => {
                  const nData = { ...data };
                  let customFields = {};
                  if (nData.customFields) {
                    customFields = nData.customFields;
                    this.setState({ data, customFields });
                  }
                  this.setState({ data });
                }}
              />
            </Page>
          )}
        </FormValidation>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyAddress);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ information, appConfig }) {
  return {
    addresses: information.addresses,
    idDetails: information.idDetails,
    appConfig
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    setAddress: (data) => dispatch(InfoAction.setAddress(data))
  };
}
