import React from 'react';
import PropTypes from 'prop-types';
import { Message } from '@AUVOI_FLOW/components';
import APIs from '@services/APIs';
import { localizedString } from '@languages';

const Success = ({ redirect = false }) => {
  const { DONE_BUTTON } = process.env;
  const largeShadow = { large: true, shadow: true };
  const completeBtns = [
    {
      label: localizedString('redirect'),
      ...largeShadow,
      onClick: () => APIs.return()
    }
  ];

  return (
    <Message
      completed
      title={localizedString('verificationComplete')}
      buttons={DONE_BUTTON ? completeBtns : []}
    >
      <div>Verifying your age</div>
      <br />
      {!DONE_BUTTON && !redirect && <div>{localizedString('youCanCloseThisWindow')}</div>}
      {!DONE_BUTTON && redirect && <div>{localizedString('youWillNowBeRedirected')}</div>}
      {DONE_BUTTON && <div>{localizedString('pressRedirectIfStuck10Secs')}</div>}
    </Message>
  );
};
Success.propTypes = {
  redirect: PropTypes.bool
};
export default Success;
