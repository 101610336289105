export default class Information {
  static SET_ID_INFO = 'SET_ID_INFO';

  static RESET_ID_INFO = 'RESET_ID_INFO';

  static SET_OTHER_INFO = 'SET_OTHER_INFO';

  static RESET_OTHER_INFO = 'RESET_OTHER_INFO';

  static SET_ADDRESS = 'SET_ADDRESS';

  /**
   * Return an action to update the identify details
   * @param {Object} data
   */
  static setIdInfo(data) {
    return {
      type: this.SET_ID_INFO,
      data
    };
  }

  /**
   * Return an action to reset the identify details
   * @param {Object} data
   */
  static resetIdInfo(data) {
    return {
      type: this.RESET_ID_INFO,
      data
    };
  }

  /**
   * Return an action to update the identify details
   * @param {Object} data
   */
  static setOtherIdInfo(data) {
    return {
      type: this.SET_OTHER_INFO,
      data
    };
  }

  /**
   * Return an action to reset the identify details
   * @param {Object} data
   */
  static resetOtherIdInfo(data) {
    return {
      type: this.RESET_OTHER_INFO,
      data
    };
  }

  /**
   * Return an action to update the contact details
   * @param {Object} data
   */
  static setAddress(data) {
    return {
      type: this.SET_ADDRESS,
      data
    };
  }
}
