import React from 'react';
import PropTypes from 'prop-types';
import { Page } from '@components';
import parse from 'html-react-parser';
import { localizedString } from '@languages';

import classes from './Privacy.style.module.scss';

const Privacy = ({ privacyContent, onAccept, onExit, onSelectLanguage }) => {
  const buttons = [
    {
      label: localizedString('exit'),
      variant: 'outline',
      onClick: onExit,
      dataTestId: 'privacy-exit'
    },
    { label: localizedString('IAccept'), onClick: onAccept, dataTestId: 'privacy-iAccept' }
  ];

  const { APP_NAME } = process.env;

  let privacyContentElements;
  if (!privacyContent) {
    const privacyContents = localizedString('privacyContents');
    const contents = privacyContents[APP_NAME].main || [];
    privacyContentElements = contents.map((item, index) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} data-testid={`privacy-consent-${index}`}>
          {parse(item)}
          <br />
          <br />
        </div>
      );
    });
  } else {
    privacyContentElements = parse(privacyContent);
  }

  return (
    <Page
      title={localizedString('privacyTitle')}
      isPrivacy
      buttons={buttons}
      className={classes.wrapper}
      onSelectLanguage={onSelectLanguage}
    >
      <div className={classes.content}>{privacyContentElements}</div>
    </Page>
  );
};

Privacy.propTypes = {
  onAccept: PropTypes.func,
  onExit: PropTypes.func,
  onSelectLanguage: PropTypes.func,
  privacyContent: PropTypes.any
};

export default Privacy;
