import React from 'react';
import PropTypes from 'prop-types';
import { Message } from '@DOC_ONLY_FLOW/components';
import { localizedString } from '@languages';
import classes from './SelfiesTip.style.module.scss';

const SelfiesTip = ({ onNextStep, onGoBack }) => {
  const buttons = [{ label: 'Start', large: true, shadow: true, onClick: onNextStep }];
  return (
    <Message
      title="Face verification"
      buttons={buttons}
      onGoBack={onGoBack}
      className={classes.wrapper}
    >
      <h3 className="b-title">{localizedString('weWillNeedYouToTake2Selfies')}</h3>
      {localizedString('forSecondPhotoYouWillBeAskedToPerformAFacialExpressionOrAction')}
      <img
        alt=""
        src="images/tips/alternate-flow/prompts.png"
        style={{
          width: '100%'
        }}
      />
    </Message>
  );
};

SelfiesTip.propTypes = {
  onNextStep: PropTypes.func,
  onGoBack: PropTypes.func
};

export default SelfiesTip;
