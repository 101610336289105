import React from 'react';
import PropTypes from 'prop-types';
import classes from './CaptureVisa.style.module.scss';

const CaptureVisa = ({ buttons }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.heading}>
        We have detected your Passport is not from New Zealand or Australia. So we require Visa
        information.
      </div>
      <div className={classes.description}>
        Please choose whether you have a physical visa (stuck in your passport) or an eVisa
      </div>

      <div className={classes.footer}>
        {buttons.map((button, i) => (
          <button
            type="button"
            className={i > 0 ? classes.second_button : classes.first_button}
            onClick={button.onClick}
          >
            {button.label}
          </button>
        ))}
      </div>
    </div>
  );
};

CaptureVisa.propTypes = {
  buttons: PropTypes.array
};

CaptureVisa.defaultProps = {
  buttons: []
};

export default CaptureVisa;
