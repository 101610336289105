import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Page from '@lib/components/v2/Page';
import classNames from 'classnames';
import { localizedString } from '@languages';
import Animation from '@components/Animation';
import APIs from '@services/APIs';
import { LoadingBar } from '../../components';
import classes from './CaptureExtra.style.module.scss';
import { Error500 } from '../../errors';

let animationReview;
import('@VOI_FLOW_V2/animations/anim_data.json').then((value) => {
  animationReview = value;
});

class CaptureExtra extends Component {
  static propTypes = {
    onNextStep: PropTypes.func,
    flowType: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.isComponentMounted = false;

    this.state = {
      optionList: [
        {
          type: 'YES',
          title: 'Yes',
          checked: true
        },
        {
          type: 'NO',
          title: 'No',
          checked: false
        }
      ],
      progressBar: 0,
      isUploading: false,
      isCaptured: false,
      error: null,
      extraCaptureAttempt: 0
    };

    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleCapture = this.handleCapture.bind(this);
  }

  componentDidMount() {
    this.isComponentMounted = true;
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  handleCapture(e) {
    if (!e.target.files[0]) {
      return;
    }
    const imagefile = e.target.files[0];
    e.target.value = '';

    this.uploadImage(imagefile);
    /* // test code
        this.setState({ isUploading: true, progressBar: 100 })
        setTimeout(() => {
            this.setState({ isUploading: false, progressBar: 0, isCaptured: true })
        }, 3000) */
  }

  handleNextStep(e) {
    if (e) {
      e.preventDefault();
    }

    const { onNextStep } = this.props;
    const { optionList } = this.state;

    const selectedItem = optionList.find((item) => item.checked);

    if (selectedItem.type === 'NO') {
      onNextStep();
    } else {
      this.element.click();
    }
  }

  onOptionClick(item, index) {
    if (item.checked) {
      return;
    }

    const { optionList } = this.state;
    for (let i = 0; i < optionList.length; i++) {
      optionList[i].checked = index === i;
    }
    this.setState({ optionList });
  }

  uploadImage(imagefile) {
    APIs.status('additionalInfo');
    const { flowType } = this.props;

    const params = { frontFile: imagefile };
    params.flowType = flowType;
    params.ocr = false;
    params.idType = 'Additional Documents';

    this.setState({ isUploading: true, progressBar: 0 });
    APIs.uploadImage(
      params,
      {
        before: () => this.setState({ progressBar: 0 }),
        onProgress: (width) => this.setState({ progressBar: width })
      },
      '/api/v4'
    )
      .then(({ status, msg: error }) => {
        const { isUploading, extraCaptureAttempt } = this.state;
        if (!isUploading) {
          return;
        }

        if (status === 'error') {
          if (this.isComponentMounted) {
            this.setState({
              error,
              isUploading: false
            });
          }
          return;
        }

        this.setState({
          isUploading: false,
          isCaptured: true,
          extraCaptureAttempt: extraCaptureAttempt + 1
        });
      })
      .catch(() => {
        const error = {
          component: Error500,
          props: {
            onTryAgain: () => {
              this.setState({ error: null, isUploading: false, progressBar: 0 });
            }
          }
        };

        this.setState({ error });
      });
  }

  /**
   * Render the component's markup
   * @return {ReactElement}
   */
  render() {
    const {
      VOI_FLOW_V2_CAPTURE_EXTRA_HEADING = 'Do you need to capture extra documents?',
      VOI_FLOW_V2_CAPTURE_EXTRA_DESCRIPTION = 'We allow up to 10 additional documents to be added to your verification.',
      VOI_FLOW_V2_UPLOADING_DETAILS_HEADING = 'Uploading your document',
      VOI_FLOW_V2_UPLOADING_DETAILS_DESCRIPTION = ''
    } = process.env;

    const { onNextStep } = this.props;
    const { optionList, progressBar, isUploading, isCaptured, error, extraCaptureAttempt } =
      this.state;
    const { component: Error, props: errorProps } = error || {};

    let footerButtons = [];
    if (isUploading) {
      footerButtons = [
        {
          label: localizedString('back'),
          variant: 'transparent',
          onClick: () => {
            this.setState({ isUploading: false });
          }
        },
        {
          label: 'Uploading',
          variant: 'transparent',
          loading: true
        }
      ];
    } else if (isCaptured) {
      footerButtons = [
        {
          label: localizedString('finish'),
          type: 'submit',
          onClick: onNextStep,
          dataTestId: 'docCapt-finish'
        }
      ];
    } else {
      footerButtons = [
        {
          label: localizedString(optionList[0].checked ? 'captureDocuments' : 'finish'),
          type: 'submit',
          onClick: this.handleNextStep,
          dataTestId: 'docs-capture-button'
        }
      ];
    }

    return (
      <div>
        <input
          type="file"
          name="image"
          accept="image/*"
          capture="environment"
          onChange={this.handleCapture}
          ref={(ref) => {
            this.element = ref;
          }}
          style={{ opacity: 0, zIindex: 99 }}
        />
        {Error && <Error {...errorProps} />}
        {!Error && (
          <Page buttons={footerButtons} forceFillViewPort={isUploading || isCaptured}>
            {!isUploading && !isCaptured && (
              <div className={classes.wrapper}>
                <div className={classes.heading}>{VOI_FLOW_V2_CAPTURE_EXTRA_HEADING}</div>
                <div className={classes.description}>{VOI_FLOW_V2_CAPTURE_EXTRA_DESCRIPTION}</div>
                <div className={classes.option_list}>
                  {optionList.map((item, index) => {
                    const iconName = `images/icons/svg/${
                      item.checked ? 'icon_radio_checked.svg' : 'icon_radio_unchecked.svg'
                    }`;

                    return (
                      <div
                        key={item.title}
                        data-testid={`docs-${item.title.toLowerCase()}`}
                        className={classNames(classes.option_item, item.checked && classes.checked)}
                        onClick={() => this.onOptionClick(item, index)}
                      >
                        <div className={classes.check_mark_wrapper}>
                          <img src={iconName} alt="" />
                        </div>
                        <div className={classes.option_title}>{item.title}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {isUploading && !isCaptured && (
              <div className={classNames(classes.wrapper, classes.fill_view_port)}>
                <div className={classes.heading}>{VOI_FLOW_V2_UPLOADING_DETAILS_HEADING}</div>
                <div className={classes.description}>
                  {VOI_FLOW_V2_UPLOADING_DETAILS_DESCRIPTION}
                </div>
                <div className={classes['animation-container']}>
                  <Animation animationData={animationReview} />
                </div>
                <LoadingBar width={progressBar} />
              </div>
            )}

            {isCaptured && !isUploading && (
              <div className={classNames(classes.wrapper, classes.fill_view_port)}>
                <div className={classes.captured_content} data-testid="docCapt-txt">
                  <img alt="" src="images/icons/svg/icon_document.svg" data-testid="docCapt-img" />
                  Document Captured
                </div>
                {extraCaptureAttempt < 10 && (
                  <button
                    type="button"
                    className={classes.capture_another}
                    onClick={() => this.element.click()}
                    data-testid="docCapt-anotherDoc"
                  >
                    Capture another document
                  </button>
                )}
              </div>
            )}
          </Page>
        )}
      </div>
    );
  }
}

export default CaptureExtra;
