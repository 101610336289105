import { getLanguage } from '@languages';

const { APP_NAME } = process.env;
const isCTM = APP_NAME === 'Compare The Market';
const voicePromptsParams = [
  {
    default: true,
    language: 'en',
    ogg: `media/audio/${isCTM ? 'prompts-ctm' : 'prompts'}.ogg`,
    mp3: `media/audio/${isCTM ? 'prompts-ctm' : 'prompts'}.mp3`,
    durations: {
      position: isCTM ? { from: 0, time: 2.5 } : { from: 0, time: 5 },
      smile: isCTM ? { from: 4, time: 2 } : { from: 5, time: 2 },
      biggerSmile: isCTM ? { from: 7, time: 2 } : { from: 5, time: 2 },
      left: isCTM ? { from: 12, time: 2.5 } : { from: 7, time: 2.7 },
      right: isCTM ? { from: 16, time: 2.5 } : { from: 9.5, time: 2.7 },
      leftFurther: isCTM ? { from: 20, time: 2.5 } : { from: 12, time: 3.5 },
      rightFurther: isCTM ? { from: 24, time: 2.5 } : { from: 15, time: 3.5 },
      thanks: isCTM ? { from: 10, time: 1 } : { from: 18, time: 2 }
    }
  },
  {
    default: true,
    language: 'uk',
    ogg: 'media/audio/English_UK.ogg',
    mp3: 'media/audio/English_UK.mp3',
    durations: {
      position: { from: 0, time: 5.8 },
      smile: { from: 5.8, time: 2.5 },
      left: { from: 8.3, time: 3.5 },
      right: { from: 11.8, time: 3.7 },
      leftFurther: { from: 15.5, time: 3.2 },
      rightFurther: { from: 18.7, time: 3.5 },
      thanks: { from: 22.2, time: 3 }
    }
  },
  {
    language: 'zh',
    ogg: 'media/audio/chinese.ogg',
    mp3: 'media/audio/chinese.mp3',
    durations: {
      position: { from: 0, time: 4.5 },
      smile: { from: 4.5, time: 3 },
      left: { from: 7.5, time: 3 },
      right: { from: 10.5, time: 2.5 },
      leftFurther: { from: 13, time: 2 },
      rightFurther: { from: 15, time: 2 },
      thanks: { from: 17.5, time: 2 }
    }
  },
  {
    language: 'tr',
    ogg: 'media/audio/turkish.ogg',
    mp3: 'media/audio/turkish.mp3',
    durations: {
      position: { from: 0, time: 4.7 },
      smile: { from: 4.7, time: 2.2 },
      left: { from: 6.9, time: 3.3 },
      right: { from: 10.2, time: 3.5 },
      leftFurther: { from: 13, time: 3.9 },
      rightFurther: { from: 16.9, time: 3.9 },
      thanks: { from: 20.8, time: 2.5 }
    }
  },
  {
    language: 'kk',
    ogg: 'media/audio/kazakh.ogg',
    mp3: 'media/audio/kazakh.mp3',
    durations: {
      position: { from: 0, time: 2.75 },
      smile: { from: 2.75, time: 1.75 },
      left: { from: 4.5, time: 2.6 },
      right: { from: 7.1, time: 2.6 },
      leftFurther: { from: 9.8, time: 2.6 },
      rightFurther: { from: 12.4, time: 2.7 },
      thanks: { from: 15.1, time: 2 }
    }
  },
  {
    language: 'ru',
    ogg: 'media/audio/russian.ogg',
    mp3: 'media/audio/russian.mp3',
    durations: {
      position: { from: 0, time: 3.7 },
      smile: { from: 3.7, time: 2.4 },
      left: { from: 6.1, time: 3.5 },
      right: { from: 9.6, time: 3.6 },
      leftFurther: { from: 13.2, time: 3.5 },
      rightFurther: { from: 16.7, time: 3.6 },
      thanks: { from: 20.3, time: 2 }
    }
  },
  {
    language: 'ar',
    ogg: 'media/audio/arabic.ogg',
    mp3: 'media/audio/arabic.mp3',
    durations: {
      position: { from: 0, time: 3.1 },
      smile: { from: 3.1, time: 2.9 },
      left: { from: 6, time: 3.1 },
      right: { from: 9.1, time: 3.1 },
      leftFurther: { from: 12.2, time: 3.1 },
      rightFurther: { from: 15.3, time: 3.1 },
      thanks: { from: 18.4, time: 3 }
    }
  },
  {
    language: 'hi',
    ogg: 'media/audio/hindi.ogg',
    mp3: 'media/audio/hindi.mp3',
    durations: {
      position: { from: 0, time: 4.7 },
      smile: { from: 4.7, time: 2.7 },
      left: { from: 7.4, time: 3.5 },
      right: { from: 10.9, time: 3.5 },
      leftFurther: { from: 14.4, time: 4 },
      rightFurther: { from: 18.4, time: 3.9 },
      thanks: { from: 22.3, time: 3 }
    }
  }
];

export const getVoicePromptInfo = () => {
  const language = getLanguage();

  let promptInfo = voicePromptsParams.find((item) => item.language === language);
  if (!promptInfo) {
    promptInfo = voicePromptsParams.find((item) => item.default);
  }

  return promptInfo;
};
