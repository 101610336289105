import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InfoAction from '@js/store/actions/information';
import CaptureAction from '@js/store/actions/capture';

import { Page, LoadingBar, Tips, CaptureVisa } from '@DOC_ONLY_FLOW/components';

import { geoLocation, getCookie, setCookie, isShownPrivacy } from '@js/lib/Utils';
import APIs from '@js/services/APIs';
import { BackOfCard } from '@DOC_ONLY_FLOW/components/Contents';
import { localizedString } from '@languages';
import CaptureTips from './Capture.tips';
import { EnableLocation, LocationSettings } from './Capture.error';
import { Error500 } from '../../errors';

class Capture extends Component {
  static propTypes = {
    idType: PropTypes.string,
    backOfCard: PropTypes.bool,
    onNextStep: PropTypes.func,
    onGoBack: PropTypes.func,
    onExit: PropTypes.func,
    resetIdInfo: PropTypes.func,
    country: PropTypes.string,
    nextCapture: PropTypes.string,
    frontTokenId: PropTypes.string,
    frontParams: PropTypes.object,
    setFrontIDParams: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.element = null;
    this.isComponentMounted = false;

    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleCapture = this.handleCapture.bind(this);
  }

  /**
   * Return the component's initial state
   * @return {Object}
   */
  getInitialState() {
    return {
      progressBar: 0,
      backOfCard: false,
      isUploading: false,
      error: null,
      frontFile: null,
      geolocation: null,
      showCaptureVisa: false
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { ENABLE_VISA_AFTER_PASSPORT } = process.env;
    const { frontParams, nextCapture } = this.props;

    if (frontParams.idType && (nextCapture === 'back' || nextCapture === 'backOnlyCrop')) {
      this.setState({
        backOfCard: true
      });
    } else if (frontParams.idType && ENABLE_VISA_AFTER_PASSPORT && nextCapture === 'visa') {
      this.setState({
        showCaptureVisa: true
      });
    }
  }

  componentDidMount() {
    APIs.status('capturingId');
    const { resetIdInfo, nextCapture, onExit } = this.props;
    this.isComponentMounted = true;
    if (nextCapture !== 'backOnlyCrop') {
      resetIdInfo();
    }

    if (process.env.LOCATION)
      geoLocation()
        .then((geolocation) => this.setState({ geolocation }))
        .catch(() => {
          if (!process.env.LOCATION_REQUIRED) {
            this.setState({ geolocation: null });
            return;
          }
          const error = {
            component: EnableLocation,
            props: {
              buttons: [
                {
                  label: localizedString('exit'),
                  variant: 'outline',
                  onClick: () => onExit()
                },
                {
                  label: localizedString('enable'),
                  onClick: () => {
                    const error = {
                      component: LocationSettings,
                      props: {
                        buttons: [
                          {
                            label: localizedString('exit'),
                            variant: 'outline',
                            onClick: () => onExit()
                          },
                          {
                            label: localizedString('checkAgainLabel'),
                            onClick: () => {
                              geoLocation()
                                .then((geolocation) =>
                                  this.setState({
                                    geolocation,
                                    error: null
                                  })
                                )
                                .catch(() => null);
                            }
                          }
                        ]
                      }
                    };
                    this.setState({ error });
                  }
                }
              ]
            }
          };
          this.setState({ error });
        });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  /**
   * Go to the next step
   * @return {Void}
   */
  handleNextStep() {
    this.element.click();
  }

  /**
   * Go back to the previous step
   * @param {ClickEvent} e
   * @return {Void}
   */
  handleGoBack(e) {
    e.preventDefault();
    const { step } = this.state;
    const { onGoBack } = this.props;
    if (step) {
      this.setState(({ step }) => ({ step: step - 1 }));
    } else {
      const { backurl } = document.body.dataset;
      if (backurl && !isShownPrivacy('DOC_ONLY')) {
        window.location.href = backurl;
      } else {
        onGoBack();
      }
    }
  }

  /**
   * When user happy with photo.
   * @param {ChangeEvent} e
   */
  handleCapture(e) {
    if (!e.target.files[0]) {
      return;
    }

    const imagefile = e.target.files[0];
    e.target.value = '';

    this.uploadImage(imagefile);
  }

  /**
   * Go to the next step on Visa Upload
   * @return {Void}
   */
  handleSkip = () => {
    const { geolocation } = this.state;
    this.props.onNextStep({ geolocation });
    this.props.setFrontIDParams({});
    this.setState({
      showCaptureVisa: false
    });
  };

  /** When user uploads visa after Passport.
   * @param {ChangeEvent} e
   */

  handleCaptureVisa = (e) => {
    if (!e.target.files[0]) {
      return;
    }

    const imagefile = e.target.files[0];
    e.target.value = '';

    this.uploadVisaImage(imagefile);
  };

  /**
   * Upload image to server
   */
  uploadImage(imagefile) {
    const {
      idType,
      country,
      backOfCard,
      frontParams,
      nextCapture,
      setFrontIDParams,
      onNextStep,
      onGoBack
    } = this.props;
    const { frontFile, geolocation, backOfCard: backOfCardState } = this.state;

    let params;

    let idCaptureAttempt = parseInt(getCookie('idCaptureAttempt'), 10);
    idCaptureAttempt = idCaptureAttempt ? idCaptureAttempt + 1 : 1;
    setCookie('idCaptureAttempt', idCaptureAttempt.toString(), 1);

    if (!frontFile && backOfCard) {
      this.setState({ backOfCard: true, frontFile: imagefile });
      return;
    }

    const nCountry = country === 'OTHER' ? 'NZ' : country;

    if (backOfCardState && nCountry === 'AU') {
      params = { ...frontParams, backFile: imagefile };
      if (nextCapture === 'backOnlyCrop') {
        params = { idType, backFile: imagefile, backOnlyCrop: nextCapture === 'backOnlyCrop' };
      }

      setFrontIDParams({});
    } else {
      if (idType !== 'PASSPORT' && backOfCard) {
        params = { idType, frontFile, backFile: imagefile };
      } else {
        params = { idType, frontFile: imagefile };
      }

      if (nCountry === 'AU' || idType === 'PASSPORT') {
        setFrontIDParams(params);
      }
    }

    this.setState({ isUploading: true, backOfCard: false });
    APIs.uploadImage(params, {
      before: () => this.setState({ progressBar: 0 }),
      onProgress: (width) => this.setState({ progressBar: width })
    })
      .then(({ status, token, msg: error }) => {
        if (status === 'error') {
          if (this.isComponentMounted) {
            this.setState({
              error,
              isUploading: false
            });
          }
          return;
        }

        onNextStep({ tokenId: token, geolocation });
      })
      .catch((err) => {
        console.error(err);
        const error = {
          component: Error500,
          props: {
            onTryAgain: onGoBack
          }
        };

        setFrontIDParams({});
        this.setState({ error, isUploading: false, backOfCard: false });
      });
  }

  uploadVisaImage(imagefile) {
    const { setFrontIDParams, onNextStep, onGoBack } = this.props;
    const { geolocation } = this.state;

    const params = { idType: 'PASSPORT', frontFile: imagefile };

    this.setState({ isUploading: true, showCaptureVisa: false, progressBar: 0 });

    APIs.uploadVisa(params, {
      before: () => this.setState({ progressBar: 0 }),
      onProgress: (width) => {
        this.setState({ progressBar: width });
      }
    })
      .then(({ status, msg: error, skip = false }) => {
        if (status === 'error') {
          this.setState({
            error,
            isUploading: false
          });
          if (skip) {
            setFrontIDParams({});
            onNextStep({ geolocation });
          }
          return;
        }

        this.setState({
          isUploading: false
        });

        setFrontIDParams({});
        onNextStep({ geolocation });
      })
      .catch(() => {
        const error = {
          component: Error500,
          props: {
            onTryAgain: () => {
              setFrontIDParams({});
              onGoBack();
            }
          }
        };

        this.setState({ error, isUploading: false });
      });
  }

  gotoCaptureBackofCard() {
    this.element.click();
  }

  render() {
    const { progressBar, backOfCard, isUploading, error, showCaptureVisa } = this.state;
    const { component: Error, props: errorProps } = error || {};
    const { idType, country } = this.props;
    const tips = CaptureTips(idType);

    // let nCountry = (country === 'OTHER') ? 'NZ' : country

    const tipsStates = {
      idType,
      tips,
      country,
      onCapture: this.handleNextStep,
      lastButtonText: localizedString('captureID')
    };

    const captureVisaProps = {
      onSkip: this.handleSkip,
      onUploadVisa: this.handleNextStep
    };

    const { backurl } = document.body.dataset;

    return (
      <Page
        onGoBack={backurl || isShownPrivacy('DOC_ONLY') ? this.handleGoBack : null}
        title={localizedString('howToCaptureYourID')}
      >
        {Error && <Error {...errorProps} />}
        {isUploading && <LoadingBar title={localizedString('uploading')} width={progressBar} />}
        {backOfCard && <BackOfCard idType={idType} onReady={() => this.gotoCaptureBackofCard()} />}
        {!showCaptureVisa && <Tips {...tipsStates} />}
        {showCaptureVisa && <CaptureVisa {...captureVisaProps} />}

        <input
          type="file"
          name="image"
          accept="image/*"
          capture="environment"
          onChange={showCaptureVisa ? this.handleCaptureVisa : this.handleCapture}
          ref={(ref) => {
            this.element = ref;
          }}
          style={{ opacity: 0, zIindex: 99 }}
        />
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Capture);

function mapStateToProps({ capture }) {
  return {
    frontParams: capture.frontParams
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    resetIdInfo: () => dispatch(InfoAction.resetIdInfo()),
    setFrontIDParams: (data) => dispatch(CaptureAction.setFrontIDParams(data))
  };
}
