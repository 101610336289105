import React from 'react';
import PropTypes from 'prop-types';
import { Page } from '@DOC_ONLY_FLOW/components';
import { localizedString } from '@languages';
import classes from './Prepare.style.module.scss';

const Prepare = ({ onNextStep, onGoBack }) => {
  const buttons = [
    {
      label: localizedString('next'),
      large: true,
      shadow: true,
      onClick: () => onNextStep()
    }
  ];
  return (
    <Page buttons={buttons} onGoBack={onGoBack} title="Face verification">
      <div className={classes.wrapper}>
        <div className="b-title">{localizedString('goingToPerformFaceVerify')}</div>
        <div className={classes.dontHave}>
          <div>{localizedString('dontHaveAnythingCoveringFacePart1')}</div>
          <img alt="" src="images/tips/facescan/no-hat-classes.png" />
          <span>{localizedString('dontHaveAnythingCoveringFacePart2')}</span>
        </div>
      </div>
    </Page>
  );
};

Prepare.propTypes = {
  onNextStep: PropTypes.func,
  onGoBack: PropTypes.func
};

Prepare.defaultProps = {
  onNextStep: () => {}
};

export default Prepare;
