import React from 'react';
import PropTypes from 'prop-types';
import { Message } from '@components';
import parse from 'html-react-parser';
import APIs from '@services/APIs';
import { localizedString } from '@languages';

const Success = ({ successMessage, redirect = false }) => {
  const { DONE_BUTTON } = process.env;
  const largeShadow = { large: true, shadow: true };
  const completeBtns = [
    {
      label: localizedString('redirect'),
      ...largeShadow,
      onClick: () => APIs.return()
    }
  ];

  let successMessageContent = (
    <h5 className="b-title" data-testid="cmplt-instruction">
      <b>Verifying your age</b>
    </h5>
  );

  if (successMessage) {
    successMessageContent = parse(successMessage);
  }

  return (
    <Message
      completed
      title={localizedString('captureProcessComplete')}
      buttons={DONE_BUTTON ? completeBtns : []}
    >
      {successMessageContent}
      <br />
      {!DONE_BUTTON && !redirect && (
        <div data-testid="cmplt-txt">{localizedString('youCanCloseThisWindow')}</div>
      )}
      {!DONE_BUTTON && redirect && <div>{localizedString('youWillNowBeRedirected')}</div>}
      {DONE_BUTTON && <div>{localizedString('pressRedirectIfStuck10Secs')}</div>}
    </Message>
  );
};
Success.propTypes = {
  successMessage: PropTypes.any,
  redirect: PropTypes.bool
};
export default Success;
