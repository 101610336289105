import { getVoicePromptInfo } from '@lib/Audio';

export default class ViewAudio {
  constructor() {
    this.audioOut = null;
  }

  /**
   * Play a specific audio for prompts.
   */
  static play(audio, prompt, muted = false, repeat = false) {
    let promptKey = prompt;
    if (repeat) {
      if (prompt === 'left') {
        promptKey = 'leftFurther';
      } else if (prompt === 'right') {
        promptKey = 'rightFurther';
      } else if (prompt === 'smile') {
        promptKey = 'biggerSmile';
      }
    }

    const { from, time } = this.getDuration(promptKey);

    if (audio) {
      // eslint-disable-next-line no-param-reassign
      audio.muted = muted;
      // eslint-disable-next-line no-param-reassign
      audio.currentTime = from;
      audio.play();

      if (this.audioOut) {
        clearTimeout(this.audioOut);
      }

      this.audioOut = setTimeout(() => {
        audio.pause();
        this.audioOut = null;
      }, time * 1000);
    }
  }

  static getDuration(prompt) {
    const { durations } = getVoicePromptInfo();
    return durations[prompt];
  }
}
