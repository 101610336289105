import React from 'react';
import PropTypes from 'prop-types';

import { localizedString } from '@languages';
import classes from './LoadingBar.style.module.scss';

const LoadingBar = ({ heading, width }) => {
  return (
    <div className={classes.wrapper}>
      <div>
        <div className={classes.spinner1} />
        <div className={classes.heading}>{heading}</div>
        <div className={classes.title}>
          {localizedString('progress')}: {width} %
          <br />
          {localizedString('thisMayTakeAFewSeconds')}
        </div>
      </div>
    </div>
  );
};

LoadingBar.propTypes = {
  heading: PropTypes.string,
  width: PropTypes.number
};

LoadingBar.defaultProps = {
  heading: localizedString('loading')
};

export default LoadingBar;
