import React from 'react';
import PropTypes from 'prop-types';
import classes from './LoadingBar.style.module.scss';

const LoadingBar = ({ width }) => {
  return (
    <div className={classes.progress} data-testid="id-statusBar">
      <div className={classes.bar} style={{ width: `${width}%` }} />
    </div>
  );
};

LoadingBar.propTypes = {
  width: PropTypes.number
};

LoadingBar.defaultProps = {};

export default LoadingBar;
