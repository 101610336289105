import { DOCUMENTS } from '@lib/constants/documents';

export const isCardNumberFieldVisible = ({
  idType = '',
  countryOfIssue = '',
  skipDigitalDriverLicence = false
}) => {
  if (!idType.match(/LICENCE/i) && !idType.match(/LICENSE/i)) {
    return false;
  }

  if (DOCUMENTS.DIGITAL_DRIVER_LICENCE.cardType === idType && skipDigitalDriverLicence) {
    return false;
  }

  return countryOfIssue.toUpperCase() === 'AUSTRALIA';
};
