import React, { Component } from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';
import { Button } from '@components';
import classNames from 'classnames';
import { localizedString } from '@languages';
import classes from './Animation.style.module.scss';
import { animationData } from './AnimationData';

export default class Animation extends Component {
  static propTypes = {
    onStart: PropTypes.func,
    isLoading: PropTypes.bool
  };

  static defaultProps = {
    onStart: () => {},
    isLoading: false
  };

  constructor(props) {
    super(props);

    this.state = {
      isCompleted: false
    };

    this.container = null;
  }

  /**
   * Start an animation
   * @return {Void}
   */
  componentDidMount() {
    if (this.container) {
      const animation = lottie.loadAnimation({
        container: this.container,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData
      });
      animation.addEventListener('enterFrame', ({ currentTime }) => {
        const { isCompleted } = this.state;
        if (currentTime >= 220 && !isCompleted) {
          this.setState({ isCompleted: true });
        }
      });
    }

    // fix SVG mobile orientation issue
    window.addEventListener('resize', () => {
      if (this.container) this.container.querySelector('svg').setAttribute('x', 0);
    });
  }

  render() {
    const { isCompleted } = this.state;
    const { onStart, isLoading } = this.props;
    return (
      <div className={classes.wrapper}>
        <div
          className={classes.animation}
          ref={(ref) => {
            this.container = ref;
          }}
          data-testid="liveness-anima"
        />
        <Button
          onClick={() => onStart()}
          loading={isLoading}
          disabled={!isCompleted}
          large
          className={classNames(classes.button, isCompleted && classes.open)}
          dataTestId="liveness-btn"
        >
          {localizedString('startNow')}
        </Button>
      </div>
    );
  }
}
