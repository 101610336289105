import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import information from './reducers/information';
import capture from './reducers/capture';
import appConfig from './reducers/appConfig';
import voiFlowV2 from './reducers/voiFlowV2';
import edited from './reducers/edited';

const reducer = combineReducers({ information, capture, appConfig, voiFlowV2, edited });

let composeEnhancers = compose;
if (process.env.NODE_ENV !== 'production') {
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      serialize: true
    });
  }
}

export default createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
