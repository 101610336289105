import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InfoAction from '@store/actions/information';
import CaptureAction from '@store/actions/capture';

import { getCookie, setCookie, isShownPrivacy } from '@lib/Utils';
import APIs from '@services/APIs';
import { localizedString } from '@languages';
import { Page, Tips, LoadingSpinner, Modal, CaptureVisa } from '../../components';
import { IdSelectionContent, BackOfCard } from '../../components/Contents';

import { Error500 } from '../../errors';
import classes from '../../components/Contents/IdSelection/IdSelection.style.module.scss';

class Capture extends Component {
  static propTypes = {
    onNextStep: PropTypes.func,
    onGoBack: PropTypes.func,
    onExit: PropTypes.func,
    country: PropTypes.string,
    nextCapture: PropTypes.string,
    frontParams: PropTypes.object,
    resetIdInfo: PropTypes.func,
    setFrontIDParams: PropTypes.func,
    onSelectLanguage: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.element = null;

    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleCapture = this.handleCapture.bind(this);
  }

  /**
   * Return the component's initial state
   * @return {Object}
   */
  getInitialState() {
    return {
      idType: 'AUS_AUTO_DRIVERLICENCE',
      backOfCard: false,
      isUploading: false,
      error: null,
      frontFile: null,
      geolocation: null,
      showCaptureVisa: false,
      showInvalidCardsModal: false
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { ENABLE_VISA_AFTER_PASSPORT } = process.env;
    const { frontParams, nextCapture } = this.props;

    if (frontParams.idType && nextCapture === 'back') {
      this.setState({
        backOfCard: true
      });
    } else if (frontParams.idType && ENABLE_VISA_AFTER_PASSPORT && nextCapture === 'visa') {
      this.setState({
        showCaptureVisa: true
      });
    }
  }

  componentDidMount() {
    const { resetIdInfo } = this.props;
    resetIdInfo();
    APIs.status('selectId');
  }

  /**
   * Go to handle Select
   * @return {Void}
   */
  handleSelectIdType = (data) => {
    const { idType, backOfCard } = data;
    this.setState({ idType, backOfCard });
  };

  /**
   * Go to the next step
   *
   */
  handleNextStep() {
    const { idType } = this.state;
    if (!idType) return;

    this.element.click();
  }

  /**
   * Go back to the previous step
   * @param {ClickEvent} e
   * @return {Void}
   */
  handleGoBack(e) {
    e.preventDefault();
    const { onGoBack } = this.props;
    const { step } = this.state;
    if (step) {
      this.setState(({ step }) => ({ step: step - 1 }));
    } else {
      const { backurl } = document.body.dataset;
      if (backurl && !isShownPrivacy('SHORT_FLOW')) {
        window.location.href = backurl;
      } else {
        onGoBack();
      }
    }
  }

  /**
   * When user happy with photo.
   * @param {ChangeEvent} e
   */
  handleCapture(e) {
    if (!e.target.files[0]) {
      return;
    }
    const imagefile = e.target.files[0];
    e.target.value = '';

    this.uploadImage(imagefile);
  }

  /**
   * Go to the next step on Visa Upload
   * @return {Void}
   */
  handleSkip = () => {
    const { setFrontIDParams } = this.props;
    const { geolocation, idType, backOfCard } = this.state;
    this.props.onNextStep({ geolocation, idType, backOfCard });
    setFrontIDParams({});
    this.setState({
      showCaptureVisa: false
    });
  };

  /** When user uploads visa after Passport.
   * @param {ChangeEvent} e
   */

  handleCaptureVisa = (e) => {
    if (!e.target.files[0]) {
      return;
    }
    const imagefile = e.target.files[0];
    e.target.value = '';

    this.uploadVisaImage(imagefile);
  };

  onClickedFooterTakePhoto() {
    const { backOfCard } = this.state;
    if (backOfCard) {
      this.element.click();
    } else {
      this.handleNextStep();
    }
  }

  onClickedCaptureBackCard() {
    this.element.click();
  }

  openInvalidCardsModal = (e) => {
    e.preventDefault();
    this.setState({
      showInvalidCardsModal: true
    });
  };

  uploadImage(imagefile) {
    APIs.status('capturingId');
    let params;

    const { country, frontParams, setFrontIDParams, onNextStep, onGoBack } = this.props;
    const { frontFile, geolocation, idType, backOfCard } = this.state;

    let idCaptureAttempt = parseInt(getCookie('idCaptureAttempt'), 10);
    idCaptureAttempt = idCaptureAttempt ? idCaptureAttempt + 1 : 1;
    setCookie('idCaptureAttempt', idCaptureAttempt.toString(), 1);

    const nCountry = country === 'OTHER' ? 'NZ' : country;

    if (backOfCard && nCountry === 'AU') {
      params = { ...frontParams, backFile: imagefile };
      setFrontIDParams({});
    } else {
      if (idType !== 'PASSPORT' && backOfCard) {
        params = { idType, frontFile, backFile: imagefile };
      } else {
        params = { idType, frontFile: imagefile };
      }

      if (nCountry === 'AU' || idType === 'PASSPORT') {
        setFrontIDParams(params);
      }
    }

    this.setState({ isUploading: true, backOfCard: false });
    APIs.uploadImage(params, {})
      .then(({ status, token, msg: error }) => {
        if (status === 'error') {
          this.setState({
            error,
            isUploading: false
          });

          return;
        }

        onNextStep({ tokenId: token, geolocation, idType, backOfCard });
      })
      .catch(() => {
        const error = {
          component: Error500,
          props: {
            onTryAgain: onGoBack
          }
        };
        setFrontIDParams({});
        this.setState({ error, isUploading: false, backOfCard: false });
      });
  }

  uploadVisaImage(imagefile) {
    APIs.status('capturingId');
    const { setFrontIDParams, onNextStep, onGoBack } = this.props;
    const { geolocation } = this.state;
    const params = { idType: 'PASSPORT', frontFile: imagefile };

    this.setState({ isUploading: true, showCaptureVisa: false });

    APIs.uploadVisa(params, {})
      .then(({ status, msg: error, skip = false }) => {
        if (status === 'error') {
          this.setState({
            error,
            isUploading: false
          });
          if (skip) {
            setFrontIDParams({});
            onNextStep({ geolocation });
          }
          return;
        }

        this.setState({
          isUploading: false
        });

        setFrontIDParams({});
        onNextStep({ geolocation });
      })
      .catch(() => {
        const error = {
          component: Error500,
          props: {
            onTryAgain: () => {
              setFrontIDParams({});
              onGoBack();
            }
          }
        };
        this.setState({ error, isUploading: false });
      });
  }

  renderInvalidCardsModal = () => {
    const { showInvalidCardsModal } = this.state;
    const { exiturl } = document.body.dataset;

    const confirmBtns = [
      {
        label: localizedString('cancel'),
        onClick: () => {
          this.setState({ showInvalidCardsModal: false });
          // APIs.cancel();
        },
        variant: 'transparent'
      },
      {
        label: localizedString('continue'),
        onClick: () => {
          window.location.href = exiturl;
        }
      }
    ];

    return (
      <Modal
        isOpen={showInvalidCardsModal}
        heading={localizedString('noDocumentNow')}
        description=""
        buttons={confirmBtns}
        small={false}
      >
        {localizedString('verifyYourIdentityUsingPhotoID1')}
        <br />
        <br />
        {localizedString('verifyYourIdentityUsingPhotoID2')}
      </Modal>
    );
  };

  render() {
    const { backurl, exiturl } = document.body.dataset;
    const { backOfCard, isUploading, error, showCaptureVisa, idType } = this.state;
    const { component: Error, props: errorProps } = error || {};
    const { country, onSelectLanguage } = this.props;
    const tips = {
      DL: localizedString('shortCaptureTipsDL'),
      PASSPORT: localizedString('shortCaptureTipsPassport')
    };

    const tipsStates = {
      idType,
      tips,
      onCapture: this.handleNextStep,
      lastButtonText: localizedString('captureID')
    };

    const captureVisaProps = {
      onSkip: this.handleSkip,
      onUploadVisa: this.handleNextStep
    };

    const footerButtons = [];
    if (backurl || isShownPrivacy('SHORT_FLOW')) {
      footerButtons.push({
        label: localizedString('back'),
        variant: 'transparent',
        onClick: this.handleGoBack
      });
    }
    footerButtons.push({
      label: localizedString('takePhoto'),
      type: 'submit',
      onClick: () => this.onClickedFooterTakePhoto()
    });

    return (
      <Page
        // isDark={backOfCard}
        buttons={footerButtons}
        onSelectLanguage={onSelectLanguage}
      >
        {Error && <Error {...errorProps} />}
        {isUploading && <LoadingSpinner heading={localizedString('viewingYourID')} showTitle />}
        {showCaptureVisa && <CaptureVisa {...captureVisaProps} />}
        {backOfCard && (
          <BackOfCard idType={idType} onReady={() => this.onClickedCaptureBackCard()} />
        )}
        {!Error && !isUploading && !backOfCard && !showCaptureVisa && (
          <IdSelectionContent
            idType={idType}
            onSelect={this.handleSelectIdType}
            country={country}
          />
        )}
        {!showCaptureVisa && !backOfCard && <Tips {...tipsStates} />}

        <input
          type="file"
          name="image"
          accept="image/*"
          capture="environment"
          onChange={showCaptureVisa ? this.handleCaptureVisa : this.handleCapture}
          ref={(ref) => {
            this.element = ref;
          }}
          style={{ opacity: 0, zIindex: 99 }}
        />
        {exiturl && !backOfCard && !showCaptureVisa && (
          <div className={classes.link}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="#"
              onClick={this.openInvalidCardsModal}
            >
              {localizedString('noValidLicenceOrPassport')}{' '}
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask id="mask0" maskUnits="userSpaceOnUse" x="1" y="1" width="14" height="14">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM7.75 12.625C8.23325 12.625 8.625 12.2332 8.625 11.75C8.625 11.2668 8.23325 10.875 7.75 10.875C7.26675 10.875 6.875 11.2668 6.875 11.75C6.875 12.2332 7.26675 12.625 7.75 12.625ZM7.95546 3.5778C9.69463 3.47422 11.0944 4.65443 11.0067 6.24794C10.9315 7.61275 10.053 8.62001 8.50004 9.25274C8.49857 9.6657 8.16335 10 7.75005 10C7.33583 10 7.00005 9.66422 7.00005 9.25001V8.72861C7.00005 8.40404 7.20882 8.1163 7.51738 8.01562C8.85907 7.57779 9.46471 6.96874 9.50895 6.16546C9.54398 5.52945 8.94177 5.02171 8.04464 5.07514C7.47708 5.10895 6.96992 5.64408 6.95896 5.96071C6.94464 6.37468 6.59744 6.69865 6.18347 6.68432C5.76951 6.67 5.44553 6.3228 5.45986 5.90883C5.49727 4.828 6.60595 3.65817 7.95546 3.5778Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0)">
                  <rect x="0" y="0" width="16" height="16" />
                </g>
              </svg>
            </a>
          </div>
        )}
        {this.renderInvalidCardsModal()}
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Capture);

function mapStateToProps({ capture }) {
  return {
    frontParams: capture.frontParams
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    resetIdInfo: () => dispatch(InfoAction.resetIdInfo()),
    setFrontIDParams: (data) => dispatch(CaptureAction.setFrontIDParams(data))
  };
}
