import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import lottie from 'lottie-web';
import Modal from '@lib/components/v2/Modal';
import { localizedString } from '@languages';
import classes from './ConfirmDetails.style.module.scss';
import { animationData } from './AnimationData';

export default class ConfirmDetails extends Component {
  static propTypes = {
    onClick: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      animCopmleted: false
    };

    this.container = null;
  }

  /**
   * Start an animation
   * @return {Void}
   */
  componentDidMount() {
    if (this.container) {
      const animation = lottie.loadAnimation({
        container: this.container,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData
      });
      animation.setSpeed(2.5);
      setTimeout(() => {
        this.setState({ animCopmleted: true });
      }, 3500);
    }
  }

  render() {
    const { animCopmleted } = this.state;
    const { onClick } = this.props;
    const buttons = [{ label: localizedString('proceed'), onClick, full: true }];
    return (
      <Modal
        buttons={buttons}
        isOpen
        className={classNames(classes.wrapper, animCopmleted && classes.open)}
        issue={null}
      >
        <h5 className={classes.animTitle}>{localizedString('confirmExtractedDetails')}</h5>
        <h5 className={classes.animTitle}>{localizedString('exampleAnimation')}</h5>
        <div className={classes.content}>{localizedString('changeIncorrectFields')}</div>
        <div
          className={classes.animation}
          ref={(ref) => {
            this.container = ref;
          }}
        />
      </Modal>
    );
  }
}
