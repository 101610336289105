import voiFlowV2 from '../actions/voiFlowV2';

const initialState = {
  cardDetails: []
};

export default function information(state = initialState, action = {}) {
  switch (action.type) {
    case voiFlowV2.SET_CARD_DETAILS:
      return {
        ...state,
        cardDetails: [...state.cardDetails, action.data]
      };

    case voiFlowV2.CLEAR_CARD_DETAILS:
      return {
        ...state,
        cardDetails: []
      };

    default:
      return state;
  }
}
