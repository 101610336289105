export default class Capture {
  static SET_APP_CONFIG = 'SET_APP_CONFIG';

  static setAppConfig(data) {
    return {
      type: this.SET_APP_CONFIG,
      data
    };
  }
}
