/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import '../../../styles/main.scss';
import Modal from '@lib/components/v2/Modal';

import { DetailMatch } from '@containers';

import {
  isWebRTCSupported,
  copyOpenSafari,
  isAndroidDevice,
  isBrowserPermitted,
  setCookie,
  getCookie,
  isShownPrivacy
} from '@lib/Utils';
import APIs from '@services/APIs';
import { envPatch } from '@languages/env_patch';
import { localizedString } from '@languages';
import parse from 'html-react-parser';
import draftToHtml from 'draftjs-to-html';
import {
  Error500,
  InternetCut,
  DeviceIncompatibleOpenChrome,
  Timeout,
  Error400
} from '../../errors';
import {
  FaceScan,
  Welcome,
  DocumentSelection,
  Privacy,
  Capture,
  NameMatch,
  VerifyDetails,
  AlternateFlow,
  CaptureExtra,
  Success
} from '..';
import { LoadingSpinner, LoadingBar, Message, Language } from '../../components';

const {
  VOI_FLOW_V2_SHOW_WELCOME = false,
  ENABLE_ONE_DOC_CONDITION = false,
  VOI_FLOW_V2_REQUIRED_DOC_CONFIG = [
    {
      numberOfDocsRequired: 2,
      possibleDocs: ['Driver Licence', 'PASSPORT']
    },
    {
      numberOfDocsRequired: 3
    }
  ],
  FLOW_V2_VERIFICATION_EXPIRED_ALERT_TITLE = 'Verification Expired',
  FLOW_V2_VERIFICATION_EXPIRED_ALERT_DESCRIPTION = 'This verification has been expired.<br/><br/>You may close this window.'
} = process.env;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      tokenId: '',
      geolocation: '',
      accepted: false,
      cancelled: false,
      confirm: false,
      confirmFR: false,
      error: null,
      tenMinsLeft: false,
      compatible: true,
      completed: false,
      isLandscape: false,
      isProcessing: false,
      isUploading: false,
      uploadBar: 0,
      webrtc: {
        todo: null,
        status: true
      },
      redirect: false,
      verify: false,
      showLanguageSelectionPrompt: false,
      showDocumentSelection: false,
      currentDocument: {
        index: -1,
        type: '',
        isProcessing: false
      },
      selectedDocumentList: [],
      showNameMatch: false,
      prevScreenState: null, // VerifyDetails state for NameMatch/Back
      showCaptureExtra: false,
      frRetryAttemptCount: 0,
      selfieFR: false,
      showWelcome: VOI_FLOW_V2_SHOW_WELCOME,
      showDetailMatch: false
    };

    this.input = null;

    this.handleComplete = this.handleComplete.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleExit = this.handleExit.bind(this);
  }

  async componentDidMount() {
    const { verify } = this.props;
    this.setState({ verify });

    let { COUNTRIES } = process.env;
    const { APP_NAME, DETAIL_MATCH, DETAIL_MATCH_ONLY_UNIVERSAL_URL } = process.env;
    if (!COUNTRIES && envPatch[APP_NAME]) {
      COUNTRIES = envPatch[APP_NAME].COUNTRIES;
    }

    const { status: compatible, todo, os } = await isWebRTCSupported();

    // Init device compatible
    if (window.location.search === '?flow=alternate') {
      // for testing
      this.setState({ compatible, webrtc: { todo: 'ALT_FLOW', status: false } });
    } else {
      this.setState({ compatible, webrtc: { todo, status: compatible } });
    }

    if (os === 'iOS') {
      document.addEventListener(
        'touchmove',
        (event) => {
          if (event.scale !== 1) {
            event.preventDefault();
          }
        },
        { passive: false }
      );
    }

    // Detect if internet cut
    window.addEventListener('offline', () =>
      this.setState({
        error: {
          component: InternetCut,
          props: {}
        }
      })
    );
    // Detect if internet re connected
    window.addEventListener('online', () => {
      this.setState({
        error: {
          component: InternetCut,
          props: {
            isOnline: true,
            onGoBack: this.handleInternetReconnect
          }
        }
      });
    });

    const transToken = document.querySelector('body').getAttribute('data-id');
    const storedTransToken = getCookie('transToken');

    if (transToken === storedTransToken) {
      if (getCookie('_permission') === 1) {
        // Capturing ID
        this.setState({ step: 0, accepted: true });
        setCookie('_permission', 0, -7);
      } else if (getCookie('_permission') === 2) {
        // Liveness step
        this.setState({ step: process.env.COUNTRIES ? 6 : 5, accepted: true });
        setCookie('_permission', 0, -7);
      }

      const FRAttempt = parseInt(getCookie('retryAttempt'), 10) || parseInt(getCookie('retry'), 10);
      if (FRAttempt) {
        this.setState({
          step: 3
        });
      }
    } else {
      setCookie('transToken', transToken, -7);
    }

    // Landscape
    const isLandscape = window.innerHeight < window.innerWidth;
    this.setState({ isLandscape });
    window.addEventListener('orientationchange', () => {
      const { isLandscape, step } = this.state;
      if (step !== 1) {
        this.setState({ isLandscape: !isLandscape });
      }
    });

    // Show detail match
    const path = window.location.pathname;
    const detailMatch = getCookie('detailMatch');
    if ((!detailMatch || detailMatch !== 'yes') && DETAIL_MATCH && DETAIL_MATCH.length > 0) {
      let showDetailMatch = true;
      const { UNIVERSAL_URL_PATH = '/verify' } = process.env;
      if (DETAIL_MATCH_ONLY_UNIVERSAL_URL && path !== UNIVERSAL_URL_PATH) {
        showDetailMatch = false;
      }
      this.setState({
        showDetailMatch
      });
    }

    // Start the timeout for 1 hour.
    let countdown = 60 * 60;
    const timerId = setInterval(() => {
      if (countdown === 600) {
        this.setState({
          tenMinsLeft: true
        });
      }

      if (countdown <= 0) {
        this.setState({
          tenMinsLeft: false,
          error: {
            component: Timeout,
            props: {}
          }
        });
        clearInterval(timerId);
      }
      countdown--;
    }, 1000);
  }

  /**
   * Unbind the event listener
   */
  componentWillUnmount() {
    window.removeEventListener('orientationchange', () => {
      this.setState(({ isLandscape }) => ({ isLandscape: !isLandscape }));
    });
  }

  /**
   * When all flows have been completed.
   *
   * @param {Object} state
   * @return {Void}
   */
  handleComplete({ sessionId: id, lr, liveness }) {
    const { tokenId, frRetryAttemptCount } = this.state;
    const { VOI_FLOW_V2_ADDITIONAL_DOC = true, WSS_URL } = process.env;
    /**
     * Upload face scan video.
     */
    const data = {
      id,
      lr,
      actions: `Smile, Turn head ${lr ? 'left' : 'right'}`,
      tokenId,
      isEngineV4: true
    };
    if (liveness) {
      data.success = 1;
    }

    let axiosParams;
    if (WSS_URL.includes('liveness.idkit.io')) {
      this.setState({ isProcessing: true });
    } else {
      this.setState({ uploadBar: 0, isUploading: true });
      axiosParams = {
        before: () => this.setState({ uploadBar: 0, isUploading: true }),
        onProgress: (width) => this.setState({ uploadBar: width })
      };
    }

    APIs.uploadVideo(data, axiosParams, '/api/v4')
      .then(({ status, token, msg: error, action = null }) => {
        if (VOI_FLOW_V2_ADDITIONAL_DOC && !ENABLE_ONE_DOC_CONDITION) {
          this.setState({
            isProcessing: false,
            showCaptureExtra: true,
            isUploading: false
          });
        }

        if (status !== 'success') {
          if (action === 'ALTERNATE') {
            this.setState({
              webrtc: { todo: 'ALT_FLOW', status: false },
              step: 2,
              showCaptureExtra: false
            });
            return;
          }
          console.error('video upload failed', { data, error });
          throw new Error(error);
        }

        if (!VOI_FLOW_V2_ADDITIONAL_DOC || ENABLE_ONE_DOC_CONDITION) {
          this.handleFinish(token);
        }
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 400 && frRetryAttemptCount < 2) {
          const error = {
            component: Error400,
            props: {
              onRetryAgain: () => {
                this.setState({
                  step: 2,
                  showCaptureExtra: false,
                  error: null,
                  frRetryAttemptCount: frRetryAttemptCount === 0 ? 1 : frRetryAttemptCount + 1
                });
              }
            }
          };
          this.setState({ error, isProcessing: false, isUploading: false });
        } else {
          const error = {
            component: Error500,
            props: {
              onTryAgain: () => {
                this.setState({ step: 2, showCaptureExtra: false, error: null });
              }
            }
          };
          this.setState({ error, isProcessing: false, isUploading: false });
        }
      });
  }

  handleCompleteAlternate = () => {
    const { VOI_FLOW_V2_ADDITIONAL_DOC = true } = process.env;
    setCookie('retryAsf', 'no', -10);
    setCookie('retry', null, -7);
    setCookie('retryCaptureWithVideoStream', null, -7);
    setCookie('retryAttempt', null, -7);
    setCookie('idCaptureAttempt', 0, -7);
    setCookie('detailMatch', 'false', -1);
    setCookie('_permission', null, -7);

    if (VOI_FLOW_V2_ADDITIONAL_DOC && !ENABLE_ONE_DOC_CONDITION) {
      this.setState({ isProcessing: false, isUploading: false, showCaptureExtra: true });
    } else {
      this.handleFinish();
    }
  };

  /**
   * Go back to pev step.
   *
   * @return {Void}
   */
  handleGoBack() {
    const { step } = this.state;
    if (step && step > 0) {
      this.setState(({ step }) => ({ step: step - 1 }));
    } else {
      this.setState({ accepted: false });
    }
  }

  /**
   * Handle internet reconnection
   *
   * @return {Void}
   */
  handleInternetReconnect = () => {
    this.setState(({ step }) => ({ step: step - 1, error: null }));
  };

  /**
   * Cancel the session.
   *
   * @return {Void}
   */
  handleExit() {
    this.setState({ confirm: false });
    this.handleGoBack();
  }

  handleFRGoBack = () => {
    // For temporary fix, we let use go back to capture Id screen
    const { VOI_FLOW_V2_AUTO_SELECT_DOCS = [] } = process.env;
    if (VOI_FLOW_V2_AUTO_SELECT_DOCS && VOI_FLOW_V2_AUTO_SELECT_DOCS.length > 0) {
      this.setState({
        showDocumentSelection: false,
        selectedDocumentList: VOI_FLOW_V2_AUTO_SELECT_DOCS,
        currentDocument: {
          index: 0,
          type: VOI_FLOW_V2_AUTO_SELECT_DOCS[0].type,
          isProcessing: false
        }
      });
    } else {
      this.setState({
        showDocumentSelection: true,
        selectedDocumentList: [],
        step: 0,
        currentDocument: {
          index: -1,
          type: '',
          isProcessing: false
        }
      });
    }
  };

  handleOnCaptured = (card) => {
    const { selectedDocumentList } = this.state;
    const docList = selectedDocumentList.map((doc) => {
      let temp = { ...doc };
      if (doc.type === card.type) {
        temp = { ...card, captured: true };
        const attempts = temp.attempts || 0;
        temp.attempts = attempts + 1;
      }
      return temp;
    });

    this.setState({
      selectedDocumentList: docList
    });
  };

  handleOnExtracted = (card, token = null) => {
    const { selectedDocumentList } = this.state;
    if (token !== null) {
      this.setState({
        tokenId: token
      });
    }

    const docList = selectedDocumentList.map((doc) => {
      const temp = { ...doc };
      if (doc.type === card.type) {
        temp.extracted = true;
        temp.params = null;
        if (card.documentId) {
          temp.documentId = card.documentId;
        }
      }
      return temp;
    });
    this.setState({
      selectedDocumentList: docList
    });
  };

  handleNameCaptureDocSelected = (card) => {
    const { selectedDocumentList } = this.state;
    const documentList = [...selectedDocumentList];
    const itemIndex = documentList.findIndex((crd) => {
      return crd.type === card.type;
    });

    if (itemIndex > -1) {
      documentList[itemIndex] = { ...card, captured: true };
    } else {
      documentList.push({ ...card, captured: true });
    }

    this.setState({
      selectedDocumentList: documentList,
      showNameMatch: false,
      prevScreenState: null,
      step: 1
    });
  };

  handleFinish = (token) => {
    let { tokenId } = this.state;
    if (token) {
      tokenId = token;
    }

    APIs.verifyScans(tokenId)
      .then(({ redirectTo }) => {
        if (redirectTo) {
          setTimeout(() => {
            APIs.return();
          }, 3000);
          this.setState({
            redirect: true,
            isProcessing: false,
            isUploading: false,
            completed: true
          });
        } else {
          this.setState({ isProcessing: false, isUploading: false, completed: true });
        }
      })
      .catch((err) => {
        console.error(err);
        const error = {
          component: Error500,
          props: {
            onTryAgain: () => {
              this.setState({ step: 2, showCaptureExtra: false, error: null });
            }
          }
        };
        this.setState({ error, isProcessing: false, isUploading: false });
      });
  };

  handleRecapture = (card) => {
    const { selectedDocumentList } = this.state;
    const docList = selectedDocumentList.map((doc) => {
      const temp = { ...doc };
      if (doc.type === card.type) {
        temp.captured = false;
        temp.extracted = false;
        temp.token = null;
        temp.backOfCard = false;
        temp.extracted = false;
      }
      return temp;
    });
    this.setState({
      selectedDocumentList: docList,
      step: 0
    });
  };

  handleCaptureBack = (card) => {
    const { selectedDocumentList } = this.state;
    const docList = selectedDocumentList.map((doc) => {
      const temp = { ...doc };
      if (doc.type === card.type) {
        temp.captured = false;
        temp.token = null;
        temp.backOfCard = true;
      }
      return temp;
    });
    this.setState({
      selectedDocumentList: docList,
      step: 0
    });
  };

  handleGoBackFromCapture = (card = null) => {
    const { selectedDocumentList } = this.state;
    const captured = selectedDocumentList.filter((doc) => {
      return doc.captured;
    });

    if (captured.length === 0 || !card) {
      const { VOI_FLOW_V2_AUTO_SELECT_DOCS = [] } = process.env;
      if (VOI_FLOW_V2_AUTO_SELECT_DOCS && VOI_FLOW_V2_AUTO_SELECT_DOCS.length > 0) {
        this.setState({
          showDocumentSelection: false,
          selectedDocumentList: VOI_FLOW_V2_AUTO_SELECT_DOCS,
          currentDocument: {
            index: 0,
            type: VOI_FLOW_V2_AUTO_SELECT_DOCS[0].type,
            isProcessing: false
          }
        });
      } else {
        this.setState({ showDocumentSelection: true });
      }
    } else {
      const docList = selectedDocumentList.map((doc) => {
        const temp = { ...doc };
        if (doc.type === card.type) {
          temp.captured = false;
          temp.token = null;
          delete temp.params;
        }
        return temp;
      });
      this.setState({
        selectedDocumentList: docList
      });
    }
  };

  handleChooseDiffId = (oldCard, newCard) => {
    const { selectedDocumentList = [] } = this.state;
    const index = selectedDocumentList.findIndex((card) => {
      return card.type === oldCard.type;
    });
    if (index > -1) {
      const selectedDocumentListNew = [...selectedDocumentList];
      selectedDocumentListNew[index] = newCard;
      this.setState({
        selectedDocumentList: selectedDocumentListNew,
        step: 0
      });
    }
  };

  /**
   * Render the component's.
   *
   * @return {ReactElement}
   */
  render() {
    const {
      step,
      tokenId,
      cancelled,
      confirm,
      confirmFR,
      error,
      tenMinsLeft,
      accepted,
      compatible,
      completed,
      isProcessing,
      isUploading,
      uploadBar,
      isLandscape,
      geolocation,
      verify,
      webrtc = {},
      showLanguageSelectionPrompt,
      showDocumentSelection,
      selectedDocumentList,
      showNameMatch,
      showCaptureExtra,
      currentDocument,
      selfieFR,
      showWelcome,
      showDetailMatch,
      redirect,
      prevScreenState
    } = this.state;
    const { VOI_FLOW_V2_AUTO_SELECT_DOCS = [] } = process.env;
    const { todo, status: isWebRTC } = webrtc;
    const { component: Error, props: errorProps } = error || {};

    const { flowType, transStatus } = this.props;

    let requiredDocumentConfig = VOI_FLOW_V2_REQUIRED_DOC_CONFIG;
    if (ENABLE_ONE_DOC_CONDITION) {
      requiredDocumentConfig = [
        {
          numberOfDocsRequired: 1
        }
      ];
    }

    /**
     * Button states
     */
    const confirmBtns = [
      {
        label: localizedString('cancel'),
        onClick: () => this.setState({ confirm: false, confirmFR: false }),
        variant: 'transparent'
      },
      {
        label: localizedString('yesImSure'),
        onClick: () => {
          setCookie('retry', null, -7);
          setCookie('retryAttempt', null, -7);

          if (VOI_FLOW_V2_AUTO_SELECT_DOCS && VOI_FLOW_V2_AUTO_SELECT_DOCS.length > 0) {
            this.setState({
              step: 0,
              confirm: false,
              confirmFR: false,
              showDocumentSelection: false,
              selectedDocumentList: VOI_FLOW_V2_AUTO_SELECT_DOCS,
              currentDocument: {
                index: 0,
                type: VOI_FLOW_V2_AUTO_SELECT_DOCS[0].type,
                isProcessing: false
              }
            });
          } else {
            this.setState({
              step: 0,
              confirm: false,
              confirmFR: false,
              showDocumentSelection: true
            });
          }
          // this.handleGoBack();
        }
      }
    ];
    const openChromeBtns = [
      {
        label: localizedString('proceed'),
        full: true,
        onClick: () => {
          const { id } = document.body.dataset;
          document.location = `googlechrome://navigate?url=${document.location.href}${id}`;
          setTimeout(() => {
            this.setState({ compatible: true });
          }, 1000);
        }
      }
    ];
    const openSafariBtns = [
      {
        children: (
          <a className="link-inside-button" href="x-web-search://" target="_self">
            {localizedString('copyLinkOpenSafari')}
          </a>
        ),
        full: true,
        style: { backgroundColor: '#000', borderStyle: 'none' },
        onClick: () => copyOpenSafari(this.input)
      }
    ];
    const tenMinsLeftBtns = [
      {
        label: localizedString('ok'),
        onClick: () => this.setState({ tenMinsLeft: false })
      }
    ];
    /**
     * Exceptions for device compatible.
     */
    if (!compatible) {
      if (todo === 'OPEN_SAFARI') {
        return (
          <Message
            title={localizedString('needToUseSafari')}
            buttons={openSafariBtns}
            issue={localizedString('useSafari')}
          >
            {localizedString('useSafariDesc2_Line1')}
            <br />
            <br />
            {localizedString('useSafariDesc2_Line2')}
            <input
              readOnly
              className="b-hidden-input"
              ref={(ref) => {
                this.input = ref;
              }}
            />
          </Message>
        );
      }
      if (todo === 'NEED_ALT_FLOW') {
        const url = `${window.location.href}?flow=alternate`;
        window.location.replace(url);
      }
    }

    // Check if Browser is Firefox, as Firefox is not permitted for mobile flow
    if (isAndroidDevice() && !isBrowserPermitted()) {
      return <DeviceIncompatibleOpenChrome issue={false} buttons={openChromeBtns} />;
    }

    let screenType = '';
    if (isLandscape) {
      screenType = 'LANDSCAPE';
    } else if (showWelcome) {
      screenType = 'WELCOME';
    } else if (
      transStatus === 'COMPLETED' ||
      transStatus === 'EXPIRED' ||
      transStatus === 'CANCELLED' ||
      transStatus === '404'
    ) {
      screenType = transStatus;
    } else if (Error) {
      screenType = 'ERROR';
    } else if (cancelled) {
      screenType = 'SESSION_CANCELLED';
    } else if (completed) {
      screenType = 'SESSION_COMPLETED';
    } else if (showLanguageSelectionPrompt) {
      screenType = 'LANGUAGE';
    } else if (isShownPrivacy('VOI_FLOW_V2') && !accepted) {
      screenType = 'PRIVACY';
    } else if (showDocumentSelection) {
      screenType = 'DOCUMENT_SELECTION';
    } else if (showNameMatch) {
      screenType = 'NAME_MATCH';
    } else if (showCaptureExtra) {
      screenType = 'CAPTURE_EXTRA';
    } else if (step === 0) {
      screenType = 'CAPTURE';
    } else if (step === 1) {
      screenType = 'VERIFY_DETAILS';
    } else if (step === 2) {
      screenType = isWebRTC && !selfieFR ? 'FACE_SCAN' : 'ALTERNATE_FLOW';
    }

    let mainContainerClass = '';
    if (ENABLE_ONE_DOC_CONDITION) {
      mainContainerClass = 'enable-one-doc-container';
    }

    if (showDetailMatch) {
      return <DetailMatch />;
    }

    const { appConfig } = this.props;

    let verificationExpiredContent = FLOW_V2_VERIFICATION_EXPIRED_ALERT_DESCRIPTION;
    if (appConfig.expiryPageContent) {
      verificationExpiredContent = draftToHtml(appConfig.expiryPageContent);
    }

    return (
      <div className={mainContainerClass}>
        {screenType === 'LANDSCAPE' && <Message landscape />}
        {screenType === 'COMPLETED' && (
          <Message title="Verification Completed" completed>
            This verification has been completed.
            <br />
            <br />
            You may close this window.
          </Message>
        )}
        {screenType === 'EXPIRED' && (
          <Message title={FLOW_V2_VERIFICATION_EXPIRED_ALERT_TITLE} issue>
            {parse(verificationExpiredContent)}
          </Message>
        )}
        {screenType === 'CANCELLED' && (
          <Message title="Session Cancelled" issue>
            This verification has been cancelled.
            <br />
            <br />
            You may close this window.
          </Message>
        )}
        {screenType === '404' && (
          <Message title="Incorrect URL" issue>
            The URL provided does not correspond to any verification.
            <br />
            <br />
            Please check the URL and try again.
            <br />
            <br />
            You can close this window or go back.
          </Message>
        )}
        {screenType === 'ERROR' && <Error {...errorProps} />}
        {screenType === 'SESSION_CANCELLED' && (
          <Message title={localizedString('sessionCancelled')} issue>
            {localizedString('verificationCancelled')}
            <br />
            <br />
            {localizedString('youMayCloseThisWindow')}
          </Message>
        )}
        {screenType === 'SESSION_COMPLETED' && <Success redirect={redirect} />}
        {screenType === 'WELCOME' && (
          <Welcome onStart={() => this.setState({ showWelcome: false })} />
        )}
        {screenType === 'PRIVACY' && (
          <Privacy
            flowType={flowType}
            onAccept={() => {
              if (VOI_FLOW_V2_AUTO_SELECT_DOCS && VOI_FLOW_V2_AUTO_SELECT_DOCS.length > 0) {
                this.setState({
                  accepted: true,
                  showDocumentSelection: false,
                  selectedDocumentList: VOI_FLOW_V2_AUTO_SELECT_DOCS,
                  currentDocument: {
                    index: 0,
                    type: VOI_FLOW_V2_AUTO_SELECT_DOCS[0].type,
                    isProcessing: false
                  }
                });
              } else {
                this.setState({ accepted: true, showDocumentSelection: true });
              }
            }}
            onSelectLanguage={() => this.setState({ showLanguageSelectionPrompt: true })}
          />
        )}
        {screenType === 'LANGUAGE' && (
          <Language onGoback={() => this.setState({ showLanguageSelectionPrompt: false })} />
        )}
        {screenType === 'DOCUMENT_SELECTION' && (
          <DocumentSelection
            currentDocument={currentDocument}
            enableOneDocCondition={ENABLE_ONE_DOC_CONDITION}
            requiredDocumentConfig={requiredDocumentConfig}
            onGoBack={() =>
              this.setState({
                showDocumentSelection: false,
                accepted: false,
                selectedDocumentList: []
              })
            }
            onNextStep={(selectedDocumentListResult) => {
              let { index, isProcessing, type } = currentDocument;

              if (ENABLE_ONE_DOC_CONDITION && index < 2) {
                isProcessing = true;

                if (index < 0) {
                  index = 0;
                }

                type = selectedDocumentListResult[0].type;
                this.setState({
                  currentDocument: { index, isProcessing, type },
                  step: 0
                });

                // Selected Document Management
                let { selectedDocumentList } = this.state;
                if (!selectedDocumentList || index === 0) {
                  selectedDocumentList = [];
                }

                // eslint-disable-next-line no-restricted-syntax
                for (const resultItem of selectedDocumentListResult) {
                  selectedDocumentList.push(resultItem);
                }

                this.setState({
                  showDocumentSelection: false,
                  selectedDocumentList
                });
              } else {
                this.setState({
                  showDocumentSelection: false,
                  selectedDocumentList: selectedDocumentListResult
                });
              }
            }}
          />
        )}
        {screenType === 'CAPTURE' && (
          <Capture
            onGoBack={this.handleGoBackFromCapture}
            onNextStep={(obj) => {
              this.setState({
                ...obj
              });
            }}
            selectedDocumentList={selectedDocumentList}
            onGeoLocation={(geolocation) => this.setState({ geolocation })}
            onCaptured={this.handleOnCaptured}
            flowType={flowType}
            verify={verify}
            onExit={() => this.setState({ confirm: true })}
          />
        )}
        {screenType === 'VERIFY_DETAILS' && (
          <VerifyDetails
            currentDocument={currentDocument}
            selectedDocumentList={selectedDocumentList}
            verify={verify}
            onExtracted={this.handleOnExtracted}
            flowType={flowType}
            location={geolocation}
            prevScreenState={prevScreenState}
            onNextStep={(obj) => {
              if (ENABLE_ONE_DOC_CONDITION && obj.secondDocument === true) {
                if (VOI_FLOW_V2_AUTO_SELECT_DOCS && VOI_FLOW_V2_AUTO_SELECT_DOCS.length > 0) {
                  this.setState({
                    showDocumentSelection: false,
                    selectedDocumentList: VOI_FLOW_V2_AUTO_SELECT_DOCS,
                    currentDocument: {
                      index: 0,
                      type: VOI_FLOW_V2_AUTO_SELECT_DOCS[0].type,
                      isProcessing: false
                    }
                  });
                } else {
                  this.setState({
                    currentDocument: {
                      index: 1,
                      isProcessing: false,
                      type: currentDocument.type
                    },
                    showDocumentSelection: true
                  });
                }
                return;
              }

              this.setState({ ...obj });
            }}
            onRecapture={this.handleRecapture}
            onCaptureBack={this.handleCaptureBack}
            onChooseDiffId={this.handleChooseDiffId}
            onGoBack={() => {
              if (VOI_FLOW_V2_AUTO_SELECT_DOCS && VOI_FLOW_V2_AUTO_SELECT_DOCS.length > 0) {
                this.setState({
                  step: 0,
                  prevScreenState: null,
                  showDocumentSelection: false,
                  selectedDocumentList: VOI_FLOW_V2_AUTO_SELECT_DOCS,
                  currentDocument: {
                    index: 0,
                    type: VOI_FLOW_V2_AUTO_SELECT_DOCS[0].type,
                    isProcessing: false
                  }
                });
              } else {
                this.setState({ step: 0, showDocumentSelection: true, prevScreenState: null });
              }
            }}
          />
        )}
        {screenType === 'NAME_MATCH' && (
          <NameMatch
            currentDocument={currentDocument}
            flowType={flowType}
            onUpdateSelectedDocList={this.handleNameCaptureDocSelected}
            onGoBack={() => this.setState({ showNameMatch: false })}
            onNextStep={(obj) => {
              this.setState({
                ...obj,
                prevScreenState: null
              });
            }}
          />
        )}
        {screenType === 'FACE_SCAN' && (
          <FaceScan
            tokenId={tokenId}
            onNextStep={this.handleComplete}
            onGoBack={this.handleFRGoBack}
            onSelfie={() => this.setState({ selfieFR: true })}
          />
        )}
        {screenType === 'ALTERNATE_FLOW' && (
          <AlternateFlow
            tokenId={tokenId}
            onNextStep={this.handleCompleteAlternate}
            onGoBack={this.handleFRGoBack}
          />
        )}
        {screenType === 'CAPTURE_EXTRA' && (
          <CaptureExtra flowType={flowType} onNextStep={() => this.handleFinish()} />
        )}
        {tenMinsLeft && (
          <Modal isOpen heading={localizedString('tenMinsLeftDesc')} buttons={tenMinsLeftBtns} />
        )}
        <Modal
          isOpen={confirm}
          heading={localizedString('leaveConfirmation')}
          description=""
          buttons={confirmBtns}
        >
          {confirmFR
            ? localizedString('scanIDAgainPersonalDetails')
            : localizedString('scanIDAgainInformationOnScreen')}
        </Modal>
        {isUploading && <LoadingBar heading={localizedString('uploading')} width={uploadBar} />}
        {isProcessing && (
          <LoadingSpinner subtitle="" heading={localizedString('verifyingYourIdentity')} />
        )}
      </div>
    );
  }
}

App.propTypes = {
  verify: PropTypes.bool,
  flowType: PropTypes.string,
  transStatus: PropTypes.string,
  appConfig: PropTypes.object
};

export default connect(mapStateToProps, null)(App);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ information, appConfig }) {
  return {
    appConfig,
    idDetails: information.idDetails,
    addresses: information.addresses
  };
}
