export default class Capture {
  static SET_FRONTID_PARAMS = 'SET_FRONTID_PARAMS';

  static setFrontIDParams(data) {
    return {
      type: this.SET_FRONTID_PARAMS,
      data
    };
  }
}
