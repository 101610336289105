import React from 'react';
import PropTypes from 'prop-types';
import { localizedString } from '@languages';
import { Message } from '../../..';
import classes from '../Face/Face.style.module.scss';

const TurnHead = ({ onCapture }) => {
  const buttons = [{ label: 'Capture', large: false, onClick: onCapture }];

  return (
    <Message buttons={buttons} className={classes.wrapper}>
      <div className={classes.content}>
        <h3 className="b-title">{localizedString('takeASelfieWithYourHeadTurned')}</h3>

        <ul>
          <li>{localizedString('turnHeadSlightlyLeftOrRightAndTakePhoto')}</li>
        </ul>
        <img alt="" style={{ maxWidth: 270 }} src="images/tips/alternate-flow/turn-head.png" />
      </div>
    </Message>
  );
};

TurnHead.propTypes = {
  onCapture: PropTypes.func
};

export default TurnHead;
