import React from 'react';
import { localizedString } from '@languages';
import { Message, Modal } from '../../components';

/**
 * If card blurry or crop etc.
 */
export const CouldNotRead = (props) => {
  return (
    <Message {...props} title={localizedString('weCouldNotReadYourIDInPhoto')} issue>
      <ul>
        <li>{localizedString('makeSureNoPartsOfCardAreCovered')}</li>
        <li>{localizedString('makeSureCameraIsNotSmudgedOrDirty')}</li>
        <li>{localizedString('makeSureIDIsNotBadlyDamaged')}</li>
        <li>{localizedString('makeSurePhotoIsInFocusAndTextReadable')}</li>
      </ul>
    </Message>
  );
};

/**
 * Wrong document.
 */
export const CouldNotRecognise = (props) => {
  return (
    <Message {...props} title={localizedString('weDidNotRecogniseYourID')} issue>
      <div>{localizedString('pleaseCapturePhotoIDAgain')}</div>
      <div>
        <strong>{localizedString('needHelpQuestion')}</strong>
      </div>
      <ol className="oli-style-success">
        <li>{localizedString('makeSurePhotoIsInFocusAndTextReadable')}</li>
        <li>{localizedString('makeSureYouSelectedCorrectID')}</li>
        <li>{localizedString('makeSureAllEdgesOfIDAreShown')}</li>
      </ol>
    </Message>
  );
};

/**
 * If image has poor quality.
 */
export const PoorImage = (props) => {
  const style = { color: '#fff' };
  return (
    <Message {...props} title={localizedString('poorImageQuality')} issue>
      <ul>
        <li style={style}>{localizedString('makeSureThereIsEnoughLight')}</li>

        <li style={style}>{localizedString('makeSureYourPhotoIsInFocusAndNotBlurry')}</li>
        <li style={style}>{localizedString('makeSureCameraIsNotSmudgedOrDirty')}</li>
      </ul>
    </Message>
  );
};

/**
 * If ID has been expired.
 */
export const ExpiredID = (props) => {
  return (
    <Message {...props} title={localizedString('yourIDExpired')} issue>
      <div>
        {localizedString('weCanNotContinueWithYourApplicationWithExpiredID')}
        <br /> {localizedString('hereAreSomeOptions')}
      </div>
      <ol className="oli-style-warn">
        <li>{localizedString('ensureYourIDExpiryDateIsCorrect')}</li>
        <li>{localizedString('chooseTheOtherIDDocumentType')}</li>
      </ol>
    </Message>
  );
};

/**
 * If ID has been expired by more than 2 years.
 */
export const ExpiredIDBy2Years = (props) => {
  const {
    FLOW_V2_EXPIRED_ID_HEADING = 'Your passport is over 2 years expired',
    FLOW_V2_EXPIRED_ID_DESCRIPTION = 'We are not able to accept an Expired I.D. document. Please recapture a valid document or go back and correct the expiry if it is incorrect.'
  } = process.env;

  return (
    <Message {...props} title={FLOW_V2_EXPIRED_ID_HEADING} issue>
      <div style={{ textAlign: 'center' }}>{FLOW_V2_EXPIRED_ID_DESCRIPTION}</div>
    </Message>
  );
};

/**
 * Confirm user age.
 */
export const ConfirmAge = (props) => {
  return <Modal isOpen {...props} heading={localizedString('areYouUnder18')} />;
};

/**
 * Confirm user age.
 */
export const Under18 = (props) => {
  return (
    <Message {...props} issue title={localizedString('weCannotContinueBecauseYouAreUnder18')}>
      {localizedString('sessionCancelledDesc1')}
      <br />
      <br />
      {localizedString('sessionCancelledDesc2')}
    </Message>
  );
};

/**
 * If image has poor quality.
 */
export const FaceNotDetectedInId = (props) => {
  const style = { color: '#fff' };
  return (
    <Message dark {...props} issue={localizedString('weCouldNotRecogniseYourID')}>
      <ul>
        <li style={style}>{localizedString('facePictureOnCardShouldBeClear')}</li>
        <li style={style}>{localizedString('makeSureCameraIsNotSmudgedOrDirty')}</li>
        <li style={style}>{localizedString('makeSureIDCleanFromAnyGrime')}</li>
        <li style={style}>{localizedString('makeSureIDIsNotBadlyDamaged')}</li>
        <li style={style}>{localizedString('makeSurePhotoIsInFocusAndTextReadable')}</li>
        <li style={style}>{localizedString('makeSureAllEdgesAreShown')}</li>
      </ul>
    </Message>
  );
};

/**
 * Confirm extracted details
 */
export const ConfirmExtracted = (props) => {
  return <Modal isOpen {...props} heading={localizedString('pleaseConfirmExtractedAreCorrect')} />;
};

/**
 * Confirm consent
 */
export const ConfirmConsent = (props) => {
  return (
    <Modal isOpen {...props} heading={localizedString('pleaesConfirmConsentToCheckDetails')} />
  );
};

/**
 * Confirm going back
 */
export const ConfirmGoBack = (props) => {
  return (
    <Modal isOpen heading={localizedString('leaveConfirmation')} {...props}>
      {localizedString('scanIDAgainInformationOnScreen')}
    </Modal>
  );
};

/**
 *  Recapture
 */
export const Recapture = (props) => {
  return (
    <Modal isOpen {...props} heading={localizedString('attendantRequestedYouToRecaptureID')} />
  );
};
/**
 * ID number not editable.
 */
export const IdNumberNotEditable = (props) => {
  const issue = localizedString('incorrectIDNumber');
  return (
    <Message {...props} title={localizedString('idNumberNotEditable')} issue={issue}>
      {localizedString('ifIDNumberNotCorrectPleaseTakeAnotherPhoto')}
    </Message>
  );
};

/**
 * ID number not editable.
 */
export const TooManyRetryAttempts = (props) => {
  const issue = localizedString('exceedNumberOfAttempts');
  return (
    <Message {...props} title={localizedString('tooManyAttempts')} issue={issue}>
      {localizedString('exceedNumberOfAttemptsToCaptureID')}
      <br />
      <br />
      {localizedString('ifStillIncorrectCanContinueAndWeWillContactYou')}
    </Message>
  );
};
