import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './Input.style.module.scss';

const Input = ({
  id,
  label,
  className,
  disabled,
  readOnly,
  onChange,
  required,
  inputRef,
  type,
  value,
  defaultValue,
  hasError,
  placeholder
}) => {
  const [cursor, setCursor] = useState(null);
  const iRef = inputRef || useRef();

  const isTextarea = type === 'textarea';
  const Element = isTextarea ? 'textarea' : 'input';

  useEffect(() => {
    if (iRef && iRef.current && !isTextarea && cursor !== null) {
      iRef.current.setSelectionRange(cursor, cursor);
    }
  }, [cursor, iRef, value]);

  const handleChange = (e) => {
    if (disabled || readOnly || !onChange) {
      return;
    }
    setCursor(e.target.selectionStart);
    onChange(e.target.value);
  };

  const onBlur = () => {
    setCursor(null);
  };

  return (
    <label
      htmlFor={id}
      className={classNames(classes.input, className, hasError && classes.hasError)}
    >
      <Element
        id={id}
        name={id}
        disabled={disabled}
        required={required}
        onChange={handleChange}
        onBlur={onBlur}
        readOnly={readOnly}
        placeholder={placeholder || ''}
        ref={iRef}
        type={isTextarea ? null : type}
        value={value}
        defaultValue={defaultValue}
      />
      <span className={classes.label}>{label}</span>
      <span className={classes.border} />
      {hasError && <div className={classes.error}>{hasError}</div>}
    </label>
  );
};

Input.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  hasError: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  type: PropTypes.oneOf([
    'date',
    'datetime-local',
    'email',
    'month',
    'number',
    'password',
    'search',
    'tel',
    'text',
    'textarea',
    'time',
    'url',
    'week',
    'hidden'
  ]),
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  inputRef: PropTypes.object
};

Input.defaultProps = {
  type: 'text'
};

export default Input;
