import React from 'react';
import PropTypes from 'prop-types';
import { localizedString } from '@languages';
import classes from './BackOfCard.style.module.scss';

/**
 * Back of card animation.
 */
export default function BackOfCard({ idType }) {
  const backDesc = idType.match(/DRIVERLICENCE/i)
    ? localizedString('backOfDriverLicence')
    : localizedString('backOfCard');

  const continueDesc1 = idType.match(/DRIVERLICENCE/i)
    ? localizedString('toContinueIDVerificationDriverLicence1')
    : localizedString('toContinueIDVerificationCard1');
  const continueDesc2 = idType.match(/DRIVERLICENCE/i)
    ? localizedString('toContinueIDVerificationDriverLicence2')
    : localizedString('toContinueIDVerificationCard2');

  return (
    <div className={classes.wrapper}>
      <div className={classes['img-container']}>
        <img alt="" src="images/icons/png/backOfCard.png" />
      </div>

      <div className={classes.heading}>{backDesc}</div>

      <div className={classes.title}>
        {continueDesc1}
        <br />
        {continueDesc2}
      </div>
    </div>
  );
}

BackOfCard.propTypes = {
  idType: PropTypes.string
};

BackOfCard.defaultProps = {
  idType: 'PASSPORT'
};
