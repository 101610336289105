export const validate = (formData, errors) => {
  if (formData && formData.occupationDetails && formData.occupationDetails.ABN) {
    const only11DigitsPattern = /^\d{11}$/;
    if (!only11DigitsPattern.test(formData.occupationDetails.ABN)) {
      errors.occupationDetails.ABN.addError('ABN should be 11 digits');
    }
  }

  return errors;
};
