import React from 'react';
import classNames from 'classnames';
import UAParser from 'ua-parser-js';
import { localizedString } from '@languages';
import classes from './Allow.style.module.scss';

const Allow = () => {
  const browserName = () => {
    const uaParser = new UAParser();
    const { name: BrowserName } = uaParser.getBrowser();
    if (BrowserName === 'Mobile Safari') {
      return 'safari';
    }
    if (BrowserName === 'Samsung Browser') {
      return 'samsung';
    }
    return BrowserName.toLocaleLowerCase();
  };
  return (
    <div className={classNames(classes.wrapper, classes[browserName()])}>
      <div className={classes.title}>{localizedString('pressAllowToStart')}</div>
      <div className={classes.popup} />
      <div className={classes.helper}>
        <div className={classes.arrow} />
      </div>
    </div>
  );
};

export default Allow;
