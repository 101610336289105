import React from 'react';
import PropTypes from 'prop-types';
import { Message } from '@DOC_ONLY_FLOW/components';
import APIs from '@services/APIs';
import { localizedString } from '@languages';

const Success = ({ redirect = false }) => {
  const {
    DONE_BUTTON,
    DOC_ONLY_SUCCESS_TITLE = localizedString('ocrLabsRepresentativeWillBeInContactIfNeeded'),
    DOC_ONLY_SUCCESS_ACTION = localizedString('pressRedirectIfStuck10Secs')
  } = process.env;
  const largeShadow = { large: true, shadow: true };
  const completeBtns = [
    {
      label: localizedString('redirect'),
      ...largeShadow,
      onClick: () => APIs.return()
    }
  ];

  return (
    <Message
      completed
      title={localizedString('captureProcessComplete')}
      buttons={DONE_BUTTON ? completeBtns : []}
    >
      <h3 className="b-title">
        <b>{DOC_ONLY_SUCCESS_TITLE}</b>
      </h3>
      <br />
      {!DONE_BUTTON && !redirect && <div>{localizedString('youCanCloseThisWindow')}</div>}
      {!DONE_BUTTON && redirect && <div>{localizedString('youWillNowBeRedirected')}</div>}
      {DONE_BUTTON && <div>{DOC_ONLY_SUCCESS_ACTION}</div>}
    </Message>
  );
};

Success.propTypes = {
  redirect: PropTypes.bool
};

export default Success;
