import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Page } from '@components';
import { localizedString } from '@languages';
import classes from './Disclaimer.style.module.scss';

const Disclaimer = ({ onAccept, onExit }) => {
  const buttons = [
    { label: localizedString('exit'), variant: 'outline', onClick: onExit },
    { label: localizedString('IAccept'), onClick: onAccept }
  ];
  const { APP_LOGO } = process.env;
  const logo = localStorage.getItem(`${window.location.host}_app_logo`);
  const finalLogo = logo || `images/logos/${APP_LOGO}`;
  return (
    <Page className={classes.wrapper} buttons={buttons}>
      <img alt="" className="b-logo" src={finalLogo} />
      <h3 className="b-title">{localizedString('digitalIdentificationProcess')}</h3>
      <div className={classes.content}>
        <div className={classNames('b-icon-warning', classes.icon)} />
        <h4>{localizedString('disclaimer')}</h4>
        <p>
          {localizedString('confirmYouUnderstandYouAreVerifying')}
          <span>{localizedString('youridentity')}</span>
          {localizedString('toPurchase')}
          <span>{localizedString('cryptocurrency')}</span>
        </p>
      </div>
    </Page>
  );
};

Disclaimer.propTypes = {
  onAccept: PropTypes.func,
  onExit: PropTypes.func
};

export default Disclaimer;
