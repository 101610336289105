import React from 'react';
import PropTypes from 'prop-types';
import { localizedString } from '@languages';
import Animation from '@components/Animation';
import classes from './BackOfCard.style.module.scss';

let animationBackCard;
import('@VOI_FLOW_V2/animations/anim_back_card.json').then((value) => {
  animationBackCard = value;
});

/**
 * Back of card animation.
 */
export default function BackOfCard({ idType }) {
  const backDesc = idType.match(/DRIVER(\s|)LICENCE/i)
    ? localizedString('backOfDriverLicence')
    : localizedString('backOfCard');

  const continueDesc1 = idType.match(/DRIVER(\s|)LICENCE/i)
    ? localizedString('toContinueIDVerificationDriverLicence1')
    : localizedString('toContinueIDVerificationCard1');
  const continueDesc2 = idType.match(/DRIVER(\s|)LICENCE/i)
    ? localizedString('toContinueIDVerificationDriverLicence2')
    : localizedString('toContinueIDVerificationCard2');

  return (
    <div className={classes.wrapper}>
      <div className={classes.heading}>{backDesc}</div>
      <div className={classes.description}>
        {continueDesc1}
        <br />
        {continueDesc2}
      </div>
      <div className={classes['animation-container']}>
        <Animation scale={1.2} animationData={animationBackCard} />
      </div>
    </div>
  );
}

BackOfCard.propTypes = {
  idType: PropTypes.string
};

BackOfCard.defaultProps = {
  idType: 'PASSPORT'
};
