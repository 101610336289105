import React from 'react';
import PropTypes from 'prop-types';
import { Page } from '@DOC_ONLY_FLOW/components';
import { CountryContent } from '@DOC_ONLY_FLOW/components/Contents';
import { localizedString } from '@languages';

const Country = ({ onGoBack, onSelect }) => {
  return (
    <Page onGoBack={onGoBack} title={localizedString('chooseYourID')}>
      <CountryContent onSelect={onSelect} />
    </Page>
  );
};

Country.propTypes = {
  onSelect: PropTypes.func,
  onGoBack: PropTypes.func
};

Country.defaultProps = {
  onSelect: () => null
};

export default Country;
