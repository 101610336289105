import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { localizedString } from '@languages';
import Page from '@lib/components/v2/Page';
import Animation from '@components/Animation';
import classes from './LoadingSpinner.style.module.scss';

let animationReview;
import('@FLOW_V2_FLOW/animations/anim_data.json').then((value) => {
  animationReview = value;
});

const LoadingSpinner = ({ heading, showTitle }) => {
  const { LOADING_SPINNER_ANZ_STYLE = false } = process.env;

  return (
    <div className={classes.wrapper}>
      {LOADING_SPINNER_ANZ_STYLE && (
        <Page forceFillViewPort>
          <div className={classes.wrapper_anz_style}>
            <div className={classnames(classes.heading)} data-testid="id-heading">
              {heading}
            </div>
            <div className={classnames(classes.title)} data-testid="id-instruction">
              {localizedString('thisMayTakeAFewSeconds')}
            </div>
            <div className={classes['animation-container']} data-testid="id-animation">
              <Animation animationData={animationReview} />
            </div>
          </div>
        </Page>
      )}

      {!LOADING_SPINNER_ANZ_STYLE && (
        <div className={classes.wrapper_default}>
          <div className={classes.content}>
            <div className={classes.spinner1} />
            <div className={classes.heading}>{heading}</div>
            <div className={classes.title}>
              {localizedString('thisMayTakeAFewSeconds')}
              <br />
              <br />
              {showTitle && localizedString('nextWeWillAskIfTheInformationShownMatchesYourPhotoID')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

LoadingSpinner.propTypes = {
  heading: PropTypes.string,
  showTitle: PropTypes.bool
};

LoadingSpinner.defaultProps = {
  heading: localizedString('pleaseWait'),
  showTitle: false
};

export default LoadingSpinner;
