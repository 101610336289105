import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './Select.style.module.scss';

const Select = ({
  id,
  label,
  className,
  disabled,
  readOnly,
  onChange,
  required,
  inputRef,
  value,
  defaultValue,
  hasError,
  options = []
}) => {
  const Element = 'select';

  const handleChange = (e) => (disabled || readOnly || !onChange ? null : onChange(e.target.value));

  const optionsHtml = options.map((country) => {
    return (
      <option key={country.name} value={country.name}>
        {country.name}
      </option>
    );
  });

  return (
    <div className={classNames(classes.Select)}>
      <label htmlFor={id} className={classNames(classes.label)}>
        {label}
      </label>

      <Element
        id={id}
        className={classNames(className, 'minimal', hasError && classes.hasError)}
        name={id}
        disabled={disabled}
        required={required}
        onChange={handleChange}
        readOnly={readOnly}
        ref={inputRef}
        value={value}
        defaultValue={defaultValue}
      >
        <option value="">Select {label}</option>
        {optionsHtml}
      </Element>
      {hasError && <div className={classes.error}>{hasError}</div>}
    </div>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  hasError: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  inputRef: PropTypes.func,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  options: PropTypes.array
};

export default Select;
