import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';

let animation;
const Animation = ({ animationData, scale = 1 }) => {
  let container;
  useEffect(() => {
    if (container) {
      if (animation) {
        animation.destroy();
      }

      animation = lottie.loadAnimation({
        container,
        animationData,
        autoplay: true,
        loop: true
      });
    }
  }, [animationData]);

  const style = {
    transform: `scale(${scale})`,
    width: '100%',
    height: '100%'
  };

  return (
    <div
      ref={(ref) => {
        container = ref;
      }}
      style={style}
    />
  );
};

Animation.propTypes = {
  animationData: PropTypes.object,
  scale: PropTypes.number
};

export default Animation;
