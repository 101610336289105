import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InfoAction from '@store/actions/information';
import CaptureAction from '@store/actions/capture';

import { getCookie, setCookie, isShownPrivacy } from '@lib/Utils';
import APIs from '@services/APIs';
import { localizedString } from '@languages';
import { Page, Tips, LoadingSpinner, Modal } from '../../components';
import { IdSelectionContent, BackOfCard } from '../../components/Contents';

import { CouldNotDetect } from './Capture.error';
import { Error500 } from '../../errors';

export class Capture extends Component {
  static propTypes = {
    onNextStep: PropTypes.func,
    onGoBack: PropTypes.func,
    onExit: PropTypes.func,
    country: PropTypes.string,
    nextCapture: PropTypes.string,
    frontParams: PropTypes.object,
    resetIdInfo: PropTypes.func,
    setFrontIDParams: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.element = null;
    this.isComponentMounted = false;

    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleCapture = this.handleCapture.bind(this);
  }

  /**
   * Return the component's initial state
   * @return {Object}
   */
  getInitialState() {
    return {
      idType: 'AUS_AUTO_DRIVERLICENCE',
      progressBar: 0,
      backOfCard: false,
      isUploading: false,
      error: null,
      frontFile: null,
      geolocation: null,
      showCaptureVisa: false,
      showInvalidCardsModal: false,
      tipStep: 0
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { frontParams, nextCapture } = this.props;

    if (frontParams.idType && nextCapture === 'back') {
      this.setState({
        backOfCard: true
      });
    } else if (frontParams.idType && nextCapture === 'visa') {
      this.setState({
        showCaptureVisa: true
      });
    }
  }

  componentDidMount() {
    const { resetIdInfo } = this.props;
    APIs.status('selectId');
    this.isComponentMounted = true;
    resetIdInfo();
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  /**
   * Go to handle Select
   * @return {Void}
   */
  handleSelectIdType = (data) => {
    const { idType, backOfCard } = data;
    this.setState({ idType, backOfCard, tipStep: 0 });
  };

  /**
   * Go to the next step
   *
   */
  handleNextStep() {
    const { idType } = this.state;
    if (!idType) {
      return;
    }

    this.element.click();
  }

  /**
   * Go back to the previous step
   * @param {ClickEvent} e
   * @return {Void}
   */
  handleGoBack(e) {
    e.preventDefault();
    const { step } = this.state;
    const { onGoBack } = this.props;
    if (step) {
      this.setState(({ step }) => ({ step: step - 1 }));
    } else {
      const { backurl } = document.body.dataset;
      if (backurl && !isShownPrivacy('AUVOI_FLOW')) {
        window.location.href = backurl;
      } else {
        onGoBack();
      }
    }
  }

  /**
   * When user happy with photo.
   * @param {ChangeEvent} e
   */
  handleCapture(e) {
    if (!e.target.files[0]) {
      return;
    }

    const imagefile = e.target.files[0];
    e.target.value = '';

    this.uploadImage(imagefile);
  }

  onClickedFooterTakePhoto() {
    const { backOfCard } = this.state;

    if (backOfCard) {
      this.element.click();
    } else {
      this.handleNextStep();
    }
  }

  /**
   * Go to the next step on Visa Upload
   * @return {Void}
   */
  handleSkip = () => {
    const { geolocation, backOfCard } = this.state;
    const { setFrontIDParams } = this.props;
    this.props.onNextStep({ geolocation, idType: 'PASSPORT', backOfCard });
    setFrontIDParams({});
    this.setState({
      showCaptureVisa: false
    });
  };

  /** When user uploads visa after Passport.
   * @param {ChangeEvent} e
   */

  handleCaptureVisa = (e) => {
    if (!e.target.files[0]) {
      return;
    }
    const imagefile = e.target.files[0];
    e.target.value = '';

    this.uploadVisaImage(imagefile);
  };

  renderVisaUploadModal = () => {
    const { showCaptureVisa } = this.state;

    const confirmBtns = [
      {
        label: localizedString('skip'),
        onClick: this.handleSkip,
        variant: 'transparent'
      },
      {
        label: localizedString('takePhoto'),
        onClick: this.handleNextStep
      }
    ];

    return (
      <Modal
        isOpen={showCaptureVisa}
        heading={localizedString('capturedForeignPassport')}
        description=""
        buttons={confirmBtns}
        small={false}
      >
        {localizedString('takeAPhotoOfPhysicalVisa')}
      </Modal>
    );
  };

  uploadVisaImage(imagefile) {
    APIs.status('capturingId');
    const { geolocation } = this.state;
    const { setFrontIDParams, onGoBack, onNextStep } = this.props;

    const params = { idType: 'PASSPORT', frontFile: imagefile };

    this.setState({ isUploading: true, showCaptureVisa: false });

    APIs.uploadVisa(params, {})
      .then(({ status, skip = false }) => {
        if (status === 'error') {
          this.setState({
            error: {
              component: CouldNotDetect,
              props: {
                buttons: [
                  {
                    label: 'Back',
                    variant: 'transparent',
                    onClick: () => {
                      setFrontIDParams({});
                      onGoBack();
                    }
                  },
                  {
                    label: 'Try again',
                    onClick: () => {
                      this.setState({
                        error: null,
                        showCaptureVisa: true
                      });
                    }
                  }
                ]
              }
            },
            isUploading: false
          });
          if (skip) {
            setFrontIDParams({});
            onNextStep({ geolocation });
          }
          return;
        }

        this.setState({
          isUploading: false
        });

        setFrontIDParams({});
        onNextStep({ geolocation });
      })
      .catch(() => {
        const error = {
          component: Error500,
          props: {
            onTryAgain: () => {
              setFrontIDParams({});
              onGoBack();
            }
          }
        };
        this.setState({ error, isUploading: false });
      });
  }

  uploadImage(imagefile) {
    APIs.status('capturingId');
    const { country, frontParams, setFrontIDParams, onNextStep, onGoBack } = this.props;
    const { frontFile, geolocation, idType, backOfCard } = this.state;

    let params;

    let idCaptureAttempt = parseInt(getCookie('idCaptureAttempt'), 10);
    idCaptureAttempt = idCaptureAttempt ? idCaptureAttempt + 1 : 1;
    setCookie('idCaptureAttempt', idCaptureAttempt.toString(), 1);

    const nCountry = country === 'OTHER' ? 'NZ' : country;

    if (backOfCard && nCountry === 'AU') {
      params = { ...frontParams, backFile: imagefile };
      setFrontIDParams({});
    } else {
      if (idType !== 'PASSPORT' && backOfCard) {
        params = { idType, frontFile, backFile: imagefile };
      } else {
        params = { idType, frontFile: imagefile };
      }

      if (nCountry === 'AU' || idType === 'PASSPORT') {
        setFrontIDParams(params);
      }
    }

    this.setState({ isUploading: true, backOfCard: false });
    APIs.uploadImage(params)
      .then(({ status, token, msg: error }) => {
        if (status === 'error') {
          if (this.isComponentMounted) {
            this.setState({
              error,
              isUploading: false
            });
          }

          return;
        }

        onNextStep({ tokenId: token, geolocation, idType, backOfCard });
      })
      .catch(() => {
        const error = {
          component: Error500,
          props: {
            onTryAgain: onGoBack
          }
        };
        setFrontIDParams({});
        this.setState({ error, isUploading: false, backOfCard: false });
      });
  }

  gotoCaptureBackofCard() {
    this.element.click();
  }

  render() {
    const { backurl } = document.body.dataset;
    const { backOfCard, isUploading, error, showCaptureVisa, idType, tipStep = 0 } = this.state;
    const { component: Error, props: errorProps } = error || {};

    const tips = {
      DL: localizedString('auvoiCaptureTipsDL'),
      PASSPORT: localizedString('auvoiCaptureTipsPassport')
    };

    const idTypes = [
      {
        label: localizedString('driverLicence'),
        value: 'AUS_AUTO_DRIVERLICENCE=0'
      },
      {
        label: localizedString('passport'),
        value: 'PASSPORT=0'
      }
    ];

    const tipsStates = {
      idType,
      tips,
      onCapture: this.handleNextStep,
      lastButtonText: localizedString('captureID'),
      step: tipStep
    };

    const footerButtons = [];
    if (backurl || isShownPrivacy('AUVOI_FLOW')) {
      footerButtons.push({
        label: localizedString('back'),
        variant: 'transparent',
        onClick: this.handleGoBack
      });
    }
    footerButtons.push({
      label: localizedString('takePhoto'),
      type: 'submit',
      onClick: () => this.onClickedFooterTakePhoto()
    });

    return (
      <div>
        {Error && <Error {...errorProps} />}
        {!Error && (
          <Page buttons={footerButtons}>
            {isUploading && <LoadingSpinner heading={localizedString('viewingYourID')} showTitle />}
            {backOfCard && (
              <BackOfCard idType={idType} onReady={() => this.gotoCaptureBackofCard()} />
            )}
            {!Error && !isUploading && !backOfCard && !showCaptureVisa && (
              <IdSelectionContent
                idTypes={idTypes}
                idType={idType}
                onSelect={this.handleSelectIdType}
              />
            )}
            {!showCaptureVisa && !backOfCard && <Tips {...tipsStates} />}

            <input
              type="file"
              name="image"
              accept="image/*"
              capture="environment"
              onChange={showCaptureVisa ? this.handleCaptureVisa : this.handleCapture}
              ref={(ref) => {
                this.element = ref;
              }}
              style={{ opacity: 0, zIindex: 99 }}
            />
            {this.renderVisaUploadModal()}
          </Page>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Capture);

function mapStateToProps({ capture }) {
  return {
    frontParams: capture.frontParams
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    resetIdInfo: () => dispatch(InfoAction.resetIdInfo()),
    setFrontIDParams: (data) => dispatch(CaptureAction.setFrontIDParams(data))
  };
}
