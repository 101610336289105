import React, { Component } from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';
import { Button } from '@components';
import classNames from 'classnames';
import { localizedString } from '@languages';
import classes from './BackOfCard.style.module.scss';
import animationData from './Animation.json';

/**
 * Back of card animation.
 */
export default class BackOfCard extends Component {
  static propTypes = {
    idType: PropTypes.string,
    onReady: PropTypes.func
  };

  static defaultProps = {
    idType: 'PASSPORT',
    onReady: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      isCompleted: false
    };

    this.container = null;
  }

  /**
   * Start an animation
   * @return {Void}
   */
  componentDidMount() {
    if (this.container) {
      const animation = lottie.loadAnimation({
        container: this.container,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData
      });
      animation.addEventListener('complete', () => this.setState({ isCompleted: true }));
    }
  }

  render() {
    const { isCompleted } = this.state;
    const { onReady, idType } = this.props;

    const takeBackPhotoDesc = idType.match(/DRIVERLICENCE/i)
      ? localizedString('weNeedPhotoOfBackOfLicence')
      : localizedString('weNeedPhotoOfBackOfCard');

    return (
      <div className={classes.wrapper}>
        <div className={classes.title} data-testid="card-back-instruction">
          {takeBackPhotoDesc}
        </div>

        <div
          className={classes.icon}
          ref={(ref) => {
            this.container = ref;
          }}
          data-testid="card-back-inmg"
        />

        <Button
          onClick={onReady}
          className={classNames(classes.button, isCompleted && classes.open)}
          dataTestId="card-back-tkPhoto"
        >
          {localizedString('readyToTakePhoto')}
        </Button>
      </div>
    );
  }
}
