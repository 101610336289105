import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import parse from 'html-react-parser';
import Animation from '@components/Animation';
import { CountrySelect } from '@lib/components/v2/Form';
import { localizedString } from '@languages';
import { LoadingBar } from '../..';
import classes from './IdSelection.style.module.scss';
import iso3 from '../../../../data/iso3.json';

let animationPassport;
import('@FLOW_V2_FLOW/animations/anim_passport.json').then((value) => {
  animationPassport = value;
});

let animationCard;
import('@FLOW_V2_FLOW/animations/anim_01_var_01.json').then((value) => {
  animationCard = value;
});

let animationReview;
import('@FLOW_V2_FLOW/animations/anim_data.json').then((value) => {
  animationReview = value;
});

const IdSelection = ({
  engine4Config = null,
  acceptableDocs = false,
  onShowAcceptableDocsClick,
  isLoading = false,
  idType,
  progressBar,
  onShowCaptureDigitalIdScreen = () => {},
  onSelectManualEntry = () => {}
}) => {
  const {
    SHOW_SEE_ACCEPTABLE_DOCUMENTS = true,
    FLOW_V2_DIGITAL_ID_ACCEPTANCE_TITLE = localizedString('digitalGovId'),
    FLOW_V2_ALLOW_MANUAL_ENTRY = false
  } = process.env;
  const {
    FLOW_V2_ID_SELECTION_HEADING = localizedString('capturePhotoId'),
    FLOW_V2_ID_SELECTION_DESCRIPTION = localizedString('takePhotoOfId'),
    FLOW_V2_ID_SELECTION_SHOW_ACCEPTABLE_DOCS = SHOW_SEE_ACCEPTABLE_DOCUMENTS,
    FLOW_V2_LOADING_DETAILS_HEADING = localizedString('reviewIdData'),
    FLOW_V2_LOADING_DETAILS_DESCRIPTION = localizedString('checkDataMatchesCard'),
    FLOW_V2_LOADING_DETAILS_PROGRESS_BAR = true,
    DEFAULT_COUNTRY_CODE = 'AU',
    DEFAULT_COUNTRY_LABEL = 'Australia'
  } = process.env;

  const [country, setCountry] = useState({
    value: DEFAULT_COUNTRY_CODE,
    label: DEFAULT_COUNTRY_LABEL
  });

  let acceptedCountries = null;
  let acceptedDocs = null;
  let acceptedDocCountryCombo = null;
  if (engine4Config) {
    acceptedCountries = engine4Config.acceptedCountries;
    acceptedDocs = engine4Config.acceptedDocs;
    acceptedDocCountryCombo = engine4Config.acceptedDocCountryCombo;
  }

  let cardTypes = ['Driver Licence', 'Passport'];

  if (acceptedDocs && acceptedDocs.length > 0) {
    cardTypes = acceptedDocs;
  }

  if (acceptedDocCountryCombo && Object.keys(acceptedDocCountryCombo).length > 0) {
    const temp = [];
    const iso3Country = iso3[country.value];
    Object.keys(acceptedDocCountryCombo).forEach((key) => {
      if (
        acceptedDocCountryCombo[key] !== 'NONE' &&
        (acceptedDocCountryCombo[key] === 'ALL' ||
          acceptedDocCountryCombo[key].includes(iso3Country))
      ) {
        temp.push(key);
      }
    });
    cardTypes = temp;
  }

  const isDigitalIdEnabled = !!cardTypes.find((type) => type.match(/digital/i));

  if (isDigitalIdEnabled) {
    cardTypes = cardTypes.filter((type) => !type.match(/digital/i));
  }

  const handleCountryChange = (val) => {
    setCountry(val);
  };

  return (
    <div style={{ height: '100%' }}>
      {!acceptableDocs && !isLoading && (
        <div className={classes.wrapper}>
          <div className={classes.heading} data-testid="cptrId-heading">
            {FLOW_V2_ID_SELECTION_HEADING}
          </div>
          <div className={classes.description} data-testid="cptrId-instruction">
            {parse(FLOW_V2_ID_SELECTION_DESCRIPTION)}
          </div>
          <div className={classes['animation-container']} data-testid="cptrId-animation">
            <Animation animationData={idType !== 'PASSPORT' ? animationCard : animationPassport} />
          </div>

          <table className={classes.link_list}>
            <tbody>
              {FLOW_V2_ID_SELECTION_SHOW_ACCEPTABLE_DOCS && (
                <tr onClick={onShowAcceptableDocsClick} className={classes.link}>
                  <td>See acceptable documents</td>
                  <td>
                    <img alt="" src="images/icons/png/next-videoid.png" />
                  </td>
                </tr>
              )}
              {isDigitalIdEnabled && (
                <tr onClick={onShowCaptureDigitalIdScreen} className={classes.link}>
                  <td>{FLOW_V2_DIGITAL_ID_ACCEPTANCE_TITLE}</td>
                  <td>
                    <img alt="arrow" src="images/icons/png/next-videoid.png" />
                  </td>
                </tr>
              )}
              {FLOW_V2_ALLOW_MANUAL_ENTRY && (
                <tr onClick={onSelectManualEntry} className={classes.link}>
                  <td>Enter details manually instead</td>
                  <td>
                    <img alt="arrow" src="images/icons/png/next-videoid.png" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {acceptableDocs && (
        <div className={classes.wrapper}>
          <div className={classes.heading} data-testid="accepted-heading">
            Accepted ID Documents
          </div>
          <div className={classes.accepted}>
            <CountrySelect
              filter={acceptedCountries}
              value={country}
              onChange={handleCountryChange}
            />
            <ul>
              {cardTypes.map((card, index) => {
                return <li data-testid={`accepted-doc${index}`}>{card}</li>;
              })}
            </ul>
          </div>
        </div>
      )}

      {isLoading && (
        <div className={classes.wrapper}>
          <div className={classnames(classes.heading)} data-testid="id-heading">
            {FLOW_V2_LOADING_DETAILS_HEADING}
          </div>
          <div className={classnames(classes.description)} data-testid="id-instruction">
            {parse(FLOW_V2_LOADING_DETAILS_DESCRIPTION)}
          </div>
          <div className={classes['animation-container']} data-testid="id-animation">
            <Animation animationData={animationReview} />
          </div>
          {FLOW_V2_LOADING_DETAILS_PROGRESS_BAR && <LoadingBar width={progressBar} />}
        </div>
      )}
    </div>
  );
};

IdSelection.propTypes = {
  engine4Config: PropTypes.object,
  acceptableDocs: PropTypes.bool,
  onShowAcceptableDocsClick: PropTypes.func,
  isLoading: PropTypes.bool,
  idType: PropTypes.string,
  progressBar: PropTypes.number,
  onShowCaptureDigitalIdScreen: PropTypes.func,
  onSelectManualEntry: PropTypes.func
};
export default IdSelection;
