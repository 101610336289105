import PropTypes from 'prop-types';
import Modal from '@lib/components/v2/Modal';
import { localizedString } from '@languages';

export const ConfirmAgePrompt = ({ eligibleAge, onConfirm, onReject }) => {
  const buttons = [
    {
      label: localizedString('no'),
      variant: 'transparent',
      onClick: () => onReject()
    },
    {
      label: localizedString('yes'),
      onClick: () => onConfirm()
    }
  ];

  return (
    <Modal
      isOpen
      buttons={buttons}
      heading={localizedString('verifyDetails.ELIGIBLE_AGE_CONFIRMATION', eligibleAge)}
    />
  );
};

ConfirmAgePrompt.propTypes = {
  eligibleAge: PropTypes.number,
  onConfirm: PropTypes.func,
  onReject: PropTypes.func
};
