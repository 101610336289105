import { localizedString } from '@languages';

const { DL_NUMBER_VALIDATION = true } = process.env;

export const firstName = {
  firstName: {
    // isRequired: 'Please enter first name',
    isRegexMatch: {
      message: localizedString('lettersAndSymbolsOnly'),
      regex: /^([a-zA-Z `‘’'-]+)$/
    }
  }
};

export const middleName = {
  middleName: {
    isRegexMatch: {
      message: localizedString('lettersAndSymbolsOnly'),
      regex: /^([a-zA-Z `‘’'-]+)$/
    }
  }
};

export const lastName = {
  lastName: {
    // isRequired: 'Please enter last name ',
    isRegexMatch: {
      message: localizedString('lettersAndSymbolsOnly'),
      regex: /^([a-zA-Z `‘’'-]+)$/
    }
  }
};

export const dateOfBirth = {
  dateOfBirth: {
    // isRequired: 'Please enter date of birth',
    isRegexMatch: {
      message: localizedString('invalidDate'),
      regex: /^([0-9/]+)$/
    }
  }
};

export const expiryDate = {
  expiryDate: {
    // isRequired: 'Please enter date of expiry',
    isRegexMatch: {
      message: localizedString('invalidDate'),
      regex: /^([0-9/]+)$/
    }
  }
};

export const visaStartDate = {
  visaStartDate: {
    // isRequired: 'Please enter Visa Start Date',
    isRegexMatch: {
      message: localizedString('invalidDate'),
      regex: /^([0-9/]+)$/
    }
  }
};

export const visaEndDate = {
  visaEndDate: {
    // isRequired: 'Please enter Visa End Date',
    isRegexMatch: {
      message: localizedString('invalidDate'),
      regex: /^([0-9/]+)$/
    }
  }
};

export const licenceNumber = {
  licenceNumber: {
    // isRequired: 'Please enter licence number',
    isRegexMatch: {
      message: localizedString('lettersAndNumbersOnly'),
      regex: /^([a-zA-Z0-9]+)/
    },
    isExactLength: {
      message: localizedString('licenceNumberMustBe8Chars'),
      length: 8
    }
  }
};
if (!DL_NUMBER_VALIDATION) {
  delete licenceNumber.licenceNumber.isExactLength;
}

export const licenceVersion = {
  licenceVersion: {
    // isRequired: 'Please enter version number',
    isRegexMatch: {
      message: localizedString('numbersOnly'),
      regex: /^([0-9]+)$/
    },
    isExactLength: {
      message: localizedString('versionNumberMustBe3Numbers'),
      length: 3
    }
  }
};

export const passportNumber = {
  passportNumber: {
    // isRequired: 'Please enter passport number',
    isRegexMatch: {
      message: localizedString('lettersAndNumbersOnly'),
      regex: /^([a-zA-Z0-9]+)$/
    },
    isMaxLength: {
      message: localizedString('alphaNumeric9DigitsMaximum'),
      length: 9
    }
  }
};

export const cardNumber = {
  cardNumber: {
    isRequired: localizedString('pleaseEnterCardNumber'),
    isRegexMatch: {
      message: localizedString('lettersAndNumbersOnly'),
      regex: /^([a-zA-Z0-9]+)$/
    }
  }
};

export const idNumber = {
  idNumber: {
    isRequired: localizedString('pleaseEnterCardNumber'),
    isRegexMatch: {
      message: localizedString('lettersAndNumbersOnly'),
      regex: /^([a-zA-Z0-9]+)$/
    }
  }
};
export default {
  firstName,
  lastName,
  middleName,
  dateOfBirth,
  expiryDate,
  visaStartDate,
  visaEndDate,
  licenceNumber,
  licenceVersion,
  passportNumber,
  cardNumber,
  idNumber
};
