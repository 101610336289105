import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InfoAction from '@store/actions/information';
import CaptureAction from '@store/actions/capture';
import Page from '@lib/components/v2/Page';
import { geoLocation } from '@lib/Utils';
import APIs from '@services/APIs';
import { localizedString } from '@languages';
import { CaptureVisa } from '../../components';
import { IdSelectionContent, BackOfCard } from '../../components/Contents';

import { Error500 } from '../../errors';
import { EnableLocation, LocationSettings } from './Capture.error';

const { ENABLE_ONE_DOC_CONDITION = false } = process.env;

class Capture extends Component {
  static propTypes = {
    onNextStep: PropTypes.func,
    onGoBack: PropTypes.func,
    onExit: PropTypes.func,
    selectedDocumentList: PropTypes.array,
    flowType: PropTypes.string,
    resetIdInfo: PropTypes.func,
    onGeoLocation: PropTypes.func,
    onCaptured: PropTypes.func,
    setFrontIDParams: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.element = null;

    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleCapture = this.handleCapture.bind(this);
  }

  /**
   * Return the component's initial state
   * @return {Object}
   */
  getInitialState() {
    return {
      progressBar: 0,
      isUploading: false,
      error: null,
      showCaptureVisa: false
    };
  }

  componentDidMount() {
    const { resetIdInfo, onGeoLocation, onExit } = this.props;
    resetIdInfo();

    if (process.env.LOCATION)
      geoLocation()
        .then((geolocation) => onGeoLocation(geolocation))
        .catch(() => {
          if (!process.env.LOCATION_REQUIRED) {
            onGeoLocation(null);
            return;
          }
          const error = {
            component: EnableLocation,
            props: {
              buttons: [
                {
                  label: localizedString('exit'),
                  variant: 'outline',
                  onClick: () => onExit()
                },
                {
                  label: localizedString('enable'),
                  onClick: () => {
                    const error = {
                      component: LocationSettings,
                      props: {
                        buttons: [
                          {
                            label: localizedString('exit'),
                            variant: 'outline',
                            onClick: () => onExit()
                          },
                          {
                            label: localizedString('checkAgainLabel'),
                            onClick: () => {
                              geoLocation()
                                .then((geolocation) =>
                                  this.setState({
                                    geolocation,
                                    error: null
                                  })
                                )
                                .catch(() => null);
                            }
                          }
                        ]
                      }
                    };
                    this.setState({ error });
                  }
                }
              ]
            }
          };
          this.setState({ error });
        });
  }

  handleNextStep() {
    this.element.click();
  }

  /**
   * Go back to the previous step
   * @param {ClickEvent} e
   * @return {Void}
   */
  async handleGoBack(e) {
    e.preventDefault();

    const { onGoBack } = this.props;

    const { selectedDocumentList } = this.props;

    const capturedDocumentList = selectedDocumentList.filter((doc) => {
      return doc.captured;
    });

    const lastCard =
      capturedDocumentList.length > 0
        ? selectedDocumentList[capturedDocumentList.length - 1]
        : null;

    if (lastCard) {
      this.setState(this.getInitialState());
    }
    onGoBack(lastCard);
  }

  /**
   * When user happy with photo.
   * @param {ChangeEvent} e
   * @param currentDoc
   */
  async handleCapture(e, currentDoc) {
    if (!e.target.files[0]) {
      return;
    }

    const imagefile = e.target.files[0];
    e.target.value = '';

    const { flowType, onCaptured, selectedDocumentList, onNextStep } = this.props;

    let params;
    if (currentDoc.backOfCard) {
      params = { backFile: imagefile, ...currentDoc.params };
    } else {
      params = { frontFile: imagefile, flowType, ocr: currentDoc.ocr };
    }

    onCaptured({ ...currentDoc, params });

    const pendingIDs = selectedDocumentList.filter((doc) => {
      return !doc.captured && currentDoc.type !== doc.type;
    });

    if (pendingIDs.length === 0) {
      onNextStep({ step: 1 });
    } else {
      this.setState(this.getInitialState());
    }
  }

  /**
   * Go to the next step on Visa Upload
   * @return {Void}
   */
  handleSkip = () => {
    const { geolocation } = this.state;
    this.props.onNextStep({ geolocation });
    this.props.setFrontIDParams({});
    this.setState({
      showCaptureVisa: false
    });
  };

  /** When user uploads visa after Passport.
   * @param {ChangeEvent} e
   */

  handleCaptureVisa = (e) => {
    if (!e.target.files[0]) {
      return;
    }
    const imagefile = e.target.files[0];
    e.target.value = '';

    this.uploadVisaImage(imagefile);
  };

  onClickedFooterTakePhoto(backOfCard) {
    if (backOfCard) {
      this.element.click();
    } else {
      this.handleNextStep();
    }
  }

  onClickedCaptureBackCard() {
    this.element.click();
  }

  uploadVisaImage(imagefile) {
    APIs.status('capturingId');
    const { setFrontIDParams, onNextStep, onGoBack } = this.props;
    const { geolocation } = this.state;
    const params = { idType: 'PASSPORT', frontFile: imagefile };

    this.setState({ isUploading: true, showCaptureVisa: false, progressBar: 0 });

    APIs.uploadVisa(
      params,
      {
        before: () => this.setState({ progressBar: 0 }),
        onProgress: (width) => {
          this.setState({ progressBar: width });
        }
      },
      '/api/v4'
    )
      .then(({ status, msg: error, skip = false }) => {
        if (status === 'error') {
          this.setState({
            error,
            isUploading: false
          });
          if (skip) {
            setFrontIDParams({});
            onNextStep({ geolocation });
          }
          return;
        }

        this.setState({
          isUploading: false
        });

        setFrontIDParams({});
        onNextStep({ geolocation });
      })
      .catch(() => {
        const error = {
          component: Error500,
          props: {
            onTryAgain: () => {
              setFrontIDParams({});
              onGoBack();
            }
          }
        };
        this.setState({ error, isUploading: false });
      });
  }

  render() {
    const { progressBar, isUploading, error, showCaptureVisa } = this.state;
    const { component: Error, props: errorProps } = error || {};
    const { selectedDocumentList } = this.props;

    const currentDoc = selectedDocumentList.find((doc) => {
      return !doc.captured;
    });

    const captureVisaProps = {
      onSkip: this.handleSkip,
      onUploadVisa: this.handleNextStep
    };

    let footerButtons = [];
    if (!Error) {
      footerButtons = [
        {
          label: localizedString('back'),
          variant: 'transparent',
          onClick: this.handleGoBack,
          dataTestId: 'docs-back'
        }
      ];
      if (isUploading) {
        footerButtons.push({
          label: isUploading && progressBar < 100 ? 'Uploading' : 'Loading',
          variant: 'transparent',
          loading: true,
          dataTestId: 'docs-uploading'
        });
      } else {
        let captureLabel = localizedString('captureMyID');
        if (ENABLE_ONE_DOC_CONDITION) {
          captureLabel = 'Capture my I.D';
        }

        footerButtons.push({
          label: captureLabel,
          type: 'submit',
          onClick: () => this.onClickedFooterTakePhoto(currentDoc.backOfCard),
          dataTestId: 'docs-capture'
        });
      }
    }

    return (
      <div>
        <input
          type="file"
          name="image"
          accept="image/*"
          capture="environment"
          onChange={
            showCaptureVisa ? this.handleCaptureVisa : (e) => this.handleCapture(e, currentDoc)
          }
          ref={(ref) => {
            this.element = ref;
          }}
          style={{ opacity: 0, zIindex: 99 }}
        />
        {Error && <Error {...errorProps} />}

        {showCaptureVisa && <CaptureVisa {...captureVisaProps} />}
        {!showCaptureVisa && !Error && currentDoc && (
          <Page buttons={footerButtons} forceFillViewPort>
            {currentDoc.backOfCard && (
              <BackOfCard
                idType={currentDoc.type}
                onReady={() => this.onClickedCaptureBackCard()}
              />
            )}
            {!currentDoc.backOfCard && (
              <IdSelectionContent selectedCard={currentDoc} progressBar={progressBar} />
            )}
          </Page>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Capture);

function mapStateToProps({ capture }) {
  return {
    frontParams: capture.frontParams
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    resetIdInfo: () => dispatch(InfoAction.resetIdInfo()),
    setFrontIDParams: (data) => dispatch(CaptureAction.setFrontIDParams(data))
  };
}
