import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactSelect, { components } from 'react-select';

import countries from '@data/countries.json';
import iso3 from '@data/iso3.json';

import classes from './CountrySelect.style.module.scss';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img alt="" src="images/icons/png/search-videoid.png" />
    </components.DropdownIndicator>
  );
};

const Option = (props) => {
  const { value, label } = props;
  return (
    <components.Option {...props}>
      <span style={{ marginRight: '0.625rem' }}>
        <img
          style={{ width: '2rem', height: '1.125rem' }}
          src={`assets-portal/images/flags/${value.toLowerCase()}.png`}
          alt=""
        />
      </span>
      {label}
    </components.Option>
  );
};

Option.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string
};

const SingleValue = ({ children, ...props }) => {
  const { data } = props;
  return (
    <components.SingleValue {...props}>
      <span style={{ marginRight: '0.625rem' }}>
        <img
          style={{ width: '2rem', height: '1.25rem' }}
          src={`assets-portal/images/flags/${data.value.toLowerCase()}.png`}
          alt=""
        />
      </span>{' '}
      {children}
    </components.SingleValue>
  );
};

SingleValue.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object
};

const iso3Toiso2Map = Object.entries(iso3).reduce((acc, [key, value]) => {
  acc[value] = key;
  return acc;
}, {});

export default class CountrySelect extends Component {
  static propTypes = {
    hasError: PropTypes.string,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    required: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    borderBottomOnly: PropTypes.bool,
    paddingLeft30: PropTypes.bool,
    backgroundColor: PropTypes.string,
    disabled: PropTypes.bool,
    filter: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  /**
   * Return the component's initial state
   * @return {Object}
   */
  getInitialState() {
    return {
      opened: false
    };
  }

  render() {
    const {
      className,
      disabled,
      readOnly,
      hasError,
      isLoading = false,
      filter = null,
      paddingLeft30,
      backgroundColor,
      borderBottomOnly,
      ...restProps
    } = this.props;
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color: '#000',
        borderBottom: state.isSelected ? 'none !important' : 'initial',
        // backgroundColor: state.isSelected ? classes.primary50: 'transparent',
        textAlign: 'left'
      }),
      control: (provided, state) => ({
        ...provided,
        width: '100%',
        fontSize: '1rem',
        lineHeight: '0',
        minHeight: '3rem',
        outline: 'none',
        boxShadow: 'none',
        backgroundColor: backgroundColor || '#F4F4F4',
        border: 'transparent',
        borderBottom: borderBottomOnly ? '1px solid rgba(25, 25, 25, 0.32)' : null,
        borderRadius: borderBottomOnly ? '0px' : '0.4375rem', // 7px
        padding: '0.5rem 0.7rem',
        color: '#394448',
        '> div': {
          overflow: state.isFocused ? 'inherit' : 'hidden',
          padding: 0
        },
        paddingLeft: paddingLeft30 ? '35%' : 'auto'
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
      }),
      indicatorsContainer: (provided) => {
        return {
          ...provided,
          '> div': {
            color: '#A5ABC1'
          },
          img: {
            width: '1.5rem',
            height: '1.5rem'
          }
        };
      },
      menu: (provided) => {
        return {
          ...provided,
          padding: '0',
          margin: '0',
          borderRadius: '4px',
          border: '1px solid #dadada',
          boxShadow: '0 10px 20px rgba(0, 0, 0, 0), 0 6px 6px rgba(0, 0, 0, 0.11)'
        };
      },
      menuList: (provided) => {
        return {
          ...provided,
          padding: '0',
          div: {
            padding: '0.8125rem',
            borderBottom: '1px solid #E8E8EC',
            fontSize: '1rem',
            fontWeight: '400'
          },
          'div:last-child': {
            borderBottom: 'none'
          }
        };
      },
      singleValue: (provided, state) => {
        let opacity = state.isDisabled ? 0.5 : 1;
        if (state.selectProps.menuIsOpen) {
          opacity = 0;
        }
        const transition = 'opacity 300ms';

        return {
          ...provided,
          fontSize: '1rem',
          lineHeight: '1.2',
          opacity,
          transition
        };
      }
    };

    let newCountries = [...countries];
    if (filter && filter.length > 0) {
      const convertedFilter = filter.map((code) => {
        return iso3Toiso2Map[code];
      });
      newCountries = newCountries.filter(({ code }) => {
        return convertedFilter.includes(code);
      });
    }

    const options = newCountries.map(({ name, code }) => {
      return {
        value: code,
        label: name
      };
    });

    return (
      <div className={classNames(classes.Select)}>
        <ReactSelect
          className={classNames(
            className,
            'minimal'
            // hasError && classes.hasError
          )}
          // menuIsOpen={true}
          options={options}
          isDisabled={disabled}
          isLoading={isLoading}
          readOnly={readOnly}
          styles={customStyles}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors
              // primary25: classes.primary25,
              // primary: classes.primary50,
              // neutral0: '#fff'
            }
          })}
          components={{ DropdownIndicator, SingleValue, Option }}
          {...restProps}
        />
        {hasError && <div className={classes.hasError}>{hasError}</div>}
      </div>
    );
  }
}
