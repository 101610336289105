import React from 'react';
import PropTypes from 'prop-types';
import { Page } from '@components';
import { IdSelectionContent } from '@components/Contents';
import APIs from '@services/APIs';
import { localizedString } from '@languages';

const IdSelection = ({ onGoBack, onSelect, country }) => {
  APIs.status('selectId');

  return (
    <Page onGoBack={onGoBack} title={localizedString('chooseYourID')}>
      <IdSelectionContent onSelect={onSelect} country={country} />
    </Page>
  );
};

IdSelection.propTypes = {
  onSelect: PropTypes.func,
  onGoBack: PropTypes.func,
  country: PropTypes.string
};

IdSelection.defaultProps = {
  onSelect: () => null
};

export default IdSelection;
