import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { localizedString } from '@languages';
import { AddressFinder } from '../..';
import classes from './VerifyAddress.style.module.scss';

export default function VerifyAddress({
  data,
  countryCode,
  onChange,
  showDetailed,
  onShowDetailed
}) {
  return (
    <div className={classNames(classes.wrapper)}>
      <div className={classes.heading}>{localizedString('whatsYourCurrentAddress')}</div>
      <AddressFinder
        data={data}
        showDetailed={showDetailed}
        onShowDetailed={onShowDetailed}
        countryCode={countryCode}
        onChange={onChange}
      />
    </div>
  );
}

VerifyAddress.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
  countryCode: PropTypes.string,
  showDetailed: PropTypes.bool,
  onShowDetailed: PropTypes.func
};

VerifyAddress.defaultProps = {
  data: {},
  showDetailed: false,
  onShowDetailed: () => null,
  onChange: () => null
};
