import { getLanguage } from '@languages';
import animationDataEnglish from './AnimationEN.data.json';
import animationDataChinese from './AnimationZH.data.json';

const loadAnimationData = () => {
  if (getLanguage() === 'en') {
    return animationDataEnglish;
  }
  if (getLanguage() === 'zh') {
    return animationDataChinese;
  }
  return animationDataEnglish;
};

export const animationData = loadAnimationData();
