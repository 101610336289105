import React, { Component } from 'react';
import { Page } from '@components';
import { DetailMatchContent } from '@components/Contents';
import { FormValidation } from 'calidation';
import { localizedString } from '@languages';
import FormModel from './DetailMatch.form';
import { setCookie } from '../../lib/Utils';
import classes from './DetailMatch.style.module.scss';
import APIs from '../../services/APIs';

export default class DetailMatch extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  /**
   * Return the component's initial state
   * @return {Object}
   */
  getInitialState() {
    return {
      name: '',
      reference: '',
      isProcessing: false,
      errorMsg: ''
    };
  }

  handleChange = (id, value) => {
    this.setState({
      [id]: value
    });
  };

  handleConfirm = ({ isValid }) => {
    // Send Request to Verify
    if (isValid) {
      const params = { ...this.state };
      this.setState({
        isProcessing: true
      });
      APIs.checkDetailMatch(params).then(({ status, message, type, url }) => {
        this.setState({
          isProcessing: false
        });
        if (status === 'success') {
          if (type === 'complete') {
            setCookie('detailMatch', null, -1);
          } else {
            setCookie('detailMatch', 'yes', 1 / 24);
          }
          window.location.href = url;
        } else {
          setCookie('detailMatch', null, -1);
          this.setState({
            errorMsg: message
          });
        }
      });
    }
  };

  render() {
    const { APP_NAME = '' } = process.env;
    const title =
      APP_NAME === 'NAB Bank'
        ? `NAB ${localizedString('detailMatchTitle')}`
        : localizedString('detailMatchTitle');
    const { isProcessing, errorMsg } = this.state;
    const buttons = [{ label: localizedString('continue'), type: 'submit', loading: isProcessing }];
    return (
      <FormValidation
        className={classes.wrapper}
        onSubmit={this.handleConfirm}
        config={FormModel}
        initialValues={{ ...this.state }}
      >
        {({ errors, dirty, submitted }) => (
          <Page title={title} buttons={buttons}>
            <DetailMatchContent
              {...this.state}
              onChange={this.handleChange}
              errors={errors}
              dirty={dirty}
              submitted={submitted}
            />
            {!errors.name && !errors.reference && errorMsg && (
              <div className={classes.error}>{errorMsg}</div>
            )}
          </Page>
        )}
      </FormValidation>
    );
  }
}
