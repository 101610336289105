import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Form from 'react-jsonschema-form';
import { validate } from '@lib/questionnaireCustomValidation';
import { transformErrors } from '@lib/questionnaireCustomErrors';
import { Page, Button } from '@DOC_ONLY_FLOW/components';

import CustomCheckboxes from './CustomCheckboxes';
import classes from './Questionnaire.style.module.scss';

export default class Questionnaire extends Component {
  static propTypes = {
    schemas: PropTypes.object,
    uiSchemas: PropTypes.object,
    onSubmit: PropTypes.func,
    onExit: PropTypes.func
  };

  constructor(props) {
    super(props);

    const { schemas, uiSchemas } = props;
    const schemaKeys = Object.keys(schemas);
    const uiSchemaKeys = Object.keys(uiSchemas);

    this.state = {
      step: 0,
      formData: {},
      schemaKeys,
      uiSchemaKeys,
      stepSchema: schemas[schemaKeys[0]],
      stepUischema: uiSchemas[uiSchemaKeys[0]],
      showReview: false
    };

    this.handleNextStep = this.handleNextStep.bind(this);
    this.handlePreviousStep = this.handlePreviousStep.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleNextStep() {
    const { schemas, uiSchemas } = this.props;
    const { step, schemaKeys, uiSchemaKeys } = this.state;

    if (this.isLastStep()) {
      this.setState({ showReview: true });
      return;
    }

    const nextStep = step + 1;
    const nextStepSchema = schemas[schemaKeys[step + 1]];
    const nextStepUichema = uiSchemas[uiSchemaKeys[step + 1]];

    this.setState({
      step: nextStep,
      stepSchema: nextStepSchema,
      stepUischema: nextStepUichema
    });
  }

  handlePreviousStep() {
    const { schemas, uiSchemas } = this.props;
    const { step, schemaKeys, uiSchemaKeys } = this.state;
    if (this.isFirstStep()) {
      return;
    }

    this.setState({
      step: step - 1,
      stepSchema: schemas[schemaKeys[step - 1]],
      stepUischema: uiSchemas[uiSchemaKeys[step - 1]]
    });
  }

  handleChange({ formData }) {
    this.setState({ formData });
  }

  handleSubmit() {
    const { onSubmit } = this.props;
    const { formData } = this.state;
    onSubmit(formData);
  }

  handleReedit(formKey) {
    const { schemas, uiSchemas } = this.props;
    this.setState({
      showReview: false,
      stepSchema: schemas[formKey],
      stepUischema: uiSchemas[formKey]
    });
  }

  isLastStep() {
    const { step, schemaKeys } = this.state;

    return step === schemaKeys.length - 1;
  }

  isFirstStep() {
    const { step } = this.state;
    return step === 0;
  }

  showReviewContent() {
    const { uiSchemas } = this.props;
    const content = Object.keys(uiSchemas).map((formKey) => {
      const formReview = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(uiSchemas[formKey])) {
        if (Object.prototype.hasOwnProperty.call(value, 'ui:title')) {
          formReview.push(this.renderTitleAndAnswer(key, value));
        } else {
          // eslint-disable-next-line no-restricted-syntax
          for (const [nestedKey, nestedValue] of Object.entries(value)) {
            const titleAndAnswer = this.renderTitleAndAnswer(nestedKey, nestedValue, key);
            if (!titleAndAnswer) {
              // eslint-disable-next-line no-continue
              continue;
            }
            formReview.push(titleAndAnswer);
          }
        }
      }

      return (
        <div className={classNames(classes.questionsGroup)} key={formKey}>
          <div className={classNames(classes.questions)}>{formReview}</div>
          <div className={classNames(classes.edit)}>
            <u
              onClick={() => {
                this.handleReedit(formKey);
              }}
            >
              Edit
            </u>
          </div>
        </div>
      );
    });

    return content;
  }

  renderTitleAndAnswer(key, value, outterKey = '') {
    const { formData } = this.state;
    let answer;

    if (outterKey) {
      if (!Object.prototype.hasOwnProperty.call(formData, outterKey)) {
        return null;
      }

      if (!Object.prototype.hasOwnProperty.call(formData[outterKey], key)) {
        return null;
      }

      answer = formData[outterKey][key];
    } else {
      answer = formData[key];
    }

    if (!answer) {
      return null;
    }

    const title = value['ui:title'];

    return (
      <div key={outterKey + key} className={classNames(classes.question)}>
        <div className={classNames(classes.title)}>{title}</div>
        <div className={classNames(classes.answer)}>
          {Array.isArray(answer) ? answer.join(', ') : answer}
        </div>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderImportantInfo() {
    return (
      <div className={classNames(classes.importantInfo)}>
        <span>Important information</span>
        <p>
          We're collecting your personal information to undertake customer due diligence in
          accordance with our obligations under Anti-Money Laundering and Counter Terrorism
          Financing (AML/CTF) laws.If we do not collect this information this may result in accounts
          being blocked or you being exited as a customer. For more information about how NAB
          handles personal information, please see NAB's{' '}
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.nab.com.au/common/privacy-policy"
          >
            <u>privacy policy</u>
          </a>
          . NAB's privacy policy includes information about how to request access to, or the
          correction of, the information NAB holds about you and also sets out how you can make a
          privacy-related enquiry or complaint. If you are providing personal information of someone
          else to us, please ensure that you obtain their permission before you do so and provide
          them with a copy of NAB's privacy policy.
        </p>
      </div>
    );
  }

  render() {
    const { formData, showReview, stepSchema, stepUischema } = this.state;
    const { onExit } = this.props;

    const widgets = {
      CheckboxesWidget: CustomCheckboxes
    };

    return (
      <Page forceFillViewPort isMessage>
        {!showReview && (
          <Form
            showErrorList={false}
            widgets={widgets}
            validate={validate}
            transformErrors={transformErrors}
            schema={stepSchema}
            uiSchema={stepUischema}
            formData={formData}
            onChange={this.handleChange}
            onSubmit={this.handleNextStep}
            noHtml5Validate
          >
            <div className={classes.footer}>
              <div className={classes.buttonsWrapper}>
                <div className={classes.buttons}>
                  {this.isFirstStep() && (
                    <Button type="button" onClick={onExit} label="Exit" variant="transparent" />
                  )}

                  {!this.isFirstStep() && (
                    <Button
                      type="button"
                      onClick={this.handlePreviousStep}
                      label="Back"
                      variant="transparent"
                    />
                  )}

                  {!this.isLastStep() && <Button type="submit" label="Next" />}

                  {this.isLastStep() && <Button type="submit" label="Preview" />}
                </div>
              </div>
            </div>
          </Form>
        )}
        {showReview && (
          <div className={classNames(classes.review)}>
            <div className={classNames(classes.header)}>
              <h3>Review and submit</h3>
            </div>
            <div className={classNames(classes.questionsGroups)}>
              {this.showReviewContent()}
              {this.renderImportantInfo()}
            </div>
            <div className={classes.footer}>
              <div className={classes.buttonsWrapper}>
                <div className={classes.buttons}>
                  <Button
                    type="button"
                    label="Back"
                    variant="transparent"
                    onClick={() => {
                      this.setState({ showReview: false });
                    }}
                  />
                  <Button type="button" label="Submit" onClick={this.handleSubmit} />
                </div>
              </div>
            </div>
          </div>
        )}
      </Page>
    );
  }
}
