/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'calidation';

import InfoAction from '@store/actions/information';

import APIs from '@services/APIs';
import Address from '@services/Address';

import { getResAddressValue } from '@lib/Utils';
import { localizedString } from '@languages';
import { Error500 } from '../../errors';
import { VerifyAddressContent } from '../../components/Contents';
import { Page, Modal } from '../../components';

class VerifyAddress extends Component {
  static propTypes = {
    onNextStep: PropTypes.func,
    onExit: PropTypes.func,
    onGoBack: PropTypes.func,
    onSubmit: PropTypes.func,
    on500Error: PropTypes.func,
    setAddress: PropTypes.func,
    addresses: PropTypes.object,
    location: PropTypes.string,
    countryCode: PropTypes.string,
    flowType: PropTypes.string,
    idDetails: PropTypes.object,
    appConfig: PropTypes.object
  };

  static defaultProps = {
    onNextStep: () => null,
    onExit: () => null,
    countryCode: 'AU'
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  /**
   * Return the component's initial state
   * @return {Object}
   */
  getInitialState() {
    const { addresses = {}, idDetails = {} } = this.props;
    let homeAddress = '';
    let addressLine1 = '';
    let street_number = '';
    let street_name = '';
    let suburb = '';
    let postcode = '';
    let state_territory = '';
    let isMatch = false;
    let country = '';
    let address = '';
    let showDetailed = false;

    homeAddress = addresses.homeAddress;
    addressLine1 = addresses.addressLine1;
    street_number = addresses.street_number;
    street_name = addresses.street_name;
    suburb = addresses.suburb;
    postcode = addresses.postcode;
    state_territory = addresses.state_territory;
    isMatch = addresses.isMatch;
    country = addresses.country;
    address = idDetails.address;
    showDetailed = addresses.showDetailed;

    const final = {
      /* Form data */
      data: {
        homeAddress: homeAddress || address,
        addressLine1,
        street_number,
        street_name,
        suburb,
        postcode,
        state_territory
      },
      loading: false,
      fatalError: null,
      showDetailed: showDetailed || false,
      disableConfirmBtn: true
    };

    if (showDetailed) {
      final.disableConfirmBtn = false;
    } else if (!showDetailed && country) {
      final.disableConfirmBtn = true;
    } else if (!showDetailed && isMatch) {
      final.disableConfirmBtn = false;
    }

    return final;
  }

  componentDidMount() {
    APIs.status('address');
  }

  /**
   * Handle go back button.
   * @return {Void}
   */
  handleGoBack() {
    const { onGoBack } = this.props;
    onGoBack();
  }

  /**
   * Save addresses and go to the next step
   * @return {Void}
   */
  async handleConfirm({ isValid }) {
    const { data, showDetailed } = this.state;
    data.showDetailed = showDetailed;
    const {
      countryCode,
      idDetails,
      location,
      flowType,
      appConfig,
      onGoBack,
      setAddress,
      onNextStep,
      onSubmit,
      on500Error
    } = this.props;
    if (isValid) {
      let addressData = { ...data };

      addressData.fullAddress = showDetailed
        ? getResAddressValue(addressData)
        : addressData.homeAddress;

      if (!showDetailed) {
        // Validate the address
        const { addressData: addrDataValidated, provider } = await Address.verify(
          addressData.homeAddress,
          countryCode,
          appConfig.dataProvider
        );
        addressData = {
          ...addressData,
          ...addrDataValidated,
          addressApiCalls:
            provider === 'experian'
              ? addressData.addressApiCalls + 1
              : 2 * (addressData.addressApiCalls + 1)
        };
      } else {
        addressData = { ...addressData, manual: true };
      }

      this.setState({ loading: true });
      const params = {
        ...idDetails,
        addressData,
        location,
        flowType,
        countryCode: countryCode === 'OTHER' ? 'NZ' : countryCode
      };

      onSubmit(params)
        .then(({ status, type, msg }) => {
          this.setState({ loading: false });
          if (status !== 'success') {
            if (type === 'cards') {
              this.setState({
                error: {
                  issue: msg,
                  buttons: [
                    {
                      label: localizedString('cancel'),
                      onClick: () => onGoBack()
                    }
                  ]
                }
              });
            } else {
              if (type === 'address') {
                this.setState({
                  data: { ...data, homeAddress: '' }
                });
              }
              this.setState({
                error: {
                  issue: msg,
                  buttons: [
                    {
                      label: localizedString('cancel'),
                      onClick: () =>
                        this.setState({
                          error: null
                        })
                    }
                  ]
                }
              });
            }
            return;
          }
          setAddress({ ...data, showDetailed });
          onNextStep();
        })
        .catch(({ message }) => {
          this.setState({ loading: false });
          console.error('hhh', message);
          const fatalError = {
            component: Error500,
            props: {
              onTryAgain: () => {
                on500Error();
              }
            }
          };
          this.setState({ fatalError, loading: false });
        });
    }
  }

  handleUpdateForm = ({ isValid }) => {
    // console.log('errors', errors)
    this.setState({
      disableConfirmBtn: !isValid
    });
  };

  render() {
    const { data, error, loading, fatalError, showDetailed, disableConfirmBtn } = this.state;
    const { component: Error, props: errorProps } = fatalError || {};
    const { countryCode } = this.props;
    const buttons = [
      { label: localizedString('back'), variant: 'transparent', onClick: this.handleGoBack },
      { label: localizedString('continue'), type: 'submit', loading, disabled: disableConfirmBtn }
    ];

    return (
      <div>
        {Error && <Error {...errorProps} />}
        {error && <Modal isOpen {...error} />}
        <Form onSubmit={this.handleConfirm} onUpdate={this.handleUpdateForm}>
          <Page buttons={buttons}>
            <VerifyAddressContent
              data={data}
              countryCode={countryCode}
              showDetailed={showDetailed}
              onShowDetailed={() => this.setState({ showDetailed: true })}
              onChange={(data) => {
                let disableConfirmBtn = true;
                if (showDetailed) {
                  disableConfirmBtn = false;
                } else if (!showDetailed && data.isMatch) {
                  disableConfirmBtn = false;
                }
                this.setState({ data, disableConfirmBtn });
              }}
            />
          </Page>
        </Form>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyAddress);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ information, appConfig }) {
  return {
    addresses: information.addresses,
    idDetails: information.idDetails,
    appConfig
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    setAddress: (data) => dispatch(InfoAction.setAddress(data))
  };
}
