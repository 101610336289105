import React from 'react';
import PropTypes from 'prop-types';

export const TickIcon = ({ width = 18, height = 20, color = '#00D996' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7629 5.87453C14.2362 5.36885 15.0035 5.36885 15.4767 5.87453C15.95 6.3802 15.95 7.20005 15.4767 7.70572L6.4792 17.3195L0.981252 11.445C0.507996 10.9393 0.507996 10.1194 0.981252 9.61378C1.45451 9.10811 2.22181 9.10811 2.69506 9.61378L6.4792 13.6571L13.7629 5.87453Z"
        fill={color}
      />
    </svg>
  );
};

TickIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};
