export default class Capture {
  static SET_CARD_DETAILS = 'SET_CARD_DETAILS';

  static CLEAR_CARD_DETAILS = 'CLEAR_CARD_DETAILS';

  static setCardDetails(data) {
    return {
      type: this.SET_CARD_DETAILS,
      data
    };
  }

  static clearCardDetails() {
    return {
      type: this.CLEAR_CARD_DETAILS
    };
  }
}
