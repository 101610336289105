import { localizedString } from '@languages';

const Address = {
  homeAddress: {
    isRequired: {
      message: localizedString('pleaseEnterYourHomeAddress'),
      validateIf: ({ fields }) => {
        // console.log('fields',fields)
        return !fields.addressLine1;
      }
    }
  },
  addressLine1: {
    isRequired: {
      message: localizedString('pleaseEnterAddrLine1'),
      validateIf: ({ fields }) => fields.homeAddress === ''
    }
  },
  addressLine2: {
    isRequired: {
      message: localizedString('pleaseEnterAddrLine2'),
      validateIf: () => false
    }
  },
  city: {
    isRequired: {
      message: localizedString('pleaseEnterCity'),
      validateIf: () => false
    }
  },
  suburb: {
    isRequired: {
      message: localizedString('pleaseEnterSuburb'),
      validateIf: () => false
    }
  },
  postcode: {
    isRequired: {
      message: localizedString('pleaseEnterPostcode'),
      validateIf: () => false
    }
  },
  country: {
    isRequired: {
      message: localizedString('pleaseEnterPostcode'),
      validateIf: () => false
    }
  }
};

const { ADDRESS_CUSTOM_FIELDS = [] } = process.env;
if (ADDRESS_CUSTOM_FIELDS.length > 0) {
  ADDRESS_CUSTOM_FIELDS.forEach((field) => {
    const { id, label } = field;
    Address[id] = {
      isRequired: {
        message: localizedString('pleaseEnter') + label + localizedString('pleaseEnterEnd'),
        validateIf: () => false
      }
    };
  });
}

export default Address;
