import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@lib/components/v2/Form';
import classes from './ExitButton.style.module.scss';

const exitButton = {
  label: 'Exit',
  type: 'submit',
  rounded: true,
  className: classes['exit-button-data-check'],
  dataTestId: 'datacheck-exit-prompt-cta'
};

export const ExitButton = ({ onClick }) => {
  return <Button {...exitButton} onClick={onClick} />;
};

ExitButton.propTypes = {
  onClick: PropTypes.func
};
