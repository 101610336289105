import { localizedString } from '@languages';

const data = {
  VISA: [
    localizedString('holdYourIDInHandCopiesNotAccepted'),
    localizedString('makeSureEdgesAreVisibleInView'),
    localizedString('makeSureNoBlurShadowsGlare')
  ]
};

export default data.VISA;
