import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { localizedString } from '@languages';
import Page from '@lib/components/v2/Page';
import Animation from '@components/Animation';
// eslint-disable-next-line import/no-unresolved
import animationReview from '../../_FLOW_V2_FLOW/animations/anim_data.json';
import classes from './LoadingSpinner.style.module.scss';

const { PROCESSING_ID_SPINNER_TITLE = null, PROCESSING_ID_SPINNER_SUBTITLE = null } = process.env;

const LoadingSpinner = ({ title, dark, subtitle = null, type = null }) => {
  let effTitle = title;
  let effSubTitle = subtitle;

  if (type && type === 'processingId' && PROCESSING_ID_SPINNER_TITLE) {
    effTitle = PROCESSING_ID_SPINNER_TITLE;
  }

  if (type && type === 'processingId' && PROCESSING_ID_SPINNER_SUBTITLE) {
    effSubTitle = PROCESSING_ID_SPINNER_SUBTITLE;
  }

  const { LOADING_SPINNER_ANZ_STYLE = false } = process.env;

  return (
    <div>
      {LOADING_SPINNER_ANZ_STYLE && (
        <Page title="  ">
          <div className={classes.wrapper} style={{ height: '100%' }}>
            <div className={classes['animation-container']} data-testid="id-animation">
              <Animation animationData={animationReview} />
            </div>
          </div>
        </Page>
      )}

      {!LOADING_SPINNER_ANZ_STYLE && (
        <div className={classNames(classes.wrapper, dark && classes.dark, 'b-splash')}>
          <svg
            className={classNames(classes.svgDot, classes.top)}
            width="750"
            height="644"
            viewBox="0 0 750 644"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0)">
              <path d="M378.939 636.577C378.855 636.661 378.855 636.772 378.939 636.856C379.022 636.94 379.134 636.94 379.218 636.856C379.301 636.772 379.301 636.661 379.218 636.577C379.134 636.493 379.022 636.493 378.939 636.577Z" />
              <path d="M410.191 640.093C410.108 640.176 410.108 640.288 410.191 640.372C410.275 640.455 410.387 640.455 410.471 640.372C410.554 640.288 410.554 640.176 410.471 640.093C410.387 640.009 410.275 640.009 410.191 640.093Z" />
              <path d="M306.555 637.638C306.471 637.722 306.471 637.833 306.555 637.917C306.639 638.001 306.75 638.001 306.834 637.917C306.918 637.833 306.918 637.722 306.834 637.638C306.75 637.554 306.639 637.554 306.555 637.638Z" />
              <path d="M330.413 616.96C330.302 617.072 330.302 617.239 330.413 617.351C330.525 617.463 330.692 617.463 330.804 617.351C330.915 617.239 330.915 617.072 330.804 616.96C330.72 616.877 330.525 616.849 330.413 616.96Z" />
              <path d="M359.378 604.348C359.266 604.46 359.266 604.627 359.378 604.739C359.489 604.85 359.657 604.85 359.768 604.739C359.88 604.627 359.88 604.46 359.768 604.348C359.657 604.236 359.489 604.236 359.378 604.348Z" />
              <path d="M390.658 600.748C390.519 600.887 390.491 601.138 390.658 601.306C390.798 601.445 391.049 601.473 391.217 601.306C391.356 601.166 391.384 600.915 391.216 600.748C391.077 600.608 390.826 600.58 390.658 600.748Z" />
              <path d="M422.162 607.082C422.27 606.974 422.27 606.799 422.162 606.691C422.054 606.583 421.88 606.583 421.772 606.691C421.664 606.799 421.664 606.974 421.772 607.082C421.88 607.19 422.054 607.19 422.162 607.082Z" />
              <path d="M449.704 621.453C449.592 621.565 449.592 621.732 449.704 621.844C449.815 621.955 449.983 621.955 450.094 621.844C450.206 621.732 450.206 621.565 450.094 621.453C449.983 621.342 449.815 621.341 449.704 621.453Z" />
              <path d="M472.083 643.832C471.999 643.916 471.999 644.027 472.083 644.111C472.167 644.195 472.278 644.195 472.362 644.111C472.446 644.027 472.446 643.916 472.362 643.832C472.278 643.748 472.167 643.748 472.083 643.832Z" />
              <path d="M267.266 631.61C267.182 631.694 267.182 631.806 267.266 631.889C267.35 631.973 267.461 631.973 267.545 631.889C267.629 631.806 267.629 631.694 267.545 631.61C267.461 631.526 267.35 631.526 267.266 631.61Z" />
              <path d="M286.91 606.775C286.799 606.887 286.799 607.054 286.911 607.166C287.022 607.277 287.19 607.277 287.301 607.166C287.413 607.054 287.413 606.887 287.301 606.775C287.217 606.691 287.022 606.664 286.91 606.775Z" />
              <path d="M311.578 587.019C311.438 587.158 311.41 587.409 311.578 587.577C311.717 587.716 311.969 587.744 312.136 587.577C312.275 587.437 312.303 587.186 312.136 587.019C311.969 586.851 311.717 586.879 311.578 587.019Z" />
              <path d="M340.012 573.207C339.817 573.402 339.845 573.709 340.012 573.876C340.208 574.072 340.515 574.044 340.682 573.876C340.849 573.709 340.849 573.374 340.682 573.207C340.487 573.011 340.18 573.039 340.012 573.207Z" />
              <path d="M370.819 566.175C370.623 566.37 370.651 566.677 370.819 566.844C371.014 567.04 371.321 567.012 371.488 566.844C371.684 566.649 371.656 566.342 371.488 566.175C371.349 565.979 371.014 565.979 370.819 566.175Z" />
              <path d="M402.462 566.175C402.267 566.37 402.295 566.677 402.462 566.845C402.657 567.04 402.964 567.012 403.132 566.845C403.327 566.649 403.299 566.342 403.132 566.175C402.964 566.007 402.657 565.98 402.462 566.175Z" />
              <path d="M433.296 573.235C433.101 573.43 433.129 573.737 433.296 573.904C433.492 574.1 433.798 574.072 433.966 573.904C434.133 573.737 434.133 573.402 433.966 573.235C433.798 573.012 433.492 573.039 433.296 573.235Z" />
              <path d="M461.87 587.019C461.731 587.158 461.703 587.409 461.87 587.577C462.01 587.716 462.261 587.744 462.428 587.577C462.568 587.437 462.596 587.186 462.428 587.019C462.261 586.851 462.037 586.851 461.87 587.019Z" />
              <path d="M486.677 606.803C486.565 606.915 486.565 607.082 486.677 607.194C486.789 607.305 486.956 607.305 487.068 607.194C487.179 607.082 487.179 606.915 487.068 606.803C486.956 606.691 486.788 606.691 486.677 606.803Z" />
              <path d="M506.461 631.61C506.377 631.694 506.377 631.806 506.461 631.889C506.545 631.973 506.656 631.973 506.74 631.889C506.824 631.806 506.824 631.694 506.74 631.61C506.656 631.527 506.545 631.527 506.461 631.61Z" />
              <path d="M229.539 626.587C229.456 626.671 229.456 626.782 229.539 626.866C229.623 626.95 229.735 626.95 229.818 626.866C229.902 626.782 229.902 626.671 229.818 626.587C229.735 626.503 229.623 626.503 229.539 626.587Z" />
              <path d="M246.226 599.632C246.087 599.771 246.059 600.022 246.226 600.19C246.366 600.329 246.617 600.357 246.784 600.19C246.924 600.05 246.952 599.799 246.784 599.632C246.645 599.492 246.366 599.492 246.226 599.632Z" />
              <path d="M267.517 576.22C267.322 576.416 267.35 576.723 267.517 576.89C267.712 577.085 268.019 577.057 268.187 576.89C268.382 576.695 268.354 576.388 268.187 576.22C268.047 576.025 267.712 576.025 267.517 576.22Z" />
              <path d="M292.631 556.966C292.408 557.189 292.408 557.58 292.631 557.803C292.854 558.027 293.245 558.027 293.468 557.803C293.691 557.58 293.691 557.189 293.468 556.966C293.245 556.743 292.854 556.743 292.631 556.966Z" />
              <path d="M321.735 543.489C321.997 543.227 321.997 542.802 321.735 542.54C321.473 542.278 321.048 542.278 320.786 542.54C320.524 542.802 320.524 543.227 320.786 543.489C321.048 543.751 321.473 543.751 321.735 543.489Z" />
              <path d="M351.09 533.443C350.811 533.722 350.839 534.14 351.09 534.392C351.369 534.671 351.788 534.643 352.039 534.392C352.318 534.113 352.29 533.694 352.039 533.443C351.788 533.136 351.369 533.164 351.09 533.443Z" />
              <path d="M382.455 529.816C382.148 530.123 382.148 530.625 382.455 530.932C382.762 531.239 383.264 531.239 383.571 530.932C383.878 530.625 383.878 530.123 383.571 529.816C383.264 529.509 382.762 529.509 382.455 529.816Z" />
              <path d="M414.042 531.936C413.735 532.243 413.735 532.746 414.042 533.052C414.349 533.359 414.851 533.359 415.158 533.052C415.465 532.746 415.465 532.243 415.158 531.936C414.851 531.629 414.349 531.629 414.042 531.936Z" />
              <path d="M445.797 540.698C446.059 540.436 446.059 540.011 445.797 539.749C445.535 539.487 445.111 539.487 444.849 539.749C444.587 540.011 444.587 540.436 444.849 540.698C445.111 540.96 445.535 540.96 445.797 540.698Z" />
              <path d="M473.702 552.92C473.478 553.143 473.478 553.534 473.701 553.757C473.925 553.98 474.315 553.98 474.539 553.757C474.762 553.534 474.762 553.143 474.539 552.92C474.315 552.697 473.953 552.669 473.702 552.92Z" />
              <path d="M499.82 571.002C499.625 571.197 499.652 571.504 499.82 571.671C500.015 571.867 500.322 571.839 500.49 571.671C500.685 571.476 500.657 571.169 500.49 571.002C500.294 570.806 500.015 570.806 499.82 571.002Z" />
              <path d="M522.255 593.437C522.115 593.577 522.087 593.828 522.255 593.995C522.394 594.135 522.646 594.163 522.813 593.995C522.953 593.856 522.98 593.605 522.813 593.437C522.673 593.298 522.394 593.297 522.255 593.437Z" />
              <path d="M540.337 619.555C540.225 619.667 540.225 619.834 540.337 619.946C540.448 620.057 540.616 620.057 540.727 619.946C540.839 619.834 540.839 619.667 540.727 619.555C540.644 619.416 540.476 619.416 540.337 619.555Z" />
              <path d="M192.594 621.955C192.483 622.067 192.482 622.234 192.594 622.346C192.706 622.457 192.873 622.457 192.985 622.346C193.096 622.234 193.096 622.067 192.985 621.955C192.873 621.843 192.706 621.843 192.594 621.955Z" />
              <path d="M207.328 593.883C207.188 594.023 207.16 594.274 207.328 594.441C207.467 594.581 207.718 594.609 207.886 594.441C208.053 594.274 208.053 594.051 207.886 593.883C207.718 593.716 207.467 593.744 207.328 593.883Z" />
              <path d="M226.051 568.351C225.856 568.547 225.884 568.853 226.051 569.021C226.247 569.216 226.554 569.188 226.721 569.021C226.916 568.826 226.888 568.519 226.721 568.351C226.554 568.128 226.247 568.156 226.051 568.351Z" />
              <path d="M249.268 546.753C249.53 546.491 249.53 546.067 249.268 545.805C249.006 545.543 248.581 545.543 248.319 545.805C248.057 546.067 248.057 546.491 248.319 546.753C248.581 547.015 249.006 547.015 249.268 546.753Z" />
              <path d="M273.712 526.941C273.405 527.248 273.405 527.75 273.712 528.057C274.019 528.364 274.521 528.364 274.828 528.057C275.135 527.75 275.135 527.248 274.828 526.941C274.521 526.634 274.019 526.634 273.712 526.941Z" />
              <path d="M301.644 512.068C301.309 512.403 301.309 512.961 301.644 513.296C301.979 513.631 302.537 513.631 302.872 513.296C303.206 512.961 303.206 512.403 302.872 512.068C302.537 511.733 301.979 511.733 301.644 512.068Z" />
              <path d="M331.474 501.548C331.083 501.939 331.083 502.553 331.474 502.944C331.864 503.334 332.478 503.334 332.869 502.944C333.259 502.553 333.259 501.939 332.869 501.548C332.478 501.158 331.864 501.158 331.474 501.548Z" />
              <path d="M362.587 495.661C362.196 496.051 362.196 496.665 362.587 497.056C362.977 497.447 363.591 497.447 363.982 497.056C364.373 496.665 364.373 496.051 363.982 495.661C363.591 495.27 362.977 495.27 362.587 495.661Z" />
              <path d="M394.202 494.461C393.812 494.851 393.812 495.465 394.202 495.856C394.593 496.246 395.207 496.246 395.597 495.856C395.988 495.465 395.988 494.851 395.597 494.461C395.235 494.098 394.593 494.07 394.202 494.461Z" />
              <path d="M425.706 498.005C425.316 498.395 425.315 499.009 425.706 499.4C426.097 499.791 426.711 499.791 427.101 499.4C427.492 499.009 427.492 498.395 427.101 498.005C426.683 497.642 426.069 497.642 425.706 498.005Z" />
              <path d="M456.345 506.264C456.01 506.599 456.01 507.157 456.345 507.492C456.68 507.827 457.238 507.827 457.573 507.492C457.908 507.157 457.908 506.599 457.573 506.264C457.238 505.929 456.68 505.929 456.345 506.264Z" />
              <path d="M486.565 520.133C486.874 519.824 486.874 519.325 486.565 519.016C486.257 518.708 485.757 518.708 485.449 519.016C485.141 519.325 485.141 519.824 485.449 520.133C485.757 520.441 486.257 520.441 486.565 520.133Z" />
              <path d="M512.349 535.927C512.07 536.206 512.098 536.624 512.349 536.876C512.628 537.155 513.047 537.127 513.298 536.876C513.549 536.624 513.549 536.178 513.298 535.927C513.019 535.648 512.6 535.676 512.349 535.927Z" />
              <path d="M536.402 556.631C536.179 556.854 536.179 557.245 536.402 557.468C536.626 557.691 537.016 557.691 537.239 557.468C537.463 557.245 537.463 556.854 537.24 556.631C537.016 556.408 536.626 556.408 536.402 556.631Z" />
              <path d="M557.805 581.383C557.99 581.198 557.99 580.898 557.805 580.713C557.62 580.528 557.32 580.528 557.135 580.713C556.95 580.898 556.95 581.198 557.135 581.383C557.32 581.568 557.62 581.568 557.805 581.383Z" />
              <path d="M574.101 607.668C573.989 607.78 573.989 607.947 574.101 608.059C574.213 608.17 574.38 608.17 574.492 608.059C574.603 607.947 574.603 607.78 574.492 607.668C574.408 607.584 574.213 607.556 574.101 607.668Z" />
              <path d="M586.853 636.773C586.769 636.856 586.769 636.968 586.853 637.052C586.937 637.135 587.049 637.135 587.132 637.052C587.216 636.968 587.216 636.856 587.132 636.773C587.048 636.689 586.937 636.689 586.853 636.773Z" />
              <path d="M156.207 617.602C156.095 617.714 156.095 617.881 156.207 617.993C156.319 618.105 156.486 618.105 156.598 617.993C156.709 617.881 156.709 617.714 156.598 617.602C156.486 617.491 156.319 617.491 156.207 617.602Z" />
              <path d="M169.406 588.777C169.266 588.917 169.238 589.168 169.406 589.335C169.545 589.475 169.796 589.503 169.964 589.335C170.131 589.168 170.131 588.945 169.964 588.777C169.824 588.638 169.573 588.61 169.406 588.777Z" />
              <path d="M186.148 561.822C185.925 562.045 185.925 562.436 186.148 562.659C186.371 562.882 186.762 562.882 186.985 562.659C187.209 562.436 187.209 562.045 186.985 561.822C186.734 561.571 186.371 561.599 186.148 561.822Z" />
              <path d="M207.16 538.242C207.422 537.98 207.422 537.556 207.16 537.294C206.898 537.032 206.473 537.032 206.211 537.294C205.949 537.556 205.949 537.98 206.211 538.242C206.473 538.504 206.898 538.504 207.16 538.242Z" />
              <path d="M230.404 516.729C230.743 516.39 230.743 515.84 230.404 515.501C230.065 515.162 229.516 515.162 229.177 515.501C228.838 515.84 228.838 516.39 229.177 516.729C229.516 517.068 230.065 517.068 230.404 516.729Z" />
              <path d="M254.765 496.888C254.374 497.279 254.374 497.893 254.765 498.284C255.156 498.674 255.769 498.674 256.16 498.284C256.551 497.893 256.551 497.279 256.16 496.888C255.797 496.526 255.156 496.498 254.765 496.888Z" />
              <path d="M282.557 481.708C282.139 482.127 282.139 482.797 282.557 483.215C282.976 483.634 283.646 483.634 284.064 483.215C284.483 482.797 284.483 482.127 284.064 481.709C283.674 481.318 282.976 481.29 282.557 481.708Z" />
              <path d="M312.052 470.24C311.606 470.686 311.578 471.44 312.052 471.914C312.499 472.36 313.252 472.388 313.726 471.914C314.173 471.468 314.201 470.714 313.726 470.24C313.252 469.765 312.499 469.793 312.052 470.24Z" />
              <path d="M344.533 464.436C345.026 463.943 345.026 463.143 344.533 462.65C344.04 462.157 343.24 462.157 342.747 462.65C342.254 463.143 342.254 463.942 342.747 464.436C343.24 464.929 344.04 464.929 344.533 464.436Z" />
              <path d="M376.009 460.864C376.502 460.371 376.502 459.571 376.009 459.078C375.516 458.585 374.716 458.585 374.223 459.078C373.73 459.571 373.73 460.371 374.223 460.864C374.716 461.357 375.516 461.357 376.009 460.864Z" />
              <path d="M405.866 459.58C405.364 460.083 405.364 460.864 405.866 461.366C406.369 461.868 407.15 461.868 407.652 461.366C408.154 460.864 408.154 460.082 407.652 459.58C407.178 459.106 406.369 459.078 405.866 459.58Z" />
              <path d="M437.231 464.157C436.728 464.659 436.728 465.44 437.231 465.942C437.733 466.445 438.514 466.445 439.016 465.942C439.519 465.44 439.519 464.659 439.016 464.157C438.514 463.654 437.733 463.654 437.231 464.157Z" />
              <path d="M467.814 472.751C467.367 473.198 467.339 473.951 467.814 474.425C468.26 474.872 469.014 474.9 469.488 474.425C469.934 473.979 469.962 473.226 469.488 472.751C469.014 472.277 468.288 472.277 467.814 472.751Z" />
              <path d="M497.029 485.168C496.611 485.587 496.611 486.256 497.03 486.675C497.448 487.094 498.118 487.094 498.536 486.675C498.955 486.257 498.955 485.587 498.536 485.168C498.118 484.75 497.448 484.75 497.029 485.168Z" />
              <path d="M524.459 501.213C524.069 501.604 524.069 502.218 524.459 502.608C524.85 502.999 525.464 502.999 525.855 502.608C526.245 502.218 526.245 501.604 525.855 501.213C525.464 500.823 524.85 500.823 524.459 501.213Z" />
              <path d="M550.801 521.807C551.109 521.499 551.109 520.999 550.801 520.691C550.493 520.382 549.993 520.382 549.685 520.691C549.376 520.999 549.376 521.499 549.685 521.807C549.993 522.115 550.493 522.115 550.801 521.807Z" />
              <path d="M573.096 544.102C573.358 543.84 573.358 543.416 573.096 543.154C572.834 542.892 572.41 542.892 572.148 543.154C571.886 543.416 571.886 543.84 572.148 544.102C572.41 544.364 572.834 544.364 573.096 544.102Z" />
              <path d="M591.625 568.379C591.429 568.574 591.457 568.881 591.625 569.049C591.792 569.216 592.127 569.216 592.294 569.049C592.49 568.853 592.462 568.546 592.294 568.379C592.127 568.212 591.82 568.184 591.625 568.379Z" />
              <path d="M607.67 595.809C607.53 595.949 607.502 596.2 607.67 596.367C607.809 596.507 608.06 596.535 608.228 596.367C608.367 596.228 608.395 595.977 608.228 595.809C608.06 595.642 607.837 595.642 607.67 595.809Z" />
              <path d="M620.45 625.387C620.527 625.31 620.527 625.185 620.45 625.108C620.373 625.031 620.248 625.031 620.171 625.108C620.094 625.185 620.094 625.31 620.171 625.387C620.248 625.464 620.373 625.464 620.45 625.387Z" />
              <path d="M111.449 643.888C111.365 643.971 111.365 644.083 111.449 644.167C111.532 644.25 111.644 644.25 111.728 644.167C111.811 644.083 111.811 643.971 111.728 643.888C111.644 643.804 111.532 643.804 111.449 643.888Z" />
              <path d="M120.127 613.389C120.015 613.501 120.015 613.668 120.127 613.78C120.238 613.891 120.406 613.891 120.517 613.78C120.629 613.668 120.629 613.501 120.517 613.389C120.434 613.249 120.266 613.249 120.127 613.389Z" />
              <path d="M132.795 584.647C132.98 584.462 132.98 584.162 132.795 583.977C132.61 583.792 132.311 583.792 132.126 583.977C131.941 584.162 131.941 584.462 132.126 584.647C132.311 584.832 132.61 584.832 132.795 584.647Z" />
              <path d="M148.227 556.994C148.458 556.763 148.458 556.388 148.227 556.157C147.995 555.926 147.621 555.926 147.389 556.157C147.158 556.388 147.158 556.763 147.389 556.994C147.621 557.225 147.995 557.225 148.227 556.994Z" />
              <path d="M166.671 531.294C166.979 530.986 166.979 530.486 166.671 530.178C166.363 529.87 165.863 529.87 165.555 530.178C165.247 530.486 165.247 530.986 165.555 531.294C165.863 531.602 166.363 531.602 166.671 531.294Z" />
              <path d="M186.623 506.487C186.288 506.822 186.288 507.38 186.623 507.715C186.958 508.05 187.516 508.05 187.851 507.715C188.185 507.38 188.185 506.822 187.851 506.487C187.516 506.152 186.958 506.152 186.623 506.487Z" />
              <path d="M210.09 485.252C209.672 485.671 209.672 486.34 210.09 486.759C210.509 487.177 211.179 487.177 211.597 486.759C212.016 486.34 212.016 485.671 211.597 485.252C211.178 484.833 210.509 484.833 210.09 485.252Z" />
              <path d="M235.846 466.864C235.399 467.31 235.371 468.064 235.846 468.538C236.292 468.985 237.046 469.012 237.52 468.538C237.967 468.092 237.994 467.338 237.52 466.864C237.046 466.389 236.32 466.389 235.846 466.864Z" />
              <path d="M263.443 451.377C262.913 451.907 262.913 452.8 263.443 453.33C263.973 453.86 264.866 453.86 265.396 453.33C265.927 452.8 265.927 451.907 265.396 451.377C264.866 450.846 263.973 450.846 263.443 451.377Z" />
              <path d="M292.659 439.182C292.073 439.768 292.101 440.689 292.659 441.247C293.217 441.805 294.166 441.805 294.724 441.247C295.31 440.661 295.282 439.741 294.724 439.182C294.166 438.624 293.217 438.624 292.659 439.182Z" />
              <path d="M325.167 432.513C325.737 431.943 325.737 431.018 325.167 430.448C324.597 429.878 323.672 429.878 323.102 430.448C322.532 431.018 322.532 431.943 323.102 432.513C323.673 433.083 324.597 433.083 325.167 432.513Z" />
              <path d="M356.476 427.267C357.092 426.651 357.092 425.651 356.476 425.035C355.859 424.418 354.86 424.418 354.243 425.035C353.627 425.651 353.627 426.651 354.243 427.267C354.86 427.884 355.859 427.883 356.476 427.267Z" />
              <path d="M385.859 423.277C385.245 423.891 385.245 424.896 385.859 425.509C386.473 426.123 387.477 426.123 388.091 425.509C388.705 424.896 388.705 423.891 388.091 423.277C387.477 422.663 386.473 422.663 385.859 423.277Z" />
              <path d="M417.502 425.063C416.888 425.676 416.888 426.681 417.502 427.295C418.116 427.909 419.121 427.909 419.735 427.295C420.349 426.681 420.349 425.676 419.735 425.063C419.121 424.449 418.116 424.449 417.502 425.063Z" />
              <path d="M450.876 432.485C451.446 431.915 451.446 430.99 450.876 430.42C450.306 429.85 449.381 429.85 448.811 430.42C448.241 430.99 448.241 431.915 448.811 432.485C449.381 433.055 450.306 433.055 450.876 432.485Z" />
              <path d="M481.319 441.275C481.89 440.705 481.89 439.781 481.319 439.21C480.749 438.64 479.825 438.64 479.254 439.21C478.684 439.781 478.684 440.705 479.254 441.275C479.825 441.846 480.749 441.846 481.319 441.275Z" />
              <path d="M508.582 451.404C508.052 451.935 508.052 452.828 508.582 453.358C509.112 453.888 510.005 453.888 510.535 453.358C511.065 452.827 511.065 451.935 510.535 451.404C510.005 450.874 509.14 450.846 508.582 451.404Z" />
              <path d="M536.458 466.836C536.012 467.282 535.984 468.036 536.458 468.51C536.905 468.956 537.658 468.984 538.132 468.51C538.579 468.064 538.607 467.31 538.132 466.836C537.686 466.389 536.905 466.389 536.458 466.836Z" />
              <path d="M562.353 485.252C561.935 485.671 561.935 486.34 562.353 486.759C562.772 487.177 563.442 487.177 563.86 486.759C564.279 486.34 564.279 485.671 563.86 485.252C563.469 484.805 562.772 484.833 562.353 485.252Z" />
              <path d="M586.128 506.515C585.793 506.85 585.793 507.408 586.128 507.743C586.463 508.078 587.021 508.078 587.355 507.743C587.69 507.408 587.69 506.85 587.355 506.515C587.048 506.152 586.49 506.152 586.128 506.515Z" />
              <path d="M607.307 530.206C607 530.513 607 531.015 607.307 531.322C607.614 531.629 608.116 531.629 608.423 531.322C608.73 531.015 608.73 530.513 608.423 530.206C608.116 529.899 607.614 529.899 607.307 530.206Z" />
              <path d="M626.617 556.994C626.848 556.763 626.848 556.388 626.617 556.157C626.386 555.926 626.011 555.926 625.78 556.157C625.548 556.388 625.548 556.763 625.78 556.994C626.011 557.225 626.386 557.225 626.617 556.994Z" />
              <path d="M641.155 583.977C640.959 584.173 640.987 584.48 641.155 584.647C641.322 584.815 641.657 584.814 641.825 584.647C641.992 584.48 641.992 584.145 641.825 583.977C641.657 583.81 641.35 583.782 641.155 583.977Z" />
              <path d="M653.433 613.389C653.321 613.501 653.321 613.668 653.433 613.78C653.544 613.891 653.712 613.891 653.823 613.78C653.935 613.668 653.935 613.5 653.823 613.389C653.74 613.249 653.544 613.277 653.433 613.389Z" />
              <path d="M662.278 643.888C662.195 643.971 662.195 644.083 662.278 644.167C662.362 644.25 662.474 644.25 662.557 644.167C662.641 644.083 662.641 643.971 662.557 643.888C662.474 643.804 662.362 643.804 662.278 643.888Z" />
              <path d="M76.1497 639.898C76.066 639.982 76.066 640.093 76.1497 640.177C76.2334 640.261 76.345 640.261 76.4288 640.177C76.5125 640.093 76.5125 639.982 76.4288 639.898C76.345 639.814 76.2334 639.814 76.1497 639.898Z" />
              <path d="M84.27 609.231C84.1584 609.342 84.1584 609.51 84.27 609.621C84.3817 609.733 84.5491 609.733 84.6607 609.621C84.7723 609.51 84.7723 609.342 84.6607 609.231C84.5491 609.119 84.4096 609.091 84.27 609.231Z" />
              <path d="M95.3479 579.457C95.1526 579.652 95.1805 579.959 95.3479 580.127C95.5153 580.294 95.8502 580.294 96.0176 580.127C96.213 579.931 96.1851 579.624 96.0176 579.457C95.8502 579.289 95.5433 579.262 95.3479 579.457Z" />
              <path d="M109.384 551.023C109.161 551.246 109.161 551.637 109.384 551.86C109.607 552.083 109.998 552.083 110.221 551.86C110.444 551.637 110.444 551.246 110.221 551.023C109.97 550.772 109.635 550.772 109.384 551.023Z" />
              <path d="M127.215 525.155C127.523 524.847 127.523 524.347 127.215 524.039C126.906 523.731 126.407 523.731 126.099 524.039C125.79 524.347 125.79 524.847 126.099 525.155C126.407 525.463 126.906 525.463 127.215 525.155Z" />
              <path d="M145.408 498.869C145.018 499.26 145.018 499.874 145.408 500.264C145.799 500.655 146.413 500.655 146.804 500.264C147.194 499.874 147.194 499.26 146.804 498.869C146.385 498.507 145.771 498.507 145.408 498.869Z" />
              <path d="M167.09 475.793C166.643 476.239 166.615 476.993 167.09 477.467C167.536 477.914 168.29 477.942 168.764 477.467C169.211 477.021 169.238 476.267 168.764 475.793C168.318 475.347 167.564 475.319 167.09 475.793Z" />
              <path d="M192.845 456.846C193.339 456.353 193.339 455.553 192.845 455.06C192.352 454.567 191.553 454.567 191.06 455.06C190.566 455.553 190.566 456.353 191.06 456.846C191.553 457.339 192.352 457.339 192.845 456.846Z" />
              <path d="M218.936 438.792C219.506 438.221 219.506 437.297 218.936 436.727C218.366 436.157 217.441 436.157 216.871 436.727C216.301 437.297 216.301 438.222 216.871 438.792C217.441 439.362 218.366 439.362 218.936 438.792Z" />
              <path d="M244.412 421.184C243.799 421.798 243.799 422.803 244.412 423.417C245.026 424.031 246.031 424.031 246.645 423.417C247.259 422.803 247.259 421.798 246.645 421.184C246.031 420.571 245.026 420.571 244.412 421.184Z" />
              <path d="M273.349 408.376C272.652 409.074 272.652 410.19 273.349 410.887C274.047 411.585 275.163 411.585 275.861 410.887C276.558 410.19 276.558 409.074 275.861 408.376C275.135 407.706 274.019 407.706 273.349 408.376Z" />
              <path d="M303.402 398.582C302.677 399.307 302.677 400.479 303.402 401.205C304.128 401.93 305.3 401.93 306.025 401.205C306.751 400.479 306.751 399.307 306.025 398.582C305.3 397.856 304.128 397.856 303.402 398.582Z" />
              <path d="M334.32 391.829C333.566 392.582 333.538 393.838 334.32 394.619C335.073 395.373 336.329 395.401 337.11 394.619C337.892 393.838 337.892 392.61 337.11 391.829C336.329 391.103 335.073 391.075 334.32 391.829Z" />
              <path d="M365.796 388.313C365.042 389.067 365.014 390.322 365.796 391.104C366.549 391.857 367.805 391.885 368.586 391.104C369.34 390.35 369.367 389.094 368.586 388.313C367.805 387.532 366.577 387.532 365.796 388.313Z" />
              <path d="M397.495 387.922C396.742 388.676 396.714 389.931 397.495 390.713C398.276 391.494 399.504 391.494 400.285 390.713C401.039 389.959 401.067 388.703 400.285 387.922C399.504 387.141 398.276 387.141 397.495 387.922Z" />
              <path d="M429.055 390.685C428.301 391.438 428.273 392.694 429.055 393.475C429.808 394.229 431.064 394.257 431.845 393.475C432.599 392.722 432.626 391.466 431.845 390.685C431.064 389.904 429.836 389.904 429.055 390.685Z" />
              <path d="M460.279 396.629C459.554 397.354 459.554 398.526 460.28 399.252C461.005 399.977 462.177 399.977 462.903 399.252C463.628 398.526 463.628 397.354 462.902 396.629C462.177 395.903 461.005 395.903 460.279 396.629Z" />
              <path d="M490.723 405.642C490.026 406.339 490.025 407.455 490.723 408.153C491.421 408.85 492.537 408.85 493.234 408.153C493.932 407.455 493.932 406.339 493.234 405.641C492.509 404.972 491.393 404.972 490.723 405.642Z" />
              <path d="M520.106 417.668C519.464 418.31 519.464 419.37 520.106 420.012C520.748 420.654 521.808 420.654 522.45 420.012C523.092 419.37 523.092 418.31 522.45 417.668C521.836 416.999 520.776 416.999 520.106 417.668Z" />
              <path d="M550.354 434.69C550.925 434.12 550.925 433.195 550.354 432.625C549.784 432.055 548.86 432.055 548.289 432.625C547.719 433.195 547.719 434.12 548.289 434.69C548.86 435.26 549.784 435.26 550.354 434.69Z" />
              <path d="M576.696 452.158C577.235 451.618 577.235 450.744 576.696 450.204C576.157 449.665 575.282 449.665 574.743 450.204C574.203 450.744 574.203 451.618 574.743 452.158C575.282 452.697 576.157 452.697 576.696 452.158Z" />
              <path d="M599.41 470.407C598.964 470.854 598.936 471.607 599.41 472.082C599.857 472.528 600.61 472.556 601.084 472.081C601.531 471.635 601.559 470.882 601.084 470.407C600.61 469.933 599.857 469.961 599.41 470.407Z" />
              <path d="M621.957 492.954C621.566 493.344 621.566 493.958 621.957 494.349C622.347 494.74 622.961 494.74 623.352 494.349C623.743 493.958 623.743 493.344 623.352 492.954C622.961 492.563 622.347 492.563 621.957 492.954Z" />
              <path d="M642.159 517.621C641.852 517.928 641.852 518.431 642.159 518.738C642.466 519.045 642.969 519.045 643.276 518.738C643.583 518.431 643.583 517.928 643.276 517.621C642.941 517.287 642.466 517.314 642.159 517.621Z" />
              <path d="M659.739 544.074C659.46 544.354 659.488 544.772 659.739 545.023C659.99 545.274 660.437 545.274 660.688 545.023C660.967 544.744 660.939 544.326 660.688 544.074C660.437 543.823 660.018 543.795 659.739 544.074Z" />
              <path d="M674.668 572.23C674.473 572.425 674.5 572.732 674.668 572.9C674.863 573.095 675.17 573.067 675.338 572.9C675.533 572.704 675.505 572.397 675.338 572.23C675.198 572.035 674.863 572.035 674.668 572.23Z" />
              <path d="M686.695 601.613C686.555 601.753 686.527 602.004 686.694 602.171C686.862 602.339 687.085 602.339 687.253 602.171C687.392 602.032 687.42 601.78 687.253 601.613C687.085 601.446 686.834 601.473 686.695 601.613Z" />
              <path d="M695.819 632.168C695.736 632.252 695.736 632.364 695.819 632.447C695.903 632.531 696.014 632.531 696.098 632.447C696.182 632.364 696.182 632.252 696.098 632.168C695.987 632.112 695.875 632.113 695.819 632.168Z" />
              <path d="M41.1858 636.186C41.2629 636.109 41.2629 635.984 41.1858 635.907C41.1088 635.83 40.9839 635.83 40.9068 635.907C40.8297 635.984 40.8297 636.109 40.9068 636.186C40.9838 636.263 41.1088 636.263 41.1858 636.186Z" />
              <path d="M48.5805 605.129C48.4689 605.241 48.4689 605.408 48.5805 605.52C48.6921 605.631 48.8596 605.631 48.9712 605.52C49.0828 605.408 49.0828 605.241 48.9712 605.129C48.8875 604.99 48.6921 605.018 48.5805 605.129Z" />
              <path d="M58.905 575.048C58.7097 575.244 58.7375 575.55 58.905 575.718C59.1003 575.913 59.4073 575.885 59.5747 575.718C59.7421 575.55 59.7421 575.216 59.5747 575.048C59.4072 574.881 59.0724 574.881 58.905 575.048Z" />
              <path d="M72.8292 547.06C73.0912 546.798 73.0912 546.373 72.8292 546.111C72.5672 545.849 72.1424 545.849 71.8804 546.111C71.6184 546.373 71.6184 546.798 71.8804 547.06C72.1424 547.322 72.5672 547.322 72.8292 547.06Z" />
              <path d="M88.5394 519.575C88.8476 519.266 88.8476 518.767 88.5394 518.459C88.2312 518.15 87.7315 518.15 87.4232 518.459C87.115 518.767 87.115 519.266 87.4232 519.575C87.7315 519.883 88.2312 519.883 88.5394 519.575Z" />
              <path d="M105.338 492.284C104.947 492.675 104.947 493.289 105.338 493.679C105.728 494.07 106.342 494.07 106.733 493.679C107.124 493.289 107.124 492.675 106.733 492.284C106.342 491.893 105.728 491.893 105.338 492.284Z" />
              <path d="M125.512 467.812C125.066 468.259 125.038 469.012 125.512 469.486C125.959 469.933 126.712 469.961 127.187 469.486C127.633 469.04 127.661 468.286 127.187 467.812C126.712 467.338 125.959 467.366 125.512 467.812Z" />
              <path d="M149.733 447.219C150.273 446.679 150.273 445.805 149.733 445.265C149.194 444.726 148.319 444.726 147.78 445.266C147.241 445.805 147.241 446.679 147.78 447.219C148.319 447.758 149.194 447.758 149.733 447.219Z" />
              <path d="M171.973 424.812C171.359 425.426 171.359 426.43 171.973 427.044C172.587 427.658 173.591 427.658 174.205 427.044C174.819 426.43 174.819 425.426 174.205 424.812C173.564 424.226 172.587 424.198 171.973 424.812Z" />
              <path d="M197.868 406.618C197.171 407.316 197.171 408.432 197.868 409.13C198.566 409.827 199.682 409.827 200.38 409.13C201.077 408.432 201.077 407.316 200.38 406.618C199.654 405.949 198.538 405.949 197.868 406.618Z" />
              <path d="M225.298 390.852C224.545 391.605 224.517 392.861 225.298 393.642C226.051 394.396 227.307 394.424 228.088 393.642C228.842 392.889 228.87 391.633 228.088 390.852C227.279 390.099 226.051 390.099 225.298 390.852Z" />
              <path d="M254.067 377.71C253.258 378.519 253.258 379.802 254.067 380.612C254.877 381.421 256.16 381.421 256.969 380.612C257.779 379.802 257.779 378.519 256.969 377.71C256.188 376.872 254.877 376.9 254.067 377.71Z" />
              <path d="M283.925 367.162C283.088 367.999 283.06 369.366 283.925 370.231C284.762 371.069 286.129 371.096 286.994 370.231C287.831 369.394 287.859 368.027 286.994 367.162C286.101 366.325 284.762 366.325 283.925 367.162Z" />
              <path d="M314.564 359.349C313.671 360.241 313.699 361.665 314.564 362.53C315.429 363.395 316.88 363.395 317.745 362.53C318.61 361.665 318.61 360.214 317.745 359.349C316.88 358.484 315.457 358.456 314.564 359.349Z" />
              <path d="M345.788 354.298C344.868 355.218 344.868 356.725 345.788 357.646C346.709 358.567 348.216 358.567 349.137 357.646C350.058 356.725 350.058 355.218 349.137 354.298C348.216 353.377 346.709 353.377 345.788 354.298Z" />
              <path d="M377.404 352.205C376.483 353.126 376.483 354.633 377.404 355.553C378.325 356.474 379.832 356.474 380.752 355.553C381.673 354.633 381.673 353.126 380.752 352.205C379.804 351.256 378.325 351.284 377.404 352.205Z" />
              <path d="M409.075 352.902C408.154 353.823 408.154 355.33 409.075 356.251C409.996 357.172 411.503 357.172 412.424 356.251C413.345 355.33 413.345 353.823 412.424 352.902C411.503 351.982 409.996 351.982 409.075 352.902Z" />
              <path d="M440.579 356.446C439.658 357.367 439.658 358.874 440.579 359.794C441.5 360.715 443.007 360.715 443.928 359.795C444.849 358.874 444.849 357.367 443.928 356.446C443.007 355.525 441.5 355.525 440.579 356.446Z" />
              <path d="M471.664 362.864C470.772 363.757 470.799 365.18 471.664 366.045C472.557 366.938 473.981 366.91 474.846 366.045C475.711 365.18 475.711 363.729 474.846 362.864C473.98 361.999 472.557 361.971 471.664 362.864Z" />
              <path d="M505.177 375.114C506.025 374.267 506.025 372.892 505.177 372.045C504.33 371.197 502.956 371.197 502.108 372.045C501.26 372.892 501.26 374.267 502.108 375.114C502.956 375.962 504.33 375.962 505.177 375.114Z" />
              <path d="M534.421 386.778C535.192 386.007 535.192 384.758 534.421 383.988C533.651 383.217 532.401 383.217 531.631 383.988C530.86 384.758 530.86 386.007 531.631 386.778C532.401 387.549 533.651 387.549 534.421 386.778Z" />
              <path d="M559.926 398.442C559.2 399.167 559.2 400.339 559.926 401.065C560.651 401.79 561.823 401.79 562.549 401.065C563.274 400.339 563.274 399.167 562.549 398.442C561.851 397.688 560.651 397.716 559.926 398.442Z" />
              <path d="M589.253 417.78C589.9 417.132 589.9 416.083 589.253 415.436C588.606 414.788 587.556 414.788 586.909 415.436C586.262 416.083 586.262 417.132 586.909 417.78C587.556 418.427 588.606 418.427 589.253 417.78Z" />
              <path d="M614.339 436.839C614.909 436.268 614.909 435.344 614.339 434.774C613.769 434.204 612.844 434.203 612.274 434.774C611.704 435.344 611.704 436.268 612.274 436.839C612.844 437.409 613.769 437.409 614.339 436.839Z" />
              <path d="M635.797 456.288C635.295 456.79 635.295 457.571 635.797 458.073C636.3 458.576 637.081 458.576 637.583 458.073C638.085 457.571 638.085 456.79 637.583 456.288C637.081 455.785 636.3 455.785 635.797 456.288Z" />
              <path d="M657.311 479.812C656.893 480.23 656.893 480.9 657.311 481.318C657.73 481.737 658.4 481.737 658.818 481.318C659.237 480.9 659.237 480.23 658.818 479.812C658.4 479.393 657.73 479.393 657.311 479.812Z" />
              <path d="M676.649 505.176C676.314 505.511 676.314 506.069 676.649 506.404C676.984 506.739 677.542 506.739 677.877 506.404C678.212 506.069 678.212 505.511 677.877 505.176C677.542 504.841 676.984 504.841 676.649 505.176Z" />
              <path d="M694.675 533.192C694.983 532.884 694.983 532.384 694.675 532.076C694.367 531.768 693.867 531.768 693.559 532.076C693.251 532.384 693.251 532.884 693.559 533.192C693.867 533.5 694.367 533.5 694.675 533.192Z" />
              <path d="M708.906 561.263C709.137 561.032 709.137 560.657 708.906 560.426C708.675 560.195 708.3 560.195 708.069 560.426C707.838 560.657 707.838 561.032 708.069 561.263C708.3 561.494 708.675 561.494 708.906 561.263Z" />
              <path d="M720.04 589.977C719.901 590.116 719.873 590.367 720.04 590.535C720.207 590.702 720.431 590.702 720.598 590.535C720.738 590.395 720.766 590.144 720.598 589.977C720.431 589.809 720.18 589.837 720.04 589.977Z" />
              <path d="M729.193 620.392C729.081 620.504 729.081 620.671 729.193 620.783C729.304 620.895 729.472 620.895 729.583 620.783C729.695 620.671 729.695 620.504 729.583 620.392C729.528 620.281 729.304 620.281 729.193 620.392Z" />
              <path d="M5.6917 631.945C5.60799 632.029 5.608 632.14 5.69171 632.224C5.77542 632.308 5.88702 632.308 5.97073 632.224C6.05444 632.14 6.05445 632.029 5.97074 631.945C5.88703 631.861 5.77541 631.861 5.6917 631.945Z" />
              <path d="M12.9189 600.999C12.7794 601.139 12.7514 601.39 12.9189 601.557C13.0584 601.697 13.3096 601.725 13.477 601.557C13.6165 601.418 13.6444 601.167 13.477 600.999C13.3095 600.832 13.0863 600.832 12.9189 600.999Z" />
              <path d="M22.6853 570.807C22.49 571.002 22.5179 571.309 22.6853 571.477C22.8806 571.672 23.1876 571.644 23.355 571.477C23.5504 571.281 23.5225 570.974 23.355 570.807C23.2155 570.612 22.8806 570.612 22.6853 570.807Z" />
              <path d="M35.8003 542.4C36.0623 542.138 36.0623 541.713 35.8003 541.451C35.5383 541.189 35.1136 541.189 34.8516 541.451C34.5896 541.713 34.5896 542.138 34.8516 542.4C35.1136 542.662 35.5383 542.662 35.8003 542.4Z" />
              <path d="M49.3338 513.185C48.999 513.52 48.999 514.078 49.3338 514.412C49.6687 514.747 50.2268 514.747 50.5616 514.412C50.8965 514.078 50.8965 513.52 50.5616 513.185C50.2268 512.85 49.6687 512.85 49.3338 513.185Z" />
              <path d="M66.0206 486.229C65.6021 486.648 65.602 487.317 66.0206 487.736C66.4392 488.154 67.1088 488.154 67.5274 487.736C67.946 487.317 67.946 486.648 67.5275 486.229C67.1368 485.783 66.4671 485.783 66.0206 486.229Z" />
              <path d="M84.8839 460.725C84.3816 461.227 84.3816 462.008 84.8839 462.511C85.3861 463.013 86.1675 463.013 86.6697 462.511C87.172 462.008 87.172 461.227 86.6697 460.725C86.1675 460.222 85.3861 460.222 84.8839 460.725Z" />
              <path d="M105.756 436.838C105.17 437.424 105.198 438.345 105.756 438.903C106.342 439.489 107.263 439.461 107.821 438.903C108.407 438.317 108.379 437.397 107.821 436.838C107.235 436.252 106.314 436.28 105.756 436.838Z" />
              <path d="M128.47 414.738C127.829 415.38 127.829 416.44 128.47 417.082C129.112 417.724 130.172 417.724 130.814 417.082C131.456 416.44 131.456 415.38 130.814 414.738C130.172 414.096 129.112 414.096 128.47 414.738Z" />
              <path d="M152.887 394.619C152.161 395.345 152.161 396.517 152.887 397.242C153.612 397.968 154.784 397.968 155.51 397.242C156.235 396.517 156.235 395.345 155.51 394.619C154.784 393.894 153.612 393.894 152.887 394.619Z" />
              <path d="M181.795 379.439C182.597 378.638 182.597 377.339 181.795 376.537C180.994 375.736 179.695 375.736 178.893 376.537C178.092 377.339 178.092 378.638 178.893 379.439C179.695 380.241 180.994 380.241 181.795 379.439Z" />
              <path d="M209.421 363.813C210.299 362.935 210.299 361.51 209.42 360.632C208.542 359.754 207.118 359.754 206.239 360.632C205.361 361.51 205.361 362.935 206.239 363.813C207.118 364.692 208.542 364.692 209.421 363.813Z" />
              <path d="M234.786 347.043C233.837 347.991 233.837 349.554 234.786 350.503C235.734 351.452 237.297 351.452 238.246 350.503C239.194 349.554 239.194 347.992 238.246 347.043C237.297 346.094 235.734 346.094 234.786 347.043Z" />
              <path d="M264.392 335.909C263.387 336.913 263.387 338.531 264.392 339.536C265.396 340.541 267.015 340.541 268.019 339.536C269.024 338.531 269.024 336.913 268.019 335.908C267.015 334.904 265.396 334.904 264.392 335.909Z" />
              <path d="M294.835 327.231C293.803 328.263 293.803 329.937 294.835 330.97C295.868 332.002 297.542 332.002 298.575 330.97C299.607 329.937 299.607 328.263 298.575 327.231C297.542 326.198 295.868 326.198 294.835 327.231Z" />
              <path d="M329.716 324.887C330.779 323.823 330.779 322.099 329.716 321.036C328.652 319.973 326.928 319.973 325.865 321.036C324.802 322.099 324.802 323.823 325.865 324.887C326.928 325.95 328.652 325.95 329.716 324.887Z" />
              <path d="M361.192 321.371C362.255 320.308 362.255 318.584 361.192 317.52C360.128 316.457 358.404 316.457 357.341 317.52C356.278 318.584 356.278 320.308 357.341 321.371C358.404 322.434 360.128 322.434 361.192 321.371Z" />
              <path d="M392.947 320.478C394.056 319.368 394.056 317.569 392.947 316.46C391.837 315.35 390.038 315.35 388.929 316.46C387.819 317.569 387.819 319.368 388.929 320.478C390.038 321.587 391.837 321.587 392.947 320.478Z" />
              <path d="M424.506 322.013C425.57 320.949 425.57 319.225 424.506 318.162C423.443 317.099 421.719 317.099 420.656 318.162C419.592 319.225 419.592 320.949 420.656 322.013C421.719 323.076 423.443 323.076 424.506 322.013Z" />
              <path d="M455.927 326.198C456.99 325.135 456.99 323.411 455.926 322.347C454.863 321.284 453.139 321.284 452.076 322.347C451.012 323.411 451.012 325.135 452.076 326.198C453.139 327.261 454.863 327.261 455.927 326.198Z" />
              <path d="M483.105 329.156C482.073 330.188 482.073 331.863 483.105 332.895C484.138 333.927 485.812 333.927 486.844 332.895C487.877 331.863 487.877 330.188 486.844 329.156C485.812 328.123 484.138 328.123 483.105 329.156Z" />
              <path d="M513.493 338.448C512.488 339.453 512.488 341.071 513.493 342.076C514.498 343.08 516.116 343.08 517.121 342.076C518.125 341.071 518.125 339.453 517.12 338.448C516.116 337.444 514.497 337.444 513.493 338.448Z" />
              <path d="M543.016 350.168C542.067 351.117 542.067 352.679 543.016 353.628C543.964 354.577 545.527 354.577 546.476 353.628C547.425 352.679 547.424 351.117 546.476 350.168C545.527 349.219 543.964 349.219 543.016 350.168Z" />
              <path d="M574.743 367.552C575.621 366.674 575.621 365.249 574.743 364.371C573.864 363.493 572.44 363.493 571.562 364.371C570.683 365.249 570.683 366.674 571.562 367.552C572.44 368.431 573.864 368.431 574.743 367.552Z" />
              <path d="M598.88 380.807C598.071 381.616 598.071 382.9 598.88 383.709C599.689 384.518 600.973 384.518 601.782 383.709C602.591 382.9 602.591 381.616 601.782 380.807C600.973 379.998 599.661 380.026 598.88 380.807Z" />
              <path d="M624.747 399.419C624.022 400.144 624.022 401.316 624.747 402.042C625.473 402.767 626.645 402.767 627.37 402.042C628.096 401.316 628.096 400.144 627.37 399.419C626.645 398.693 625.473 398.693 624.747 399.419Z" />
              <path d="M649.135 420.124C648.521 420.738 648.521 421.742 649.135 422.356C649.749 422.97 650.754 422.97 651.368 422.356C651.982 421.742 651.982 420.738 651.368 420.124C650.726 419.538 649.721 419.538 649.135 420.124Z" />
              <path d="M673.635 444.623C674.175 444.084 674.175 443.209 673.635 442.67C673.096 442.131 672.221 442.131 671.682 442.67C671.143 443.209 671.143 444.084 671.682 444.623C672.221 445.163 673.096 445.163 673.635 444.623Z" />
              <path d="M693.978 468.649C694.44 468.187 694.44 467.437 693.978 466.975C693.515 466.513 692.766 466.513 692.303 466.975C691.841 467.437 691.841 468.187 692.303 468.649C692.766 469.111 693.515 469.111 693.978 468.649Z" />
              <path d="M710.915 492.842C710.525 493.233 710.525 493.846 710.915 494.237C711.306 494.628 711.92 494.628 712.311 494.237C712.701 493.846 712.701 493.233 712.311 492.842C711.892 492.479 711.278 492.479 710.915 492.842Z" />
              <path d="M728.467 521.276C728.775 520.968 728.775 520.468 728.467 520.16C728.159 519.852 727.659 519.852 727.351 520.16C727.043 520.468 727.043 520.968 727.351 521.276C727.659 521.585 728.159 521.585 728.467 521.276Z" />
              <path d="M741.554 548.707C741.331 548.93 741.331 549.321 741.554 549.544C741.778 549.767 742.168 549.767 742.391 549.544C742.615 549.321 742.615 548.93 742.391 548.707C742.14 548.511 741.778 548.483 741.554 548.707Z" />
              <path d="M12.9467 509.501C13.2858 509.162 13.2858 508.612 12.9467 508.273C12.6077 507.934 12.058 507.934 11.7189 508.273C11.3799 508.612 11.3799 509.162 11.7189 509.501C12.058 509.84 12.6077 509.84 12.9467 509.501Z" />
              <path d="M27.4291 480.676C27.0105 481.094 27.0105 481.764 27.4291 482.183C27.8476 482.601 28.5173 482.601 28.9359 482.183C29.3544 481.764 29.3545 481.094 28.9359 480.676C28.5174 480.257 27.8476 480.257 27.4291 480.676Z" />
              <path d="M45.1482 454.362C44.646 454.865 44.646 455.646 45.1482 456.148C45.6505 456.651 46.4318 456.651 46.9341 456.148C47.4364 455.646 47.4364 454.865 46.9341 454.362C46.4318 453.86 45.6505 453.86 45.1482 454.362Z" />
              <path d="M64.6812 429.36C64.0673 429.974 64.0673 430.979 64.6812 431.593C65.2951 432.207 66.2996 432.207 66.9135 431.593C67.5274 430.979 67.5274 429.974 66.9135 429.36C66.2996 428.746 65.2951 428.746 64.6812 429.36Z" />
              <path d="M86.1117 405.977C85.4141 406.674 85.4141 407.791 86.1117 408.488C86.8093 409.186 87.9255 409.186 88.6231 408.488C89.3207 407.79 89.3207 406.674 88.6231 405.977C87.8976 405.307 86.7814 405.307 86.1117 405.977Z" />
              <path d="M109.188 384.295C108.435 385.048 108.407 386.304 109.188 387.085C109.942 387.839 111.198 387.866 111.979 387.085C112.732 386.332 112.76 385.076 111.979 384.295C111.225 383.541 109.942 383.541 109.188 384.295Z" />
              <path d="M133.772 364.343C132.879 365.236 132.907 366.659 133.772 367.524C134.637 368.389 136.088 368.389 136.953 367.524C137.818 366.659 137.818 365.208 136.953 364.343C136.088 363.478 134.637 363.478 133.772 364.343Z" />
              <path d="M159.807 346.345C158.858 347.294 158.858 348.857 159.807 349.805C160.756 350.754 162.318 350.754 163.267 349.805C164.216 348.856 164.216 347.294 163.267 346.345C162.318 345.396 160.756 345.396 159.807 346.345Z" />
              <path d="M187.125 330.356C186.093 331.389 186.093 333.063 187.125 334.095C188.158 335.128 189.832 335.128 190.864 334.095C191.897 333.063 191.897 331.389 190.864 330.356C189.832 329.324 188.158 329.324 187.125 330.356Z" />
              <path d="M219.55 320.478C220.659 319.368 220.659 317.569 219.55 316.46C218.44 315.35 216.641 315.35 215.532 316.46C214.422 317.569 214.422 319.368 215.532 320.478C216.641 321.587 218.44 321.587 219.55 320.478Z" />
              <path d="M249.1 308.953C250.241 307.813 250.241 305.964 249.1 304.823C247.96 303.683 246.111 303.683 244.971 304.823C243.83 305.964 243.83 307.813 244.971 308.953C246.111 310.094 247.96 310.094 249.1 308.953Z" />
              <path d="M279.488 299.717C280.675 298.531 280.675 296.607 279.488 295.42C278.301 294.233 276.377 294.233 275.191 295.42C274.004 296.607 274.004 298.531 275.191 299.717C276.377 300.904 278.301 300.904 279.488 299.717Z" />
              <path d="M305.969 288.304C304.742 289.532 304.769 291.513 305.969 292.713C307.169 293.913 309.178 293.913 310.378 292.713C311.606 291.485 311.578 289.504 310.378 288.304C309.178 287.104 307.197 287.076 305.969 288.304Z" />
              <path d="M337.278 283.505C336.022 284.76 336.022 286.825 337.278 288.081C338.533 289.337 340.598 289.337 341.854 288.081C343.11 286.825 343.11 284.76 341.854 283.505C340.543 282.249 338.533 282.249 337.278 283.505Z" />
              <path d="M368.865 281.161C367.61 282.416 367.61 284.481 368.865 285.737C370.121 286.993 372.186 286.993 373.442 285.737C374.697 284.481 374.697 282.416 373.442 281.161C372.158 279.877 370.121 279.905 368.865 281.161Z" />
              <path d="M400.537 281.133C399.281 282.388 399.281 284.453 400.537 285.709C401.792 286.965 403.857 286.965 405.113 285.709C406.369 284.453 406.369 282.388 405.113 281.133C403.857 279.877 401.792 279.877 400.537 281.133Z" />
              <path d="M432.152 283.505C430.896 284.76 430.896 286.825 432.152 288.081C433.408 289.337 435.473 289.337 436.728 288.081C437.984 286.825 437.984 284.76 436.728 283.505C435.473 282.249 433.408 282.249 432.152 283.505Z" />
              <path d="M463.572 288.304C462.344 289.532 462.372 291.513 463.572 292.713C464.8 293.941 466.781 293.913 467.981 292.713C469.209 291.485 469.181 289.504 467.981 288.304C466.753 287.076 464.8 287.076 463.572 288.304Z" />
              <path d="M494.518 295.42C493.346 296.592 493.318 298.517 494.518 299.717C495.69 300.889 497.615 300.917 498.815 299.717C499.987 298.545 500.015 296.62 498.815 295.42C497.643 294.248 495.69 294.248 494.518 295.42Z" />
              <path d="M524.878 304.851C523.734 305.995 523.734 307.837 524.878 308.981C526.022 310.125 527.864 310.125 529.008 308.981C530.152 307.837 530.152 305.995 529.008 304.851C527.891 303.679 526.022 303.707 524.878 304.851Z" />
              <path d="M558.447 320.506C559.556 319.396 559.556 317.597 558.447 316.487C557.337 315.378 555.538 315.378 554.428 316.487C553.319 317.597 553.319 319.396 554.428 320.506C555.538 321.615 557.337 321.615 558.447 320.506Z" />
              <path d="M583.142 330.356C582.109 331.388 582.109 333.063 583.142 334.095C584.174 335.128 585.849 335.128 586.881 334.095C587.914 333.063 587.914 331.388 586.881 330.356C585.849 329.324 584.174 329.324 583.142 330.356Z" />
              <path d="M610.711 346.373C609.763 347.322 609.763 348.885 610.711 349.833C611.66 350.782 613.223 350.782 614.171 349.833C615.12 348.885 615.12 347.322 614.171 346.373C613.195 345.396 611.688 345.396 610.711 346.373Z" />
              <path d="M640.234 367.524C641.113 366.646 641.113 365.222 640.234 364.343C639.356 363.465 637.931 363.465 637.053 364.343C636.175 365.222 636.175 366.646 637.053 367.524C637.931 368.403 639.356 368.403 640.234 367.524Z" />
              <path d="M662.027 384.295C661.274 385.048 661.246 386.304 662.027 387.085C662.781 387.839 664.036 387.866 664.818 387.085C665.571 386.332 665.599 385.076 664.818 384.295C664.008 383.541 662.781 383.541 662.027 384.295Z" />
              <path d="M685.383 405.977C684.685 406.674 684.685 407.791 685.383 408.488C686.081 409.186 687.197 409.186 687.894 408.488C688.592 407.791 688.592 406.674 687.894 405.977C687.169 405.307 686.053 405.307 685.383 405.977Z" />
              <path d="M707.092 429.36C706.479 429.974 706.479 430.979 707.093 431.593C707.706 432.206 708.711 432.206 709.325 431.593C709.939 430.979 709.939 429.974 709.325 429.36C708.683 428.774 707.678 428.774 707.092 429.36Z" />
              <path d="M727.044 454.334C726.542 454.837 726.542 455.618 727.044 456.12C727.546 456.623 728.328 456.622 728.83 456.12C729.332 455.618 729.332 454.837 728.83 454.334C728.356 453.86 727.518 453.86 727.044 454.334Z" />
              <path d="M745.014 480.676C744.596 481.094 744.596 481.764 745.014 482.183C745.433 482.601 746.103 482.601 746.521 482.183C746.94 481.764 746.94 481.094 746.521 480.676C746.131 480.229 745.433 480.257 745.014 480.676Z" />
              <path d="M5.99856 448.419C5.46838 448.949 5.46841 449.842 5.9986 450.372C6.52879 450.902 7.42173 450.902 7.95192 450.372C8.4821 449.842 8.48209 448.949 7.9519 448.419C7.3938 447.861 6.52875 447.889 5.99856 448.419Z" />
              <path d="M26.7314 424.896C27.3479 424.279 27.3479 423.28 26.7314 422.663C26.115 422.047 25.1155 422.047 24.4991 422.663C23.8827 423.28 23.8827 424.279 24.4991 424.896C25.1156 425.512 26.115 425.512 26.7314 424.896Z" />
              <path d="M44.7018 398.219C43.9763 398.945 43.9763 400.117 44.7018 400.842C45.4273 401.568 46.5993 401.568 47.3248 400.842C48.0503 400.117 48.0503 398.945 47.3248 398.219C46.6272 397.466 45.4273 397.494 44.7018 398.219Z" />
              <path d="M66.5787 375.282C65.7695 376.091 65.7695 377.375 66.5787 378.184C67.3879 378.993 68.6715 378.993 69.4808 378.184C70.29 377.375 70.29 376.091 69.4808 375.282C68.6715 374.473 67.3879 374.473 66.5787 375.282Z" />
              <path d="M89.9066 353.851C88.9858 354.772 88.9858 356.279 89.9066 357.2C90.8274 358.121 92.3343 358.121 93.2551 357.2C94.1759 356.279 94.176 354.772 93.2551 353.851C92.3343 352.93 90.8275 352.93 89.9066 353.851Z" />
              <path d="M114.714 334.179C113.709 335.183 113.709 336.802 114.714 337.806C115.718 338.811 117.337 338.811 118.341 337.806C119.346 336.802 119.346 335.184 118.341 334.179C117.337 333.174 115.718 333.174 114.714 334.179Z" />
              <path d="M144.766 320.199C145.876 319.089 145.876 317.29 144.766 316.18C143.657 315.071 141.858 315.071 140.748 316.18C139.639 317.29 139.639 319.089 140.748 320.199C141.858 321.308 143.657 321.308 144.766 320.199Z" />
              <path d="M168.011 300.135C166.839 301.307 166.811 303.233 168.011 304.433C169.183 305.605 171.108 305.632 172.308 304.433C173.48 303.261 173.508 301.335 172.308 300.135C171.108 298.935 169.211 298.935 168.011 300.135Z" />
              <path d="M196.306 285.96C195.05 287.216 195.05 289.281 196.306 290.536C197.561 291.792 199.626 291.792 200.882 290.536C202.138 289.281 202.138 287.216 200.882 285.96C199.626 284.704 197.561 284.704 196.306 285.96Z" />
              <path d="M225.493 273.85C224.154 275.189 224.154 277.366 225.493 278.705C226.833 280.045 229.009 280.045 230.349 278.705C231.688 277.366 231.688 275.19 230.349 273.85C229.009 272.511 226.833 272.511 225.493 273.85Z" />
              <path d="M260.513 268.799C261.885 267.428 261.885 265.204 260.513 263.832C259.142 262.461 256.918 262.461 255.546 263.832C254.175 265.204 254.175 267.428 255.546 268.799C256.918 270.171 259.142 270.171 260.513 268.799Z" />
              <path d="M291.264 261.042C292.682 259.624 292.682 257.325 291.264 255.907C289.846 254.489 287.547 254.489 286.129 255.907C284.712 257.325 284.712 259.624 286.129 261.042C287.547 262.459 289.846 262.459 291.264 261.042Z" />
              <path d="M322.489 255.377C323.937 253.929 323.937 251.58 322.489 250.131C321.04 248.683 318.691 248.683 317.243 250.131C315.794 251.58 315.794 253.929 317.243 255.377C318.691 256.826 321.04 256.826 322.489 255.377Z" />
              <path d="M348.663 246.504C347.184 247.983 347.156 250.411 348.663 251.917C350.17 253.424 352.569 253.424 354.076 251.917C355.555 250.439 355.583 248.011 354.076 246.504C352.569 244.997 350.169 244.997 348.663 246.504Z" />
              <path d="M380.334 245.136C378.855 246.615 378.827 249.043 380.334 250.55C381.813 252.029 384.241 252.056 385.747 250.55C387.226 249.071 387.254 246.643 385.747 245.136C384.241 243.629 381.813 243.657 380.334 245.136Z" />
              <path d="M412.005 245.945C410.526 247.424 410.498 249.852 412.005 251.359C413.484 252.838 415.912 252.866 417.419 251.359C418.926 249.852 418.926 247.452 417.419 245.945C415.94 244.466 413.484 244.466 412.005 245.945Z" />
              <path d="M443.621 249.043C442.17 250.494 442.17 252.838 443.621 254.289C445.072 255.74 447.416 255.74 448.867 254.289C450.318 252.838 450.318 250.494 448.867 249.043C447.444 247.564 445.072 247.592 443.621 249.043Z" />
              <path d="M480.091 259.395C481.509 257.977 481.509 255.678 480.091 254.261C478.674 252.843 476.375 252.843 474.957 254.261C473.539 255.679 473.539 257.977 474.957 259.395C476.375 260.813 478.674 260.813 480.091 259.395Z" />
              <path d="M510.842 266.651C512.214 265.279 512.214 263.055 510.842 261.684C509.471 260.312 507.247 260.312 505.875 261.684C504.504 263.055 504.504 265.279 505.875 266.651C507.247 268.022 509.471 268.022 510.842 266.651Z" />
              <path d="M541.034 276.026C542.375 274.685 542.375 272.511 541.034 271.171C539.694 269.83 537.52 269.83 536.179 271.171C534.838 272.511 534.838 274.685 536.179 276.026C537.52 277.367 539.694 277.367 541.034 276.026Z" />
              <path d="M565.869 282.779C564.613 284.035 564.613 286.1 565.869 287.355C567.125 288.611 569.19 288.611 570.446 287.355C571.701 286.1 571.701 284.035 570.445 282.779C569.19 281.523 567.125 281.523 565.869 282.779Z" />
              <path d="M598.964 300.749C600.15 299.563 600.15 297.639 598.964 296.452C597.777 295.266 595.853 295.266 594.666 296.452C593.48 297.639 593.48 299.563 594.666 300.749C595.853 301.936 597.777 301.936 598.964 300.749Z" />
              <path d="M626.477 316.041C627.587 314.931 627.587 313.132 626.477 312.023C625.368 310.913 623.569 310.913 622.459 312.023C621.349 313.132 621.349 314.931 622.459 316.041C623.569 317.151 625.368 317.151 626.477 316.041Z" />
              <path d="M649.163 329.463C648.131 330.495 648.131 332.17 649.163 333.202C650.196 334.235 651.87 334.235 652.903 333.202C653.935 332.17 653.935 330.495 652.903 329.463C651.87 328.431 650.196 328.43 649.163 329.463Z" />
              <path d="M674.556 348.717C673.607 349.666 673.607 351.229 674.556 352.177C675.505 353.126 677.068 353.126 678.016 352.177C678.965 351.229 678.965 349.666 678.016 348.717C677.068 347.769 675.505 347.768 674.556 348.717Z" />
              <path d="M701.735 372.826C702.582 371.979 702.582 370.604 701.735 369.757C700.887 368.909 699.513 368.909 698.665 369.757C697.818 370.604 697.818 371.979 698.665 372.826C699.513 373.674 700.887 373.674 701.735 372.826Z" />
              <path d="M721.268 392.359C720.542 393.084 720.542 394.256 721.268 394.982C721.993 395.707 723.165 395.707 723.891 394.982C724.616 394.256 724.616 393.084 723.891 392.359C723.221 391.633 722.021 391.605 721.268 392.359Z" />
              <path d="M742.252 416.413C741.61 417.054 741.61 418.115 742.252 418.757C742.894 419.398 743.954 419.398 744.596 418.757C745.238 418.115 745.238 417.054 744.596 416.413C743.926 415.743 742.894 415.771 742.252 416.413Z" />
              <path d="M6.8637 393.866C7.63426 393.095 7.63426 391.846 6.86371 391.075C6.09315 390.305 4.84382 390.305 4.07326 391.075C3.3027 391.846 3.3027 393.095 4.07326 393.866C4.84381 394.636 6.09314 394.636 6.8637 393.866Z" />
              <path d="M27.9313 370.231C28.7789 369.383 28.7789 368.009 27.9313 367.161C27.0837 366.314 25.7094 366.314 24.8618 367.161C24.0142 368.009 24.0142 369.383 24.8618 370.231C25.7094 371.078 27.0837 371.078 27.9313 370.231Z" />
              <path d="M47.0736 344.559C46.1249 345.508 46.1249 347.071 47.0736 348.019C48.0224 348.968 49.585 348.968 50.5338 348.019C51.4825 347.07 51.4825 345.508 50.5338 344.559C49.585 343.61 48.0224 343.61 47.0736 344.559Z" />
              <path d="M70.6527 323.436C69.5924 324.496 69.5924 326.226 70.6528 327.287C71.7131 328.347 73.4432 328.347 74.5035 327.287C75.5639 326.226 75.5639 324.496 74.5035 323.436C73.4711 322.348 71.741 322.348 70.6527 323.436Z" />
              <path d="M95.5992 303.902C94.4551 305.046 94.4551 306.888 95.5992 308.032C96.7432 309.176 98.5849 309.176 99.729 308.032C100.873 306.888 100.873 305.046 99.729 303.902C98.6129 302.786 96.7433 302.758 95.5992 303.902Z" />
              <path d="M121.69 286.016C120.434 287.272 120.434 289.337 121.69 290.592C122.945 291.848 125.01 291.848 126.266 290.592C127.522 289.337 127.522 287.272 126.266 286.016C124.982 284.732 122.945 284.76 121.69 286.016Z" />
              <path d="M153.752 274.715C155.092 273.374 155.092 271.2 153.752 269.859C152.411 268.519 150.237 268.519 148.896 269.859C147.555 271.2 147.555 273.374 148.896 274.715C150.237 276.056 152.411 276.056 153.752 274.715Z" />
              <path d="M182.214 260.651C183.632 259.233 183.632 256.935 182.214 255.517C180.796 254.099 178.497 254.099 177.079 255.517C175.662 256.935 175.662 259.233 177.079 260.651C178.497 262.069 180.796 262.069 182.214 260.651Z" />
              <path d="M206.156 243.015C204.677 244.494 204.649 246.922 206.156 248.429C207.635 249.908 210.062 249.936 211.569 248.429C213.048 246.95 213.076 244.522 211.569 243.016C210.034 241.537 207.635 241.537 206.156 243.015Z" />
              <path d="M235.902 232.412C234.339 233.975 234.339 236.542 235.902 238.105C237.464 239.667 240.031 239.667 241.594 238.105C243.157 236.542 243.157 233.975 241.594 232.412C240.031 230.849 237.464 230.849 235.902 232.412Z" />
              <path d="M266.373 223.817C264.755 225.436 264.783 228.031 266.373 229.621C267.992 231.24 270.587 231.212 272.177 229.621C273.796 228.003 273.768 225.408 272.177 223.817C270.587 222.227 267.964 222.227 266.373 223.817Z" />
              <path d="M297.291 217.176C295.645 218.823 295.645 221.502 297.291 223.148C298.937 224.794 301.616 224.794 303.263 223.148C304.909 221.501 304.909 218.823 303.263 217.176C301.616 215.53 298.937 215.53 297.291 217.176Z" />
              <path d="M334.655 218.655C336.335 216.975 336.335 214.252 334.655 212.572C332.975 210.892 330.251 210.892 328.572 212.572C326.892 214.252 326.892 216.975 328.572 218.655C330.251 220.335 332.975 220.335 334.655 218.655Z" />
              <path d="M366.354 216.2C368.08 214.473 368.08 211.675 366.354 209.949C364.628 208.223 361.83 208.223 360.103 209.949C358.377 211.675 358.377 214.473 360.103 216.199C361.83 217.926 364.628 217.926 366.354 216.2Z" />
              <path d="M398.053 215.697C399.779 213.971 399.779 211.173 398.053 209.447C396.327 207.721 393.529 207.721 391.803 209.447C390.077 211.173 390.077 213.971 391.803 215.697C393.529 217.423 396.327 217.423 398.053 215.697Z" />
              <path d="M423.53 211.037C421.856 212.712 421.856 215.446 423.53 217.121C425.204 218.795 427.939 218.795 429.613 217.121C431.287 215.446 431.287 212.712 429.613 211.037C427.939 209.363 425.204 209.363 423.53 211.037Z" />
              <path d="M461.089 220.692C462.769 219.012 462.769 216.289 461.089 214.609C459.409 212.929 456.686 212.929 455.006 214.609C453.326 216.289 453.326 219.012 455.006 220.692C456.686 222.372 459.409 222.372 461.089 220.692Z" />
              <path d="M486.286 220.218C484.64 221.864 484.64 224.543 486.286 226.189C487.933 227.836 490.612 227.836 492.258 226.189C493.904 224.543 493.904 221.864 492.258 220.218C490.612 218.571 487.933 218.571 486.286 220.218Z" />
              <path d="M517.121 227.836C515.502 229.454 515.53 232.049 517.12 233.64C518.739 235.258 521.334 235.23 522.925 233.64C524.543 232.021 524.515 229.426 522.925 227.836C521.334 226.245 518.739 226.217 517.121 227.836Z" />
              <path d="M547.508 237.462C545.973 238.997 545.973 241.453 547.508 242.987C549.043 244.522 551.499 244.522 553.033 242.987C554.568 241.453 554.568 238.997 553.033 237.462C551.526 235.956 549.015 235.956 547.508 237.462Z" />
              <path d="M582.472 254.289C583.921 252.84 583.921 250.492 582.472 249.043C581.024 247.594 578.675 247.594 577.226 249.043C575.778 250.492 575.778 252.84 577.226 254.289C578.675 255.738 581.024 255.738 582.472 254.289Z" />
              <path d="M611.074 267.432C612.446 266.06 612.446 263.837 611.074 262.465C609.703 261.093 607.479 261.093 606.107 262.465C604.736 263.837 604.736 266.06 606.107 267.432C607.479 268.804 609.702 268.804 611.074 267.432Z" />
              <path d="M634.123 277.701C632.811 279.012 632.839 281.105 634.123 282.389C635.434 283.7 637.527 283.672 638.811 282.389C640.122 281.077 640.094 278.984 638.811 277.701C637.527 276.417 635.407 276.417 634.123 277.701Z" />
              <path d="M661.078 294.722C659.851 295.95 659.879 297.931 661.078 299.131C662.278 300.331 664.287 300.331 665.487 299.131C666.715 297.903 666.687 295.922 665.487 294.722C664.287 293.522 662.306 293.494 661.078 294.722Z" />
              <path d="M686.974 313.474C685.857 314.59 685.857 316.376 686.973 317.492C688.09 318.608 689.876 318.608 690.992 317.492C692.108 316.376 692.108 314.59 690.992 313.474C689.876 312.358 688.09 312.358 686.974 313.474Z" />
              <path d="M711.669 333.816C710.664 334.82 710.664 336.439 711.669 337.443C712.673 338.448 714.292 338.448 715.296 337.443C716.301 336.439 716.301 334.82 715.296 333.816C714.264 332.839 712.646 332.839 711.669 333.816Z" />
              <path d="M734.913 355.665C733.992 356.585 733.992 358.092 734.913 359.013C735.834 359.934 737.341 359.934 738.262 359.013C739.182 358.092 739.182 356.585 738.262 355.665C737.313 354.716 735.834 354.744 734.913 355.665Z" />
              <path d="M5.07776 336.16C4.07321 337.164 4.0732 338.783 5.07775 339.787C6.0823 340.792 7.70076 340.792 8.70532 339.787C9.70987 338.783 9.70987 337.164 8.70532 336.16C7.70076 335.155 6.05441 335.183 5.07776 336.16Z" />
              <path d="M27.6245 313.892C26.5083 315.008 26.5083 316.794 27.6245 317.91C28.7406 319.027 30.5265 319.027 31.6427 317.91C32.7589 316.794 32.7589 315.008 31.6427 313.892C30.5265 312.776 28.7127 312.804 27.6245 313.892Z" />
              <path d="M51.4546 293.02C50.2268 294.248 50.2547 296.229 51.4546 297.429C52.6824 298.657 54.6636 298.629 55.8635 297.429C57.0913 296.201 57.0634 294.22 55.8635 293.02C54.6357 291.793 52.6545 291.82 51.4546 293.02Z" />
              <path d="M76.429 273.627C75.0896 274.966 75.0896 277.143 76.429 278.482C77.7684 279.821 79.9449 279.821 81.2843 278.482C82.6237 277.143 82.6237 274.966 81.2843 273.627C79.9449 272.287 77.7684 272.287 76.429 273.627Z" />
              <path d="M107.737 260.958C109.155 259.54 109.155 257.241 107.738 255.823C106.32 254.405 104.021 254.405 102.603 255.823C101.185 257.241 101.185 259.54 102.603 260.958C104.021 262.376 106.32 262.376 107.737 260.958Z" />
              <path d="M129.754 239.556C128.219 241.09 128.219 243.546 129.754 245.081C131.289 246.615 133.744 246.615 135.279 245.081C136.814 243.546 136.814 241.09 135.279 239.556C133.744 238.021 131.261 238.049 129.754 239.556Z" />
              <path d="M157.826 225.046C156.207 226.664 156.235 229.259 157.826 230.85C159.444 232.468 162.039 232.44 163.63 230.85C165.248 229.231 165.22 226.636 163.63 225.046C162.067 223.427 159.444 223.427 157.826 225.046Z" />
              <path d="M186.734 212.209C185.06 213.883 185.06 216.618 186.734 218.292C188.409 219.966 191.143 219.966 192.817 218.292C194.492 216.618 194.492 213.883 192.817 212.209C191.143 210.535 188.409 210.535 186.734 212.209Z" />
              <path d="M222.731 207.522C224.488 205.765 224.488 202.916 222.731 201.159C220.974 199.403 218.125 199.403 216.369 201.159C214.612 202.916 214.612 205.765 216.369 207.522C218.126 209.278 220.974 209.278 222.731 207.522Z" />
              <path d="M246.589 191.923C244.747 193.765 244.747 196.722 246.589 198.564C248.431 200.406 251.389 200.406 253.23 198.564C255.072 196.723 255.072 193.765 253.23 191.923C251.388 190.081 248.431 190.081 246.589 191.923Z" />
              <path d="M277.339 184.556C275.47 186.426 275.442 189.468 277.339 191.365C279.237 193.263 282.251 193.263 284.148 191.365C286.018 189.496 286.046 186.454 284.148 184.556C282.251 182.659 279.209 182.687 277.339 184.556Z" />
              <path d="M308.508 179.059C306.583 180.985 306.611 184.082 308.508 185.98C310.434 187.905 313.531 187.877 315.429 185.979C317.326 184.082 317.326 180.957 315.429 179.059C313.531 177.162 310.406 177.162 308.508 179.059Z" />
              <path d="M346.933 182.435C348.844 180.524 348.844 177.426 346.933 175.515C345.022 173.604 341.923 173.604 340.012 175.515C338.101 177.426 338.101 180.524 340.012 182.435C341.923 184.346 345.022 184.346 346.933 182.435Z" />
              <path d="M378.632 180.873C380.589 178.916 380.589 175.743 378.632 173.786C376.675 171.828 373.501 171.828 371.544 173.786C369.587 175.743 369.587 178.916 371.544 180.873C373.501 182.83 376.675 182.83 378.632 180.873Z" />
              <path d="M403.271 174.036C401.318 175.99 401.318 179.171 403.271 181.124C405.225 183.077 408.406 183.077 410.359 181.124C412.312 179.171 412.312 175.99 410.359 174.036C408.406 172.083 405.225 172.083 403.271 174.036Z" />
              <path d="M434.943 176.24C433.017 178.166 433.045 181.263 434.943 183.161C436.868 185.086 439.965 185.058 441.863 183.161C443.788 181.235 443.76 178.138 441.863 176.24C439.965 174.343 436.868 174.315 434.943 176.24Z" />
              <path d="M466.391 180.231C464.465 182.156 464.493 185.254 466.391 187.151C468.316 189.077 471.414 189.049 473.311 187.151C475.236 185.226 475.208 182.129 473.311 180.231C471.414 178.334 468.288 178.334 466.391 180.231Z" />
              <path d="M497.588 186.202C495.718 188.072 495.69 191.113 497.588 193.011C499.485 194.908 502.499 194.908 504.396 193.011C506.294 191.113 506.294 188.1 504.396 186.202C502.499 184.305 499.457 184.333 497.588 186.202Z" />
              <path d="M528.478 194.099C526.692 195.885 526.664 198.815 528.478 200.629C530.263 202.415 533.193 202.443 535.007 200.629C536.793 198.843 536.821 195.913 535.007 194.099C533.193 192.286 530.263 192.314 528.478 194.099Z" />
              <path d="M558.754 203.726C556.996 205.484 556.996 208.33 558.754 210.088C560.512 211.846 563.358 211.846 565.116 210.088C566.874 208.33 566.874 205.484 565.116 203.726C563.358 201.968 560.511 201.968 558.754 203.726Z" />
              <path d="M594.555 221.334C596.235 219.654 596.235 216.93 594.555 215.25C592.875 213.571 590.151 213.571 588.472 215.251C586.792 216.93 586.792 219.654 588.472 221.334C590.151 223.013 592.875 223.013 594.555 221.334Z" />
              <path d="M617.492 228.477C615.874 230.096 615.902 232.691 617.492 234.282C619.111 235.9 621.706 235.872 623.296 234.282C624.915 232.663 624.887 230.068 623.296 228.478C621.678 226.859 619.083 226.887 617.492 228.477Z" />
              <path d="M645.675 243.49C644.196 244.969 644.168 247.397 645.675 248.904C647.154 250.383 649.582 250.411 651.089 248.904C652.568 247.425 652.596 244.997 651.089 243.49C649.61 242.011 647.182 241.983 645.675 243.49Z" />
              <path d="M678.044 265.199C679.462 263.782 679.462 261.483 678.044 260.065C676.626 258.647 674.328 258.647 672.91 260.065C671.492 261.483 671.492 263.782 672.91 265.199C674.328 266.617 676.626 266.617 678.044 265.199Z" />
              <path d="M699.168 278.342C697.856 279.654 697.884 281.747 699.168 283.03C700.479 284.342 702.572 284.314 703.856 283.03C705.139 281.747 705.139 279.626 703.856 278.342C702.6 277.031 700.479 277.031 699.168 278.342Z" />
              <path d="M724.31 298.127C723.138 299.298 723.11 301.224 724.31 302.424C725.482 303.596 727.407 303.624 728.607 302.424C729.779 301.252 729.807 299.326 728.607 298.127C727.407 296.927 725.509 296.927 724.31 298.127Z" />
              <path d="M748.223 319.361C747.163 320.422 747.163 322.152 748.223 323.212C749.284 324.273 751.014 324.273 752.074 323.212C753.135 322.152 753.135 320.422 752.074 319.361C751.042 318.273 749.284 318.301 748.223 319.361Z" />
              <path d="M8.203 283.309C6.94731 284.565 6.94731 286.63 8.20301 287.885C9.4587 289.141 11.5236 289.141 12.7793 287.885C14.035 286.63 14.035 284.565 12.7793 283.309C11.5236 282.053 9.48659 282.025 8.203 283.309Z" />
              <path d="M32.2286 262.633C30.8613 264 30.8612 266.232 32.2285 267.6C33.5958 268.967 35.8282 268.967 37.1955 267.6C38.5628 266.232 38.5629 264 37.1955 262.633C35.8282 261.265 33.5959 261.265 32.2286 262.633Z" />
              <path d="M57.3424 243.378C55.8635 244.857 55.8356 247.285 57.3424 248.792C58.8213 250.271 61.249 250.299 62.7558 248.792C64.2626 247.285 64.2627 244.885 62.7558 243.378C61.2769 241.9 58.8213 241.9 57.3424 243.378Z" />
              <path d="M89.2927 231.408C90.8955 229.805 90.8954 227.206 89.2927 225.604C87.6899 224.001 85.0914 224.001 83.4886 225.603C81.8859 227.206 81.8859 229.805 83.4887 231.408C85.0914 233.01 87.69 233.01 89.2927 231.408Z" />
              <path d="M110.584 209.279C108.854 211.009 108.854 213.8 110.584 215.53C112.314 217.26 115.104 217.26 116.834 215.53C118.564 213.8 118.564 211.009 116.834 209.279C115.104 207.549 112.314 207.549 110.584 209.279Z" />
              <path d="M138.572 194.574C136.786 196.359 136.758 199.289 138.572 201.103C140.385 202.917 143.287 202.917 145.101 201.103C146.887 199.317 146.915 196.387 145.101 194.574C143.287 192.76 140.385 192.76 138.572 194.574Z" />
              <path d="M167.397 181.487C165.527 183.357 165.499 186.398 167.397 188.296C169.266 190.165 172.308 190.193 174.205 188.296C176.075 186.426 176.103 183.385 174.205 181.487C172.28 179.617 169.266 179.617 167.397 181.487Z" />
              <path d="M203.951 177.134C205.909 175.177 205.909 172.003 203.951 170.046C201.994 168.089 198.821 168.089 196.864 170.046C194.906 172.003 194.906 175.177 196.864 177.134C198.821 179.091 201.994 179.091 203.951 177.134Z" />
              <path d="M234.255 167.646C236.29 165.612 236.29 162.314 234.255 160.279C232.221 158.245 228.923 158.245 226.889 160.279C224.854 162.314 224.854 165.612 226.889 167.646C228.923 169.68 232.221 169.68 234.255 167.646Z" />
              <path d="M257.472 152.243C255.379 154.336 255.379 157.74 257.472 159.833C259.564 161.926 262.969 161.926 265.062 159.833C267.154 157.74 267.154 154.336 265.062 152.243C262.969 150.151 259.564 150.151 257.472 152.243Z" />
              <path d="M288.473 145.965C286.325 148.113 286.325 151.573 288.473 153.722C290.622 155.871 294.082 155.871 296.231 153.722C298.379 151.573 298.379 148.113 296.231 145.965C294.11 143.844 290.594 143.844 288.473 145.965Z" />
              <path d="M319.782 141.5C317.605 143.677 317.605 147.193 319.782 149.369C321.958 151.546 325.474 151.546 327.651 149.369C329.827 147.193 329.827 143.677 327.651 141.5C325.502 139.296 321.958 139.324 319.782 141.5Z" />
              <path d="M359.238 146.69C361.411 144.517 361.411 140.994 359.238 138.821C357.065 136.648 353.542 136.648 351.369 138.821C349.196 140.994 349.196 144.517 351.369 146.69C353.542 148.863 357.065 148.863 359.238 146.69Z" />
              <path d="M390.91 145.825C393.083 143.652 393.083 140.129 390.91 137.956C388.737 135.783 385.214 135.783 383.041 137.956C380.868 140.129 380.868 143.652 383.041 145.825C385.214 147.998 388.737 147.998 390.91 145.825Z" />
              <path d="M422.609 146.718C424.782 144.545 424.782 141.022 422.609 138.849C420.436 136.676 416.913 136.676 414.74 138.849C412.567 141.022 412.567 144.545 414.74 146.718C416.913 148.891 420.436 148.891 422.609 146.718Z" />
              <path d="M446.3 141.5C444.123 143.677 444.123 147.193 446.3 149.369C448.476 151.546 451.992 151.546 454.169 149.369C456.345 147.193 456.345 143.677 454.169 141.5C452.02 139.296 448.476 139.324 446.3 141.5Z" />
              <path d="M477.748 145.993C475.599 148.141 475.599 151.601 477.748 153.75C479.896 155.899 483.356 155.899 485.505 153.75C487.654 151.601 487.654 148.141 485.505 145.993C483.356 143.844 479.896 143.844 477.748 145.993Z" />
              <path d="M508.889 152.243C506.796 154.336 506.796 157.74 508.889 159.833C510.982 161.926 514.386 161.926 516.479 159.833C518.572 157.74 518.572 154.336 516.479 152.243C514.386 150.15 511.009 150.123 508.889 152.243Z" />
              <path d="M547.062 167.646C549.096 165.612 549.096 162.314 547.062 160.279C545.028 158.245 541.729 158.245 539.695 160.279C537.661 162.314 537.661 165.612 539.695 167.646C541.729 169.68 545.028 169.68 547.062 167.646Z" />
              <path d="M577.143 177.134C579.1 175.177 579.1 172.003 577.143 170.046C575.185 168.089 572.012 168.089 570.055 170.046C568.098 172.004 568.098 175.177 570.055 177.134C572.012 179.091 575.185 179.091 577.143 177.134Z" />
              <path d="M599.801 181.487C597.931 183.357 597.903 186.398 599.801 188.296C601.698 190.193 604.712 190.193 606.609 188.296C608.479 186.426 608.507 183.384 606.609 181.487C604.712 179.589 601.67 179.617 599.801 181.487Z" />
              <path d="M628.877 194.601C627.091 196.387 627.063 199.317 628.877 201.131C630.691 202.945 633.593 202.945 635.407 201.131C637.22 199.317 637.22 196.415 635.407 194.601C633.593 192.788 630.691 192.788 628.877 194.601Z" />
              <path d="M657.172 209.279C655.442 211.009 655.442 213.8 657.172 215.53C658.902 217.26 661.692 217.26 663.422 215.53C665.153 213.8 665.153 211.009 663.422 209.279C661.692 207.549 658.902 207.549 657.172 209.279Z" />
              <path d="M690.49 231.379C692.092 229.777 692.092 227.178 690.49 225.575C688.887 223.973 686.288 223.973 684.685 225.575C683.083 227.178 683.083 229.777 684.685 231.379C686.288 232.982 688.887 232.982 690.49 231.379Z" />
              <path d="M711.25 243.378C709.771 244.857 709.743 247.285 711.25 248.792C712.757 250.299 715.157 250.299 716.664 248.792C718.143 247.313 718.171 244.885 716.664 243.378C715.157 241.872 712.729 241.9 711.25 243.378Z" />
              <path d="M736.783 262.66C735.415 264.027 735.415 266.26 736.783 267.627C738.15 268.994 740.382 268.994 741.75 267.627C743.117 266.26 743.117 264.027 741.75 262.66C740.382 261.293 738.15 261.293 736.783 262.66Z" />
              <path d="M12.9468 232.244C11.3841 233.807 11.3841 236.374 12.9468 237.937C14.5094 239.5 17.0766 239.5 18.6393 237.937C20.2019 236.374 20.2019 233.807 18.6393 232.244C17.0766 230.682 14.5373 230.654 12.9468 232.244Z" />
              <path d="M44.2553 219.185C45.9351 217.505 45.9352 214.782 44.2554 213.102C42.5755 211.422 39.852 211.422 38.1722 213.102C36.4924 214.782 36.4924 217.505 38.1722 219.185C39.852 220.865 42.5755 220.865 44.2553 219.185Z" />
              <path d="M64.3743 195.327C62.5884 197.113 62.5605 200.043 64.3743 201.857C66.1881 203.67 69.0901 203.67 70.9039 201.857C72.6898 200.071 72.7177 197.141 70.9039 195.327C69.0901 193.513 66.1602 193.541 64.3743 195.327Z" />
              <path d="M91.4135 178.947C89.4881 180.873 89.5159 183.97 91.4134 185.868C93.3109 187.765 96.4362 187.765 98.3337 185.868C100.231 183.97 100.231 180.845 98.3338 178.947C96.4363 177.05 93.311 177.05 91.4135 178.947Z" />
              <path d="M126.573 171.357C128.561 169.369 128.561 166.146 126.573 164.158C124.585 162.17 121.362 162.17 119.374 164.158C117.386 166.146 117.386 169.369 119.374 171.357C121.362 173.345 124.585 173.345 126.573 171.357Z" />
              <path d="M147.975 150.736C145.883 152.829 145.883 156.234 147.975 158.326C150.068 160.419 153.473 160.419 155.565 158.326C157.658 156.234 157.658 152.829 155.565 150.736C153.473 148.644 150.068 148.644 147.975 150.736Z" />
              <path d="M177.303 138.988C175.126 141.165 175.126 144.681 177.303 146.857C179.479 149.034 182.995 149.034 185.172 146.857C187.348 144.681 187.348 141.165 185.172 138.988C183.023 136.784 179.479 136.812 177.303 138.988Z" />
              <path d="M215.364 136.923C217.614 134.673 217.614 131.025 215.364 128.775C213.114 126.525 209.466 126.525 207.216 128.775C204.966 131.025 204.966 134.673 207.216 136.923C209.466 139.173 213.114 139.173 215.364 136.923Z" />
              <path d="M246.059 128.609C248.386 126.282 248.386 122.509 246.059 120.181C243.732 117.854 239.959 117.854 237.632 120.181C235.305 122.509 235.305 126.281 237.632 128.609C239.959 130.936 243.732 130.936 246.059 128.609Z" />
              <path d="M268.494 113.261C266.122 115.633 266.122 119.484 268.494 121.856C270.866 124.227 274.716 124.227 277.088 121.856C279.46 119.484 279.46 115.633 277.088 113.261C274.716 110.889 270.866 110.889 268.494 113.261Z" />
              <path d="M299.691 107.959C297.291 110.359 297.291 114.266 299.691 116.665C302.091 119.065 305.997 119.065 308.397 116.665C310.797 114.266 310.797 110.359 308.397 107.959C305.997 105.559 302.091 105.559 299.691 107.959Z" />
              <path d="M339.985 113.205C342.435 110.755 342.435 106.782 339.985 104.331C337.534 101.881 333.561 101.881 331.111 104.331C328.661 106.782 328.661 110.755 331.111 113.205C333.561 115.655 337.534 115.655 339.985 113.205Z" />
              <path d="M362.671 102.406C360.187 104.89 360.187 108.908 362.671 111.392C365.154 113.875 369.172 113.875 371.656 111.391C374.139 108.908 374.139 104.89 371.656 102.406C369.2 99.8949 365.182 99.8949 362.671 102.406Z" />
              <path d="M403.355 111.168C405.836 108.687 405.836 104.664 403.355 102.183C400.874 99.7017 396.851 99.7016 394.37 102.183C391.889 104.664 391.889 108.687 394.37 111.168C396.851 113.649 400.874 113.649 403.355 111.168Z" />
              <path d="M426.097 103.718C423.641 106.173 423.641 110.136 426.097 112.591C428.553 115.047 432.515 115.047 434.971 112.591C437.426 110.136 437.426 106.173 434.971 103.718C432.515 101.262 428.553 101.262 426.097 103.718Z" />
              <path d="M457.629 106.843C455.173 109.299 455.173 113.261 457.629 115.717C460.084 118.172 464.047 118.172 466.502 115.717C468.958 113.261 468.958 109.299 466.502 106.843C464.047 104.387 460.084 104.387 457.629 106.843Z" />
              <path d="M489.049 111.699C486.649 114.098 486.649 118.005 489.049 120.405C491.449 122.804 495.355 122.804 497.755 120.405C500.155 118.005 500.155 114.098 497.755 111.699C495.355 109.299 491.449 109.299 489.049 111.699Z" />
              <path d="M520.19 118.284C517.846 120.628 517.874 124.395 520.19 126.711C522.534 129.055 526.301 129.027 528.617 126.711C530.961 124.367 530.933 120.6 528.617 118.284C526.329 115.94 522.534 115.94 520.19 118.284Z" />
              <path d="M550.996 126.487C548.736 128.747 548.764 132.403 550.996 134.635C553.257 136.895 556.912 136.868 559.144 134.635C561.405 132.375 561.377 128.72 559.144 126.487C556.912 124.255 553.257 124.227 550.996 126.487Z" />
              <path d="M581.3 136.31C579.124 138.487 579.124 142.002 581.3 144.179C583.477 146.356 586.993 146.356 589.169 144.179C591.346 142.003 591.346 138.487 589.169 136.31C587.048 134.133 583.477 134.133 581.3 136.31Z" />
              <path d="M611.102 147.695C609.009 149.788 609.009 153.192 611.102 155.285C613.195 157.378 616.599 157.378 618.692 155.285C620.785 153.192 620.785 149.788 618.692 147.695C616.599 145.602 613.195 145.602 611.102 147.695Z" />
              <path d="M647.573 167.981C649.607 165.947 649.607 162.649 647.573 160.615C645.539 158.58 642.24 158.58 640.206 160.614C638.172 162.649 638.172 165.947 640.206 167.981C642.24 170.015 645.539 170.015 647.573 167.981Z" />
              <path d="M675.7 182.156C677.657 180.199 677.657 177.026 675.7 175.069C673.743 173.111 670.57 173.111 668.613 175.069C666.655 177.026 666.655 180.199 668.613 182.156C670.57 184.114 673.743 184.114 675.7 182.156Z" />
              <path d="M696.294 191.086C694.452 192.928 694.452 195.885 696.294 197.727C698.135 199.569 701.093 199.569 702.935 197.727C704.776 195.885 704.776 192.928 702.935 191.086C701.093 189.244 698.135 189.244 696.294 191.086Z" />
              <path d="M729.388 214.748C731.114 213.022 731.114 210.224 729.388 208.498C727.662 206.772 724.864 206.772 723.138 208.498C721.411 210.224 721.411 213.022 723.138 214.748C724.864 216.474 727.662 216.474 729.388 214.748Z" />
              <path d="M754.809 233.138C756.411 231.535 756.412 228.937 754.809 227.334C753.206 225.731 750.607 225.731 749.005 227.334C747.402 228.937 747.402 231.535 749.005 233.138C750.607 234.741 753.206 234.741 754.809 233.138Z" />
              <path d="M0.0829135 208.219C1.83977 206.462 1.83977 203.614 0.0829064 201.857C-1.67396 200.1 -4.52239 200.1 -6.27925 201.857C-8.03611 203.614 -8.03611 206.462 -6.27924 208.219C-4.52238 209.976 -1.67395 209.976 0.0829135 208.219Z" />
              <path d="M19.002 182.826C17.1324 184.696 17.1045 187.737 19.002 189.635C20.8716 191.504 23.9131 191.532 25.8106 189.635C27.6802 187.765 27.7081 184.724 25.8107 182.826C23.9411 180.957 20.8995 180.929 19.002 182.826Z" />
              <path d="M52.4313 172.278C54.4193 170.29 54.4193 167.067 52.4313 165.079C50.4432 163.091 47.22 163.091 45.232 165.079C43.244 167.067 43.2439 170.29 45.232 172.278C47.22 174.266 50.4433 174.266 52.4313 172.278Z" />
              <path d="M72.2711 148.699C70.1783 150.792 70.1783 154.196 72.2711 156.289C74.3639 158.382 77.7683 158.382 79.8611 156.289C81.9539 154.196 81.9539 150.792 79.8611 148.699C77.7683 146.606 74.364 146.606 72.2711 148.699Z" />
              <path d="M100.064 133.687C97.8595 135.891 97.8316 139.491 100.064 141.723C102.268 143.928 105.868 143.956 108.1 141.723C110.333 139.491 110.333 135.919 108.1 133.687C105.868 131.455 102.296 131.455 100.064 133.687Z" />
              <path d="M137.009 128.497C139.336 126.17 139.336 122.397 137.009 120.07C134.682 117.743 130.909 117.743 128.582 120.07C126.255 122.397 126.255 126.17 128.582 128.497C130.909 130.824 134.682 130.824 137.009 128.497Z" />
              <path d="M157.798 107.987C155.398 110.387 155.398 114.294 157.798 116.693C160.197 119.093 164.104 119.093 166.504 116.693C168.904 114.294 168.904 110.387 166.504 107.987C164.104 105.588 160.197 105.588 157.798 107.987Z" />
              <path d="M187.516 97.2994C185.004 99.8108 184.976 103.913 187.516 106.452C190.055 108.991 194.129 108.991 196.668 106.452C199.18 103.941 199.208 99.8387 196.668 97.2995C194.129 94.7602 190.027 94.7881 187.516 97.2994Z" />
              <path d="M217.848 88.2865C215.28 90.8537 215.28 94.9835 217.848 97.5507C220.415 100.118 224.545 100.118 227.112 97.5507C229.679 94.9835 229.679 90.8537 227.112 88.2865C224.545 85.7194 220.415 85.7193 217.848 88.2865Z" />
              <path d="M248.486 80.6966C245.836 83.3476 245.863 87.6169 248.486 90.2399C251.137 92.8908 255.407 92.8629 258.03 90.2399C260.653 87.6169 260.653 83.3196 258.03 80.6966C255.435 78.0457 251.137 78.0457 248.486 80.6966Z" />
              <path d="M279.572 74.7253C276.893 77.4041 276.893 81.7571 279.572 84.4359C282.251 87.1147 286.604 87.1147 289.282 84.4359C291.961 81.7571 291.961 77.4041 289.282 74.7253C286.604 72.0465 282.251 72.0465 279.572 74.7253Z" />
              <path d="M310.908 70.3445C308.202 73.0512 308.202 77.46 310.908 80.1667C313.615 82.8735 318.024 82.8734 320.731 80.1668C323.437 77.4601 323.437 73.0512 320.731 70.3445C318.024 67.6378 313.615 67.6378 310.908 70.3445Z" />
              <path d="M342.412 67.5257C339.65 70.2882 339.65 74.7528 342.412 77.5154C345.175 80.2779 349.639 80.2779 352.402 77.5154C355.164 74.7529 355.164 70.2882 352.402 67.5257C349.639 64.7632 345.175 64.7632 342.412 67.5257Z" />
              <path d="M374.084 66.3261C371.321 69.0886 371.321 73.5533 374.084 76.3158C376.846 79.0783 381.311 79.0783 384.073 76.3158C386.836 73.5532 386.836 69.0886 384.073 66.3261C381.311 63.5635 376.846 63.5635 374.084 66.3261Z" />
              <path d="M405.783 66.7165C403.02 69.479 403.02 73.9436 405.783 76.7062C408.545 79.4687 413.01 79.4687 415.772 76.7062C418.535 73.9436 418.535 69.479 415.772 66.7165C413.01 63.954 408.545 63.954 405.783 66.7165Z" />
              <path d="M447.388 78.6878C450.147 75.9292 450.147 71.4567 447.388 68.6981C444.629 65.9395 440.157 65.9395 437.398 68.6981C434.64 71.4567 434.64 75.9293 437.398 78.6879C440.157 81.4464 444.629 81.4464 447.388 78.6878Z" />
              <path d="M468.958 72.2975C466.251 75.0042 466.251 79.413 468.958 82.1197C471.665 84.8264 476.073 84.8265 478.78 82.1198C481.487 79.4131 481.487 75.0042 478.78 72.2975C476.073 69.5908 471.665 69.5908 468.958 72.2975Z" />
              <path d="M500.322 77.4875C497.643 80.1664 497.643 84.5194 500.322 87.1983C503.001 89.8771 507.354 89.8771 510.033 87.1982C512.712 84.5194 512.712 80.1663 510.033 77.4875C507.354 74.8087 503.001 74.8087 500.322 77.4875Z" />
              <path d="M531.463 84.296C528.868 86.8911 528.868 91.1326 531.463 93.7277C534.058 96.3228 538.3 96.3227 540.895 93.7276C543.49 91.1325 543.49 86.891 540.895 84.296C538.3 81.7009 534.059 81.7009 531.463 84.296Z" />
              <path d="M571.394 101.848C573.953 99.29 573.953 95.1423 571.394 92.5841C568.836 90.0258 564.688 90.0258 562.13 92.5841C559.572 95.1423 559.572 99.29 562.13 101.848C564.688 104.407 568.836 104.407 571.394 101.848Z" />
              <path d="M601.419 111.391C603.901 108.91 603.901 104.888 601.419 102.406C598.938 99.9252 594.915 99.9251 592.434 102.406C589.953 104.887 589.953 108.91 592.434 111.392C594.915 113.873 598.938 113.873 601.419 111.391Z" />
              <path d="M622.292 113.847C619.92 116.219 619.92 120.069 622.292 122.441C624.664 124.813 628.514 124.813 630.886 122.441C633.258 120.07 633.258 116.219 630.886 113.847C628.514 111.475 624.663 111.475 622.292 113.847Z" />
              <path d="M659.683 134.887C661.933 132.637 661.933 128.989 659.683 126.739C657.433 124.489 653.785 124.489 651.535 126.739C649.285 128.989 649.285 132.637 651.535 134.887C653.785 137.137 657.433 137.137 659.683 134.887Z" />
              <path d="M680.053 140.969C677.877 143.146 677.877 146.662 680.053 148.838C682.23 151.015 685.746 151.015 687.922 148.838C690.099 146.662 690.099 143.146 687.922 140.969C685.746 138.793 682.23 138.793 680.053 140.969Z" />
              <path d="M715.408 164.158C717.473 162.093 717.473 158.745 715.408 156.68C713.343 154.615 709.995 154.615 707.93 156.68C705.865 158.745 705.865 162.093 707.93 164.158C709.995 166.223 713.343 166.223 715.408 164.158Z" />
              <path d="M742.029 180.845C743.986 178.888 743.986 175.715 742.029 173.757C740.071 171.8 736.898 171.8 734.941 173.757C732.984 175.715 732.984 178.888 734.941 180.845C736.898 182.802 740.071 182.802 742.029 180.845Z" />
              <path d="M-0.196159 152.466C-2.28898 154.559 -2.28899 157.963 -0.196173 160.056C1.89664 162.149 5.30096 162.149 7.39379 160.056C9.48661 157.963 9.48663 154.559 7.39381 152.466C5.30099 150.373 1.89667 150.373 -0.196159 152.466Z" />
              <path d="M34.0424 142.784C36.2616 140.565 36.2616 136.967 34.0424 134.748C31.8232 132.528 28.2251 132.528 26.0059 134.748C23.7867 136.967 23.7867 140.565 26.0059 142.784C28.2251 145.003 31.8232 145.003 34.0424 142.784Z" />
              <path d="M53.0172 118.339C50.6733 120.683 50.7012 124.45 53.0173 126.767C55.3612 129.11 59.1283 129.083 61.4443 126.767C63.7883 124.423 63.7604 120.656 61.4443 118.339C59.1562 115.995 55.3612 115.996 53.0172 118.339Z" />
              <path d="M80.782 103.188C78.3265 105.643 78.3265 109.606 80.782 112.061C83.2376 114.517 87.2 114.517 89.6556 112.061C92.1111 109.606 92.1111 105.643 89.6555 103.188C87.2279 100.76 83.2097 100.76 80.782 103.188Z" />
              <path d="M109.216 89.4308C106.649 91.998 106.649 96.1278 109.216 98.695C111.784 101.262 115.913 101.262 118.481 98.695C121.048 96.1278 121.048 91.998 118.481 89.4308C115.913 86.8636 111.784 86.8636 109.216 89.4308Z" />
              <path d="M138.237 76.9855C135.558 79.6643 135.558 84.0174 138.237 86.6962C140.916 89.375 145.269 89.375 147.947 86.6962C150.626 84.0174 150.626 79.6643 147.947 76.9855C145.269 74.3067 140.916 74.3067 138.237 76.9855Z" />
              <path d="M167.899 66.019C165.136 68.7815 165.136 73.2461 167.899 76.0087C170.661 78.7712 175.126 78.7712 177.889 76.0087C180.651 73.2462 180.651 68.7815 177.889 66.019C175.098 63.2844 170.634 63.2843 167.899 66.019Z" />
              <path d="M208.304 66.7447C211.14 63.9091 211.14 59.3116 208.304 56.476C205.469 53.6403 200.871 53.6403 198.036 56.476C195.2 59.3116 195.2 63.9091 198.036 66.7447C200.871 69.5804 205.469 69.5804 208.304 66.7447Z" />
              <path d="M228.563 48.3835C225.661 51.2855 225.633 56.0013 228.563 58.9312C231.493 61.8612 236.181 61.8612 239.111 58.9313C242.013 56.0292 242.041 51.3134 239.111 48.3835C236.181 45.4535 231.465 45.4814 228.563 48.3835Z" />
              <path d="M259.509 41.8262C256.551 44.784 256.579 49.5556 259.509 52.4856C262.439 55.4155 267.238 55.4155 270.168 52.4856C273.126 49.5277 273.098 44.7561 270.168 41.8261C267.238 38.8962 262.467 38.8683 259.509 41.8262Z" />
              <path d="M290.733 36.7193C287.748 39.705 287.748 44.5604 290.733 47.5461C293.719 50.5319 298.575 50.5319 301.56 47.5461C304.546 44.5604 304.546 39.705 301.56 36.7193C298.575 33.7335 293.719 33.7335 290.733 36.7193Z" />
              <path d="M322.154 33.0919C319.14 36.1056 319.14 41.0167 322.154 44.0304C325.167 47.044 330.078 47.044 333.092 44.0304C336.106 41.0167 336.106 36.1055 333.092 33.0919C330.079 30.0782 325.167 30.0783 322.154 33.0919Z" />
              <path d="M353.741 30.9714C350.672 34.0409 350.672 39.0079 353.741 42.0773C356.811 45.1468 361.778 45.1468 364.847 42.0773C367.917 39.0078 367.917 34.0408 364.847 30.9714C361.75 27.9298 356.783 27.9298 353.741 30.9714Z" />
              <path d="M385.413 30.4414C382.343 33.5109 382.343 38.4779 385.413 41.5473C388.482 44.6168 393.449 44.6168 396.519 41.5473C399.588 38.4778 399.588 33.5109 396.519 30.4414C393.421 27.3441 388.482 27.3719 385.413 30.4414Z" />
              <path d="M428.106 42.384C431.127 39.3634 431.127 34.4661 428.106 31.4455C425.086 28.425 420.188 28.4249 417.168 31.4455C414.147 34.4661 414.147 39.3635 417.168 42.384C420.188 45.4046 425.086 45.4046 428.106 42.384Z" />
              <path d="M448.755 33.8453C445.742 36.859 445.742 41.7701 448.755 44.7838C451.769 47.7975 456.68 47.7975 459.694 44.7838C462.707 41.7701 462.707 36.8589 459.694 33.8453C456.68 30.8316 451.769 30.8316 448.755 33.8453Z" />
              <path d="M480.287 37.8637C477.301 40.8494 477.301 45.7048 480.287 48.6905C483.273 51.6763 488.128 51.6763 491.114 48.6905C494.1 45.7047 494.1 40.8494 491.114 37.8636C488.1 34.85 483.273 34.8779 480.287 37.8637Z" />
              <path d="M511.568 43.3051C508.61 46.263 508.638 51.0345 511.568 53.9645C514.526 56.9223 519.297 56.8945 522.227 53.9645C525.185 51.0067 525.157 46.2351 522.227 43.3051C519.297 40.3752 514.525 40.3473 511.568 43.3051Z" />
              <path d="M553.033 60.7173C555.9 57.8509 555.9 53.2034 553.033 50.337C550.167 47.4705 545.52 47.4705 542.653 50.337C539.787 53.2034 539.787 57.8509 542.653 60.7174C545.519 63.5839 550.167 63.5838 553.033 60.7173Z" />
              <path d="M573.292 58.7083C570.473 61.5266 570.446 66.1308 573.292 68.9771C576.11 71.7954 580.714 71.8233 583.561 68.9771C586.379 66.1588 586.407 61.5546 583.561 58.7083C580.742 55.89 576.138 55.862 573.292 58.7083Z" />
              <path d="M603.624 68.6143C600.861 71.3769 600.861 75.8416 603.624 78.6041C606.386 81.3666 610.851 81.3666 613.614 78.6041C616.376 75.8415 616.376 71.3768 613.614 68.6143C610.851 65.8517 606.358 65.8797 603.624 68.6143Z" />
              <path d="M633.481 79.999C630.83 82.6499 630.858 86.9192 633.481 89.5422C636.132 92.1931 640.401 92.1653 643.024 89.5423C645.675 86.8914 645.647 82.6221 643.024 79.9991C640.373 77.3482 636.132 77.3481 633.481 79.999Z" />
              <path d="M671.961 101.959C674.519 99.4012 674.519 95.2534 671.961 92.6952C669.403 90.1369 665.255 90.1369 662.697 92.6952C660.139 95.2534 660.139 99.4011 662.697 101.959C665.255 104.518 669.403 104.518 671.961 101.959Z" />
              <path d="M691.382 106.815C688.927 109.271 688.927 113.233 691.382 115.689C693.838 118.144 697.8 118.144 700.256 115.689C702.712 113.233 702.712 109.271 700.256 106.815C697.8 104.359 693.838 104.359 691.382 106.815Z" />
              <path d="M719.454 122.33C717.166 124.618 717.166 128.357 719.454 130.645C721.742 132.933 725.481 132.933 727.77 130.645C730.058 128.357 730.058 124.618 727.77 122.33C725.481 120.042 721.742 120.042 719.454 122.33Z" />
              <path d="M746.689 139.072C744.512 141.249 744.512 144.765 746.689 146.941C748.865 149.118 752.381 149.118 754.558 146.941C756.734 144.765 756.734 141.249 754.558 139.072C752.409 136.924 748.865 136.896 746.689 139.072Z" />
              <path d="M6.80786 104.443C4.35229 106.899 4.35229 110.861 6.80787 113.317C9.26344 115.772 13.2258 115.772 15.6814 113.317C18.137 110.861 18.137 106.899 15.6814 104.443C13.2258 101.988 9.26344 101.988 6.80786 104.443Z" />
              <path d="M33.7634 87.9242C31.1683 90.5193 31.1683 94.7607 33.7634 97.3558C36.3585 99.9509 40.5999 99.951 43.195 97.3559C45.7901 94.7608 45.7901 90.5194 43.195 87.9243C40.5441 85.3292 36.3585 85.3292 33.7634 87.9242Z" />
              <path d="M61.4444 72.6885C58.7377 75.3952 58.7377 79.804 61.4444 82.5108C64.1511 85.2175 68.56 85.2175 71.2667 82.5108C73.9734 79.8041 73.9734 75.3952 71.2667 72.6885C68.56 69.9818 64.1511 69.9818 61.4444 72.6885Z" />
              <path d="M100.064 69.0051C102.899 66.1695 102.899 61.572 100.064 58.7364C97.2282 55.9007 92.6307 55.9007 89.7951 58.7364C86.9594 61.572 86.9594 66.1695 89.7951 69.0051C92.6307 71.8408 97.2282 71.8408 100.064 69.0051Z" />
              <path d="M118.788 46.1513C115.886 49.0533 115.858 53.7691 118.788 56.6991C121.718 59.629 126.405 59.629 129.335 56.6991C132.237 53.797 132.265 49.0812 129.335 46.1513C126.405 43.2213 121.718 43.2213 118.788 46.1513Z" />
              <path d="M148.282 34.7943C145.269 37.808 145.269 42.7191 148.282 45.7328C151.296 48.7464 156.207 48.7464 159.221 45.7328C162.234 42.7191 162.234 37.808 159.221 34.7943C156.179 31.7528 151.296 31.7807 148.282 34.7943Z" />
              <path d="M189.469 36.0779C192.567 32.9803 192.567 27.958 189.469 24.8604C186.371 21.7628 181.349 21.7628 178.252 24.8604C175.154 27.958 175.154 32.9802 178.252 36.0779C181.349 39.1755 186.371 39.1755 189.469 36.0779Z" />
              <path d="M208.695 16.2936C205.514 19.4747 205.514 24.6091 208.695 27.7902C211.876 30.9712 217.011 30.9712 220.192 27.7901C223.373 24.6091 223.373 19.4747 220.192 16.2936C217.011 13.1125 211.848 13.1404 208.695 16.2936Z" />
              <path d="M239.501 9.14989C236.292 12.3589 236.292 17.5491 239.501 20.7581C242.71 23.967 247.9 23.967 251.109 20.7581C254.318 17.5491 254.318 12.3589 251.109 9.14988C247.928 5.96879 242.71 5.9409 239.501 9.14989Z" />
              <path d="M270.615 3.42996C267.378 6.66685 267.35 11.9408 270.615 15.2055C273.851 18.4424 279.125 18.4703 282.39 15.2055C285.627 11.9687 285.655 6.69476 282.39 3.42996C279.153 0.193069 273.851 0.193067 270.615 3.42996Z" />
              <path d="M301.951 -0.895609C298.658 2.3971 298.686 7.72677 301.951 10.9916C305.216 14.2564 310.573 14.2564 313.838 10.9916C317.131 7.69888 317.103 2.36915 313.838 -0.895643C310.573 -4.16044 305.244 -4.18831 301.951 -0.895609Z" />
              <path d="M345.509 8.28499C348.838 4.95619 348.838 -0.44086 345.509 -3.76965C342.181 -7.09845 336.784 -7.09845 333.455 -3.76965C330.126 -0.440845 330.126 4.95622 333.455 8.28502C336.784 11.6138 342.181 11.6138 345.509 8.28499Z" />
              <path d="M365.126 -5.19264C361.806 -1.87202 361.806 3.54144 365.126 6.86205C368.447 10.1827 373.86 10.1827 377.181 6.86203C380.502 3.54141 380.501 -1.87201 377.181 -5.19262C373.832 -8.54113 368.475 -8.54117 365.126 -5.19264Z" />
              <path d="M396.798 -5.22057C393.477 -1.89996 393.477 3.51346 396.797 6.83407C400.118 10.1547 405.532 10.1547 408.852 6.83406C412.173 3.51345 412.173 -1.9 408.852 -5.2206C405.532 -8.54121 400.118 -8.54118 396.798 -5.22057Z" />
              <path d="M440.523 8.25714C443.852 4.92834 443.852 -0.468725 440.523 -3.79752C437.195 -7.12631 431.798 -7.12628 428.469 -3.79748C425.14 -0.468677 425.14 4.92837 428.469 8.25716C431.798 11.5859 437.195 11.5859 440.523 8.25714Z" />
              <path d="M460.112 -0.895652C456.819 2.39705 456.847 7.72679 460.112 10.9916C463.405 14.2843 468.735 14.2564 471.999 10.9916C475.264 7.72676 475.264 2.36918 471.999 -0.89562C468.735 -4.16041 463.405 -4.18836 460.112 -0.895652Z" />
              <path d="M491.588 3.45789C488.351 6.69479 488.323 11.9687 491.588 15.2335C494.825 18.4704 500.099 18.4983 503.364 15.2335C506.601 11.9966 506.629 6.72272 503.364 3.45792C500.099 0.193121 494.853 0.193083 491.588 3.45789Z" />
              <path d="M522.841 9.14993C519.632 12.3589 519.632 17.5491 522.841 20.7581C526.05 23.9671 531.24 23.9671 534.449 20.7581C537.658 17.5491 537.658 12.3589 534.449 9.14989C531.268 5.96881 526.05 5.94094 522.841 9.14993Z" />
              <path d="M553.815 16.2936C550.634 19.4747 550.633 24.6092 553.815 27.7903C556.996 30.9713 562.13 30.9713 565.311 27.7902C568.492 24.6091 568.492 19.4747 565.311 16.2936C562.13 13.1125 556.996 13.1125 553.815 16.2936Z" />
              <path d="M595.699 36.0779C598.797 32.9802 598.797 27.958 595.699 24.8603C592.601 21.7627 587.579 21.7627 584.481 24.8604C581.384 27.958 581.384 32.9802 584.481 36.0779C587.579 39.1755 592.601 39.1755 595.699 36.0779Z" />
              <path d="M614.785 34.7943C611.772 37.808 611.772 42.7191 614.785 45.7328C617.799 48.7464 622.71 48.7465 625.724 45.7328C628.738 42.7191 628.737 37.808 625.724 34.7943C622.71 31.7807 617.799 31.7807 614.785 34.7943Z" />
              <path d="M644.671 46.1512C641.769 49.0533 641.741 53.7692 644.671 56.6991C647.573 59.6011 652.289 59.629 655.219 56.6991C658.149 53.7691 658.149 49.0813 655.219 46.1513C652.289 43.2214 647.573 43.2492 644.671 46.1512Z" />
              <path d="M684.211 69.005C687.047 66.1694 687.047 61.572 684.211 58.7363C681.375 55.9007 676.778 55.9007 673.942 58.7364C671.107 61.572 671.107 66.1694 673.942 69.0051C676.778 71.8407 681.375 71.8407 684.211 69.005Z" />
              <path d="M702.712 72.7157C700.005 75.4225 700.005 79.8313 702.712 82.538C705.418 85.2447 709.827 85.2448 712.534 82.5381C715.241 79.8313 715.241 75.4225 712.534 72.7157C709.827 69.9532 705.446 69.9811 702.712 72.7157Z" />
              <path d="M730.811 87.924C728.216 90.5191 728.216 94.7606 730.811 97.3557C733.406 99.9508 737.648 99.9508 740.243 97.3556C742.838 94.7605 742.838 90.5191 740.243 87.924C737.648 85.3289 733.434 85.301 730.811 87.924Z" />
              <path d="M-12.3903 74.1394C-15.0691 76.8182 -15.0691 81.1713 -12.3903 83.8501C-9.71152 86.5289 -5.35844 86.5289 -2.67964 83.8501C-0.000831726 81.1713 -0.000858995 76.8182 -2.67967 74.1394C-5.35847 71.4606 -9.71151 71.4606 -12.3903 74.1394Z" />
              <path d="M24.7783 67.8326C27.6139 64.997 27.6139 60.3995 24.7783 57.5639C21.9426 54.7282 17.3451 54.7282 14.5095 57.5639C11.6739 60.3995 11.6739 64.997 14.5095 67.8326C17.3451 70.6683 21.9426 70.6683 24.7783 67.8326Z" />
              <path d="M42.1625 42.2445C39.2047 45.2024 39.2326 49.974 42.1625 52.9039C45.1204 55.8618 49.892 55.8339 52.822 52.9039C55.7519 49.974 55.7519 45.1745 52.8219 42.2445C49.892 39.3146 45.1204 39.2867 42.1625 42.2445Z" />
              <path d="M70.4295 28.1533C67.36 31.2227 67.3601 36.1897 70.4295 39.2591C73.499 42.3286 78.4659 42.3286 81.5354 39.2592C84.6048 36.1897 84.6048 31.2227 81.5354 28.1533C78.4659 25.0838 73.4989 25.0838 70.4295 28.1533Z" />
              <path d="M99.2825 15.261C96.1014 18.4421 96.1014 23.5765 99.2825 26.7575C102.464 29.9386 107.598 29.9386 110.779 26.7575C113.96 23.5765 113.96 18.4421 110.779 15.261C107.598 12.0799 102.464 12.0799 99.2825 15.261Z" />
              <path d="M128.721 3.68127C125.485 6.91818 125.457 12.1921 128.721 15.4569C131.958 18.6938 137.232 18.7217 140.497 15.4569C143.734 12.22 143.762 6.94604 140.497 3.68124C137.232 0.472258 131.958 0.444366 128.721 3.68127Z" />
              <path d="M170.689 5.5227C174.049 2.16307 174.049 -3.28397 170.689 -6.64359C167.33 -10.0032 161.883 -10.0032 158.523 -6.64355C155.164 -3.28392 155.164 2.16309 158.523 5.52271C161.883 8.88232 167.33 8.88232 170.689 5.5227Z" />
              <path d="M188.827 -15.6285C185.395 -12.1962 185.395 -6.61534 188.827 -3.18312C192.259 0.249102 197.84 0.249063 201.273 -3.18317C204.705 -6.61539 204.705 -12.1962 201.273 -15.6285C197.868 -19.0886 192.287 -19.0886 188.827 -15.6285Z" />
              <path d="M565.032 -17.6381C561.6 -14.2059 561.6 -8.62498 565.032 -5.19276C568.464 -1.76054 574.045 -1.76059 577.477 -5.19282C580.91 -8.62504 580.91 -14.2058 577.477 -17.638C574.073 -21.0982 568.492 -21.0982 565.032 -17.6381Z" />
              <path d="M607.865 3.20661C611.225 -0.152989 611.225 -5.59995 607.865 -8.95957C604.506 -12.3192 599.059 -12.3192 595.699 -8.95963C592.339 -5.60002 592.339 -0.152954 595.699 3.20667C599.059 6.56629 604.506 6.56621 607.865 3.20661Z" />
              <path d="M625.947 0.974335C622.654 4.26704 622.682 9.59677 625.947 12.8616C629.24 16.1543 634.569 16.1263 637.834 12.8615C641.127 9.56883 641.099 4.23909 637.834 0.974296C634.597 -2.3184 629.24 -2.31837 625.947 0.974335Z" />
              <path d="M655.86 12.3032C652.679 15.4842 652.679 20.6187 655.86 23.7998C659.041 26.9809 664.176 26.9808 667.357 23.7997C670.538 20.6186 670.538 15.4843 667.357 12.3032C664.176 9.12209 659.042 9.12209 655.86 12.3032Z" />
              <path d="M696.377 36.0222C699.475 32.9245 699.475 27.9023 696.377 24.8047C693.28 21.7071 688.257 21.7071 685.16 24.8047C682.062 27.9023 682.062 32.9245 685.16 36.0221C688.257 39.1197 693.28 39.1198 696.377 36.0222Z" />
              <path d="M714.013 38.589C711.027 41.5748 711.027 46.4301 714.013 49.4159C716.999 52.4016 721.854 52.4016 724.84 49.4159C727.825 46.4301 727.825 41.5748 724.84 38.589C721.826 35.6312 716.999 35.6033 714.013 38.589Z" />
              <path d="M742.252 53.6015C739.378 56.4756 739.378 61.1077 742.252 63.9818C745.126 66.8559 749.758 66.856 752.632 63.9818C755.506 61.1077 755.506 56.4756 752.632 53.6015C749.758 50.7274 745.098 50.7552 742.252 53.6015Z" />
              <path d="M6.38927 38.3381C9.45608 35.2713 9.45606 30.299 6.38925 27.2322C3.32244 24.1654 -1.64981 24.1654 -4.71662 27.2322C-7.78343 30.299 -7.78346 35.2713 -4.71665 38.3381C-1.64984 41.4049 3.32245 41.4049 6.38927 38.3381Z" />
              <path d="M22.8527 11.7733C19.6437 14.9823 19.6437 20.1725 22.8527 23.3815C26.0617 26.5905 31.2519 26.5905 34.4609 23.3815C37.6699 20.1725 37.6699 14.9823 34.4609 11.7733C31.2519 8.56431 26.0617 8.56431 22.8527 11.7733Z" />
              <path d="M63.1186 9.56843C66.4474 6.23963 66.4474 0.842577 63.1186 -2.48622C59.7898 -5.81501 54.3928 -5.815 51.064 -2.4862C47.7352 0.842602 47.7351 6.23966 51.0639 9.56845C54.3927 12.8972 59.7898 12.8972 63.1186 9.56843Z" />
              <path d="M79.8052 -15.5453C76.373 -12.1131 76.373 -6.53221 79.8052 -3.09998C83.2374 0.332238 88.8183 0.332233 92.2505 -3.10001C95.6828 -6.53225 95.6828 -12.1131 92.2506 -15.5453C88.8462 -19.0054 83.2654 -19.0054 79.8052 -15.5453Z" />
              <path d="M708.599 3.03917C711.959 -0.320451 711.959 -5.76747 708.599 -9.12709C705.24 -12.4867 699.793 -12.4867 696.433 -9.12707C693.073 -5.76744 693.073 -0.320461 696.433 3.03915C699.793 6.39877 705.24 6.3988 708.599 3.03917Z" />
              <path d="M725.314 4.51778C722.077 7.75468 722.049 13.0286 725.314 16.2934C728.551 19.5303 733.825 19.5582 737.09 16.2934C740.327 13.0565 740.354 7.78259 737.09 4.51779C733.797 1.2809 728.551 1.28087 725.314 4.51778Z" />
              <path d="M764.994 30.6923C768.122 27.5639 768.122 22.4917 764.994 19.3632C761.865 16.2347 756.793 16.2347 753.665 19.3632C750.536 22.4916 750.536 27.5638 753.665 30.6923C756.793 33.8207 761.865 33.8208 764.994 30.6923Z" />
              <path d="M3.5989 -18.6422C0.166685 -15.21 0.166706 -9.62913 3.59893 -6.19691C7.03115 -2.76469 12.612 -2.76467 16.0442 -6.19688C19.4764 -9.62909 19.4764 -15.21 16.0442 -18.6422C12.612 -22.0744 7.03111 -22.0744 3.5989 -18.6422Z" />
              <path d="M351.844 630.996C351.816 630.968 351.816 630.912 351.844 630.885C351.872 630.857 351.927 630.857 351.955 630.885C351.983 630.912 351.983 630.968 351.955 630.996C351.927 631.024 351.872 631.024 351.844 630.996Z" />
              <path d="M420.265 638.697C420.237 638.669 420.237 638.613 420.265 638.586C420.293 638.558 420.349 638.558 420.377 638.586C420.404 638.613 420.404 638.669 420.377 638.697C420.349 638.725 420.293 638.725 420.265 638.697Z" />
              <path d="M316.88 634.819C316.911 634.849 316.91 634.899 316.88 634.93C316.849 634.961 316.799 634.961 316.768 634.93C316.737 634.899 316.737 634.85 316.768 634.819C316.799 634.788 316.849 634.788 316.88 634.819Z" />
              <path d="M281.776 638.781C281.807 638.812 281.807 638.862 281.776 638.893C281.745 638.924 281.695 638.924 281.664 638.893C281.634 638.862 281.634 638.812 281.664 638.781C281.695 638.75 281.745 638.75 281.776 638.781Z" />
              <path d="M492.9 630.857C492.93 630.888 492.93 630.938 492.9 630.968C492.869 630.999 492.819 630.999 492.788 630.968C492.757 630.938 492.757 630.888 492.788 630.857C492.819 630.826 492.869 630.826 492.9 630.857Z" />
              <path d="M246.561 642.855C246.533 642.827 246.533 642.772 246.561 642.744C246.589 642.716 246.645 642.716 246.673 642.744C246.701 642.772 246.7 642.827 246.673 642.855C246.645 642.883 246.589 642.883 246.561 642.855Z" />
              <path d="M527.278 642.855C527.25 642.827 527.25 642.772 527.278 642.744C527.305 642.716 527.361 642.716 527.389 642.744C527.417 642.772 527.417 642.827 527.389 642.855C527.361 642.883 527.305 642.883 527.278 642.855Z" />
              <path d="M598.768 634.819C598.799 634.849 598.799 634.899 598.768 634.93C598.737 634.961 598.688 634.961 598.657 634.93C598.626 634.899 598.626 634.849 598.657 634.819C598.687 634.788 598.737 634.788 598.768 634.819Z" />
              <path d="M69.2575 630.857C69.2883 630.887 69.2883 630.937 69.2575 630.968C69.2266 630.999 69.1767 630.999 69.1458 630.968C69.115 630.937 69.115 630.887 69.1459 630.857C69.1767 630.826 69.2267 630.826 69.2575 630.857Z" />
              <path d="M704.497 638.921C704.47 638.893 704.469 638.837 704.497 638.809C704.525 638.781 704.581 638.781 704.609 638.809C704.637 638.837 704.637 638.893 704.609 638.921C704.637 638.949 704.553 638.976 704.497 638.921Z" />
              <path d="M33.9028 634.958C33.8749 634.93 33.8749 634.874 33.9028 634.847C33.9307 634.819 33.9865 634.819 34.0144 634.847C34.0423 634.874 34.0423 634.93 34.0144 634.958C33.9865 634.986 33.9307 634.986 33.9028 634.958Z" />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="750" height="644" fill="transparent" />
              </clipPath>
            </defs>
          </svg>
          <div className={classes.title} data-testid="processing-txt">
            {effTitle}
          </div>
          {effSubTitle && <div className={classes.subtitle}>{effSubTitle}</div>}
          <div className={classes.spinner} data-testid="processing-Loading">
            {/* <img alt="" src={spinner} /> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0,0 200,200"
            >
              <defs>
                <clipPath id="ring">
                  <path
                    d="M 200, 100
                            A 100, 100, 0, 1, 1, 197.81, 79.21
                            L 188.03, 81.29
                            A 90, 90, 0, 1, 0, 190, 100 z"
                  />
                </clipPath>
                <filter id="blur" x="0" y="0">
                  <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
                </filter>
                <path
                  id="p"
                  className={classes.svg}
                  d="M 250, 100
                                A 150, 150, 0, 0, 1, 246.72, 131.19
                                L 100, 100
                                A 0, 0, 0, 0, 0, 100, 100 z"
                />
              </defs>
              <g clipPath="url(#ring)">
                <g filter="url(#blur)" transform="rotate(-6 100 100)">
                  <use xlinkHref="#p" fillOpacity="0" transform="rotate(  0 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.03" transform="rotate( 12 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.07" transform="rotate( 24 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.1" transform="rotate( 36 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.14" transform="rotate( 48 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.17" transform="rotate( 60 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.2" transform="rotate( 72 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.24" transform="rotate( 84 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.28" transform="rotate( 96 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.31" transform="rotate(108 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.34" transform="rotate(120 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.38" transform="rotate(132 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.41" transform="rotate(144 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.45" transform="rotate(156 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.48" transform="rotate(168 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.52" transform="rotate(180 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.55" transform="rotate(192 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.59" transform="rotate(204 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.62" transform="rotate(216 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.66" transform="rotate(228 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.69" transform="rotate(240 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.7" transform="rotate(252 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.72" transform="rotate(264 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.76" transform="rotate(276 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.79" transform="rotate(288 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.83" transform="rotate(300 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.86" transform="rotate(312 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.93" transform="rotate(324 100 100)" />
                  <use xlinkHref="#p" fillOpacity="0.97" transform="rotate(336 100 100)" />
                  <use xlinkHref="#p" fillOpacity="1" transform="rotate(348 100 100)" />
                </g>
              </g>
            </svg>
          </div>
          <svg
            className={classNames(classes.svgDot, classes.bottom)}
            width="750"
            height="644"
            viewBox="0 0 750 644"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0)">
              <path d="M378.939 7.42297C378.855 7.33926 378.855 7.22762 378.939 7.1439C379.022 7.06018 379.134 7.06018 379.218 7.14389C379.301 7.2276 379.301 7.33921 379.218 7.42294C379.134 7.50666 379.022 7.50668 378.939 7.42297Z" />
              <path d="M410.191 3.90747C410.108 3.82376 410.108 3.71212 410.191 3.6284C410.275 3.54468 410.387 3.54468 410.471 3.62839C410.554 3.7121 410.554 3.82374 410.471 3.90746C410.387 3.99118 410.275 3.99119 410.191 3.90747Z" />
              <path d="M306.555 6.36228C306.471 6.27857 306.471 6.16697 306.555 6.08326C306.639 5.99956 306.75 5.99954 306.834 6.08325C306.918 6.16696 306.918 6.27859 306.834 6.36229C306.75 6.446 306.639 6.44599 306.555 6.36228Z" />
              <path d="M330.413 27.0397C330.302 26.9281 330.302 26.7607 330.413 26.6491C330.525 26.5375 330.692 26.5375 330.804 26.6491C330.915 26.7607 330.915 26.9281 330.804 27.0397C330.72 27.1234 330.525 27.1513 330.413 27.0397Z" />
              <path d="M359.378 39.6521C359.266 39.5405 359.266 39.3731 359.378 39.2614C359.489 39.1498 359.657 39.1498 359.768 39.2615C359.88 39.3731 359.88 39.5405 359.768 39.6521C359.657 39.7637 359.489 39.7637 359.378 39.6521Z" />
              <path d="M390.658 43.2522C390.519 43.1127 390.491 42.8616 390.658 42.6941C390.798 42.5546 391.049 42.5267 391.217 42.6941C391.356 42.8337 391.384 43.0848 391.216 43.2522C391.077 43.3917 390.826 43.4196 390.658 43.2522Z" />
              <path d="M422.162 36.9181C422.27 37.026 422.27 37.2008 422.162 37.3087C422.054 37.4166 421.88 37.4166 421.772 37.3087C421.664 37.2009 421.664 37.026 421.772 36.9181C421.88 36.8102 422.054 36.8102 422.162 36.9181Z" />
              <path d="M449.704 22.547C449.592 22.4354 449.592 22.268 449.704 22.1563C449.815 22.0447 449.983 22.0448 450.094 22.1564C450.206 22.268 450.206 22.4354 450.094 22.547C449.983 22.6586 449.815 22.6586 449.704 22.547Z" />
              <path d="M472.083 0.16808C471.999 0.0843758 471.999 -0.0272554 472.083 -0.110972C472.167 -0.194689 472.278 -0.194655 472.362 -0.110951C472.446 -0.0272469 472.446 0.0843417 472.362 0.168059C472.278 0.251776 472.167 0.251784 472.083 0.16808Z" />
              <path d="M267.266 12.3898C267.182 12.3061 267.182 12.1944 267.266 12.1107C267.35 12.027 267.461 12.027 267.545 12.1108C267.629 12.1945 267.629 12.3061 267.545 12.3898C267.461 12.4735 267.35 12.4735 267.266 12.3898Z" />
              <path d="M286.91 37.2249C286.799 37.1132 286.799 36.9458 286.911 36.8342C287.022 36.7226 287.19 36.7226 287.301 36.8342C287.413 36.9458 287.413 37.1132 287.301 37.2248C287.217 37.3085 287.022 37.3365 286.91 37.2249Z" />
              <path d="M311.578 56.9812C311.438 56.8417 311.41 56.5905 311.578 56.4231C311.717 56.2836 311.969 56.2557 312.136 56.4231C312.275 56.5627 312.303 56.8138 312.136 56.9812C311.969 57.1486 311.717 57.1207 311.578 56.9812Z" />
              <path d="M340.012 70.7934C339.817 70.598 339.845 70.291 340.012 70.1236C340.208 69.9283 340.515 69.9562 340.682 70.1236C340.849 70.2911 340.849 70.6259 340.682 70.7933C340.487 70.9887 340.18 70.9608 340.012 70.7934Z" />
              <path d="M370.819 77.8254C370.623 77.63 370.651 77.3231 370.819 77.1557C371.014 76.9603 371.321 76.9883 371.488 77.1557C371.684 77.3511 371.656 77.658 371.488 77.8254C371.349 78.0207 371.014 78.0207 370.819 77.8254Z" />
              <path d="M402.462 77.8252C402.267 77.6299 402.295 77.323 402.462 77.1555C402.657 76.9602 402.964 76.9882 403.132 77.1556C403.327 77.351 403.299 77.6579 403.132 77.8253C402.964 77.9927 402.657 78.0206 402.462 77.8252Z" />
              <path d="M433.296 70.7652C433.101 70.5699 433.129 70.263 433.296 70.0956C433.492 69.9002 433.798 69.9281 433.966 70.0956C434.133 70.263 434.133 70.5978 433.966 70.7652C433.798 70.9884 433.492 70.9606 433.296 70.7652Z" />
              <path d="M461.87 56.9812C461.731 56.8416 461.703 56.5905 461.87 56.4231C462.01 56.2836 462.261 56.2556 462.428 56.423C462.568 56.5626 462.596 56.8137 462.428 56.9812C462.261 57.1486 462.037 57.1486 461.87 56.9812Z" />
              <path d="M486.677 37.1971C486.565 37.0854 486.565 36.918 486.677 36.8063C486.789 36.6947 486.956 36.6947 487.068 36.8063C487.179 36.918 487.179 37.0854 487.068 37.197C486.956 37.3086 486.788 37.3087 486.677 37.1971Z" />
              <path d="M506.461 12.3897C506.377 12.306 506.377 12.1944 506.461 12.1107C506.545 12.027 506.656 12.027 506.74 12.1107C506.824 12.1944 506.824 12.306 506.74 12.3897C506.656 12.4735 506.545 12.4735 506.461 12.3897Z" />
              <path d="M229.539 17.413C229.456 17.3292 229.456 17.2176 229.539 17.1339C229.623 17.0502 229.735 17.0502 229.818 17.1339C229.902 17.2176 229.902 17.3293 229.818 17.413C229.735 17.4967 229.623 17.4967 229.539 17.413Z" />
              <path d="M246.226 44.368C246.087 44.2285 246.059 43.9774 246.226 43.81C246.366 43.6704 246.617 43.6425 246.784 43.8099C246.924 43.9494 246.952 44.2006 246.784 44.368C246.645 44.5076 246.366 44.5076 246.226 44.368Z" />
              <path d="M267.517 67.7797C267.322 67.5844 267.35 67.2774 267.517 67.11C267.712 66.9146 268.019 66.9425 268.187 67.1099C268.382 67.3053 268.354 67.6123 268.187 67.7797C268.047 67.975 267.712 67.975 267.517 67.7797Z" />
              <path d="M292.631 87.0338C292.408 86.8106 292.408 86.4199 292.631 86.1967C292.854 85.9734 293.245 85.9734 293.468 86.1967C293.691 86.4199 293.691 86.8106 293.468 87.0338C293.245 87.2571 292.854 87.257 292.631 87.0338Z" />
              <path d="M321.735 100.511C321.997 100.773 321.997 101.198 321.735 101.46C321.473 101.722 321.048 101.722 320.786 101.46C320.524 101.198 320.524 100.773 320.786 100.511C321.048 100.249 321.473 100.249 321.735 100.511Z" />
              <path d="M351.09 110.557C350.811 110.278 350.839 109.86 351.09 109.608C351.369 109.329 351.788 109.357 352.039 109.608C352.318 109.887 352.29 110.306 352.039 110.557C351.788 110.864 351.369 110.836 351.09 110.557Z" />
              <path d="M382.455 114.184C382.148 113.877 382.148 113.375 382.455 113.068C382.762 112.761 383.264 112.761 383.571 113.068C383.878 113.375 383.878 113.877 383.571 114.184C383.264 114.491 382.762 114.491 382.455 114.184Z" />
              <path d="M414.042 112.064C413.735 111.757 413.735 111.255 414.042 110.948C414.349 110.641 414.851 110.641 415.158 110.948C415.465 111.255 415.465 111.757 415.158 112.064C414.851 112.371 414.349 112.371 414.042 112.064Z" />
              <path d="M445.797 103.302C446.059 103.564 446.059 103.989 445.797 104.251C445.535 104.513 445.111 104.513 444.849 104.251C444.587 103.989 444.587 103.564 444.849 103.302C445.111 103.04 445.535 103.04 445.797 103.302Z" />
              <path d="M473.702 91.0801C473.478 90.8569 473.478 90.4662 473.702 90.2429C473.925 90.0197 474.315 90.0197 474.539 90.2429C474.762 90.4662 474.762 90.8568 474.539 91.0801C474.315 91.3033 473.953 91.3312 473.702 91.0801Z" />
              <path d="M499.82 72.9982C499.625 72.8029 499.652 72.4959 499.82 72.3285C500.015 72.1332 500.322 72.1611 500.49 72.3285C500.685 72.5239 500.657 72.8308 500.49 72.9982C500.294 73.1935 500.015 73.1935 499.82 72.9982Z" />
              <path d="M522.255 50.5629C522.115 50.4234 522.087 50.1722 522.255 50.0047C522.394 49.8652 522.646 49.8373 522.813 50.0047C522.953 50.1442 522.98 50.3954 522.813 50.5628C522.673 50.7024 522.394 50.7024 522.255 50.5629Z" />
              <path d="M540.337 24.4449C540.225 24.3332 540.225 24.1658 540.337 24.0542C540.448 23.9426 540.616 23.9426 540.727 24.0542C540.839 24.1658 540.839 24.3333 540.727 24.4449C540.644 24.5844 540.476 24.5844 540.337 24.4449Z" />
              <path d="M192.594 22.0451C192.483 21.9334 192.483 21.766 192.594 21.6544C192.706 21.5428 192.873 21.5428 192.985 21.6544C193.096 21.766 193.096 21.9335 192.985 22.0451C192.873 22.1567 192.706 22.1567 192.594 22.0451Z" />
              <path d="M207.328 50.1167C207.188 49.9772 207.16 49.7261 207.328 49.5586C207.467 49.4191 207.718 49.3912 207.886 49.5586C208.053 49.7261 208.053 49.9493 207.886 50.1168C207.718 50.2842 207.467 50.2562 207.328 50.1167Z" />
              <path d="M226.051 75.6489C225.856 75.4536 225.884 75.1467 226.051 74.9792C226.247 74.7839 226.554 74.8118 226.721 74.9792C226.916 75.1746 226.888 75.4815 226.721 75.6489C226.554 75.8722 226.247 75.8443 226.051 75.6489Z" />
              <path d="M249.268 97.2466C249.53 97.5086 249.53 97.9333 249.268 98.1953C249.006 98.4573 248.581 98.4573 248.319 98.1953C248.057 97.9333 248.057 97.5086 248.319 97.2466C248.581 96.9846 249.006 96.9846 249.268 97.2466Z" />
              <path d="M273.712 117.059C273.405 116.752 273.405 116.25 273.712 115.943C274.019 115.636 274.521 115.636 274.828 115.943C275.135 116.25 275.135 116.752 274.828 117.059C274.521 117.366 274.019 117.366 273.712 117.059Z" />
              <path d="M301.644 131.932C301.309 131.597 301.309 131.039 301.644 130.704C301.979 130.369 302.537 130.369 302.872 130.704C303.206 131.039 303.206 131.597 302.872 131.932C302.537 132.267 301.979 132.267 301.644 131.932Z" />
              <path d="M331.474 142.452C331.083 142.061 331.083 141.447 331.474 141.056C331.864 140.666 332.478 140.666 332.869 141.056C333.259 141.447 333.259 142.061 332.869 142.452C332.478 142.842 331.864 142.842 331.474 142.452Z" />
              <path d="M362.587 148.339C362.196 147.949 362.196 147.335 362.587 146.944C362.977 146.553 363.591 146.553 363.982 146.944C364.373 147.335 364.373 147.949 363.982 148.339C363.591 148.73 362.977 148.73 362.587 148.339Z" />
              <path d="M394.202 149.539C393.812 149.149 393.812 148.535 394.202 148.144C394.593 147.754 395.207 147.754 395.597 148.144C395.988 148.535 395.988 149.149 395.597 149.539C395.235 149.902 394.593 149.93 394.202 149.539Z" />
              <path d="M425.706 145.995C425.316 145.604 425.315 144.991 425.706 144.6C426.097 144.209 426.711 144.209 427.101 144.6C427.492 144.991 427.492 145.604 427.101 145.995C426.683 146.358 426.069 146.358 425.706 145.995Z" />
              <path d="M456.345 137.736C456.01 137.401 456.01 136.843 456.345 136.508C456.68 136.173 457.238 136.173 457.573 136.508C457.908 136.843 457.908 137.401 457.573 137.736C457.238 138.07 456.68 138.07 456.345 137.736Z" />
              <path d="M486.565 123.867C486.874 124.175 486.874 124.675 486.565 124.983C486.257 125.292 485.757 125.292 485.449 124.984C485.141 124.675 485.141 124.176 485.449 123.867C485.757 123.559 486.257 123.559 486.565 123.867Z" />
              <path d="M512.349 108.073C512.07 107.794 512.098 107.376 512.349 107.125C512.628 106.846 513.047 106.873 513.298 107.125C513.549 107.376 513.549 107.822 513.298 108.073C513.019 108.352 512.6 108.324 512.349 108.073Z" />
              <path d="M536.402 87.369C536.179 87.1457 536.179 86.7551 536.402 86.5318C536.626 86.3086 537.016 86.3086 537.239 86.5318C537.463 86.755 537.463 87.1457 537.24 87.3689C537.016 87.5922 536.626 87.5922 536.402 87.369Z" />
              <path d="M557.805 62.6174C557.99 62.8024 557.99 63.1022 557.805 63.2871C557.62 63.4721 557.32 63.4721 557.135 63.2872C556.95 63.1022 556.95 62.8023 557.135 62.6174C557.32 62.4324 557.62 62.4325 557.805 62.6174Z" />
              <path d="M574.101 36.3319C573.989 36.2203 573.989 36.0529 574.101 35.9413C574.213 35.8297 574.38 35.8296 574.492 35.9412C574.603 36.0529 574.603 36.2203 574.492 36.3319C574.408 36.4157 574.213 36.4435 574.101 36.3319Z" />
              <path d="M586.853 7.22753C586.769 7.14382 586.769 7.03224 586.853 6.94852C586.937 6.8648 587.049 6.86479 587.132 6.9485C587.216 7.0322 587.216 7.14383 587.132 7.22755C587.048 7.31127 586.937 7.31123 586.853 7.22753Z" />
              <path d="M156.207 26.3979C156.095 26.2862 156.095 26.1188 156.207 26.0071C156.319 25.8955 156.486 25.8955 156.598 26.0071C156.709 26.1188 156.709 26.2862 156.598 26.3978C156.486 26.5095 156.319 26.5095 156.207 26.3979Z" />
              <path d="M169.406 55.223C169.266 55.0835 169.238 54.8323 169.406 54.6649C169.545 54.5254 169.796 54.4975 169.964 54.6649C170.131 54.8323 170.131 55.0555 169.964 55.223C169.824 55.3625 169.573 55.3904 169.406 55.223Z" />
              <path d="M186.148 82.1782C185.925 81.955 185.925 81.5643 186.148 81.341C186.371 81.1178 186.762 81.1178 186.985 81.3411C187.209 81.5643 187.209 81.955 186.985 82.1782C186.734 82.4293 186.371 82.4015 186.148 82.1782Z" />
              <path d="M207.16 105.758C207.422 106.02 207.422 106.444 207.16 106.706C206.898 106.968 206.473 106.968 206.211 106.706C205.949 106.444 205.949 106.02 206.211 105.758C206.473 105.496 206.898 105.496 207.16 105.758Z" />
              <path d="M230.404 127.271C230.743 127.611 230.743 128.16 230.404 128.499C230.065 128.838 229.516 128.838 229.177 128.499C228.838 128.16 228.838 127.611 229.177 127.271C229.516 126.932 230.065 126.932 230.404 127.271Z" />
              <path d="M254.765 147.112C254.374 146.721 254.374 146.107 254.765 145.716C255.156 145.326 255.769 145.326 256.16 145.716C256.551 146.107 256.551 146.721 256.16 147.112C255.797 147.474 255.156 147.502 254.765 147.112Z" />
              <path d="M282.557 162.292C282.139 161.873 282.139 161.203 282.557 160.785C282.976 160.366 283.646 160.366 284.064 160.785C284.483 161.203 284.483 161.873 284.064 162.291C283.674 162.682 282.976 162.71 282.557 162.292Z" />
              <path d="M312.052 173.76C311.606 173.314 311.578 172.56 312.052 172.086C312.499 171.64 313.252 171.612 313.726 172.086C314.173 172.533 314.201 173.286 313.726 173.76C313.252 174.235 312.499 174.207 312.052 173.76Z" />
              <path d="M344.533 179.564C345.026 180.057 345.026 180.857 344.533 181.35C344.04 181.843 343.24 181.843 342.747 181.35C342.254 180.857 342.254 180.057 342.747 179.564C343.24 179.071 344.04 179.071 344.533 179.564Z" />
              <path d="M376.009 183.136C376.502 183.629 376.502 184.429 376.009 184.922C375.516 185.415 374.716 185.415 374.223 184.922C373.73 184.429 373.73 183.629 374.223 183.136C374.716 182.643 375.516 182.643 376.009 183.136Z" />
              <path d="M405.866 184.42C405.364 183.917 405.364 183.136 405.866 182.634C406.369 182.132 407.15 182.132 407.652 182.634C408.154 183.136 408.154 183.917 407.652 184.42C407.178 184.894 406.369 184.922 405.866 184.42Z" />
              <path d="M437.231 179.843C436.728 179.341 436.728 178.56 437.231 178.058C437.733 177.555 438.514 177.555 439.016 178.058C439.519 178.56 439.519 179.341 439.016 179.843C438.514 180.346 437.733 180.346 437.231 179.843Z" />
              <path d="M467.814 171.249C467.367 170.802 467.339 170.049 467.814 169.574C468.26 169.128 469.014 169.1 469.488 169.574C469.934 170.021 469.962 170.774 469.488 171.249C469.014 171.723 468.288 171.723 467.814 171.249Z" />
              <path d="M497.029 158.832C496.611 158.413 496.611 157.744 497.029 157.325C497.448 156.906 498.118 156.906 498.536 157.325C498.955 157.743 498.955 158.413 498.536 158.832C498.118 159.25 497.448 159.25 497.029 158.832Z" />
              <path d="M524.459 142.787C524.069 142.396 524.069 141.782 524.459 141.392C524.85 141.001 525.464 141.001 525.855 141.392C526.245 141.782 526.245 142.396 525.855 142.787C525.464 143.177 524.85 143.177 524.459 142.787Z" />
              <path d="M550.801 122.193C551.109 122.501 551.109 123.001 550.801 123.309C550.493 123.617 549.993 123.617 549.685 123.309C549.377 123.001 549.377 122.501 549.685 122.193C549.993 121.885 550.493 121.885 550.801 122.193Z" />
              <path d="M573.096 99.8977C573.358 100.16 573.358 100.584 573.096 100.846C572.834 101.108 572.41 101.108 572.148 100.846C571.886 100.584 571.886 100.16 572.148 99.8977C572.41 99.6357 572.834 99.6357 573.096 99.8977Z" />
              <path d="M591.625 75.6209C591.429 75.4256 591.457 75.1186 591.625 74.9511C591.792 74.7837 592.127 74.7838 592.294 74.9512C592.49 75.1466 592.462 75.4535 592.295 75.6209C592.127 75.7883 591.82 75.8162 591.625 75.6209Z" />
              <path d="M607.67 48.1909C607.53 48.0514 607.502 47.8003 607.67 47.6329C607.809 47.4933 608.06 47.4654 608.228 47.6328C608.367 47.7723 608.395 48.0235 608.228 48.1909C608.06 48.3583 607.837 48.3583 607.67 48.1909Z" />
              <path d="M620.45 18.6128C620.527 18.6899 620.527 18.8148 620.45 18.8918C620.373 18.9689 620.248 18.9689 620.171 18.8918C620.094 18.8148 620.094 18.6899 620.171 18.6128C620.248 18.5358 620.373 18.5358 620.45 18.6128Z" />
              <path d="M111.449 0.112283C111.365 0.0285726 111.365 -0.0830415 111.449 -0.166759C111.532 -0.250476 111.644 -0.250459 111.728 -0.166748C111.811 -0.0830373 111.811 0.0285556 111.728 0.112273C111.644 0.19599 111.532 0.195994 111.449 0.112283Z" />
              <path d="M120.127 30.6112C120.015 30.4996 120.015 30.3322 120.127 30.2205C120.238 30.1089 120.406 30.1089 120.517 30.2206C120.629 30.3322 120.629 30.4996 120.517 30.6112C120.434 30.7507 120.266 30.7507 120.127 30.6112Z" />
              <path d="M132.795 59.3531C132.98 59.538 132.98 59.8378 132.795 60.0228C132.61 60.2077 132.311 60.2077 132.126 60.0227C131.941 59.8378 131.941 59.538 132.126 59.353C132.311 59.1681 132.61 59.1681 132.795 59.3531Z" />
              <path d="M148.227 87.0057C148.458 87.2369 148.458 87.6117 148.227 87.8429C147.995 88.0741 147.621 88.0741 147.389 87.8429C147.158 87.6117 147.158 87.2369 147.389 87.0058C147.621 86.7746 147.995 86.7746 148.227 87.0057Z" />
              <path d="M166.671 112.706C166.979 113.014 166.979 113.514 166.671 113.822C166.363 114.13 165.863 114.13 165.555 113.822C165.247 113.514 165.247 113.014 165.555 112.706C165.863 112.398 166.363 112.398 166.671 112.706Z" />
              <path d="M186.623 137.513C186.288 137.178 186.288 136.62 186.623 136.285C186.958 135.95 187.516 135.95 187.851 136.285C188.185 136.62 188.185 137.178 187.851 137.513C187.516 137.848 186.958 137.848 186.623 137.513Z" />
              <path d="M210.09 158.748C209.672 158.329 209.672 157.66 210.09 157.241C210.509 156.823 211.179 156.823 211.597 157.241C212.016 157.66 212.016 158.329 211.597 158.748C211.178 159.166 210.509 159.166 210.09 158.748Z" />
              <path d="M235.846 177.136C235.399 176.69 235.371 175.936 235.846 175.462C236.292 175.015 237.046 174.988 237.52 175.462C237.967 175.908 237.994 176.662 237.52 177.136C237.046 177.611 236.32 177.611 235.846 177.136Z" />
              <path d="M263.443 192.623C262.913 192.093 262.913 191.2 263.443 190.67C263.973 190.14 264.866 190.14 265.396 190.67C265.927 191.2 265.927 192.093 265.396 192.623C264.866 193.154 263.973 193.154 263.443 192.623Z" />
              <path d="M292.659 204.818C292.073 204.232 292.101 203.311 292.659 202.753C293.217 202.194 294.166 202.194 294.724 202.753C295.31 203.339 295.282 204.259 294.724 204.817C294.166 205.376 293.217 205.376 292.659 204.818Z" />
              <path d="M325.167 211.487C325.737 212.057 325.737 212.982 325.167 213.552C324.597 214.122 323.672 214.122 323.102 213.552C322.532 212.982 322.532 212.057 323.102 211.487C323.673 210.917 324.597 210.917 325.167 211.487Z" />
              <path d="M356.476 216.733C357.092 217.35 357.092 218.349 356.476 218.965C355.859 219.582 354.86 219.582 354.243 218.965C353.627 218.349 353.627 217.349 354.243 216.733C354.86 216.117 355.859 216.117 356.476 216.733Z" />
              <path d="M385.859 220.723C385.245 220.109 385.245 219.105 385.859 218.491C386.473 217.877 387.477 217.877 388.091 218.491C388.705 219.105 388.705 220.109 388.091 220.723C387.477 221.337 386.473 221.337 385.859 220.723Z" />
              <path d="M417.502 218.937C416.888 218.324 416.888 217.319 417.502 216.705C418.116 216.091 419.121 216.091 419.735 216.705C420.349 217.319 420.349 218.324 419.735 218.937C419.121 219.551 418.116 219.551 417.502 218.937Z" />
              <path d="M450.876 211.515C451.446 212.085 451.446 213.01 450.876 213.58C450.306 214.15 449.381 214.15 448.811 213.58C448.241 213.01 448.241 212.085 448.811 211.515C449.381 210.945 450.306 210.945 450.876 211.515Z" />
              <path d="M481.319 202.725C481.89 203.295 481.889 204.219 481.319 204.79C480.749 205.36 479.825 205.36 479.254 204.79C478.684 204.219 478.684 203.295 479.254 202.725C479.825 202.155 480.749 202.154 481.319 202.725Z" />
              <path d="M508.582 192.596C508.052 192.065 508.052 191.172 508.582 190.642C509.112 190.112 510.005 190.112 510.535 190.642C511.065 191.173 511.065 192.065 510.535 192.596C510.005 193.126 509.14 193.154 508.582 192.596Z" />
              <path d="M536.458 177.164C536.012 176.718 535.984 175.964 536.458 175.49C536.905 175.044 537.658 175.016 538.132 175.49C538.579 175.936 538.607 176.69 538.132 177.164C537.686 177.611 536.905 177.611 536.458 177.164Z" />
              <path d="M562.353 158.748C561.935 158.329 561.935 157.66 562.353 157.241C562.772 156.822 563.442 156.822 563.86 157.241C564.279 157.66 564.279 158.329 563.86 158.748C563.469 159.194 562.772 159.166 562.353 158.748Z" />
              <path d="M586.128 137.485C585.793 137.15 585.793 136.592 586.128 136.257C586.462 135.922 587.021 135.922 587.355 136.257C587.69 136.592 587.69 137.15 587.355 137.485C587.048 137.848 586.49 137.848 586.128 137.485Z" />
              <path d="M607.307 113.794C607 113.487 607 112.985 607.307 112.678C607.614 112.371 608.116 112.371 608.423 112.678C608.73 112.985 608.73 113.487 608.423 113.794C608.116 114.101 607.614 114.101 607.307 113.794Z" />
              <path d="M626.617 87.0059C626.848 87.2371 626.848 87.6119 626.617 87.843C626.386 88.0742 626.011 88.0742 625.78 87.843C625.548 87.6119 625.548 87.2371 625.78 87.0059C626.011 86.7747 626.386 86.7748 626.617 87.0059Z" />
              <path d="M641.155 60.0226C640.959 59.8273 640.987 59.5203 641.155 59.3529C641.322 59.1855 641.657 59.1855 641.824 59.3529C641.992 59.5203 641.992 59.8551 641.824 60.0226C641.657 60.19 641.35 60.2179 641.155 60.0226Z" />
              <path d="M653.433 30.6111C653.321 30.4995 653.321 30.3321 653.433 30.2205C653.544 30.1088 653.712 30.1089 653.823 30.2205C653.935 30.3321 653.935 30.4995 653.823 30.6111C653.74 30.7507 653.544 30.7227 653.433 30.6111Z" />
              <path d="M662.278 0.112273C662.195 0.0285556 662.195 -0.083044 662.278 -0.166765C662.362 -0.250485 662.474 -0.25053 662.557 -0.166813C662.641 -0.0830963 662.641 0.0285459 662.557 0.112267C662.474 0.195987 662.362 0.19599 662.278 0.112273Z" />
              <path d="M76.1497 4.10227C76.066 4.01856 76.066 3.90695 76.1498 3.82324C76.2335 3.73953 76.3451 3.7395 76.4288 3.82321C76.5125 3.90693 76.5125 4.01856 76.4288 4.10227C76.3451 4.18598 76.2334 4.18598 76.1497 4.10227Z" />
              <path d="M84.27 34.7692C84.1584 34.6576 84.1584 34.4901 84.27 34.3785C84.3817 34.2669 84.5491 34.2669 84.6607 34.3785C84.7723 34.4901 84.7723 34.6576 84.6607 34.7692C84.5491 34.8808 84.4096 34.9087 84.27 34.7692Z" />
              <path d="M95.3479 64.5431C95.1526 64.3478 95.1805 64.0408 95.3479 63.8734C95.5153 63.706 95.8502 63.706 96.0176 63.8734C96.213 64.0687 96.1851 64.3757 96.0176 64.5431C95.8502 64.7106 95.5433 64.7385 95.3479 64.5431Z" />
              <path d="M109.384 92.9773C109.161 92.754 109.161 92.3634 109.384 92.1401C109.607 91.9169 109.998 91.9169 110.221 92.1401C110.444 92.3634 110.444 92.754 110.221 92.9773C109.97 93.2284 109.635 93.2284 109.384 92.9773Z" />
              <path d="M127.215 118.845C127.523 119.153 127.523 119.653 127.215 119.961C126.906 120.269 126.407 120.269 126.098 119.961C125.79 119.653 125.79 119.153 126.098 118.845C126.407 118.537 126.906 118.537 127.215 118.845Z" />
              <path d="M145.408 145.131C145.018 144.74 145.018 144.126 145.408 143.735C145.799 143.345 146.413 143.345 146.803 143.735C147.194 144.126 147.194 144.74 146.803 145.131C146.385 145.493 145.771 145.493 145.408 145.131Z" />
              <path d="M167.09 168.207C166.643 167.761 166.615 167.007 167.09 166.533C167.536 166.086 168.29 166.058 168.764 166.533C169.211 166.979 169.238 167.733 168.764 168.207C168.318 168.653 167.564 168.681 167.09 168.207Z" />
              <path d="M192.845 187.154C193.339 187.647 193.339 188.447 192.845 188.94C192.352 189.433 191.553 189.433 191.06 188.94C190.566 188.447 190.566 187.647 191.06 187.154C191.553 186.661 192.352 186.661 192.845 187.154Z" />
              <path d="M218.936 205.208C219.506 205.779 219.506 206.703 218.936 207.273C218.366 207.844 217.441 207.844 216.871 207.273C216.301 206.703 216.301 205.779 216.871 205.208C217.441 204.638 218.366 204.638 218.936 205.208Z" />
              <path d="M244.412 222.816C243.799 222.202 243.799 221.197 244.412 220.583C245.026 219.969 246.031 219.969 246.645 220.583C247.259 221.197 247.259 222.202 246.645 222.816C246.031 223.429 245.026 223.429 244.412 222.816Z" />
              <path d="M273.349 235.624C272.652 234.926 272.652 233.81 273.349 233.113C274.047 232.415 275.163 232.415 275.861 233.113C276.558 233.81 276.558 234.926 275.861 235.624C275.135 236.294 274.019 236.294 273.349 235.624Z" />
              <path d="M303.402 245.418C302.677 244.693 302.677 243.521 303.402 242.795C304.128 242.07 305.3 242.07 306.025 242.795C306.751 243.521 306.751 244.693 306.025 245.418C305.3 246.144 304.128 246.144 303.402 245.418Z" />
              <path d="M334.32 252.171C333.566 251.418 333.538 250.162 334.32 249.381C335.073 248.627 336.329 248.599 337.11 249.381C337.892 250.162 337.892 251.39 337.11 252.171C336.329 252.897 335.073 252.925 334.32 252.171Z" />
              <path d="M365.796 255.687C365.042 254.933 365.014 253.678 365.796 252.896C366.549 252.143 367.805 252.115 368.586 252.896C369.34 253.65 369.367 254.906 368.586 255.687C367.805 256.468 366.577 256.468 365.796 255.687Z" />
              <path d="M397.495 256.078C396.742 255.324 396.714 254.069 397.495 253.287C398.276 252.506 399.504 252.506 400.285 253.287C401.039 254.041 401.067 255.297 400.285 256.078C399.504 256.859 398.276 256.859 397.495 256.078Z" />
              <path d="M429.055 253.315C428.301 252.562 428.273 251.306 429.055 250.525C429.808 249.771 431.064 249.743 431.845 250.525C432.599 251.278 432.626 252.534 431.845 253.315C431.064 254.096 429.836 254.096 429.055 253.315Z" />
              <path d="M460.279 247.371C459.554 246.646 459.554 245.474 460.28 244.748C461.005 244.023 462.177 244.023 462.903 244.748C463.628 245.474 463.628 246.646 462.902 247.371C462.177 248.097 461.005 248.097 460.279 247.371Z" />
              <path d="M490.723 238.358C490.026 237.661 490.025 236.545 490.723 235.847C491.421 235.15 492.537 235.15 493.234 235.847C493.932 236.545 493.932 237.661 493.234 238.359C492.509 239.028 491.393 239.028 490.723 238.358Z" />
              <path d="M520.106 226.332C519.464 225.69 519.464 224.63 520.106 223.988C520.748 223.346 521.808 223.346 522.45 223.988C523.092 224.63 523.092 225.69 522.45 226.332C521.836 227.001 520.776 227.001 520.106 226.332Z" />
              <path d="M550.354 209.31C550.925 209.88 550.925 210.805 550.354 211.375C549.784 211.945 548.86 211.945 548.289 211.375C547.719 210.805 547.719 209.88 548.289 209.31C548.86 208.74 549.784 208.74 550.354 209.31Z" />
              <path d="M576.696 191.842C577.235 192.382 577.235 193.256 576.696 193.796C576.157 194.335 575.282 194.335 574.743 193.796C574.203 193.256 574.203 192.382 574.743 191.842C575.282 191.303 576.157 191.303 576.696 191.842Z" />
              <path d="M599.41 173.593C598.964 173.146 598.936 172.393 599.41 171.918C599.857 171.472 600.61 171.444 601.084 171.919C601.531 172.365 601.559 173.118 601.084 173.593C600.61 174.067 599.857 174.039 599.41 173.593Z" />
              <path d="M621.957 151.046C621.566 150.656 621.566 150.042 621.957 149.651C622.347 149.26 622.961 149.26 623.352 149.651C623.743 150.042 623.743 150.656 623.352 151.046C622.961 151.437 622.347 151.437 621.957 151.046Z" />
              <path d="M642.159 126.379C641.852 126.072 641.852 125.569 642.159 125.262C642.466 124.955 642.969 124.955 643.276 125.262C643.583 125.569 643.583 126.072 643.276 126.379C642.941 126.713 642.466 126.686 642.159 126.379Z" />
              <path d="M659.739 99.9255C659.46 99.6465 659.488 99.2279 659.739 98.9768C659.99 98.7257 660.437 98.7257 660.688 98.9768C660.967 99.2559 660.939 99.6744 660.688 99.9255C660.437 100.177 660.018 100.205 659.739 99.9255Z" />
              <path d="M674.668 71.7701C674.473 71.5747 674.5 71.2678 674.668 71.1004C674.863 70.905 675.17 70.933 675.338 71.1004C675.533 71.2957 675.505 71.6026 675.338 71.7701C675.198 71.9654 674.863 71.9654 674.668 71.7701Z" />
              <path d="M686.695 42.3871C686.555 42.2476 686.527 41.9964 686.694 41.829C686.862 41.6616 687.085 41.6616 687.253 41.829C687.392 41.9686 687.42 42.2197 687.253 42.3871C687.085 42.5546 686.834 42.5266 686.695 42.3871Z" />
              <path d="M695.819 11.8317C695.736 11.7479 695.736 11.6363 695.819 11.5526C695.903 11.4689 696.015 11.469 696.098 11.5527C696.182 11.6364 696.182 11.748 696.098 11.8317C695.987 11.8875 695.875 11.8875 695.819 11.8317Z" />
              <path d="M41.1858 7.8135C41.2629 7.89055 41.2629 8.01549 41.1858 8.09254C41.1088 8.1696 40.9839 8.16959 40.9068 8.09253C40.8298 8.01548 40.8297 7.89055 40.9068 7.8135C40.9838 7.73644 41.1088 7.73645 41.1858 7.8135Z" />
              <path d="M48.5805 38.8707C48.4689 38.7591 48.4689 38.5917 48.5805 38.4801C48.6921 38.3685 48.8595 38.3685 48.9712 38.4801C49.0828 38.5917 49.0828 38.7591 48.9712 38.8708C48.8875 39.0103 48.6921 38.9824 48.5805 38.8707Z" />
              <path d="M58.905 68.9519C58.7097 68.7566 58.7376 68.4496 58.905 68.2822C59.1003 68.0869 59.4073 68.1148 59.5747 68.2822C59.7421 68.4496 59.7421 68.7845 59.5747 68.9519C59.4073 69.1193 59.0724 69.1193 58.905 68.9519Z" />
              <path d="M72.8292 96.9399C73.0912 97.2019 73.0912 97.6267 72.8292 97.8887C72.5672 98.1507 72.1425 98.1507 71.8805 97.8887C71.6185 97.6267 71.6185 97.2019 71.8805 96.9399C72.1424 96.6779 72.5672 96.6779 72.8292 96.9399Z" />
              <path d="M88.5394 124.425C88.8476 124.734 88.8476 125.233 88.5394 125.541C88.2311 125.85 87.7314 125.85 87.4232 125.541C87.115 125.233 87.115 124.734 87.4232 124.425C87.7315 124.117 88.2312 124.117 88.5394 124.425Z" />
              <path d="M105.338 151.716C104.947 151.325 104.947 150.711 105.338 150.321C105.728 149.93 106.342 149.93 106.733 150.321C107.124 150.711 107.124 151.325 106.733 151.716C106.342 152.106 105.728 152.106 105.338 151.716Z" />
              <path d="M125.512 176.188C125.066 175.741 125.038 174.988 125.512 174.514C125.959 174.067 126.712 174.039 127.187 174.514C127.633 174.96 127.661 175.714 127.187 176.188C126.712 176.662 125.959 176.634 125.512 176.188Z" />
              <path d="M149.733 196.781C150.273 197.32 150.273 198.195 149.733 198.734C149.194 199.274 148.319 199.274 147.78 198.734C147.241 198.195 147.241 197.32 147.78 196.781C148.319 196.242 149.194 196.242 149.733 196.781Z" />
              <path d="M171.973 219.188C171.359 218.574 171.359 217.57 171.973 216.956C172.587 216.342 173.591 216.342 174.205 216.956C174.819 217.57 174.819 218.574 174.205 219.188C173.564 219.774 172.587 219.802 171.973 219.188Z" />
              <path d="M197.868 237.382C197.171 236.684 197.171 235.568 197.868 234.87C198.566 234.173 199.682 234.173 200.38 234.87C201.077 235.568 201.077 236.684 200.38 237.382C199.654 238.051 198.538 238.051 197.868 237.382Z" />
              <path d="M225.298 253.148C224.545 252.395 224.517 251.139 225.298 250.358C226.051 249.604 227.307 249.576 228.088 250.358C228.842 251.111 228.87 252.367 228.088 253.148C227.279 253.901 226.051 253.901 225.298 253.148Z" />
              <path d="M254.067 266.29C253.258 265.481 253.258 264.198 254.067 263.388C254.877 262.579 256.16 262.579 256.969 263.388C257.779 264.198 257.779 265.481 256.969 266.29C256.188 267.128 254.877 267.1 254.067 266.29Z" />
              <path d="M283.925 276.838C283.088 276.001 283.06 274.634 283.925 273.769C284.762 272.931 286.129 272.904 286.994 273.769C287.831 274.606 287.859 275.973 286.994 276.838C286.101 277.675 284.762 277.675 283.925 276.838Z" />
              <path d="M314.564 284.651C313.671 283.759 313.699 282.335 314.564 281.47C315.429 280.605 316.88 280.605 317.745 281.47C318.61 282.335 318.61 283.786 317.745 284.651C316.88 285.516 315.457 285.544 314.564 284.651Z" />
              <path d="M345.788 289.702C344.868 288.782 344.868 287.275 345.788 286.354C346.709 285.433 348.216 285.433 349.137 286.354C350.058 287.275 350.058 288.782 349.137 289.702C348.216 290.623 346.709 290.623 345.788 289.702Z" />
              <path d="M377.404 291.795C376.483 290.874 376.483 289.367 377.404 288.447C378.325 287.526 379.832 287.526 380.752 288.447C381.673 289.367 381.673 290.874 380.752 291.795C379.804 292.744 378.325 292.716 377.404 291.795Z" />
              <path d="M409.075 291.098C408.154 290.177 408.154 288.67 409.075 287.749C409.996 286.828 411.503 286.828 412.424 287.749C413.345 288.67 413.345 290.177 412.424 291.098C411.503 292.018 409.996 292.018 409.075 291.098Z" />
              <path d="M440.579 287.554C439.658 286.633 439.658 285.126 440.579 284.206C441.5 283.285 443.007 283.285 443.928 284.205C444.849 285.126 444.849 286.633 443.928 287.554C443.007 288.475 441.5 288.475 440.579 287.554Z" />
              <path d="M471.664 281.136C470.772 280.243 470.799 278.82 471.664 277.955C472.557 277.062 473.981 277.09 474.846 277.955C475.711 278.82 475.711 280.271 474.846 281.136C473.98 282.001 472.557 282.029 471.664 281.136Z" />
              <path d="M505.177 268.886C506.025 269.733 506.025 271.108 505.177 271.955C504.33 272.803 502.956 272.803 502.108 271.955C501.26 271.108 501.26 269.733 502.108 268.886C502.956 268.038 504.33 268.038 505.177 268.886Z" />
              <path d="M534.421 257.222C535.192 257.993 535.192 259.242 534.421 260.012C533.651 260.783 532.401 260.783 531.631 260.012C530.86 259.242 530.86 257.993 531.631 257.222C532.401 256.451 533.651 256.451 534.421 257.222Z" />
              <path d="M559.926 245.558C559.2 244.833 559.2 243.661 559.926 242.935C560.651 242.21 561.823 242.21 562.548 242.935C563.274 243.661 563.274 244.833 562.549 245.558C561.851 246.312 560.651 246.284 559.926 245.558Z" />
              <path d="M589.253 226.22C589.9 226.868 589.9 227.917 589.253 228.564C588.606 229.212 587.556 229.212 586.909 228.564C586.262 227.917 586.262 226.868 586.909 226.22C587.556 225.573 588.606 225.573 589.253 226.22Z" />
              <path d="M614.339 207.161C614.909 207.732 614.909 208.656 614.339 209.226C613.769 209.796 612.844 209.797 612.274 209.226C611.704 208.656 611.704 207.732 612.274 207.161C612.844 206.591 613.769 206.591 614.339 207.161Z" />
              <path d="M635.797 187.713C635.295 187.21 635.295 186.429 635.797 185.927C636.3 185.425 637.081 185.425 637.583 185.927C638.085 186.429 638.085 187.21 637.583 187.713C637.081 188.215 636.3 188.215 635.797 187.713Z" />
              <path d="M657.311 164.189C656.893 163.77 656.893 163.1 657.311 162.682C657.73 162.263 658.4 162.263 658.818 162.682C659.237 163.1 659.237 163.77 658.818 164.189C658.4 164.607 657.73 164.607 657.311 164.189Z" />
              <path d="M676.649 138.824C676.314 138.489 676.314 137.931 676.649 137.596C676.984 137.261 677.542 137.261 677.877 137.596C678.212 137.931 678.212 138.489 677.877 138.824C677.542 139.159 676.984 139.159 676.649 138.824Z" />
              <path d="M694.675 110.808C694.983 111.116 694.983 111.616 694.675 111.924C694.367 112.232 693.867 112.232 693.559 111.924C693.251 111.616 693.251 111.116 693.559 110.808C693.867 110.5 694.367 110.5 694.675 110.808Z" />
              <path d="M708.906 82.7367C709.137 82.9679 709.137 83.3427 708.906 83.5739C708.675 83.8051 708.3 83.805 708.069 83.5738C707.838 83.3427 707.838 82.9679 708.069 82.7367C708.3 82.5056 708.675 82.5056 708.906 82.7367Z" />
              <path d="M720.04 54.0235C719.901 53.8839 719.873 53.6328 720.04 53.4654C720.207 53.298 720.431 53.298 720.598 53.4654C720.738 53.6049 720.766 53.8561 720.598 54.0235C720.431 54.1909 720.18 54.163 720.04 54.0235Z" />
              <path d="M729.193 23.6077C729.081 23.4961 729.081 23.3286 729.193 23.217C729.304 23.1054 729.472 23.1054 729.583 23.217C729.695 23.3287 729.695 23.496 729.583 23.6076C729.527 23.7193 729.304 23.7193 729.193 23.6077Z" />
              <path d="M5.69171 12.055C5.608 11.9713 5.60801 11.8597 5.69172 11.776C5.77543 11.6923 5.88703 11.6923 5.97074 11.776C6.05445 11.8597 6.05446 11.9713 5.97075 12.055C5.88704 12.1387 5.77542 12.1388 5.69171 12.055Z" />
              <path d="M12.9189 43.0007C12.7793 42.8612 12.7514 42.6101 12.9188 42.4426C13.0584 42.3031 13.3095 42.2752 13.477 42.4426C13.6165 42.5822 13.6444 42.8333 13.4769 43.0007C13.3095 43.1682 13.0863 43.1682 12.9189 43.0007Z" />
              <path d="M22.6853 73.1931C22.49 72.9978 22.5179 72.6909 22.6853 72.5234C22.8806 72.3281 23.1876 72.356 23.355 72.5234C23.5503 72.7187 23.5224 73.0257 23.355 73.1931C23.2155 73.3885 22.8806 73.3885 22.6853 73.1931Z" />
              <path d="M35.8003 101.6C36.0623 101.862 36.0623 102.287 35.8003 102.549C35.5383 102.811 35.1136 102.811 34.8516 102.549C34.5896 102.287 34.5896 101.862 34.8516 101.6C35.1136 101.338 35.5383 101.338 35.8003 101.6Z" />
              <path d="M49.3338 130.815C48.999 130.48 48.999 129.922 49.3338 129.587C49.6687 129.253 50.2268 129.253 50.5616 129.587C50.8965 129.922 50.8965 130.48 50.5616 130.815C50.2268 131.15 49.6687 131.15 49.3338 130.815Z" />
              <path d="M66.0206 157.771C65.6021 157.352 65.602 156.683 66.0206 156.264C66.4392 155.846 67.1088 155.846 67.5274 156.264C67.946 156.683 67.946 157.352 67.5275 157.771C67.1368 158.217 66.4671 158.217 66.0206 157.771Z" />
              <path d="M84.8839 183.275C84.3816 182.773 84.3816 181.992 84.8839 181.489C85.3862 180.987 86.1675 180.987 86.6698 181.489C87.1721 181.992 87.1721 182.773 86.6698 183.275C86.1675 183.778 85.3862 183.778 84.8839 183.275Z" />
              <path d="M105.756 207.162C105.17 206.576 105.198 205.655 105.756 205.097C106.342 204.511 107.263 204.539 107.821 205.097C108.407 205.683 108.379 206.603 107.821 207.162C107.235 207.748 106.314 207.72 105.756 207.162Z" />
              <path d="M128.47 229.262C127.829 228.62 127.829 227.56 128.47 226.918C129.112 226.276 130.172 226.276 130.814 226.918C131.456 227.56 131.456 228.62 130.814 229.262C130.173 229.904 129.112 229.904 128.47 229.262Z" />
              <path d="M152.887 249.381C152.161 248.655 152.161 247.483 152.887 246.758C153.612 246.032 154.784 246.032 155.51 246.758C156.235 247.483 156.235 248.655 155.51 249.381C154.784 250.106 153.612 250.106 152.887 249.381Z" />
              <path d="M181.795 264.561C182.597 265.362 182.597 266.661 181.795 267.463C180.994 268.264 179.695 268.264 178.893 267.463C178.092 266.661 178.092 265.362 178.893 264.561C179.695 263.759 180.994 263.759 181.795 264.561Z" />
              <path d="M209.42 280.187C210.299 281.065 210.299 282.49 209.42 283.368C208.542 284.246 207.118 284.246 206.239 283.368C205.361 282.49 205.361 281.065 206.239 280.187C207.118 279.308 208.542 279.308 209.42 280.187Z" />
              <path d="M234.786 296.957C233.837 296.009 233.837 294.446 234.786 293.497C235.734 292.548 237.297 292.548 238.246 293.497C239.194 294.446 239.194 296.008 238.246 296.957C237.297 297.906 235.734 297.906 234.786 296.957Z" />
              <path d="M264.392 308.091C263.387 307.087 263.387 305.469 264.392 304.464C265.396 303.459 267.015 303.459 268.019 304.464C269.024 305.469 269.024 307.087 268.019 308.092C267.015 309.096 265.396 309.096 264.392 308.091Z" />
              <path d="M294.835 316.769C293.803 315.737 293.803 314.063 294.835 313.03C295.868 311.998 297.542 311.998 298.575 313.03C299.607 314.063 299.607 315.737 298.575 316.769C297.542 317.802 295.868 317.802 294.835 316.769Z" />
              <path d="M329.716 319.113C330.779 320.177 330.779 321.901 329.716 322.964C328.652 324.027 326.928 324.027 325.865 322.964C324.802 321.901 324.802 320.177 325.865 319.113C326.928 318.05 328.652 318.05 329.716 319.113Z" />
              <path d="M361.192 322.629C362.255 323.692 362.255 325.416 361.192 326.48C360.128 327.543 358.404 327.543 357.341 326.48C356.278 325.416 356.278 323.692 357.341 322.629C358.404 321.566 360.128 321.566 361.192 322.629Z" />
              <path d="M392.947 323.522C394.056 324.632 394.056 326.431 392.947 327.54C391.837 328.65 390.038 328.65 388.929 327.54C387.819 326.431 387.819 324.632 388.929 323.522C390.038 322.413 391.837 322.413 392.947 323.522Z" />
              <path d="M424.506 321.987C425.57 323.051 425.57 324.775 424.506 325.838C423.443 326.901 421.719 326.901 420.656 325.838C419.592 324.775 419.592 323.051 420.656 321.987C421.719 320.924 423.443 320.924 424.506 321.987Z" />
              <path d="M455.927 317.802C456.99 318.865 456.99 320.589 455.926 321.653C454.863 322.716 453.139 322.716 452.076 321.653C451.012 320.589 451.012 318.865 452.076 317.802C453.139 316.739 454.863 316.739 455.927 317.802Z" />
              <path d="M483.105 314.844C482.073 313.812 482.073 312.137 483.105 311.105C484.138 310.073 485.812 310.073 486.844 311.105C487.877 312.137 487.877 313.812 486.844 314.844C485.812 315.877 484.138 315.877 483.105 314.844Z" />
              <path d="M513.493 305.552C512.488 304.547 512.488 302.929 513.493 301.924C514.498 300.92 516.116 300.92 517.121 301.924C518.125 302.929 518.125 304.547 517.12 305.552C516.116 306.556 514.498 306.556 513.493 305.552Z" />
              <path d="M543.016 293.832C542.067 292.883 542.067 291.321 543.016 290.372C543.964 289.423 545.527 289.423 546.476 290.372C547.425 291.321 547.424 292.883 546.476 293.832C545.527 294.781 543.964 294.781 543.016 293.832Z" />
              <path d="M574.743 276.448C575.621 277.326 575.621 278.751 574.743 279.629C573.864 280.507 572.44 280.507 571.562 279.629C570.683 278.751 570.683 277.326 571.562 276.448C572.44 275.569 573.864 275.569 574.743 276.448Z" />
              <path d="M598.88 263.193C598.071 262.384 598.071 261.1 598.88 260.291C599.689 259.482 600.973 259.482 601.782 260.291C602.591 261.1 602.591 262.384 601.782 263.193C600.973 264.002 599.661 263.974 598.88 263.193Z" />
              <path d="M624.747 244.581C624.022 243.856 624.022 242.684 624.747 241.958C625.473 241.233 626.645 241.233 627.37 241.958C628.096 242.684 628.096 243.856 627.37 244.581C626.645 245.307 625.473 245.307 624.747 244.581Z" />
              <path d="M649.135 223.876C648.521 223.262 648.521 222.258 649.135 221.644C649.749 221.03 650.754 221.03 651.368 221.644C651.982 222.258 651.982 223.262 651.368 223.876C650.726 224.462 649.721 224.462 649.135 223.876Z" />
              <path d="M673.635 199.377C674.175 199.916 674.175 200.79 673.635 201.33C673.096 201.869 672.221 201.869 671.682 201.33C671.143 200.79 671.143 199.916 671.682 199.377C672.221 198.837 673.096 198.837 673.635 199.377Z" />
              <path d="M693.978 175.351C694.44 175.813 694.44 176.563 693.978 177.025C693.515 177.488 692.766 177.488 692.303 177.025C691.841 176.563 691.841 175.813 692.303 175.351C692.766 174.889 693.515 174.889 693.978 175.351Z" />
              <path d="M710.915 151.158C710.525 150.767 710.525 150.153 710.915 149.763C711.306 149.372 711.92 149.372 712.311 149.763C712.701 150.153 712.701 150.767 712.311 151.158C711.892 151.521 711.278 151.521 710.915 151.158Z" />
              <path d="M728.467 122.724C728.775 123.032 728.775 123.532 728.467 123.84C728.159 124.148 727.659 124.148 727.351 123.84C727.043 123.532 727.043 123.032 727.351 122.724C727.659 122.415 728.159 122.415 728.467 122.724Z" />
              <path d="M741.554 95.2935C741.331 95.0702 741.331 94.6795 741.554 94.4563C741.778 94.233 742.168 94.2331 742.391 94.4563C742.615 94.6796 742.615 95.0702 742.391 95.2934C742.14 95.4888 741.778 95.5167 741.554 95.2935Z" />
              <path d="M12.9467 134.499C13.2858 134.838 13.2858 135.388 12.9467 135.727C12.6077 136.066 12.058 136.066 11.7189 135.727C11.3799 135.388 11.3799 134.838 11.719 134.499C12.058 134.16 12.6077 134.16 12.9467 134.499Z" />
              <path d="M27.4291 163.324C27.0105 162.906 27.0105 162.236 27.4291 161.817C27.8477 161.399 28.5173 161.399 28.9359 161.817C29.3545 162.236 29.3545 162.906 28.9359 163.324C28.5174 163.743 27.8477 163.743 27.4291 163.324Z" />
              <path d="M45.1482 189.638C44.6459 189.135 44.6459 188.354 45.1482 187.852C45.6505 187.349 46.4318 187.349 46.9341 187.852C47.4364 188.354 47.4364 189.135 46.9341 189.638C46.4318 190.14 45.6505 190.14 45.1482 189.638Z" />
              <path d="M64.6812 214.64C64.0673 214.026 64.0672 213.021 64.6811 212.407C65.295 211.794 66.2996 211.794 66.9135 212.407C67.5274 213.021 67.5274 214.026 66.9135 214.64C66.2996 215.254 65.2951 215.254 64.6812 214.64Z" />
              <path d="M86.1117 238.023C85.4141 237.326 85.4141 236.209 86.1117 235.512C86.8093 234.814 87.9255 234.814 88.6231 235.512C89.3207 236.21 89.3207 237.326 88.6231 238.023C87.8976 238.693 86.7814 238.693 86.1117 238.023Z" />
              <path d="M109.188 259.705C108.435 258.952 108.407 257.696 109.188 256.915C109.942 256.161 111.198 256.134 111.979 256.915C112.732 257.668 112.76 258.924 111.979 259.705C111.225 260.459 109.942 260.459 109.188 259.705Z" />
              <path d="M133.772 279.657C132.879 278.764 132.907 277.341 133.772 276.476C134.637 275.611 136.088 275.611 136.953 276.476C137.818 277.341 137.818 278.792 136.953 279.657C136.088 280.522 134.637 280.522 133.772 279.657Z" />
              <path d="M159.807 297.655C158.858 296.706 158.858 295.143 159.807 294.195C160.756 293.246 162.318 293.246 163.267 294.195C164.216 295.144 164.216 296.706 163.267 297.655C162.318 298.604 160.756 298.604 159.807 297.655Z" />
              <path d="M187.125 313.644C186.093 312.611 186.093 310.937 187.125 309.905C188.158 308.872 189.832 308.872 190.864 309.905C191.897 310.937 191.897 312.611 190.864 313.644C189.832 314.676 188.158 314.676 187.125 313.644Z" />
              <path d="M219.55 323.522C220.659 324.632 220.659 326.431 219.55 327.54C218.44 328.65 216.641 328.65 215.532 327.54C214.422 326.431 214.422 324.632 215.532 323.522C216.641 322.413 218.44 322.413 219.55 323.522Z" />
              <path d="M249.1 335.047C250.241 336.187 250.241 338.036 249.1 339.177C247.96 340.317 246.111 340.317 244.971 339.177C243.83 338.036 243.83 336.187 244.971 335.047C246.111 333.906 247.96 333.906 249.1 335.047Z" />
              <path d="M279.488 344.283C280.675 345.469 280.675 347.393 279.488 348.58C278.301 349.767 276.377 349.767 275.191 348.58C274.004 347.393 274.004 345.469 275.191 344.283C276.377 343.096 278.301 343.096 279.488 344.283Z" />
              <path d="M305.969 355.696C304.742 354.468 304.769 352.487 305.969 351.287C307.169 350.087 309.178 350.087 310.378 351.287C311.606 352.515 311.578 354.496 310.378 355.696C309.178 356.896 307.197 356.924 305.969 355.696Z" />
              <path d="M337.278 360.495C336.022 359.24 336.022 357.175 337.278 355.919C338.533 354.663 340.598 354.663 341.854 355.919C343.11 357.175 343.11 359.24 341.854 360.495C340.543 361.751 338.533 361.751 337.278 360.495Z" />
              <path d="M368.865 362.839C367.61 361.584 367.61 359.519 368.865 358.263C370.121 357.007 372.186 357.007 373.442 358.263C374.697 359.519 374.697 361.584 373.442 362.839C372.158 364.123 370.121 364.095 368.865 362.839Z" />
              <path d="M400.537 362.867C399.281 361.612 399.281 359.547 400.537 358.291C401.792 357.035 403.857 357.035 405.113 358.291C406.369 359.547 406.369 361.612 405.113 362.867C403.857 364.123 401.792 364.123 400.537 362.867Z" />
              <path d="M432.152 360.495C430.896 359.24 430.896 357.175 432.152 355.919C433.408 354.663 435.473 354.663 436.728 355.919C437.984 357.175 437.984 359.24 436.728 360.495C435.473 361.751 433.408 361.751 432.152 360.495Z" />
              <path d="M463.572 355.696C462.344 354.468 462.372 352.487 463.572 351.287C464.8 350.059 466.781 350.087 467.981 351.287C469.209 352.515 469.181 354.496 467.981 355.696C466.753 356.924 464.8 356.924 463.572 355.696Z" />
              <path d="M494.518 348.58C493.346 347.408 493.318 345.483 494.518 344.283C495.69 343.111 497.615 343.083 498.815 344.283C499.987 345.455 500.015 347.38 498.815 348.58C497.643 349.752 495.69 349.752 494.518 348.58Z" />
              <path d="M524.878 339.149C523.734 338.005 523.734 336.163 524.878 335.019C526.022 333.875 527.864 333.875 529.008 335.019C530.152 336.163 530.152 338.005 529.008 339.149C527.891 340.321 526.022 340.293 524.878 339.149Z" />
              <path d="M558.447 323.494C559.556 324.604 559.556 326.403 558.447 327.513C557.337 328.622 555.538 328.622 554.428 327.513C553.319 326.403 553.319 324.604 554.428 323.494C555.538 322.385 557.337 322.385 558.447 323.494Z" />
              <path d="M583.142 313.644C582.109 312.612 582.109 310.937 583.142 309.905C584.174 308.872 585.849 308.872 586.881 309.905C587.914 310.937 587.914 312.612 586.881 313.644C585.849 314.676 584.174 314.676 583.142 313.644Z" />
              <path d="M610.711 297.627C609.763 296.678 609.763 295.115 610.711 294.167C611.66 293.218 613.223 293.218 614.171 294.167C615.12 295.115 615.12 296.678 614.171 297.627C613.195 298.604 611.688 298.604 610.711 297.627Z" />
              <path d="M640.234 276.476C641.113 277.354 641.113 278.778 640.234 279.657C639.356 280.535 637.931 280.535 637.053 279.657C636.175 278.778 636.175 277.354 637.053 276.476C637.931 275.597 639.356 275.597 640.234 276.476Z" />
              <path d="M662.027 259.705C661.274 258.952 661.246 257.696 662.027 256.915C662.781 256.161 664.036 256.134 664.818 256.915C665.571 257.668 665.599 258.924 664.818 259.705C664.008 260.459 662.781 260.459 662.027 259.705Z" />
              <path d="M685.383 238.023C684.685 237.326 684.685 236.209 685.383 235.512C686.081 234.814 687.197 234.814 687.894 235.512C688.592 236.209 688.592 237.326 687.895 238.023C687.169 238.693 686.053 238.693 685.383 238.023Z" />
              <path d="M707.093 214.64C706.479 214.026 706.479 213.021 707.093 212.408C707.706 211.794 708.711 211.794 709.325 212.408C709.939 213.021 709.939 214.026 709.325 214.64C708.683 215.226 707.678 215.226 707.093 214.64Z" />
              <path d="M727.044 189.666C726.542 189.163 726.542 188.382 727.044 187.88C727.546 187.377 728.328 187.378 728.83 187.88C729.332 188.382 729.332 189.163 728.83 189.666C728.356 190.14 727.518 190.14 727.044 189.666Z" />
              <path d="M745.014 163.324C744.596 162.906 744.596 162.236 745.014 161.817C745.433 161.399 746.103 161.399 746.521 161.817C746.94 162.236 746.94 162.906 746.521 163.324C746.131 163.771 745.433 163.743 745.014 163.324Z" />
              <path d="M5.99858 195.581C5.46839 195.051 5.46842 194.158 5.99862 193.628C6.52881 193.097 7.42175 193.097 7.95193 193.628C8.48212 194.158 8.48211 195.051 7.95192 195.581C7.39382 196.139 6.52876 196.111 5.99858 195.581Z" />
              <path d="M26.7314 219.104C27.3479 219.721 27.3479 220.72 26.7314 221.337C26.115 221.953 25.1155 221.953 24.4991 221.337C23.8826 220.72 23.8827 219.721 24.4991 219.104C25.1155 218.488 26.115 218.488 26.7314 219.104Z" />
              <path d="M44.7018 245.781C43.9763 245.055 43.9763 243.883 44.7018 243.158C45.4274 242.432 46.5993 242.432 47.3249 243.158C48.0504 243.883 48.0504 245.055 47.3249 245.781C46.6272 246.534 45.4274 246.506 44.7018 245.781Z" />
              <path d="M66.5787 268.718C65.7695 267.909 65.7695 266.625 66.5787 265.816C67.388 265.007 68.6716 265.007 69.4808 265.816C70.29 266.625 70.29 267.909 69.4808 268.718C68.6716 269.527 67.388 269.527 66.5787 268.718Z" />
              <path d="M89.9066 290.149C88.9858 289.228 88.9858 287.721 89.9066 286.8C90.8275 285.879 92.3343 285.879 93.2551 286.8C94.176 287.721 94.176 289.228 93.2552 290.149C92.3343 291.07 90.8275 291.07 89.9066 290.149Z" />
              <path d="M114.714 309.821C113.709 308.817 113.709 307.198 114.714 306.194C115.718 305.189 117.337 305.189 118.341 306.194C119.346 307.198 119.346 308.816 118.341 309.821C117.337 310.826 115.718 310.826 114.714 309.821Z" />
              <path d="M144.766 323.801C145.876 324.911 145.876 326.71 144.766 327.82C143.657 328.929 141.858 328.929 140.748 327.82C139.639 326.71 139.639 324.911 140.748 323.801C141.858 322.692 143.657 322.692 144.766 323.801Z" />
              <path d="M168.011 343.865C166.839 342.693 166.811 340.767 168.011 339.567C169.183 338.395 171.108 338.368 172.308 339.567C173.48 340.739 173.508 342.665 172.308 343.865C171.108 345.065 169.211 345.065 168.011 343.865Z" />
              <path d="M196.306 358.04C195.05 356.784 195.05 354.719 196.306 353.464C197.561 352.208 199.626 352.208 200.882 353.464C202.138 354.719 202.138 356.784 200.882 358.04C199.626 359.296 197.561 359.296 196.306 358.04Z" />
              <path d="M225.493 370.15C224.154 368.811 224.154 366.634 225.493 365.295C226.833 363.955 229.009 363.955 230.349 365.295C231.688 366.634 231.688 368.81 230.349 370.15C229.009 371.489 226.833 371.489 225.493 370.15Z" />
              <path d="M260.513 375.201C261.885 376.572 261.885 378.796 260.513 380.168C259.142 381.539 256.918 381.539 255.546 380.168C254.175 378.796 254.175 376.572 255.546 375.201C256.918 373.829 259.142 373.829 260.513 375.201Z" />
              <path d="M291.264 382.958C292.682 384.376 292.682 386.675 291.264 388.093C289.846 389.511 287.547 389.511 286.129 388.093C284.712 386.675 284.712 384.376 286.129 382.958C287.547 381.541 289.846 381.541 291.264 382.958Z" />
              <path d="M322.489 388.623C323.937 390.071 323.937 392.42 322.489 393.869C321.04 395.317 318.691 395.317 317.243 393.869C315.794 392.42 315.794 390.071 317.243 388.623C318.691 387.174 321.04 387.174 322.489 388.623Z" />
              <path d="M348.663 397.496C347.184 396.017 347.156 393.589 348.663 392.083C350.17 390.576 352.569 390.576 354.076 392.083C355.555 393.561 355.583 395.989 354.076 397.496C352.569 399.003 350.169 399.003 348.663 397.496Z" />
              <path d="M380.334 398.864C378.855 397.385 378.827 394.957 380.334 393.45C381.813 391.971 384.241 391.944 385.747 393.45C387.226 394.929 387.254 397.357 385.747 398.864C384.241 400.371 381.813 400.343 380.334 398.864Z" />
              <path d="M412.005 398.055C410.526 396.576 410.498 394.148 412.005 392.641C413.484 391.162 415.912 391.134 417.419 392.641C418.926 394.148 418.926 396.548 417.419 398.055C415.94 399.534 413.484 399.534 412.005 398.055Z" />
              <path d="M443.621 394.957C442.17 393.506 442.17 391.162 443.621 389.711C445.072 388.26 447.416 388.26 448.867 389.711C450.318 391.162 450.318 393.506 448.867 394.957C447.444 396.436 445.072 396.408 443.621 394.957Z" />
              <path d="M480.091 384.605C481.509 386.023 481.509 388.322 480.091 389.739C478.674 391.157 476.375 391.157 474.957 389.739C473.539 388.321 473.539 386.023 474.957 384.605C476.375 383.187 478.674 383.187 480.091 384.605Z" />
              <path d="M510.842 377.349C512.214 378.721 512.214 380.945 510.842 382.316C509.471 383.688 507.247 383.688 505.875 382.316C504.504 380.945 504.504 378.721 505.875 377.349C507.247 375.978 509.471 375.978 510.842 377.349Z" />
              <path d="M541.034 367.974C542.375 369.315 542.375 371.489 541.034 372.829C539.694 374.17 537.52 374.17 536.179 372.829C534.838 371.489 534.838 369.315 536.179 367.974C537.52 366.633 539.694 366.633 541.034 367.974Z" />
              <path d="M565.869 361.221C564.613 359.965 564.613 357.9 565.869 356.645C567.125 355.389 569.19 355.389 570.445 356.645C571.701 357.9 571.701 359.965 570.445 361.221C569.19 362.477 567.125 362.477 565.869 361.221Z" />
              <path d="M598.964 343.251C600.15 344.437 600.15 346.361 598.964 347.548C597.777 348.734 595.853 348.734 594.666 347.548C593.48 346.361 593.48 344.437 594.666 343.251C595.853 342.064 597.777 342.064 598.964 343.251Z" />
              <path d="M626.477 327.959C627.587 329.069 627.587 330.868 626.477 331.977C625.368 333.087 623.569 333.087 622.459 331.977C621.349 330.868 621.349 329.069 622.459 327.959C623.569 326.849 625.368 326.849 626.477 327.959Z" />
              <path d="M649.163 314.537C648.131 313.505 648.131 311.83 649.163 310.798C650.196 309.765 651.87 309.765 652.903 310.798C653.935 311.83 653.935 313.505 652.903 314.537C651.87 315.569 650.196 315.57 649.163 314.537Z" />
              <path d="M674.556 295.283C673.607 294.334 673.607 292.771 674.556 291.823C675.505 290.874 677.068 290.874 678.016 291.823C678.965 292.771 678.965 294.334 678.016 295.283C677.068 296.231 675.505 296.232 674.556 295.283Z" />
              <path d="M701.735 271.174C702.582 272.021 702.582 273.396 701.735 274.243C700.887 275.091 699.513 275.091 698.665 274.243C697.818 273.396 697.818 272.021 698.665 271.174C699.513 270.326 700.887 270.326 701.735 271.174Z" />
              <path d="M721.268 251.641C720.542 250.916 720.542 249.744 721.268 249.018C721.993 248.293 723.165 248.293 723.891 249.018C724.616 249.744 724.616 250.916 723.891 251.641C723.221 252.367 722.021 252.395 721.268 251.641Z" />
              <path d="M742.252 227.587C741.61 226.946 741.61 225.885 742.252 225.243C742.894 224.602 743.954 224.602 744.596 225.243C745.238 225.885 745.238 226.946 744.596 227.587C743.926 228.257 742.894 228.229 742.252 227.587Z" />
              <path d="M6.8637 250.134C7.63426 250.905 7.63426 252.154 6.86371 252.925C6.09315 253.695 4.84382 253.695 4.07326 252.925C3.3027 252.154 3.3027 250.905 4.07326 250.134C4.84381 249.364 6.09314 249.364 6.8637 250.134Z" />
              <path d="M27.9313 273.769C28.7789 274.617 28.7789 275.991 27.9313 276.839C27.0837 277.686 25.7094 277.686 24.8618 276.839C24.0142 275.991 24.0142 274.617 24.8618 273.769C25.7094 272.922 27.0837 272.922 27.9313 273.769Z" />
              <path d="M47.0737 299.441C46.1249 298.492 46.1249 296.929 47.0736 295.981C48.0224 295.032 49.585 295.032 50.5338 295.981C51.4825 296.93 51.4825 298.492 50.5338 299.441C49.585 300.39 48.0224 300.39 47.0737 299.441Z" />
              <path d="M70.6527 320.564C69.5924 319.504 69.5924 317.774 70.6528 316.713C71.7131 315.653 73.4432 315.653 74.5035 316.713C75.5639 317.774 75.5639 319.504 74.5035 320.564C73.4711 321.652 71.741 321.652 70.6527 320.564Z" />
              <path d="M95.5992 340.098C94.4551 338.954 94.4551 337.112 95.5991 335.968C96.7432 334.824 98.5849 334.824 99.729 335.968C100.873 337.112 100.873 338.954 99.729 340.098C98.6128 341.214 96.7432 341.242 95.5992 340.098Z" />
              <path d="M121.69 357.984C120.434 356.728 120.434 354.663 121.69 353.408C122.945 352.152 125.01 352.152 126.266 353.408C127.522 354.663 127.522 356.728 126.266 357.984C124.982 359.268 122.945 359.24 121.69 357.984Z" />
              <path d="M153.752 369.285C155.092 370.626 155.092 372.8 153.752 374.141C152.411 375.481 150.237 375.481 148.896 374.141C147.555 372.8 147.555 370.626 148.896 369.285C150.237 367.944 152.411 367.944 153.752 369.285Z" />
              <path d="M182.214 383.349C183.632 384.767 183.632 387.065 182.214 388.483C180.796 389.901 178.497 389.901 177.08 388.483C175.662 387.065 175.662 384.767 177.079 383.349C178.497 381.931 180.796 381.931 182.214 383.349Z" />
              <path d="M206.156 400.985C204.677 399.506 204.649 397.078 206.156 395.571C207.635 394.092 210.062 394.064 211.569 395.571C213.048 397.05 213.076 399.478 211.569 400.984C210.034 402.463 207.635 402.463 206.156 400.985Z" />
              <path d="M235.902 411.588C234.339 410.025 234.339 407.458 235.902 405.895C237.464 404.333 240.031 404.333 241.594 405.895C243.157 407.458 243.157 410.025 241.594 411.588C240.031 413.151 237.464 413.151 235.902 411.588Z" />
              <path d="M266.373 420.183C264.755 418.564 264.783 415.969 266.373 414.379C267.992 412.76 270.587 412.788 272.177 414.379C273.796 415.997 273.768 418.592 272.177 420.183C270.587 421.773 267.964 421.773 266.373 420.183Z" />
              <path d="M297.291 426.824C295.645 425.177 295.645 422.498 297.291 420.852C298.937 419.206 301.616 419.206 303.263 420.852C304.909 422.499 304.909 425.177 303.263 426.824C301.616 428.47 298.937 428.47 297.291 426.824Z" />
              <path d="M334.655 425.345C336.335 427.025 336.335 429.748 334.655 431.428C332.975 433.108 330.251 433.108 328.572 431.428C326.892 429.748 326.892 427.024 328.572 425.345C330.251 423.665 332.975 423.665 334.655 425.345Z" />
              <path d="M366.354 427.8C368.08 429.527 368.08 432.325 366.354 434.051C364.628 435.777 361.83 435.777 360.103 434.051C358.377 432.325 358.377 429.527 360.103 427.801C361.83 426.074 364.628 426.074 366.354 427.8Z" />
              <path d="M398.053 428.302C399.779 430.028 399.779 432.827 398.053 434.553C396.327 436.279 393.529 436.279 391.803 434.553C390.077 432.827 390.077 430.029 391.803 428.302C393.529 426.576 396.327 426.576 398.053 428.302Z" />
              <path d="M423.53 432.963C421.856 431.288 421.856 428.554 423.53 426.88C425.204 425.205 427.939 425.205 429.613 426.88C431.287 428.554 431.287 431.288 429.613 432.963C427.939 434.637 425.204 434.637 423.53 432.963Z" />
              <path d="M461.089 423.308C462.769 424.988 462.769 427.711 461.089 429.391C459.409 431.071 456.686 431.071 455.006 429.391C453.326 427.711 453.326 424.988 455.006 423.308C456.686 421.628 459.409 421.628 461.089 423.308Z" />
              <path d="M486.286 423.782C484.64 422.136 484.64 419.457 486.286 417.811C487.933 416.164 490.612 416.164 492.258 417.811C493.904 419.457 493.904 422.136 492.258 423.782C490.612 425.429 487.933 425.429 486.286 423.782Z" />
              <path d="M517.121 416.164C515.502 414.546 515.53 411.951 517.12 410.36C518.739 408.742 521.334 408.77 522.925 410.36C524.543 411.979 524.515 414.574 522.925 416.164C521.334 417.755 518.739 417.783 517.121 416.164Z" />
              <path d="M547.508 406.538C545.973 405.003 545.973 402.547 547.508 401.013C549.043 399.478 551.499 399.478 553.033 401.013C554.568 402.547 554.568 405.003 553.033 406.538C551.526 408.044 549.015 408.044 547.508 406.538Z" />
              <path d="M582.472 389.711C583.921 391.16 583.921 393.508 582.472 394.957C581.024 396.406 578.675 396.406 577.226 394.957C575.778 393.508 575.778 391.16 577.226 389.711C578.675 388.262 581.024 388.262 582.472 389.711Z" />
              <path d="M611.074 376.568C612.446 377.94 612.446 380.163 611.074 381.535C609.703 382.907 607.479 382.907 606.107 381.535C604.736 380.163 604.736 377.94 606.107 376.568C607.479 375.196 609.702 375.196 611.074 376.568Z" />
              <path d="M634.123 366.299C632.811 364.988 632.839 362.895 634.123 361.611C635.434 360.3 637.527 360.328 638.811 361.611C640.122 362.923 640.095 365.016 638.811 366.299C637.527 367.583 635.407 367.583 634.123 366.299Z" />
              <path d="M661.078 349.278C659.851 348.05 659.879 346.069 661.078 344.869C662.278 343.669 664.287 343.669 665.487 344.869C666.715 346.097 666.687 348.078 665.487 349.278C664.287 350.478 662.306 350.506 661.078 349.278Z" />
              <path d="M686.974 330.526C685.857 329.41 685.857 327.624 686.974 326.508C688.09 325.392 689.876 325.392 690.992 326.508C692.108 327.624 692.108 329.41 690.992 330.526C689.876 331.642 688.09 331.642 686.974 330.526Z" />
              <path d="M711.669 310.184C710.664 309.18 710.664 307.561 711.669 306.557C712.673 305.552 714.292 305.552 715.296 306.557C716.301 307.561 716.301 309.18 715.296 310.184C714.264 311.161 712.646 311.161 711.669 310.184Z" />
              <path d="M734.913 288.335C733.992 287.415 733.992 285.908 734.913 284.987C735.834 284.066 737.341 284.066 738.262 284.987C739.182 285.908 739.182 287.415 738.262 288.335C737.313 289.284 735.834 289.256 734.913 288.335Z" />
              <path d="M5.07774 307.84C4.07318 306.836 4.07317 305.217 5.07773 304.213C6.08228 303.208 7.70074 303.208 8.70529 304.213C9.70985 305.217 9.70985 306.836 8.7053 307.84C7.70074 308.845 6.05439 308.817 5.07774 307.84Z" />
              <path d="M27.6245 330.108C26.5083 328.992 26.5083 327.206 27.6245 326.09C28.7406 324.973 30.5265 324.973 31.6427 326.09C32.7588 327.206 32.7588 328.992 31.6427 330.108C30.5265 331.224 28.7127 331.196 27.6245 330.108Z" />
              <path d="M51.4546 350.98C50.2268 349.752 50.2547 347.771 51.4546 346.571C52.6824 345.343 54.6636 345.371 55.8635 346.571C57.0913 347.799 57.0634 349.78 55.8635 350.98C54.6357 352.207 52.6545 352.18 51.4546 350.98Z" />
              <path d="M76.429 370.373C75.0896 369.034 75.0896 366.857 76.429 365.518C77.7684 364.179 79.9449 364.179 81.2843 365.518C82.6237 366.857 82.6237 369.034 81.2843 370.373C79.9449 371.713 77.7684 371.713 76.429 370.373Z" />
              <path d="M107.737 383.042C109.155 384.46 109.155 386.759 107.737 388.177C106.32 389.595 104.021 389.595 102.603 388.177C101.185 386.759 101.185 384.46 102.603 383.042C104.021 381.624 106.32 381.624 107.737 383.042Z" />
              <path d="M129.754 404.444C128.219 402.91 128.219 400.454 129.754 398.919C131.289 397.385 133.744 397.385 135.279 398.919C136.814 400.454 136.814 402.91 135.279 404.444C133.744 405.979 131.261 405.951 129.754 404.444Z" />
              <path d="M157.826 418.954C156.207 417.336 156.235 414.741 157.826 413.15C159.444 411.532 162.039 411.56 163.63 413.15C165.248 414.769 165.22 417.364 163.63 418.954C162.067 420.573 159.444 420.573 157.826 418.954Z" />
              <path d="M186.734 431.791C185.06 430.117 185.06 427.382 186.734 425.708C188.409 424.033 191.143 424.033 192.817 425.708C194.492 427.382 194.492 430.117 192.817 431.791C191.143 433.465 188.409 433.465 186.734 431.791Z" />
              <path d="M222.731 436.479C224.488 438.235 224.488 441.084 222.731 442.841C220.974 444.598 218.125 444.598 216.369 442.841C214.612 441.084 214.612 438.235 216.369 436.479C218.126 434.722 220.974 434.722 222.731 436.479Z" />
              <path d="M246.589 452.077C244.747 450.235 244.747 447.277 246.589 445.436C248.431 443.594 251.389 443.594 253.23 445.436C255.072 447.277 255.072 450.235 253.23 452.077C251.388 453.919 248.431 453.919 246.589 452.077Z" />
              <path d="M277.339 459.443C275.47 457.574 275.442 454.532 277.339 452.635C279.237 450.737 282.251 450.737 284.148 452.635C286.018 454.504 286.046 457.546 284.148 459.443C282.251 461.341 279.209 461.313 277.339 459.443Z" />
              <path d="M308.508 464.941C306.583 463.015 306.611 459.918 308.508 458.02C310.434 456.095 313.531 456.123 315.429 458.02C317.326 459.918 317.326 463.043 315.429 464.941C313.531 466.838 310.406 466.838 308.508 464.941Z" />
              <path d="M346.933 461.565C348.844 463.476 348.844 466.574 346.933 468.485C345.022 470.396 341.923 470.396 340.012 468.485C338.101 466.574 338.101 463.476 340.012 461.565C341.923 459.654 345.022 459.654 346.933 461.565Z" />
              <path d="M378.632 463.127C380.589 465.084 380.589 468.257 378.632 470.214C376.675 472.172 373.501 472.172 371.544 470.214C369.587 468.257 369.587 465.084 371.544 463.127C373.501 461.17 376.675 461.17 378.632 463.127Z" />
              <path d="M403.271 469.964C401.318 468.01 401.318 464.829 403.271 462.876C405.225 460.923 408.406 460.923 410.359 462.876C412.312 464.829 412.312 468.01 410.359 469.964C408.406 471.917 405.225 471.917 403.271 469.964Z" />
              <path d="M434.943 467.759C433.017 465.834 433.045 462.737 434.943 460.839C436.868 458.914 439.965 458.942 441.863 460.839C443.788 462.765 443.76 465.862 441.863 467.759C439.965 469.657 436.868 469.685 434.943 467.759Z" />
              <path d="M466.391 463.769C464.465 461.844 464.493 458.746 466.391 456.849C468.316 454.923 471.414 454.951 473.311 456.849C475.236 458.774 475.208 461.871 473.311 463.769C471.414 465.666 468.288 465.666 466.391 463.769Z" />
              <path d="M497.588 457.798C495.718 455.928 495.69 452.886 497.588 450.989C499.485 449.092 502.499 449.091 504.396 450.989C506.294 452.886 506.294 455.9 504.396 457.798C502.499 459.695 499.457 459.667 497.588 457.798Z" />
              <path d="M528.478 449.901C526.692 448.115 526.664 445.185 528.478 443.371C530.263 441.585 533.193 441.557 535.007 443.371C536.793 445.157 536.821 448.087 535.007 449.901C533.193 451.714 530.263 451.686 528.478 449.901Z" />
              <path d="M558.754 440.274C556.996 438.516 556.996 435.67 558.754 433.912C560.512 432.154 563.358 432.154 565.116 433.912C566.874 435.67 566.874 438.516 565.116 440.274C563.358 442.032 560.511 442.032 558.754 440.274Z" />
              <path d="M594.555 422.666C596.234 424.346 596.235 427.07 594.555 428.75C592.875 430.429 590.151 430.429 588.472 428.75C586.792 427.07 586.792 424.346 588.472 422.666C590.151 420.987 592.875 420.987 594.555 422.666Z" />
              <path d="M617.492 415.523C615.874 413.904 615.901 411.309 617.492 409.718C619.11 408.1 621.706 408.128 623.296 409.718C624.915 411.337 624.887 413.932 623.296 415.522C621.678 417.141 619.083 417.113 617.492 415.523Z" />
              <path d="M645.675 400.51C644.196 399.031 644.168 396.603 645.675 395.096C647.154 393.617 649.582 393.589 651.089 395.096C652.568 396.575 652.596 399.003 651.089 400.51C649.61 401.989 647.182 402.017 645.675 400.51Z" />
              <path d="M678.044 378.801C679.462 380.218 679.462 382.517 678.044 383.935C676.626 385.353 674.328 385.353 672.91 383.935C671.492 382.517 671.492 380.218 672.91 378.801C674.328 377.383 676.626 377.383 678.044 378.801Z" />
              <path d="M699.168 365.658C697.856 364.346 697.884 362.253 699.168 360.97C700.479 359.658 702.572 359.686 703.856 360.97C705.139 362.253 705.139 364.374 703.856 365.658C702.6 366.969 700.479 366.969 699.168 365.658Z" />
              <path d="M724.31 345.873C723.138 344.702 723.11 342.776 724.31 341.576C725.482 340.404 727.407 340.376 728.607 341.576C729.779 342.748 729.807 344.674 728.607 345.873C727.407 347.073 725.509 347.073 724.31 345.873Z" />
              <path d="M748.223 324.639C747.163 323.578 747.163 321.848 748.223 320.788C749.284 319.727 751.014 319.727 752.074 320.788C753.135 321.848 753.135 323.578 752.074 324.639C751.042 325.727 749.284 325.699 748.223 324.639Z" />
              <path d="M8.20303 360.691C6.94734 359.435 6.94734 357.37 8.20304 356.115C9.45873 354.859 11.5236 354.859 12.7793 356.115C14.035 357.37 14.035 359.435 12.7793 360.691C11.5236 361.947 9.48662 361.975 8.20303 360.691Z" />
              <path d="M32.2286 381.367C30.8613 380 30.8612 377.768 32.2285 376.4C33.5958 375.033 35.8282 375.033 37.1955 376.4C38.5629 377.768 38.5629 380 37.1956 381.367C35.8283 382.735 33.5959 382.735 32.2286 381.367Z" />
              <path d="M57.3424 400.622C55.8635 399.143 55.8356 396.715 57.3424 395.208C58.8213 393.729 61.249 393.701 62.7558 395.208C64.2627 396.715 64.2627 399.115 62.7559 400.622C61.2769 402.1 58.8213 402.1 57.3424 400.622Z" />
              <path d="M89.2927 412.592C90.8955 414.195 90.8955 416.794 89.2927 418.396C87.6899 419.999 85.0914 419.999 83.4886 418.397C81.8859 416.794 81.8859 414.195 83.4887 412.592C85.0914 410.99 87.69 410.99 89.2927 412.592Z" />
              <path d="M110.584 434.721C108.854 432.991 108.854 430.2 110.584 428.47C112.314 426.74 115.104 426.74 116.834 428.47C118.564 430.2 118.564 432.991 116.834 434.721C115.104 436.451 112.314 436.451 110.584 434.721Z" />
              <path d="M138.572 449.426C136.786 447.641 136.758 444.711 138.572 442.897C140.385 441.083 143.287 441.083 145.101 442.897C146.887 444.683 146.915 447.613 145.101 449.426C143.287 451.24 140.385 451.24 138.572 449.426Z" />
              <path d="M167.397 462.513C165.527 460.643 165.499 457.602 167.397 455.704C169.266 453.835 172.308 453.807 174.205 455.704C176.075 457.574 176.103 460.615 174.205 462.513C172.28 464.383 169.266 464.383 167.397 462.513Z" />
              <path d="M203.951 466.866C205.909 468.823 205.909 471.997 203.951 473.954C201.994 475.911 198.821 475.911 196.864 473.954C194.906 471.997 194.906 468.823 196.864 466.866C198.821 464.909 201.994 464.909 203.951 466.866Z" />
              <path d="M234.255 476.354C236.29 478.388 236.29 481.686 234.255 483.721C232.221 485.755 228.923 485.755 226.889 483.72C224.854 481.686 224.854 478.388 226.889 476.354C228.923 474.319 232.221 474.319 234.255 476.354Z" />
              <path d="M257.472 491.757C255.379 489.664 255.379 486.26 257.472 484.167C259.564 482.074 262.969 482.074 265.062 484.167C267.154 486.26 267.154 489.664 265.062 491.757C262.969 493.849 259.564 493.849 257.472 491.757Z" />
              <path d="M288.473 498.035C286.325 495.887 286.325 492.426 288.473 490.278C290.622 488.129 294.082 488.129 296.231 490.278C298.379 492.426 298.379 495.887 296.231 498.035C294.11 500.156 290.594 500.156 288.473 498.035Z" />
              <path d="M319.782 502.5C317.605 500.323 317.605 496.807 319.782 494.631C321.958 492.454 325.474 492.454 327.651 494.631C329.827 496.807 329.827 500.323 327.651 502.5C325.502 504.704 321.958 504.676 319.782 502.5Z" />
              <path d="M359.238 497.31C361.411 499.483 361.411 503.006 359.238 505.179C357.065 507.352 353.542 507.352 351.369 505.179C349.196 503.006 349.196 499.483 351.369 497.31C353.542 495.137 357.065 495.137 359.238 497.31Z" />
              <path d="M390.91 498.175C393.083 500.348 393.083 503.871 390.91 506.044C388.737 508.217 385.214 508.217 383.041 506.044C380.868 503.871 380.868 500.348 383.041 498.175C385.214 496.002 388.737 496.002 390.91 498.175Z" />
              <path d="M422.609 497.282C424.782 499.455 424.782 502.978 422.609 505.151C420.436 507.324 416.913 507.324 414.74 505.151C412.567 502.978 412.567 499.455 414.74 497.282C416.913 495.109 420.436 495.109 422.609 497.282Z" />
              <path d="M446.3 502.5C444.123 500.323 444.123 496.807 446.3 494.631C448.476 492.454 451.992 492.454 454.169 494.631C456.345 496.807 456.345 500.323 454.169 502.5C452.02 504.704 448.476 504.676 446.3 502.5Z" />
              <path d="M477.748 498.007C475.599 495.859 475.599 492.399 477.748 490.25C479.896 488.101 483.356 488.101 485.505 490.25C487.654 492.399 487.654 495.859 485.505 498.007C483.356 500.156 479.896 500.156 477.748 498.007Z" />
              <path d="M508.889 491.757C506.796 489.664 506.796 486.26 508.889 484.167C510.982 482.074 514.386 482.074 516.479 484.167C518.572 486.26 518.572 489.664 516.479 491.757C514.386 493.849 511.01 493.877 508.889 491.757Z" />
              <path d="M547.062 476.354C549.096 478.388 549.096 481.686 547.062 483.721C545.028 485.755 541.729 485.755 539.695 483.72C537.661 481.686 537.661 478.388 539.695 476.354C541.729 474.319 545.028 474.319 547.062 476.354Z" />
              <path d="M577.143 466.866C579.1 468.823 579.1 471.996 577.143 473.954C575.185 475.911 572.012 475.911 570.055 473.954C568.098 471.996 568.098 468.823 570.055 466.866C572.012 464.909 575.185 464.909 577.143 466.866Z" />
              <path d="M599.801 462.513C597.931 460.643 597.903 457.602 599.801 455.704C601.698 453.807 604.712 453.807 606.609 455.704C608.479 457.574 608.507 460.615 606.609 462.513C604.712 464.41 601.67 464.383 599.801 462.513Z" />
              <path d="M628.877 449.399C627.091 447.613 627.063 444.683 628.877 442.869C630.691 441.055 633.593 441.055 635.407 442.869C637.22 444.683 637.22 447.585 635.407 449.399C633.593 451.212 630.691 451.212 628.877 449.399Z" />
              <path d="M657.172 434.721C655.442 432.991 655.442 430.2 657.172 428.47C658.902 426.74 661.692 426.74 663.422 428.47C665.153 430.2 665.152 432.991 663.422 434.721C661.692 436.451 658.902 436.451 657.172 434.721Z" />
              <path d="M690.49 412.621C692.092 414.223 692.092 416.822 690.49 418.425C688.887 420.027 686.288 420.027 684.685 418.425C683.083 416.822 683.083 414.223 684.686 412.621C686.288 411.018 688.887 411.018 690.49 412.621Z" />
              <path d="M711.25 400.622C709.771 399.143 709.743 396.715 711.25 395.208C712.757 393.701 715.157 393.701 716.664 395.208C718.143 396.687 718.171 399.115 716.664 400.622C715.157 402.128 712.729 402.1 711.25 400.622Z" />
              <path d="M736.783 381.34C735.415 379.973 735.415 377.74 736.783 376.373C738.15 375.006 740.382 375.006 741.75 376.373C743.117 377.74 743.117 379.973 741.75 381.34C740.382 382.707 738.15 382.707 736.783 381.34Z" />
              <path d="M12.9468 411.756C11.3841 410.193 11.3841 407.626 12.9468 406.063C14.5094 404.5 17.0766 404.5 18.6393 406.063C20.2019 407.626 20.2019 410.193 18.6393 411.756C17.0766 413.318 14.5373 413.346 12.9468 411.756Z" />
              <path d="M44.2553 424.815C45.9352 426.494 45.9352 429.218 44.2554 430.898C42.5756 432.578 39.852 432.578 38.1722 430.898C36.4924 429.218 36.4924 426.494 38.1722 424.815C39.852 423.135 42.5755 423.135 44.2553 424.815Z" />
              <path d="M64.3743 448.673C62.5884 446.887 62.5605 443.957 64.3743 442.143C66.188 440.33 69.0901 440.33 70.9039 442.143C72.6897 443.929 72.7176 446.859 70.9039 448.673C69.0901 450.487 66.1602 450.459 64.3743 448.673Z" />
              <path d="M91.4135 465.052C89.4881 463.127 89.516 460.03 91.4135 458.132C93.3109 456.235 96.4363 456.235 98.3338 458.132C100.231 460.03 100.231 463.155 98.3338 465.052C96.4363 466.95 93.311 466.95 91.4135 465.052Z" />
              <path d="M126.573 472.643C128.561 474.631 128.561 477.854 126.573 479.842C124.585 481.83 121.362 481.83 119.374 479.842C117.386 477.854 117.386 474.631 119.374 472.643C121.362 470.655 124.585 470.655 126.573 472.643Z" />
              <path d="M147.975 493.264C145.883 491.171 145.883 487.766 147.975 485.674C150.068 483.581 153.473 483.581 155.565 485.674C157.658 487.766 157.658 491.171 155.565 493.264C153.473 495.356 150.068 495.356 147.975 493.264Z" />
              <path d="M177.303 505.011C175.126 502.835 175.126 499.319 177.303 497.142C179.479 494.966 182.995 494.966 185.172 497.142C187.348 499.319 187.348 502.835 185.172 505.011C183.023 507.216 179.479 507.188 177.303 505.011Z" />
              <path d="M215.364 507.077C217.614 509.327 217.614 512.975 215.364 515.225C213.114 517.475 209.466 517.475 207.216 515.225C204.966 512.975 204.966 509.327 207.216 507.077C209.466 504.827 213.114 504.827 215.364 507.077Z" />
              <path d="M246.059 515.391C248.386 517.718 248.386 521.491 246.059 523.819C243.732 526.146 239.959 526.146 237.632 523.819C235.305 521.491 235.305 517.719 237.632 515.391C239.959 513.064 243.732 513.064 246.059 515.391Z" />
              <path d="M268.494 530.739C266.122 528.367 266.122 524.516 268.494 522.144C270.866 519.773 274.716 519.773 277.088 522.144C279.46 524.516 279.46 528.367 277.088 530.739C274.716 533.111 270.866 533.111 268.494 530.739Z" />
              <path d="M299.691 536.041C297.291 533.641 297.291 529.734 299.691 527.334C302.091 524.935 305.997 524.935 308.397 527.334C310.797 529.734 310.797 533.641 308.397 536.041C305.997 538.44 302.091 538.44 299.691 536.041Z" />
              <path d="M339.985 530.795C342.435 533.245 342.435 537.218 339.985 539.669C337.534 542.119 333.561 542.119 331.111 539.669C328.661 537.218 328.661 533.245 331.111 530.795C333.561 528.345 337.534 528.345 339.985 530.795Z" />
              <path d="M362.671 541.594C360.187 539.11 360.187 535.092 362.671 532.608C365.154 530.125 369.172 530.125 371.656 532.609C374.139 535.092 374.139 539.11 371.656 541.594C369.2 544.105 365.182 544.105 362.671 541.594Z" />
              <path d="M403.355 532.832C405.836 535.313 405.836 539.336 403.355 541.817C400.874 544.298 396.851 544.298 394.37 541.817C391.889 539.336 391.889 535.313 394.37 532.832C396.851 530.351 400.874 530.351 403.355 532.832Z" />
              <path d="M426.097 540.282C423.641 537.827 423.641 533.864 426.097 531.409C428.553 528.953 432.515 528.953 434.971 531.409C437.426 533.864 437.426 537.827 434.971 540.282C432.515 542.738 428.553 542.738 426.097 540.282Z" />
              <path d="M457.629 537.157C455.173 534.701 455.173 530.739 457.629 528.283C460.084 525.828 464.047 525.828 466.502 528.283C468.958 530.739 468.958 534.701 466.502 537.157C464.047 539.613 460.084 539.613 457.629 537.157Z" />
              <path d="M489.049 532.302C486.649 529.902 486.649 525.995 489.049 523.595C491.449 521.196 495.355 521.196 497.755 523.595C500.155 525.995 500.155 529.902 497.755 532.301C495.355 534.701 491.449 534.701 489.049 532.302Z" />
              <path d="M520.19 525.716C517.846 523.373 517.874 519.605 520.19 517.289C522.534 514.945 526.301 514.973 528.617 517.289C530.961 519.633 530.933 523.4 528.617 525.717C526.329 528.06 522.534 528.06 520.19 525.716Z" />
              <path d="M550.996 517.513C548.736 515.252 548.764 511.597 550.996 509.365C553.257 507.104 556.912 507.132 559.144 509.365C561.405 511.625 561.377 515.28 559.144 517.513C556.912 519.745 553.257 519.773 550.996 517.513Z" />
              <path d="M581.3 507.69C579.124 505.513 579.124 501.997 581.3 499.821C583.477 497.644 586.993 497.644 589.169 499.821C591.346 501.997 591.346 505.513 589.169 507.69C587.048 509.866 583.477 509.866 581.3 507.69Z" />
              <path d="M611.102 496.305C609.009 494.212 609.009 490.808 611.102 488.715C613.195 486.622 616.599 486.622 618.692 488.715C620.785 490.808 620.785 494.212 618.692 496.305C616.599 498.398 613.195 498.398 611.102 496.305Z" />
              <path d="M647.573 476.019C649.607 478.053 649.607 481.351 647.573 483.385C645.539 485.42 642.24 485.42 640.206 483.386C638.172 481.351 638.172 478.053 640.206 476.019C642.24 473.985 645.539 473.985 647.573 476.019Z" />
              <path d="M675.7 461.844C677.658 463.801 677.657 466.974 675.7 468.931C673.743 470.889 670.57 470.889 668.613 468.931C666.655 466.974 666.655 463.801 668.613 461.844C670.57 459.886 673.743 459.886 675.7 461.844Z" />
              <path d="M696.294 452.914C694.452 451.072 694.452 448.115 696.294 446.273C698.135 444.431 701.093 444.431 702.935 446.273C704.777 448.115 704.777 451.072 702.935 452.914C701.093 454.756 698.135 454.756 696.294 452.914Z" />
              <path d="M729.388 429.252C731.114 430.978 731.114 433.776 729.388 435.502C727.662 437.228 724.864 437.228 723.138 435.502C721.412 433.776 721.411 430.978 723.138 429.252C724.864 427.526 727.662 427.526 729.388 429.252Z" />
              <path d="M754.809 410.862C756.412 412.465 756.412 415.063 754.809 416.666C753.206 418.269 750.607 418.269 749.005 416.666C747.402 415.063 747.402 412.465 749.005 410.862C750.607 409.259 753.206 409.259 754.809 410.862Z" />
              <path d="M0.0829325 435.781C1.83979 437.538 1.83979 440.386 0.0829255 442.143C-1.67394 443.9 -4.52237 443.9 -6.27923 442.143C-8.03609 440.386 -8.03609 437.538 -6.27923 435.781C-4.52236 434.024 -1.67393 434.024 0.0829325 435.781Z" />
              <path d="M19.002 461.174C17.1324 459.304 17.1045 456.262 19.002 454.365C20.8716 452.495 23.9132 452.467 25.8107 454.365C27.6802 456.235 27.7082 459.276 25.8107 461.174C23.9411 463.043 20.8995 463.071 19.002 461.174Z" />
              <path d="M52.4313 471.722C54.4193 473.71 54.4193 476.933 52.4313 478.921C50.4432 480.909 47.22 480.909 45.232 478.921C43.2439 476.933 43.2439 473.71 45.232 471.722C47.22 469.734 50.4432 469.734 52.4313 471.722Z" />
              <path d="M72.2711 495.301C70.1783 493.208 70.1783 489.804 72.2711 487.711C74.3639 485.618 77.7682 485.618 79.8611 487.711C81.9539 489.804 81.9539 493.208 79.8611 495.301C77.7683 497.394 74.3639 497.394 72.2711 495.301Z" />
              <path d="M100.064 510.313C97.8595 508.109 97.8316 504.509 100.064 502.277C102.268 500.072 105.868 500.044 108.1 502.277C110.333 504.509 110.333 508.081 108.1 510.313C105.868 512.545 102.296 512.545 100.064 510.313Z" />
              <path d="M137.009 515.503C139.336 517.83 139.336 521.603 137.009 523.93C134.682 526.257 130.909 526.257 128.582 523.93C126.255 521.603 126.255 517.83 128.582 515.503C130.909 513.176 134.682 513.176 137.009 515.503Z" />
              <path d="M157.798 536.013C155.398 533.613 155.398 529.706 157.798 527.307C160.197 524.907 164.104 524.907 166.504 527.307C168.904 529.706 168.904 533.613 166.504 536.013C164.104 538.412 160.197 538.412 157.798 536.013Z" />
              <path d="M187.516 546.701C185.004 544.189 184.976 540.087 187.516 537.548C190.055 535.009 194.129 535.009 196.668 537.548C199.18 540.059 199.208 544.161 196.668 546.701C194.129 549.24 190.027 549.212 187.516 546.701Z" />
              <path d="M217.848 555.714C215.28 553.146 215.28 549.017 217.848 546.449C220.415 543.882 224.545 543.882 227.112 546.449C229.679 549.017 229.679 553.146 227.112 555.714C224.545 558.281 220.415 558.281 217.848 555.714Z" />
              <path d="M248.486 563.303C245.836 560.652 245.863 556.383 248.486 553.76C251.137 551.109 255.407 551.137 258.03 553.76C260.653 556.383 260.653 560.68 258.03 563.303C255.435 565.954 251.137 565.954 248.486 563.303Z" />
              <path d="M279.572 569.275C276.893 566.596 276.893 562.243 279.572 559.564C282.251 556.885 286.604 556.885 289.282 559.564C291.961 562.243 291.961 566.596 289.282 569.275C286.604 571.954 282.251 571.954 279.572 569.275Z" />
              <path d="M310.908 573.656C308.202 570.949 308.202 566.54 310.908 563.833C313.615 561.127 318.024 561.127 320.731 563.833C323.437 566.54 323.437 570.949 320.731 573.656C318.024 576.362 313.615 576.362 310.908 573.656Z" />
              <path d="M342.412 576.474C339.65 573.712 339.65 569.247 342.412 566.485C345.175 563.722 349.639 563.722 352.402 566.485C355.164 569.247 355.164 573.712 352.402 576.474C349.639 579.237 345.175 579.237 342.412 576.474Z" />
              <path d="M374.084 577.674C371.321 574.911 371.321 570.447 374.084 567.684C376.846 564.922 381.311 564.922 384.073 567.684C386.836 570.447 386.836 574.911 384.073 577.674C381.311 580.436 376.846 580.436 374.084 577.674Z" />
              <path d="M405.783 577.283C403.02 574.521 403.02 570.056 405.783 567.294C408.545 564.531 413.01 564.531 415.772 567.294C418.535 570.056 418.535 574.521 415.772 577.283C413.01 580.046 408.545 580.046 405.783 577.283Z" />
              <path d="M447.388 565.312C450.147 568.071 450.147 572.543 447.388 575.302C444.629 578.061 440.157 578.061 437.398 575.302C434.64 572.543 434.64 568.071 437.398 565.312C440.157 562.554 444.629 562.554 447.388 565.312Z" />
              <path d="M468.958 571.702C466.251 568.996 466.251 564.587 468.958 561.88C471.665 559.173 476.073 559.173 478.78 561.88C481.487 564.587 481.487 568.996 478.78 571.702C476.073 574.409 471.665 574.409 468.958 571.702Z" />
              <path d="M500.322 566.512C497.643 563.834 497.643 559.48 500.322 556.802C503.001 554.123 507.354 554.123 510.033 556.802C512.712 559.48 512.712 563.834 510.033 566.512C507.354 569.191 503.001 569.191 500.322 566.512Z" />
              <path d="M531.463 559.704C528.868 557.109 528.868 552.867 531.463 550.272C534.058 547.677 538.3 547.677 540.895 550.272C543.49 552.867 543.49 557.109 540.895 559.704C538.3 562.299 534.059 562.299 531.463 559.704Z" />
              <path d="M571.394 542.152C573.953 544.71 573.953 548.858 571.394 551.416C568.836 553.974 564.688 553.974 562.13 551.416C559.572 548.858 559.572 544.71 562.13 542.152C564.688 539.593 568.836 539.593 571.394 542.152Z" />
              <path d="M601.419 532.609C603.901 535.09 603.901 539.112 601.419 541.594C598.938 544.075 594.915 544.075 592.434 541.594C589.953 539.113 589.953 535.09 592.434 532.608C594.915 530.127 598.938 530.127 601.419 532.609Z" />
              <path d="M622.292 530.153C619.92 527.781 619.92 523.931 622.292 521.559C624.664 519.187 628.514 519.187 630.886 521.559C633.258 523.93 633.258 527.781 630.886 530.153C628.514 532.525 624.663 532.525 622.292 530.153Z" />
              <path d="M659.683 509.114C661.933 511.364 661.933 515.012 659.683 517.262C657.433 519.512 653.785 519.512 651.535 517.262C649.285 515.012 649.285 511.364 651.535 509.114C653.785 506.863 657.433 506.864 659.683 509.114Z" />
              <path d="M680.053 503.03C677.877 500.854 677.877 497.338 680.053 495.161C682.23 492.985 685.746 492.985 687.922 495.161C690.099 497.338 690.099 500.854 687.922 503.03C685.746 505.207 682.23 505.207 680.053 503.03Z" />
              <path d="M715.408 479.842C717.473 481.907 717.473 485.255 715.408 487.32C713.343 489.385 709.995 489.385 707.93 487.32C705.865 485.255 705.865 481.907 707.93 479.842C709.995 477.777 713.343 477.777 715.408 479.842Z" />
              <path d="M742.029 463.155C743.986 465.112 743.986 468.285 742.029 470.243C740.071 472.2 736.898 472.2 734.941 470.243C732.984 468.285 732.984 465.112 734.941 463.155C736.898 461.198 740.071 461.198 742.029 463.155Z" />
              <path d="M-0.196136 491.534C-2.28896 489.441 -2.28897 486.037 -0.196151 483.944C1.89667 481.851 5.30099 481.851 7.39381 483.944C9.48664 486.037 9.48665 489.441 7.39383 491.534C5.30101 493.627 1.89669 493.627 -0.196136 491.534Z" />
              <path d="M34.0424 501.216C36.2616 503.435 36.2616 507.033 34.0424 509.253C31.8232 511.472 28.2251 511.472 26.0059 509.253C23.7868 507.033 23.7868 503.435 26.006 501.216C28.2251 498.997 31.8232 498.997 34.0424 501.216Z" />
              <path d="M53.0172 525.661C50.6733 523.317 50.7012 519.55 53.0172 517.233C55.3612 514.89 59.1282 514.917 61.4443 517.233C63.7882 519.577 63.7604 523.344 61.4443 525.661C59.1562 528.005 55.3612 528.004 53.0172 525.661Z" />
              <path d="M80.782 540.812C78.3264 538.357 78.3265 534.394 80.782 531.939C83.2376 529.483 87.2 529.483 89.6555 531.939C92.1111 534.394 92.1111 538.357 89.6555 540.812C87.2279 543.24 83.2097 543.24 80.782 540.812Z" />
              <path d="M109.216 554.569C106.649 552.002 106.649 547.872 109.216 545.305C111.784 542.738 115.913 542.738 118.481 545.305C121.048 547.872 121.048 552.002 118.481 554.569C115.913 557.136 111.784 557.136 109.216 554.569Z" />
              <path d="M138.237 567.014C135.558 564.336 135.558 559.983 138.237 557.304C140.916 554.625 145.269 554.625 147.947 557.304C150.626 559.983 150.626 564.336 147.947 567.014C145.269 569.693 140.916 569.693 138.237 567.014Z" />
              <path d="M167.899 577.981C165.136 575.219 165.136 570.754 167.899 567.991C170.661 565.229 175.126 565.229 177.889 567.991C180.651 570.754 180.651 575.219 177.889 577.981C175.098 580.716 170.634 580.716 167.899 577.981Z" />
              <path d="M208.304 577.255C211.14 580.091 211.14 584.688 208.304 587.524C205.469 590.36 200.871 590.36 198.036 587.524C195.2 584.688 195.2 580.091 198.036 577.255C200.871 574.42 205.469 574.42 208.304 577.255Z" />
              <path d="M228.563 595.617C225.661 592.715 225.633 587.999 228.563 585.069C231.493 582.139 236.181 582.139 239.111 585.069C242.013 587.971 242.041 592.687 239.111 595.617C236.181 598.547 231.465 598.519 228.563 595.617Z" />
              <path d="M259.509 602.174C256.551 599.216 256.579 594.444 259.509 591.515C262.439 588.585 267.238 588.585 270.168 591.515C273.126 594.472 273.098 599.244 270.168 602.174C267.238 605.104 262.467 605.132 259.509 602.174Z" />
              <path d="M290.733 607.281C287.748 604.295 287.748 599.44 290.733 596.454C293.719 593.468 298.575 593.468 301.56 596.454C304.546 599.439 304.546 604.295 301.56 607.281C298.575 610.266 293.719 610.266 290.733 607.281Z" />
              <path d="M322.154 610.908C319.14 607.895 319.14 602.983 322.154 599.97C325.167 596.956 330.078 596.956 333.092 599.97C336.106 602.983 336.106 607.895 333.092 610.908C330.079 613.922 325.167 613.922 322.154 610.908Z" />
              <path d="M353.741 613.029C350.672 609.959 350.672 604.992 353.741 601.923C356.811 598.853 361.778 598.853 364.847 601.923C367.917 604.992 367.917 609.959 364.847 613.029C361.75 616.07 356.783 616.07 353.741 613.029Z" />
              <path d="M385.413 613.559C382.343 610.489 382.343 605.522 385.413 602.453C388.482 599.383 393.449 599.383 396.519 602.453C399.588 605.522 399.588 610.489 396.519 613.559C393.421 616.656 388.482 616.628 385.413 613.559Z" />
              <path d="M428.106 601.616C431.127 604.637 431.127 609.534 428.106 612.554C425.086 615.575 420.188 615.575 417.168 612.554C414.147 609.534 414.147 604.637 417.168 601.616C420.188 598.595 425.086 598.595 428.106 601.616Z" />
              <path d="M448.755 610.155C445.742 607.141 445.742 602.23 448.755 599.216C451.769 596.202 456.68 596.202 459.694 599.216C462.707 602.23 462.707 607.141 459.694 610.155C456.68 613.168 451.769 613.168 448.755 610.155Z" />
              <path d="M480.287 606.136C477.301 603.151 477.301 598.295 480.287 595.309C483.273 592.324 488.128 592.324 491.114 595.309C494.1 598.295 494.1 603.151 491.114 606.136C488.1 609.15 483.273 609.122 480.287 606.136Z" />
              <path d="M511.568 600.695C508.61 597.737 508.638 592.965 511.568 590.036C514.526 587.078 519.297 587.106 522.227 590.035C525.185 592.993 525.157 597.765 522.227 600.695C519.297 603.625 514.525 603.653 511.568 600.695Z" />
              <path d="M553.033 583.283C555.9 586.149 555.9 590.796 553.033 593.663C550.167 596.529 545.52 596.529 542.653 593.663C539.787 590.796 539.786 586.149 542.653 583.282C545.519 580.416 550.167 580.416 553.033 583.283Z" />
              <path d="M573.292 585.292C570.473 582.473 570.446 577.869 573.292 575.023C576.11 572.204 580.714 572.177 583.561 575.023C586.379 577.841 586.407 582.445 583.561 585.292C580.742 588.11 576.138 588.138 573.292 585.292Z" />
              <path d="M603.624 575.386C600.861 572.623 600.861 568.159 603.624 565.396C606.386 562.633 610.851 562.634 613.614 565.396C616.376 568.159 616.376 572.623 613.614 575.386C610.851 578.148 606.358 578.12 603.624 575.386Z" />
              <path d="M633.481 564.001C630.83 561.35 630.858 557.081 633.481 554.458C636.132 551.807 640.401 551.835 643.024 554.458C645.675 557.108 645.647 561.378 643.024 564.001C640.373 566.652 636.132 566.652 633.481 564.001Z" />
              <path d="M671.961 542.041C674.519 544.599 674.519 548.747 671.961 551.305C669.403 553.863 665.255 553.863 662.697 551.305C660.139 548.747 660.139 544.599 662.697 542.041C665.255 539.483 669.403 539.482 671.961 542.041Z" />
              <path d="M691.382 537.185C688.927 534.729 688.927 530.767 691.382 528.311C693.838 525.856 697.8 525.856 700.256 528.311C702.712 530.767 702.712 534.729 700.256 537.185C697.8 539.641 693.838 539.641 691.382 537.185Z" />
              <path d="M719.454 521.67C717.166 519.382 717.166 515.643 719.454 513.355C721.742 511.067 725.482 511.067 727.77 513.355C730.058 515.643 730.058 519.382 727.77 521.67C725.482 523.958 721.742 523.958 719.454 521.67Z" />
              <path d="M746.689 504.928C744.512 502.751 744.512 499.235 746.689 497.059C748.865 494.882 752.381 494.882 754.558 497.059C756.734 499.235 756.734 502.751 754.558 504.928C752.409 507.077 748.865 507.104 746.689 504.928Z" />
              <path d="M6.80786 539.557C4.35228 537.101 4.35229 533.139 6.80786 530.683C9.26343 528.228 13.2258 528.228 15.6814 530.683C18.137 533.139 18.137 537.101 15.6814 539.557C13.2258 542.012 9.26343 542.012 6.80786 539.557Z" />
              <path d="M33.7634 556.076C31.1683 553.481 31.1683 549.239 33.7634 546.644C36.3585 544.049 40.5999 544.049 43.195 546.644C45.7901 549.239 45.7901 553.481 43.195 556.076C40.5441 558.671 36.3585 558.671 33.7634 556.076Z" />
              <path d="M61.4443 571.311C58.7377 568.605 58.7377 564.196 61.4444 561.489C64.1511 558.783 68.5599 558.783 71.2666 561.489C73.9733 564.196 73.9734 568.605 71.2666 571.312C68.5599 574.018 64.151 574.018 61.4443 571.311Z" />
              <path d="M100.064 574.995C102.899 577.831 102.899 582.428 100.064 585.264C97.2282 588.099 92.6307 588.099 89.7951 585.264C86.9594 582.428 86.9594 577.831 89.7951 574.995C92.6307 572.159 97.2282 572.159 100.064 574.995Z" />
              <path d="M118.788 597.849C115.886 594.947 115.858 590.231 118.788 587.301C121.717 584.371 126.405 584.371 129.335 587.301C132.237 590.203 132.265 594.919 129.335 597.849C126.405 600.779 121.717 600.779 118.788 597.849Z" />
              <path d="M148.282 609.206C145.269 606.192 145.269 601.281 148.282 598.267C151.296 595.254 156.207 595.254 159.221 598.267C162.234 601.281 162.234 606.192 159.221 609.206C156.179 612.247 151.296 612.219 148.282 609.206Z" />
              <path d="M189.469 607.922C192.567 611.02 192.567 616.042 189.469 619.14C186.371 622.237 181.349 622.237 178.252 619.14C175.154 616.042 175.154 611.02 178.252 607.922C181.349 604.825 186.371 604.825 189.469 607.922Z" />
              <path d="M208.695 627.706C205.514 624.525 205.514 619.391 208.695 616.21C211.876 613.029 217.011 613.029 220.192 616.21C223.373 619.391 223.373 624.525 220.192 627.706C217.011 630.887 211.848 630.859 208.695 627.706Z" />
              <path d="M239.501 634.85C236.292 631.641 236.292 626.451 239.501 623.242C242.71 620.033 247.9 620.033 251.109 623.242C254.318 626.451 254.318 631.641 251.109 634.85C247.928 638.031 242.71 638.059 239.501 634.85Z" />
              <path d="M270.615 640.57C267.378 637.333 267.35 632.059 270.615 628.794C273.851 625.557 279.125 625.53 282.39 628.794C285.627 632.031 285.655 637.305 282.39 640.57C279.153 643.807 273.851 643.807 270.615 640.57Z" />
              <path d="M301.951 644.896C298.658 641.603 298.686 636.273 301.951 633.009C305.216 629.744 310.573 629.744 313.838 633.009C317.131 636.301 317.103 641.631 313.838 644.896C310.573 648.161 305.244 648.188 301.951 644.896Z" />
              <path d="M345.509 635.715C348.838 639.044 348.838 644.441 345.509 647.77C342.181 651.098 336.784 651.098 333.455 647.77C330.126 644.441 330.126 639.044 333.455 635.715C336.784 632.386 342.181 632.386 345.509 635.715Z" />
              <path d="M365.126 649.193C361.806 645.872 361.806 640.459 365.126 637.138C368.447 633.817 373.86 633.817 377.181 637.138C380.502 640.459 380.501 645.872 377.181 649.193C373.832 652.541 368.475 652.541 365.126 649.193Z" />
              <path d="M396.798 649.221C393.477 645.9 393.477 640.487 396.797 637.166C400.118 633.845 405.532 633.845 408.852 637.166C412.173 640.487 412.173 645.9 408.852 649.221C405.532 652.541 400.118 652.541 396.798 649.221Z" />
              <path d="M440.523 635.743C443.852 639.072 443.852 644.469 440.523 647.798C437.195 651.126 431.798 651.126 428.469 647.798C425.14 644.469 425.14 639.072 428.469 635.743C431.798 632.414 437.195 632.414 440.523 635.743Z" />
              <path d="M460.112 644.896C456.819 641.603 456.847 636.273 460.112 633.009C463.405 629.716 468.735 629.744 471.999 633.009C475.264 636.273 475.264 641.631 471.999 644.896C468.735 648.161 463.405 648.188 460.112 644.896Z" />
              <path d="M491.588 640.542C488.351 637.305 488.323 632.031 491.588 628.766C494.825 625.53 500.099 625.502 503.364 628.767C506.601 632.003 506.629 637.277 503.364 640.542C500.099 643.807 494.853 643.807 491.588 640.542Z" />
              <path d="M522.841 634.85C519.632 631.641 519.632 626.451 522.841 623.242C526.05 620.033 531.24 620.033 534.449 623.242C537.658 626.451 537.658 631.641 534.449 634.85C531.268 638.031 526.05 638.059 522.841 634.85Z" />
              <path d="M553.815 627.706C550.634 624.525 550.633 619.391 553.815 616.21C556.996 613.029 562.13 613.029 565.311 616.21C568.492 619.391 568.492 624.525 565.311 627.706C562.13 630.887 556.996 630.887 553.815 627.706Z" />
              <path d="M595.699 607.922C598.797 611.02 598.797 616.042 595.699 619.14C592.601 622.237 587.579 622.237 584.481 619.14C581.384 616.042 581.384 611.02 584.481 607.922C587.579 604.824 592.601 604.824 595.699 607.922Z" />
              <path d="M614.785 609.206C611.772 606.192 611.772 601.281 614.785 598.267C617.799 595.253 622.71 595.253 625.724 598.267C628.738 601.281 628.737 606.192 625.724 609.206C622.71 612.219 617.799 612.219 614.785 609.206Z" />
              <path d="M644.671 597.849C641.769 594.947 641.741 590.231 644.671 587.301C647.573 584.399 652.289 584.371 655.219 587.301C658.149 590.231 658.149 594.919 655.219 597.849C652.289 600.779 647.573 600.751 644.671 597.849Z" />
              <path d="M684.211 574.995C687.047 577.831 687.047 582.428 684.211 585.264C681.375 588.099 676.778 588.099 673.942 585.264C671.107 582.428 671.107 577.831 673.942 574.995C676.778 572.159 681.375 572.159 684.211 574.995Z" />
              <path d="M702.712 571.284C700.005 568.578 700.005 564.169 702.712 561.462C705.418 558.755 709.827 558.755 712.534 561.462C715.241 564.169 715.241 568.578 712.534 571.284C709.827 574.047 705.446 574.019 702.712 571.284Z" />
              <path d="M730.811 556.076C728.216 553.481 728.216 549.239 730.811 546.644C733.406 544.049 737.648 544.049 740.243 546.644C742.838 549.239 742.838 553.481 740.243 556.076C737.648 558.671 733.434 558.699 730.811 556.076Z" />
              <path d="M-12.3903 569.861C-15.0691 567.182 -15.0691 562.829 -12.3903 560.15C-9.71153 557.471 -5.35846 557.471 -2.67965 560.15C-0.00084317 562.829 -0.000870439 567.182 -2.67968 569.861C-5.35849 572.54 -9.71152 572.54 -12.3903 569.861Z" />
              <path d="M24.7783 576.167C27.6139 579.003 27.6139 583.6 24.7783 586.436C21.9426 589.272 17.3452 589.272 14.5095 586.436C11.6739 583.6 11.6739 579.003 14.5095 576.167C17.3452 573.332 21.9426 573.332 24.7783 576.167Z" />
              <path d="M42.1625 601.755C39.2047 598.798 39.2326 594.026 42.1625 591.096C45.1204 588.138 49.892 588.166 52.822 591.096C55.7519 594.026 55.7519 598.826 52.8219 601.755C49.892 604.685 45.1204 604.713 42.1625 601.755Z" />
              <path d="M70.4295 615.847C67.36 612.777 67.36 607.81 70.4295 604.741C73.499 601.672 78.4659 601.671 81.5354 604.741C84.6048 607.81 84.6048 612.777 81.5353 615.847C78.4659 618.916 73.4989 618.916 70.4295 615.847Z" />
              <path d="M99.2825 628.739C96.1014 625.558 96.1014 620.424 99.2825 617.242C102.464 614.061 107.598 614.061 110.779 617.242C113.96 620.424 113.96 625.558 110.779 628.739C107.598 631.92 102.464 631.92 99.2825 628.739Z" />
              <path d="M128.721 640.319C125.485 637.082 125.457 631.808 128.721 628.543C131.958 625.306 137.232 625.278 140.497 628.543C143.734 631.78 143.762 637.054 140.497 640.319C137.232 643.528 131.958 643.556 128.721 640.319Z" />
              <path d="M170.689 638.477C174.049 641.837 174.049 647.284 170.689 650.643C167.33 654.003 161.883 654.003 158.523 650.643C155.164 647.284 155.164 641.837 158.523 638.477C161.883 635.118 167.33 635.118 170.689 638.477Z" />
              <path d="M188.827 659.628C185.395 656.196 185.395 650.615 188.827 647.183C192.259 643.751 197.84 643.751 201.273 647.183C204.705 650.615 204.705 656.196 201.273 659.628C197.868 663.089 192.287 663.089 188.827 659.628Z" />
              <path d="M565.032 661.638C561.6 658.206 561.6 652.625 565.032 649.193C568.464 645.761 574.045 645.761 577.477 649.193C580.91 652.625 580.91 658.206 577.477 661.638C574.073 665.098 568.492 665.098 565.032 661.638Z" />
              <path d="M607.865 640.793C611.225 644.153 611.225 649.6 607.865 652.96C604.506 656.319 599.059 656.319 595.699 652.96C592.339 649.6 592.339 644.153 595.699 640.793C599.059 637.434 604.506 637.434 607.865 640.793Z" />
              <path d="M625.947 643.026C622.654 639.733 622.682 634.403 625.947 631.138C629.24 627.846 634.57 627.874 637.834 631.138C641.127 634.431 641.099 639.761 637.834 643.026C634.597 646.318 629.24 646.318 625.947 643.026Z" />
              <path d="M655.86 631.697C652.679 628.516 652.679 623.381 655.86 620.2C659.041 617.019 664.176 617.019 667.357 620.2C670.538 623.381 670.538 628.516 667.357 631.697C664.176 634.878 659.042 634.878 655.86 631.697Z" />
              <path d="M696.377 607.978C699.475 611.075 699.475 616.098 696.377 619.195C693.28 622.293 688.257 622.293 685.16 619.195C682.062 616.098 682.062 611.076 685.16 607.978C688.257 604.88 693.28 604.88 696.377 607.978Z" />
              <path d="M714.013 605.411C711.027 602.425 711.027 597.57 714.013 594.584C716.999 591.598 721.854 591.598 724.84 594.584C727.825 597.57 727.825 602.425 724.84 605.411C721.826 608.369 716.999 608.397 714.013 605.411Z" />
              <path d="M742.252 590.398C739.378 587.524 739.378 582.892 742.252 580.018C745.126 577.144 749.758 577.144 752.632 580.018C755.506 582.892 755.506 587.524 752.632 590.398C749.758 593.273 745.098 593.245 742.252 590.398Z" />
              <path d="M6.38927 605.662C9.45608 608.729 9.45606 613.701 6.38925 616.768C3.32245 619.835 -1.6498 619.835 -4.71662 616.768C-7.78343 613.701 -7.78345 608.729 -4.71665 605.662C-1.64984 602.595 3.32246 602.595 6.38927 605.662Z" />
              <path d="M22.8528 632.227C19.6438 629.018 19.6438 623.828 22.8528 620.619C26.0617 617.41 31.2519 617.41 34.4609 620.619C37.6699 623.828 37.6699 629.018 34.4609 632.227C31.2519 635.436 26.0618 635.436 22.8528 632.227Z" />
              <path d="M63.1186 634.431C66.4474 637.76 66.4474 643.157 63.1186 646.486C59.7898 649.815 54.3927 649.815 51.0639 646.486C47.7351 643.157 47.7351 637.76 51.0639 634.431C54.3927 631.103 59.7898 631.103 63.1186 634.431Z" />
              <path d="M79.8052 659.545C76.373 656.113 76.373 650.532 79.8052 647.1C83.2374 643.668 88.8183 643.668 92.2505 647.1C95.6828 650.532 95.6828 656.113 92.2506 659.545C88.8462 663.005 83.2654 663.005 79.8052 659.545Z" />
              <path d="M708.599 640.961C711.959 644.32 711.959 649.767 708.599 653.127C705.24 656.487 699.793 656.487 696.433 653.127C693.073 649.767 693.073 644.32 696.433 640.961C699.793 637.601 705.24 637.601 708.599 640.961Z" />
              <path d="M725.314 639.482C722.077 636.245 722.049 630.971 725.314 627.707C728.551 624.47 733.825 624.442 737.09 627.707C740.327 630.944 740.354 636.218 737.09 639.482C733.797 642.719 728.551 642.719 725.314 639.482Z" />
              <path d="M764.994 613.308C768.122 616.436 768.122 621.508 764.994 624.637C761.865 627.765 756.793 627.765 753.665 624.637C750.536 621.509 750.536 616.436 753.665 613.308C756.793 610.179 761.865 610.179 764.994 613.308Z" />
              <path d="M3.59888 662.642C0.16667 659.21 0.16669 653.629 3.59891 650.197C7.03113 646.765 12.612 646.765 16.0442 650.197C19.4764 653.629 19.4764 659.21 16.0442 662.642C12.612 666.075 7.03109 666.075 3.59888 662.642Z" />
              <path d="M351.844 13.0038C351.816 13.0317 351.816 13.0875 351.844 13.1154C351.872 13.1434 351.927 13.1434 351.955 13.1154C351.983 13.0875 351.983 13.0317 351.955 13.0038C351.927 12.9759 351.872 12.9759 351.844 13.0038Z" />
              <path d="M420.265 5.30272C420.237 5.33062 420.237 5.38639 420.265 5.41428C420.293 5.44218 420.349 5.44219 420.377 5.41429C420.404 5.38638 420.404 5.33056 420.377 5.30266C420.349 5.27476 420.293 5.27481 420.265 5.30272Z" />
              <path d="M316.88 9.18138C316.911 9.15056 316.91 9.10056 316.88 9.06973C316.849 9.0389 316.799 9.03889 316.768 9.0697C316.737 9.10052 316.737 9.15049 316.768 9.18133C316.799 9.21216 316.849 9.21219 316.88 9.18138Z" />
              <path d="M281.776 5.21894C281.807 5.18812 281.807 5.13813 281.776 5.1073C281.745 5.07647 281.695 5.07644 281.664 5.10726C281.634 5.13809 281.634 5.18809 281.664 5.21893C281.695 5.24976 281.745 5.24976 281.776 5.21894Z" />
              <path d="M492.9 13.1434C492.93 13.1126 492.93 13.0626 492.9 13.0318C492.869 13.0009 492.819 13.0009 492.788 13.0317C492.757 13.0625 492.757 13.1125 492.788 13.1434C492.819 13.1742 492.869 13.1742 492.9 13.1434Z" />
              <path d="M246.561 1.14462C246.533 1.17252 246.533 1.22836 246.561 1.25628C246.589 1.28419 246.645 1.2842 246.673 1.2563C246.701 1.22839 246.7 1.17257 246.673 1.14466C246.645 1.11674 246.589 1.11671 246.561 1.14462Z" />
              <path d="M527.278 1.14461C527.25 1.17252 527.25 1.22836 527.278 1.25627C527.305 1.28418 527.361 1.28414 527.389 1.25623C527.417 1.22832 527.417 1.17256 527.389 1.14465C527.361 1.11674 527.305 1.1167 527.278 1.14461Z" />
              <path d="M598.768 9.18149C598.799 9.15067 598.799 9.10069 598.768 9.06987C598.737 9.03906 598.688 9.0391 598.657 9.06992C598.626 9.10074 598.626 9.15068 598.657 9.1815C598.687 9.21231 598.737 9.21231 598.768 9.18149Z" />
              <path d="M69.2575 13.1434C69.2883 13.1126 69.2883 13.0626 69.2575 13.0317C69.2267 13.0009 69.1767 13.0009 69.1459 13.0317C69.115 13.0625 69.1151 13.1125 69.1459 13.1433C69.1767 13.1742 69.2267 13.1742 69.2575 13.1434Z" />
              <path d="M704.497 5.07942C704.47 5.10733 704.469 5.16312 704.497 5.19102C704.525 5.21891 704.581 5.2189 704.609 5.19099C704.637 5.16308 704.637 5.1073 704.609 5.0794C704.637 5.05149 704.553 5.02361 704.497 5.07942Z" />
              <path d="M33.9028 9.04173C33.8749 9.06963 33.8749 9.12545 33.9028 9.15335C33.9307 9.18125 33.9865 9.18125 34.0144 9.15335C34.0423 9.12545 34.0423 9.06964 34.0144 9.04174C33.9865 9.01384 33.9307 9.01383 33.9028 9.04173Z" />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  width="750"
                  height="644"
                  fill="transparent"
                  transform="matrix(1 0 0 -1 0 644)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      )}
    </div>
  );
};

LoadingSpinner.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.string,
  dark: PropTypes.bool
};

LoadingSpinner.defaultProps = {
  title: localizedString('loading')
};

export default LoadingSpinner;
