import english from './en.json';
import zh from './zh.json';
import tr from './tr.json';
import kk from './kk.json';
import ru from './ru.json';
import ar from './ar.json';
import hi from './hi.json';

export const LANGUAGES = [
  {
    name: 'en',
    title: 'English',
    dict: english,
    default: true,
    countryCode: 'US'
  },
  {
    name: 'uk',
    title: 'English (UK)',
    dict: english,
    countryCode: 'GB'
  },
  {
    name: 'zh',
    title: 'Chinese (中文)',
    dict: zh,
    countryCode: 'CN'
  },
  {
    name: 'tr',
    title: 'Turkish (Türkce)',
    dict: tr,
    countryCode: 'TR'
  },
  {
    name: 'kk',
    title: 'Kazakh (Қазақ)',
    dict: kk,
    countryCode: 'KZ'
  },
  {
    name: 'ru',
    title: 'Russian (русский)',
    dict: ru,
    countryCode: 'RU'
  },
  {
    name: 'ar',
    title: 'Arabic (العربية)',
    dict: ar,
    countryCode: 'AR'
  },
  {
    name: 'hi',
    title: 'Hindi (हिन्दी)',
    dict: hi,
    countryCode: 'IN'
  }
];

const getLanguageItemPerName = (name = '') => {
  return LANGUAGES.find((langItem) => langItem.name.toUpperCase() === name.toUpperCase());
};

export const getDefaultLanguage = () => {
  const { DEFAULT_LANGUAGE = 'en' } = process.env;
  let defaultLang = getLanguageItemPerName(DEFAULT_LANGUAGE);
  if (!defaultLang) {
    [defaultLang] = LANGUAGES;
  }

  return defaultLang;
};

export const setLanguage = (language) => {
  document.body.dataset.language = language;
};

export const getLanguage = () => {
  const { MULTI_LANGUAGE } = process.env;

  if (MULTI_LANGUAGE !== true) {
    return getDefaultLanguage().name;
  }

  let { language } = document.body.dataset;
  const languageItem = LANGUAGES.find((item) => item.name === language);

  if (!language || language.trim().length === 0 || !languageItem) {
    console.warn('Language', language, 'not found yet. Switching to default language-English');
    language = LANGUAGES[0].name;
  }

  return language;
};

export const localizedString = (name, ...values) => {
  const languageName = getLanguage();
  const languageDict = LANGUAGES.find((item) => item.name === languageName).dict;

  let result = languageDict?.[name];

  if (result === null || typeof result === 'undefined') {
    console.warn('Cannot find localized string for key:', name);
    // alert("Cannot find localized string for key: " + name)

    const defaultLang = getDefaultLanguage();
    result = defaultLang?.dict?.[name] || name;
  }

  if (values && values.length) {
    return values.reduce((finalString, value) => {
      return finalString.replace('{}', value);
    }, result);
  }

  return result;
};
