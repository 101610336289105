import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LANGUAGES } from '@languages';
import classes from './Header.style.module.scss';

const { ENABLE_ONE_DOC_CONDITION = false } = process.env;

const Header = ({ hideLogo, isDark, onSelectLanguage }) => {
  const { APP_LOGO_SF = null, APP_LOGO, MULTI_LANGUAGE, FEEDBACK_LINK } = process.env;
  const logo = localStorage.getItem(`${window.location.host}_app_logo`);
  const finalLogo = logo || `images/logos/${APP_LOGO_SF || APP_LOGO}`;
  const language = document.body.dataset.language || 'en';
  const languageItem = LANGUAGES.find((item) => item.name === language);

  let logoClassName = 'img-container';
  if (ENABLE_ONE_DOC_CONDITION) {
    logoClassName = 'img-container-one-doc';
  }

  return (
    <header className={classNames(classes.warpper, 'clearfix', isDark && classes.isDark)}>
      <div className={classes[logoClassName]} data-testid="header-title">
        {!hideLogo && <img alt="" src={finalLogo} />}
      </div>

      <div className={classes['right-items']}>
        <div>
          {MULTI_LANGUAGE && onSelectLanguage && (
            <div
              data-testid="header-language"
              className={classes['language-chooser']}
              onClick={onSelectLanguage}
            >
              {languageItem ? languageItem.title : 'English'}
              <img alt="" src="images/icons/png/forward-videoid.png" />
            </div>
          )}

          {FEEDBACK_LINK && (
            <a
              className={classes['feedback-link']}
              target="_blank"
              href={FEEDBACK_LINK}
              rel="noreferrer"
            >
              Feedback
            </a>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  /* If its `true` the header position will be fixed */
  isDark: PropTypes.bool,
  hideLogo: PropTypes.bool,
  onSelectLanguage: PropTypes.func
};

Header.defaultProps = {
  hideLogo: false
};

export default Header;
