import React from 'react';
import PropTypes from 'prop-types';
import { envPatch } from '@languages/env_patch';
import { localizedString } from '@languages';
import Select from '../../Form/Select';

import classes from './IdSelection.style.module.scss';

const handleChange = (obj, onSelect) => {
  const { value } = obj;
  const parts = value.split('=');
  onSelect({
    idType: parts[0],
    backOfCard: parseInt(parts[1], 10) === 1
  });
};

const IdSelection = ({ onSelect, country, idType }) => {
  let { ID_TYPES } = process.env;
  const { APP_NAME } = process.env;
  if (!ID_TYPES && envPatch[APP_NAME]) {
    ID_TYPES = envPatch[APP_NAME].ID_TYPES;
  }

  let idTypes = [];
  let index;
  let value;
  if (ID_TYPES[country]) {
    idTypes = ID_TYPES[country].map(({ label, id: value, backOfCard }) => {
      return {
        value: `${value}=${backOfCard ? 1 : 0}`,
        label
      };
    });
    index = ID_TYPES[country].findIndex(({ id: value }) => {
      return value.includes(idType);
    });

    value = index > -1 ? idTypes[index] : null;
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.heading}>{localizedString('chooseYourIDType')}</div>
      {ID_TYPES[country] && (
        <Select
          id="idType"
          placeholder={localizedString('idDocumentType')}
          options={idTypes}
          value={value}
          hasError={!value ? localizedString('selectDocumentType') : null}
          onChange={(val) => handleChange(val, onSelect)}
        />
      )}
    </div>
  );
};

IdSelection.propTypes = {
  onSelect: PropTypes.func,
  country: PropTypes.string,
  idType: PropTypes.string
};

IdSelection.defaultProps = {
  onSelect: () => null,
  country: process.env.COUNTRY
};

export default IdSelection;
