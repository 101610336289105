import React from 'react';
import { localizedString } from '@languages';
import classes from './Overview.style.module.scss';

const Overview = () => {
  return (
    <div className={classes.wrapper}>
      <h2>{localizedString('letsVerifyYourID')}</h2>
      {localizedString('weNeedCheckItsReallyYou')}
      <br />
      <br />
      {localizedString('youWillNeedTo')}
      <ol>
        <li>
          <strong>{localizedString('takePhotoOfIDDocument')}</strong>
          <br />
          {localizedString('validDriverLicenceAndPassportWillWorkBest')}
        </li>
        <li>
          <strong>{localizedString('confirmYourPersonalDetails')}</strong>
          <br />
          {localizedString('soWeHaveTheRightAccountInformation')}
        </li>
        <li>
          <strong>{localizedString('takeAQuickSelfieVideo')}</strong>
          <br />
          {localizedString('justAFewSecondsHelpUsMatchYouToYourPhotoID')}
        </li>
      </ol>
    </div>
  );
};

export default Overview;
