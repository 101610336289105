import { localizedString } from '@languages';

function getEnvironmentPatch() {
  return {
    AusDemo: {
      ID_TYPES: {
        NZ: [
          {
            label: localizedString('ID_TYPE_NZ_LICENCE'),
            icon: 'licence.svg',
            id: 'NZL_DRIVERLICENCE',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_NZ_PASSPORT'),
            icon: 'passport.svg',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        AU: [
          {
            label: localizedString('ID_TYPE_AU_LICENCE'),
            icon: 'au-licence.png',
            id: 'AUS_AUTO_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_AU_PASSPORT'),
            icon: 'au-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_AU_PROOF_OF_AGE'),
            icon: 'proof_of_age.png',
            id: 'PROOF_OF_AGE',
            expired: false,
            backOfCard: false
          }
        ],
        CN: [
          {
            label: localizedString('ID_TYPE_CN_IDCARD'),
            icon: 'cn-idcard.png',
            id: 'CHN_IDCARD',
            expired: false,
            backOfCard: false
          }
        ],
        HK: [
          {
            label: localizedString('ID_TYPE_HK_IDCARD'),
            icon: 'hk-idcard.png',
            id: 'HKG_IDCARD',
            expired: false,
            backOfCard: false
          }
        ],
        MY: [
          {
            label: localizedString('ID_TYPE_MY_LICENCE'),
            icon: 'my-licence.png',
            id: 'MYS_DLCARD',
            expired: false,
            backOfCard: false
          }
        ],
        SG: [
          {
            label: localizedString('ID_TYPE_SG_IDCARD'),
            icon: 'sg-idcard.png',
            id: 'SGP_IDCARD',
            expired: false,
            backOfCard: false
          }
        ],
        UK: [
          {
            label: localizedString('ID_TYPE_UK_LICENCE'),
            icon: 'uk-licence.png',
            id: 'EUR_UK_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_UK_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_UK_RESIDENCE_PERMIT'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          }
        ],
        US: [
          {
            label: localizedString('ID_TYPE_US_LICENCE'),
            icon: 'us-licence.png',
            id: 'MYS_DLCARD',
            expired: false,
            backOfCard: false
          }
        ],
        CA: [
          {
            label: localizedString('ID_TYPE_CAN_AB_DRIVERLICENCE'),
            icon: 'ca-licence.png',
            id: 'CAN_AB_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_CAN_BC_DRIVERLICENCE'),
            icon: 'ca-licence.png',
            id: 'CAN_BC_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_CAN_ON_DRIVERLICENCE'),
            icon: 'ca-licence.png',
            id: 'CAN_ON_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          }
        ],
        AT: [
          {
            label: localizedString('ID_TYPE_AUSTRIAN_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_AUSTRIAN_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        KZ: [
          {
            label: localizedString('ID_TYPE_KAZAKH_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_KAZAKH_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        TR: [
          {
            label: localizedString('ID_TYPE_TURKEY_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'TUR_IDENTITYCARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_TURKEY_DRIVER_LICENCE'),
            icon: 'international-idcard.png',
            id: 'EUR_TUR_DRIVERLICENCE',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_TURKEY_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        OTHER: [
          {
            label: localizedString('ID_TYPE_OTHER_IDCARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_OTHER_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ]
      },
      COUNTRIES: [
        {
          name: localizedString('COUNTRY_AU'),
          iso: 'AU',
          flag: 'australia.svg'
        },
        {
          name: localizedString('COUNTRY_NZ'),
          iso: 'NZ',
          flag: 'new-zealand.svg'
        },
        {
          name: localizedString('COUNTRY_CN'),
          iso: 'CN',
          flag: 'china.svg'
        },
        {
          name: localizedString('COUNTRY_HK'),
          iso: 'HK',
          flag: 'hong-kong.svg'
        },
        {
          name: localizedString('COUNTRY_MY'),
          iso: 'MY',
          flag: 'malaysia.svg'
        },
        {
          name: localizedString('COUNTRY_SG'),
          iso: 'SG',
          flag: 'singapore.svg'
        },
        {
          name: localizedString('COUNTRY_UK'),
          iso: 'UK',
          flag: 'united-kingdom.svg'
        },
        {
          name: localizedString('COUNTRY_US'),
          iso: 'US',
          flag: 'united-states-of-america.svg'
        },
        {
          name: localizedString('COUNTRY_CA'),
          iso: 'CA',
          flag: 'canada.svg'
        },
        {
          name: localizedString('COUNTRY_AT'),
          iso: 'AT',
          flag: 'austria.svg'
        },
        {
          name: localizedString('COUNTRY_KZ'),
          iso: 'KZ',
          flag: 'kazakhstan.svg'
        },
        {
          name: localizedString('COUNTRY_TR'),
          iso: 'TR',
          flag: 'turkey.svg'
        },
        {
          name: localizedString('COUNTRY_OTHER'),
          iso: 'OTHER',
          flag: 'world.svg'
        }
      ],
      // AUVOI Configurations
      AUVOI_ID_TYPES: [
        {
          label: localizedString('AUVOI_ID_DRIVER_LICENCE'),
          id: 'AUS_AUTO_DRIVERLICENCE',
          ocr: true,
          backOfCard: false
        },
        {
          label: localizedString('AUVOI_ID_PASSPORT'),
          id: 'PASSPORT',
          ocr: true,
          backOfCard: false
        },
        {
          label: localizedString('AUVOI_ID_MEDICARE_CARD'),
          id: 'AUS_MEDICARE',
          ocr: false,
          backOfCard: false
        },
        {
          label: localizedString('AUVOI_ID_AUS_BIRTH_CERT'),
          id: 'AUS_AUTO_BIRTH',
          ocr: false,
          backOfCard: false
        }
      ]
    },
    Birlesikodeme: {
      ID_TYPES: {
        NZ: [
          {
            label: localizedString('ID_TYPE_NZ_LICENCE'),
            icon: 'licence.svg',
            id: 'NZL_DRIVERLICENCE',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_NZ_PASSPORT'),
            icon: 'passport.svg',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        AU: [
          {
            label: localizedString('ID_TYPE_AU_LICENCE'),
            icon: 'au-licence.png',
            id: 'AUS_AUTO_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_AU_PASSPORT'),
            icon: 'au-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        CN: [
          {
            label: localizedString('ID_TYPE_CN_IDCARD'),
            icon: 'cn-idcard.png',
            id: 'CHN_IDCARD',
            expired: false,
            backOfCard: false
          }
        ],
        HK: [
          {
            label: localizedString('ID_TYPE_HK_IDCARD'),
            icon: 'hk-idcard.png',
            id: 'HKG_IDCARD',
            expired: false,
            backOfCard: false
          }
        ],
        MY: [
          {
            label: localizedString('ID_TYPE_MY_LICENCE'),
            icon: 'my-licence.png',
            id: 'MYS_DLCARD',
            expired: false,
            backOfCard: false
          }
        ],
        SG: [
          {
            label: localizedString('ID_TYPE_SG_IDCARD'),
            icon: 'sg-idcard.png',
            id: 'SGP_IDCARD',
            expired: false,
            backOfCard: false
          }
        ],
        UK: [
          {
            label: localizedString('ID_TYPE_UK_LICENCE'),
            icon: 'uk-licence.png',
            id: 'EUR_UK_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_UK_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_UK_RESIDENCE_PERMIT'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          }
        ],
        US: [
          {
            label: localizedString('ID_TYPE_US_LICENCE'),
            icon: 'us-licence.png',
            id: 'MYS_DLCARD',
            expired: false,
            backOfCard: false
          }
        ],
        CA: [
          {
            label: localizedString('ID_TYPE_CAN_AB_DRIVERLICENCE'),
            icon: 'ca-licence.png',
            id: 'CAN_AB_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_CAN_BC_DRIVERLICENCE'),
            icon: 'ca-licence.png',
            id: 'CAN_BC_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_CAN_ON_DRIVERLICENCE'),
            icon: 'ca-licence.png',
            id: 'CAN_ON_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          }
        ],
        AT: [
          {
            label: localizedString('ID_TYPE_AUSTRIAN_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_AUSTRIAN_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        KZ: [
          {
            label: localizedString('ID_TYPE_KAZAKH_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_KAZAKH_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        TR: [
          {
            label: localizedString('ID_TYPE_TURKEY_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'TUR_IDENTITYCARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_TURKEY_DRIVER_LICENCE'),
            icon: 'international-idcard.png',
            id: 'EUR_TUR_DRIVERLICENCE',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_TURKEY_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        OTHER: [
          {
            label: localizedString('ID_TYPE_OTHER_IDCARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_OTHER_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ]
      },
      COUNTRIES: [
        {
          name: localizedString('COUNTRY_AU'),
          iso: 'AU',
          flag: 'australia.svg'
        },
        {
          name: localizedString('COUNTRY_NZ'),
          iso: 'NZ',
          flag: 'new-zealand.svg'
        },
        {
          name: localizedString('COUNTRY_CN'),
          iso: 'CN',
          flag: 'china.svg'
        },
        {
          name: localizedString('COUNTRY_HK'),
          iso: 'HK',
          flag: 'hong-kong.svg'
        },
        {
          name: localizedString('COUNTRY_MY'),
          iso: 'MY',
          flag: 'malaysia.svg'
        },
        {
          name: localizedString('COUNTRY_SG'),
          iso: 'SG',
          flag: 'singapore.svg'
        },
        {
          name: localizedString('COUNTRY_UK'),
          iso: 'UK',
          flag: 'united-kingdom.svg'
        },
        {
          name: localizedString('COUNTRY_US'),
          iso: 'US',
          flag: 'united-states-of-america.svg'
        },
        {
          name: localizedString('COUNTRY_CA'),
          iso: 'CA',
          flag: 'canada.svg'
        },
        {
          name: localizedString('COUNTRY_AT'),
          iso: 'AT',
          flag: 'austria.svg'
        },
        {
          name: localizedString('COUNTRY_KZ'),
          iso: 'KZ',
          flag: 'kazakhstan.svg'
        },
        {
          name: localizedString('COUNTRY_TR'),
          iso: 'TR',
          flag: 'turkey.svg'
        },
        {
          name: localizedString('COUNTRY_OTHER'),
          iso: 'OTHER',
          flag: 'world.svg'
        }
      ],
      // AUVOI Configurations
      AUVOI_ID_TYPES: [
        {
          label: localizedString('AUVOI_ID_DRIVER_LICENCE'),
          id: 'AUS_AUTO_DRIVERLICENCE',
          ocr: true,
          backOfCard: false
        },
        {
          label: localizedString('AUVOI_ID_PASSPORT'),
          id: 'PASSPORT',
          ocr: true,
          backOfCard: false
        },
        {
          label: localizedString('AUVOI_ID_MEDICARE_CARD'),
          id: 'AUS_MEDICARE',
          ocr: false,
          backOfCard: false
        },
        {
          label: localizedString('AUVOI_ID_AUS_BIRTH_CERT'),
          id: 'AUS_AUTO_BIRTH',
          ocr: false,
          backOfCard: false
        }
      ]
    },
    Oyak: {
      ID_TYPES: {
        NZ: [
          {
            label: localizedString('ID_TYPE_NZ_LICENCE'),
            icon: 'licence.svg',
            id: 'NZL_DRIVERLICENCE',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_NZ_PASSPORT'),
            icon: 'passport.svg',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        AU: [
          {
            label: localizedString('ID_TYPE_AU_LICENCE'),
            icon: 'au-licence.png',
            id: 'AUS_AUTO_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_AU_PASSPORT'),
            icon: 'au-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        CN: [
          {
            label: localizedString('ID_TYPE_CN_IDCARD'),
            icon: 'cn-idcard.png',
            id: 'CHN_IDCARD',
            expired: false,
            backOfCard: false
          }
        ],
        HK: [
          {
            label: localizedString('ID_TYPE_HK_IDCARD'),
            icon: 'hk-idcard.png',
            id: 'HKG_IDCARD',
            expired: false,
            backOfCard: false
          }
        ],
        MY: [
          {
            label: localizedString('ID_TYPE_MY_LICENCE'),
            icon: 'my-licence.png',
            id: 'MYS_DLCARD',
            expired: false,
            backOfCard: false
          }
        ],
        SG: [
          {
            label: localizedString('ID_TYPE_SG_IDCARD'),
            icon: 'sg-idcard.png',
            id: 'SGP_IDCARD',
            expired: false,
            backOfCard: false
          }
        ],
        UK: [
          {
            label: localizedString('ID_TYPE_UK_LICENCE'),
            icon: 'uk-licence.png',
            id: 'EUR_UK_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_UK_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_UK_RESIDENCE_PERMIT'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          }
        ],
        US: [
          {
            label: localizedString('ID_TYPE_US_LICENCE'),
            icon: 'us-licence.png',
            id: 'MYS_DLCARD',
            expired: false,
            backOfCard: false
          }
        ],
        CA: [
          {
            label: localizedString('ID_TYPE_CAN_AB_DRIVERLICENCE'),
            icon: 'ca-licence.png',
            id: 'CAN_AB_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_CAN_BC_DRIVERLICENCE'),
            icon: 'ca-licence.png',
            id: 'CAN_BC_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_CAN_ON_DRIVERLICENCE'),
            icon: 'ca-licence.png',
            id: 'CAN_ON_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          }
        ],
        AT: [
          {
            label: localizedString('ID_TYPE_AUSTRIAN_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_AUSTRIAN_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        KZ: [
          {
            label: localizedString('ID_TYPE_KAZAKH_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_KAZAKH_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        TR: [
          {
            label: localizedString('ID_TYPE_TURKEY_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'TUR_IDENTITYCARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_TURKEY_DRIVER_LICENCE'),
            icon: 'international-idcard.png',
            id: 'EUR_TUR_DRIVERLICENCE',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_TURKEY_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        OTHER: [
          {
            label: localizedString('ID_TYPE_OTHER_IDCARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_OTHER_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ]
      }
    },
    DijitalImza: {
      ID_TYPES: {
        NZ: [
          {
            label: localizedString('ID_TYPE_NZ_LICENCE'),
            icon: 'licence.svg',
            id: 'NZL_DRIVERLICENCE',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_NZ_PASSPORT'),
            icon: 'passport.svg',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        AU: [
          {
            label: localizedString('ID_TYPE_AU_LICENCE'),
            icon: 'au-licence.png',
            id: 'AUS_AUTO_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_AU_PASSPORT'),
            icon: 'au-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        CN: [
          {
            label: localizedString('ID_TYPE_CN_IDCARD'),
            icon: 'cn-idcard.png',
            id: 'CHN_IDCARD',
            expired: false,
            backOfCard: false
          }
        ],
        HK: [
          {
            label: localizedString('ID_TYPE_HK_IDCARD'),
            icon: 'hk-idcard.png',
            id: 'HKG_IDCARD',
            expired: false,
            backOfCard: false
          }
        ],
        MY: [
          {
            label: localizedString('ID_TYPE_MY_LICENCE'),
            icon: 'my-licence.png',
            id: 'MYS_DLCARD',
            expired: false,
            backOfCard: false
          }
        ],
        SG: [
          {
            label: localizedString('ID_TYPE_SG_IDCARD'),
            icon: 'sg-idcard.png',
            id: 'SGP_IDCARD',
            expired: false,
            backOfCard: false
          }
        ],
        UK: [
          {
            label: localizedString('ID_TYPE_UK_LICENCE'),
            icon: 'uk-licence.png',
            id: 'EUR_UK_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_UK_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_UK_RESIDENCE_PERMIT'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          }
        ],
        US: [
          {
            label: localizedString('ID_TYPE_US_LICENCE'),
            icon: 'us-licence.png',
            id: 'MYS_DLCARD',
            expired: false,
            backOfCard: false
          }
        ],
        CA: [
          {
            label: localizedString('ID_TYPE_CAN_AB_DRIVERLICENCE'),
            icon: 'ca-licence.png',
            id: 'CAN_AB_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_CAN_BC_DRIVERLICENCE'),
            icon: 'ca-licence.png',
            id: 'CAN_BC_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_CAN_ON_DRIVERLICENCE'),
            icon: 'ca-licence.png',
            id: 'CAN_ON_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          }
        ],
        AT: [
          {
            label: localizedString('ID_TYPE_AUSTRIAN_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_AUSTRIAN_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        KZ: [
          {
            label: localizedString('ID_TYPE_KAZAKH_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_KAZAKH_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        TR: [
          {
            label: localizedString('ID_TYPE_TURKEY_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'TUR_IDENTITYCARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_TURKEY_DRIVER_LICENCE'),
            icon: 'international-idcard.png',
            id: 'EUR_TUR_DRIVERLICENCE',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_TURKEY_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        OTHER: [
          {
            label: localizedString('ID_TYPE_OTHER_IDCARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_OTHER_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ]
      }
    },
    Aurakz: {
      ID_TYPES: {
        KZ: [
          {
            label: localizedString('ID_TYPE_KAZAKH_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_KAZAKH_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ]
      },
      COUNTRIES: [
        {
          name: localizedString('COUNTRY_KZ'),
          iso: 'KZ',
          flag: 'kazakhstan.svg'
        }
      ],
      // AUVOI Configurations
      AUVOI_ID_TYPES: [
        {
          label: localizedString('AUVOI_ID_DRIVER_LICENCE'),
          id: 'AUS_AUTO_DRIVERLICENCE',
          ocr: true,
          backOfCard: false
        },
        {
          label: localizedString('AUVOI_ID_PASSPORT'),
          id: 'PASSPORT',
          ocr: true,
          backOfCard: false
        },
        {
          label: localizedString('AUVOI_ID_MEDICARE_CARD'),
          id: 'AUS_MEDICARE',
          ocr: false,
          backOfCard: false
        },
        {
          label: localizedString('AUVOI_ID_AUS_BIRTH_CERT'),
          id: 'AUS_AUTO_BIRTH',
          ocr: false,
          backOfCard: false
        }
      ]
    },
    IAM: {
      ID_TYPES: {
        NZ: [
          {
            label: localizedString('ID_TYPE_NZ_LICENCE'),
            icon: 'licence.svg',
            id: 'NZL_DRIVERLICENCE',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_NZ_PASSPORT'),
            icon: 'passport.svg',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        AU: [
          {
            label: localizedString('ID_TYPE_AU_LICENCE'),
            icon: 'au-licence.png',
            id: 'AUS_AUTO_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_AU_PASSPORT'),
            icon: 'au-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_AU_PROOF_OF_AGE'),
            icon: 'proof_of_age.png',
            id: 'PROOF_OF_AGE',
            expired: false,
            backOfCard: false
          }
        ],
        CN: [
          {
            label: localizedString('ID_TYPE_CN_IDCARD'),
            icon: 'cn-idcard.png',
            id: 'CHN_IDCARD',
            expired: false,
            backOfCard: false
          }
        ],
        HK: [
          {
            label: localizedString('ID_TYPE_HK_IDCARD'),
            icon: 'hk-idcard.png',
            id: 'HKG_IDCARD',
            expired: false,
            backOfCard: false
          }
        ],
        MY: [
          {
            label: localizedString('ID_TYPE_MY_LICENCE'),
            icon: 'my-licence.png',
            id: 'MYS_DLCARD',
            expired: false,
            backOfCard: false
          }
        ],
        SG: [
          {
            label: localizedString('ID_TYPE_SG_IDCARD'),
            icon: 'sg-idcard.png',
            id: 'SGP_IDCARD',
            expired: false,
            backOfCard: false
          }
        ],
        UK: [
          {
            label: localizedString('ID_TYPE_UK_LICENCE'),
            icon: 'uk-licence.png',
            id: 'EUR_UK_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_UK_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_UK_RESIDENCE_PERMIT'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          }
        ],
        US: [
          {
            label: localizedString('ID_TYPE_US_LICENCE'),
            icon: 'us-licence.png',
            id: 'MYS_DLCARD',
            expired: false,
            backOfCard: false
          }
        ],
        CA: [
          {
            label: localizedString('ID_TYPE_CAN_AB_DRIVERLICENCE'),
            icon: 'ca-licence.png',
            id: 'CAN_AB_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_CAN_BC_DRIVERLICENCE'),
            icon: 'ca-licence.png',
            id: 'CAN_BC_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_CAN_ON_DRIVERLICENCE'),
            icon: 'ca-licence.png',
            id: 'CAN_ON_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          }
        ],
        AT: [
          {
            label: localizedString('ID_TYPE_AUSTRIAN_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_AUSTRIAN_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        KZ: [
          {
            label: localizedString('ID_TYPE_KAZAKH_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_KAZAKH_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        TR: [
          {
            label: localizedString('ID_TYPE_TURKEY_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_TURKEY_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        OTHER: [
          {
            label: localizedString('ID_TYPE_OTHER_IDCARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_OTHER_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ]
      },
      COUNTRIES: [
        {
          name: localizedString('COUNTRY_AU'),
          iso: 'AU',
          flag: 'australia.svg'
        },
        {
          name: localizedString('COUNTRY_NZ'),
          iso: 'NZ',
          flag: 'new-zealand.svg'
        },
        {
          name: localizedString('COUNTRY_CN'),
          iso: 'CN',
          flag: 'china.svg'
        },
        {
          name: localizedString('COUNTRY_HK'),
          iso: 'HK',
          flag: 'hong-kong.svg'
        },
        {
          name: localizedString('COUNTRY_MY'),
          iso: 'MY',
          flag: 'malaysia.svg'
        },
        {
          name: localizedString('COUNTRY_SG'),
          iso: 'SG',
          flag: 'singapore.svg'
        },
        {
          name: localizedString('COUNTRY_UK'),
          iso: 'UK',
          flag: 'united-kingdom.svg'
        },
        {
          name: localizedString('COUNTRY_US'),
          iso: 'US',
          flag: 'united-states-of-america.svg'
        },
        {
          name: localizedString('COUNTRY_CA'),
          iso: 'CA',
          flag: 'canada.svg'
        },
        {
          name: localizedString('COUNTRY_AT'),
          iso: 'AT',
          flag: 'austria.svg'
        },
        {
          name: localizedString('COUNTRY_KZ'),
          iso: 'KZ',
          flag: 'kazakhstan.svg'
        },
        {
          name: localizedString('COUNTRY_TR'),
          iso: 'TR',
          flag: 'turkey.svg'
        },
        {
          name: localizedString('COUNTRY_OTHER'),
          iso: 'OTHER',
          flag: 'world.svg'
        }
      ],
      // AUVOI Configurations
      AUVOI_ID_TYPES: [
        {
          label: localizedString('AUVOI_ID_DRIVER_LICENCE'),
          id: 'AUS_AUTO_DRIVERLICENCE',
          ocr: true,
          backOfCard: false
        },
        {
          label: localizedString('AUVOI_ID_PASSPORT'),
          id: 'PASSPORT',
          ocr: true,
          backOfCard: false
        },
        {
          label: localizedString('AUVOI_ID_MEDICARE_CARD'),
          id: 'AUS_MEDICARE',
          ocr: false,
          backOfCard: false
        },
        {
          label: localizedString('AUVOI_ID_AUS_BIRTH_CERT'),
          id: 'AUS_AUTO_BIRTH',
          ocr: false,
          backOfCard: false
        }
      ]
    },
    Singpass: {
      ID_TYPES: {
        NZ: [
          {
            label: localizedString('ID_TYPE_NZ_LICENCE'),
            icon: 'licence.svg',
            id: 'NZL_DRIVERLICENCE',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_NZ_PASSPORT'),
            icon: 'passport.svg',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        AU: [
          {
            label: localizedString('ID_TYPE_AU_LICENCE'),
            icon: 'au-licence.png',
            id: 'AUS_AUTO_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_AU_PASSPORT'),
            icon: 'au-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_AU_PROOF_OF_AGE'),
            icon: 'proof_of_age.png',
            id: 'PROOF_OF_AGE',
            expired: false,
            backOfCard: false
          }
        ],
        CN: [
          {
            label: localizedString('ID_TYPE_CN_IDCARD'),
            icon: 'cn-idcard.png',
            id: 'CHN_IDCARD',
            expired: false,
            backOfCard: false
          }
        ],
        HK: [
          {
            label: localizedString('ID_TYPE_HK_IDCARD'),
            icon: 'hk-idcard.png',
            id: 'HKG_IDCARD',
            expired: false,
            backOfCard: false
          }
        ],
        MY: [
          {
            label: localizedString('ID_TYPE_MY_LICENCE'),
            icon: 'my-licence.png',
            id: 'MYS_DLCARD',
            expired: false,
            backOfCard: false
          }
        ],
        SG: [
          {
            label: localizedString('ID_TYPE_SG_IDCARD'),
            icon: 'sg-idcard.png',
            id: 'SGP_IDCARD',
            expired: false,
            backOfCard: false
          }
        ],
        UK: [
          {
            label: localizedString('ID_TYPE_UK_LICENCE'),
            icon: 'uk-licence.png',
            id: 'EUR_UK_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_UK_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_UK_RESIDENCE_PERMIT'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          }
        ],
        US: [
          {
            label: localizedString('ID_TYPE_US_LICENCE'),
            icon: 'us-licence.png',
            id: 'MYS_DLCARD',
            expired: false,
            backOfCard: false
          }
        ],
        CA: [
          {
            label: localizedString('ID_TYPE_CAN_AB_DRIVERLICENCE'),
            icon: 'ca-licence.png',
            id: 'CAN_AB_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_CAN_BC_DRIVERLICENCE'),
            icon: 'ca-licence.png',
            id: 'CAN_BC_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_CAN_ON_DRIVERLICENCE'),
            icon: 'ca-licence.png',
            id: 'CAN_ON_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          }
        ],
        AT: [
          {
            label: localizedString('ID_TYPE_AUSTRIAN_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_AUSTRIAN_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        KZ: [
          {
            label: localizedString('ID_TYPE_KAZAKH_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_KAZAKH_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        TR: [
          {
            label: localizedString('ID_TYPE_TURKEY_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_TURKEY_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        OTHER: [
          {
            label: localizedString('ID_TYPE_OTHER_IDCARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_OTHER_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ]
      },
      COUNTRIES: [
        {
          name: localizedString('COUNTRY_AU'),
          iso: 'AU',
          flag: 'australia.svg'
        },
        {
          name: localizedString('COUNTRY_NZ'),
          iso: 'NZ',
          flag: 'new-zealand.svg'
        },
        {
          name: localizedString('COUNTRY_CN'),
          iso: 'CN',
          flag: 'china.svg'
        },
        {
          name: localizedString('COUNTRY_HK'),
          iso: 'HK',
          flag: 'hong-kong.svg'
        },
        {
          name: localizedString('COUNTRY_MY'),
          iso: 'MY',
          flag: 'malaysia.svg'
        },
        {
          name: localizedString('COUNTRY_SG'),
          iso: 'SG',
          flag: 'singapore.svg'
        },
        {
          name: localizedString('COUNTRY_UK'),
          iso: 'UK',
          flag: 'united-kingdom.svg'
        },
        {
          name: localizedString('COUNTRY_US'),
          iso: 'US',
          flag: 'united-states-of-america.svg'
        },
        {
          name: localizedString('COUNTRY_CA'),
          iso: 'CA',
          flag: 'canada.svg'
        },
        {
          name: localizedString('COUNTRY_AT'),
          iso: 'AT',
          flag: 'austria.svg'
        },
        {
          name: localizedString('COUNTRY_KZ'),
          iso: 'KZ',
          flag: 'kazakhstan.svg'
        },
        {
          name: localizedString('COUNTRY_TR'),
          iso: 'TR',
          flag: 'turkey.svg'
        },
        {
          name: localizedString('COUNTRY_OTHER'),
          iso: 'OTHER',
          flag: 'world.svg'
        }
      ],
      // AUVOI Configurations
      AUVOI_ID_TYPES: [
        {
          label: localizedString('AUVOI_ID_DRIVER_LICENCE'),
          id: 'AUS_AUTO_DRIVERLICENCE',
          ocr: true,
          backOfCard: false
        },
        {
          label: localizedString('AUVOI_ID_PASSPORT'),
          id: 'PASSPORT',
          ocr: true,
          backOfCard: false
        },
        {
          label: localizedString('AUVOI_ID_MEDICARE_CARD'),
          id: 'AUS_MEDICARE',
          ocr: false,
          backOfCard: false
        },
        {
          label: localizedString('AUVOI_ID_AUS_BIRTH_CERT'),
          id: 'AUS_AUTO_BIRTH',
          ocr: false,
          backOfCard: false
        }
      ]
    },
    TurkeyDemo: {
      ID_TYPES: {
        NZ: [
          {
            label: localizedString('ID_TYPE_NZ_LICENCE'),
            icon: 'licence.svg',
            id: 'NZL_DRIVERLICENCE',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_NZ_PASSPORT'),
            icon: 'passport.svg',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        AU: [
          {
            label: localizedString('ID_TYPE_AU_LICENCE'),
            icon: 'au-licence.png',
            id: 'AUS_AUTO_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_AU_PASSPORT'),
            icon: 'au-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_AU_PROOF_OF_AGE'),
            icon: 'proof_of_age.png',
            id: 'PROOF_OF_AGE',
            expired: false,
            backOfCard: false
          }
        ],
        CN: [
          {
            label: localizedString('ID_TYPE_CN_IDCARD'),
            icon: 'cn-idcard.png',
            id: 'CHN_IDCARD',
            expired: false,
            backOfCard: false
          }
        ],
        HK: [
          {
            label: localizedString('ID_TYPE_HK_IDCARD'),
            icon: 'hk-idcard.png',
            id: 'HKG_IDCARD',
            expired: false,
            backOfCard: false
          }
        ],
        MY: [
          {
            label: localizedString('ID_TYPE_MY_LICENCE'),
            icon: 'my-licence.png',
            id: 'MYS_DLCARD',
            expired: false,
            backOfCard: false
          }
        ],
        SG: [
          {
            label: localizedString('ID_TYPE_SG_IDCARD'),
            icon: 'sg-idcard.png',
            id: 'SGP_IDCARD',
            expired: false,
            backOfCard: false
          }
        ],
        UK: [
          {
            label: localizedString('ID_TYPE_UK_LICENCE'),
            icon: 'uk-licence.png',
            id: 'EUR_UK_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_UK_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_UK_RESIDENCE_PERMIT'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          }
        ],
        US: [
          {
            label: localizedString('ID_TYPE_US_LICENCE'),
            icon: 'us-licence.png',
            id: 'MYS_DLCARD',
            expired: false,
            backOfCard: false
          }
        ],
        CA: [
          {
            label: localizedString('ID_TYPE_CAN_AB_DRIVERLICENCE'),
            icon: 'ca-licence.png',
            id: 'CAN_AB_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_CAN_BC_DRIVERLICENCE'),
            icon: 'ca-licence.png',
            id: 'CAN_BC_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_CAN_ON_DRIVERLICENCE'),
            icon: 'ca-licence.png',
            id: 'CAN_ON_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          }
        ],
        AT: [
          {
            label: localizedString('ID_TYPE_AUSTRIAN_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_AUSTRIAN_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        KZ: [
          {
            label: localizedString('ID_TYPE_KAZAKH_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_KAZAKH_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        TR: [
          {
            label: localizedString('ID_TYPE_TURKEY_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'TUR_IDENTITYCARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_TURKEY_DRIVER_LICENCE'),
            icon: 'international-idcard.png',
            id: 'EUR_TUR_DRIVERLICENCE',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_TURKEY_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        OTHER: [
          {
            label: localizedString('ID_TYPE_OTHER_IDCARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_OTHER_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ]
      },
      // AUVOI Configurations
      AUVOI_ID_TYPES: [
        {
          label: localizedString('AUVOI_ID_DRIVER_LICENCE'),
          id: 'AUS_AUTO_DRIVERLICENCE',
          ocr: true,
          backOfCard: false
        },
        {
          label: localizedString('AUVOI_ID_PASSPORT'),
          id: 'PASSPORT',
          ocr: true,
          backOfCard: false
        },
        {
          label: localizedString('AUVOI_ID_MEDICARE_CARD'),
          id: 'AUS_MEDICARE',
          ocr: false,
          backOfCard: false
        },
        {
          label: localizedString('AUVOI_ID_AUS_BIRTH_CERT'),
          id: 'AUS_AUTO_BIRTH',
          ocr: false,
          backOfCard: false
        }
      ]
    },
    NextGen: {
      ID_TYPES: {
        NZ: [
          {
            label: localizedString('ID_TYPE_NZ_LICENCE'),
            icon: 'licence.svg',
            id: 'NZL_DRIVERLICENCE',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_NZ_PASSPORT'),
            icon: 'passport.svg',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        AU: [
          {
            label: localizedString('ID_TYPE_AU_LICENCE'),
            icon: 'au-licence.png',
            id: 'AUS_AUTO_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_AU_PASSPORT'),
            icon: 'au-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        OTHER: [
          {
            label: localizedString('ID_TYPE_OTHER_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ]
      },
      // AUVOI Configurations
      AUVOI_ID_TYPES: [
        {
          label: localizedString('AUVOI_ID_DRIVER_LICENCE'),
          id: 'AUS_AUTO_DRIVERLICENCE',
          ocr: true,
          backOfCard: false
        },
        {
          label: localizedString('AUVOI_ID_PASSPORT'),
          id: 'PASSPORT',
          ocr: true,
          backOfCard: false
        },
        {
          label: localizedString('AUVOI_ID_MEDICARE_CARD'),
          id: 'AUS_MEDICARE',
          ocr: false,
          backOfCard: false
        },
        {
          label: localizedString('AUVOI_ID_AUS_BIRTH_CERT'),
          id: 'AUS_AUTO_BIRTH',
          ocr: false,
          backOfCard: false
        }
      ]
    },
    Twisto: {
      ID_TYPES: {
        NZ: [
          {
            label: localizedString('ID_TYPE_NZ_LICENCE'),
            icon: 'licence.svg',
            id: 'NZL_DRIVERLICENCE',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_NZ_PASSPORT'),
            icon: 'passport.svg',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        AU: [
          {
            label: localizedString('ID_TYPE_AU_LICENCE'),
            icon: 'au-licence.png',
            id: 'AUS_AUTO_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_AU_PASSPORT'),
            icon: 'au-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_AU_PROOF_OF_AGE'),
            icon: 'proof_of_age.png',
            id: 'PROOF_OF_AGE',
            expired: false,
            backOfCard: false
          }
        ],
        CN: [
          {
            label: localizedString('ID_TYPE_CN_IDCARD'),
            icon: 'cn-idcard.png',
            id: 'CHN_IDCARD',
            expired: false,
            backOfCard: false
          }
        ],
        HK: [
          {
            label: localizedString('ID_TYPE_HK_IDCARD'),
            icon: 'hk-idcard.png',
            id: 'HKG_IDCARD',
            expired: false,
            backOfCard: false
          }
        ],
        MY: [
          {
            label: localizedString('ID_TYPE_MY_LICENCE'),
            icon: 'my-licence.png',
            id: 'MYS_DLCARD',
            expired: false,
            backOfCard: false
          }
        ],
        SG: [
          {
            label: localizedString('ID_TYPE_SG_IDCARD'),
            icon: 'sg-idcard.png',
            id: 'SGP_IDCARD',
            expired: false,
            backOfCard: false
          }
        ],
        UK: [
          {
            label: localizedString('ID_TYPE_UK_LICENCE'),
            icon: 'uk-licence.png',
            id: 'EUR_UK_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_UK_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_UK_RESIDENCE_PERMIT'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          }
        ],
        US: [
          {
            label: localizedString('ID_TYPE_US_LICENCE'),
            icon: 'us-licence.png',
            id: 'MYS_DLCARD',
            expired: false,
            backOfCard: false
          }
        ],
        CA: [
          {
            label: localizedString('ID_TYPE_CAN_AB_DRIVERLICENCE'),
            icon: 'ca-licence.png',
            id: 'CAN_AB_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_CAN_BC_DRIVERLICENCE'),
            icon: 'ca-licence.png',
            id: 'CAN_BC_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          },
          {
            label: localizedString('ID_TYPE_CAN_ON_DRIVERLICENCE'),
            icon: 'ca-licence.png',
            id: 'CAN_ON_DRIVERLICENCE',
            expired: false,
            backOfCard: false
          }
        ],
        AT: [
          {
            label: localizedString('ID_TYPE_AUSTRIAN_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_AUSTRIAN_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        KZ: [
          {
            label: localizedString('ID_TYPE_KAZAKH_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_KAZAKH_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        TR: [
          {
            label: localizedString('ID_TYPE_TURKEY_ID_CARD'),
            icon: 'international-idcard.png',
            id: 'TUR_IDENTITYCARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_TURKEY_DRIVER_LICENCE'),
            icon: 'international-idcard.png',
            id: 'EUR_TUR_DRIVERLICENCE',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_TURKEY_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ],
        OTHER: [
          {
            label: localizedString('ID_TYPE_OTHER_IDCARD'),
            icon: 'international-idcard.png',
            id: 'ID_CARD',
            expired: false,
            backOfCard: true
          },
          {
            label: localizedString('ID_TYPE_OTHER_PASSPORT'),
            icon: 'generic-passport.png',
            id: 'PASSPORT',
            expired: false,
            backOfCard: false
          }
        ]
      },
      COUNTRIES: [
        {
          name: localizedString('COUNTRY_AU'),
          iso: 'AU',
          flag: 'australia.svg'
        },
        {
          name: localizedString('COUNTRY_NZ'),
          iso: 'NZ',
          flag: 'new-zealand.svg'
        },
        {
          name: localizedString('COUNTRY_CN'),
          iso: 'CN',
          flag: 'china.svg'
        },
        {
          name: localizedString('COUNTRY_HK'),
          iso: 'HK',
          flag: 'hong-kong.svg'
        },
        {
          name: localizedString('COUNTRY_MY'),
          iso: 'MY',
          flag: 'malaysia.svg'
        },
        {
          name: localizedString('COUNTRY_SG'),
          iso: 'SG',
          flag: 'singapore.svg'
        },
        {
          name: localizedString('COUNTRY_UK'),
          iso: 'UK',
          flag: 'united-kingdom.svg'
        },
        {
          name: localizedString('COUNTRY_US'),
          iso: 'US',
          flag: 'united-states-of-america.svg'
        },
        {
          name: localizedString('COUNTRY_CA'),
          iso: 'CA',
          flag: 'canada.svg'
        },
        {
          name: localizedString('COUNTRY_AT'),
          iso: 'AT',
          flag: 'austria.svg'
        },
        {
          name: localizedString('COUNTRY_KZ'),
          iso: 'KZ',
          flag: 'kazakhstan.svg'
        },
        {
          name: localizedString('COUNTRY_TR'),
          iso: 'TR',
          flag: 'turkey.svg'
        },
        {
          name: localizedString('COUNTRY_OTHER'),
          iso: 'OTHER',
          flag: 'world.svg'
        }
      ],
      // AUVOI Configurations
      AUVOI_ID_TYPES: [
        {
          label: localizedString('AUVOI_ID_DRIVER_LICENCE'),
          id: 'AUS_AUTO_DRIVERLICENCE',
          ocr: true,
          backOfCard: false
        },
        {
          label: localizedString('AUVOI_ID_PASSPORT'),
          id: 'PASSPORT',
          ocr: true,
          backOfCard: false
        },
        {
          label: localizedString('AUVOI_ID_MEDICARE_CARD'),
          id: 'AUS_MEDICARE',
          ocr: false,
          backOfCard: false
        },
        {
          label: localizedString('AUVOI_ID_AUS_BIRTH_CERT'),
          id: 'AUS_AUTO_BIRTH',
          ocr: false,
          backOfCard: false
        }
      ]
    }
  };
}

export const envPatch = getEnvironmentPatch();
