import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { localizedString } from '@languages';
import Page from '../Page';

import classes from './Message.style.module.scss';

const Message = ({ title, children, landscape, dark, issue, buttons }) => {
  return (
    <Page
      buttons={buttons}
      className={classNames(classes.wrapper)}
      isDark={landscape || dark}
      isMessage
    >
      <div className={classes.content}>
        {landscape && (
          <div className={classes.landscape}>
            <div className={classNames(classes.content, { 'text-white': dark || landscape })}>
              {localizedString('pleaseRotateYourDeviceToPortrait')}
              <img alt="" src="images/icons/png/landscope.png" />
            </div>
          </div>
        )}
        {issue && (
          <div className={classes.issue}>
            <img alt="" src="images/icons/png/issue.png" />
          </div>
        )}
        {!landscape && title && <div className={classes.heading}>{title}</div>}
        {!landscape && children}
      </div>
    </Page>
  );
};

Message.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      shadow: PropTypes.bool,
      large: PropTypes.bool
    })
  ),
  issue: PropTypes.bool,
  dark: PropTypes.bool,
  landscape: PropTypes.bool
};

Message.defaultProps = {
  title: '',
  issue: false,
  dark: false,
  landscape: false
};

export default Message;
