import { localizedString } from '@languages';

const data = {
  DRIVERLICENCE: [
    localizedString('holdYourIDInHandCopiesNotAccepted'),
    localizedString('ensureEdgesAreVisible'),
    localizedString('makeSureNoBlurShadowsGlare')
  ],
  PASSPORT: [
    localizedString('holdYourIDInHandCopiesNotAccepted'),
    localizedString('makeSureEdgesAreVisibleInView'),
    localizedString('makeSureNoBlurShadowsGlare')
  ]
};

const howToCapture = (idType) => {
  return idType === 'PASSPORT' ? data.PASSPORT : data.DRIVERLICENCE;
};

export default howToCapture;
