import { localizedString } from '@languages';
import { isCardNumberFieldVisible } from '../cardNumberField';

export const getFields = ({
  idType,
  countryOfIssue,
  address,
  addressData,
  useIdNumber,
  values = {}
}) => {
  let fields = [
    { id: 'firstName', label: localizedString('firstName') },
    { id: 'middleName', label: localizedString('middleName') },
    { id: 'lastName', label: localizedString('surname') },
    {
      id: 'dateOfBirth',
      label: localizedString('dateOfBirth'),
      date: true,
      halfWith: true
    },
    {
      id: 'expiryDate',
      label: localizedString('dateOfExpiry'),
      date: true,
      halfWith: true
    }
  ];

  if (address) {
    fields.push({
      id: 'addressData',
      label: localizedString('residentalAddress'),
      addressFinder: true
    });
  }

  /**
   * if ID type is Passport, it should display the Passport Number field
   * instead of the Licence Number and Licence Version.
   */
  if (idType === 'PASSPORT') {
    fields.push({
      id: useIdNumber ? 'idNumber' : 'passportNumber',
      label: localizedString('passportNumber'),
      confirm: true,
      required: true,
      halfWith: true
    });

    // If visa then add following field
    const { ENABLE_VISA_AFTER_PASSPORT } = process.env;
    if (
      ENABLE_VISA_AFTER_PASSPORT &&
      countryOfIssue !== 'NEW ZEALAND' &&
      countryOfIssue !== 'AUSTRALIA'
    ) {
      fields.push({
        id: 'visaStartDate',
        label: localizedString('visaStartDate'),
        date: true,
        halfWith: false
      });
      fields.push({
        id: 'visaEndDate',
        label: localizedString('visaEndDate'),
        date: true,
        halfWith: false
      });
    }
  } else if (idType === 'ID_CARD') {
    fields.push({
      id: useIdNumber ? 'idNumber' : 'passportNumber',
      label: useIdNumber ? localizedString('idNumber') : localizedString('cardNumber'),
      confirm: true,
      required: true,
      halfWith: true
    });
  } else if (
    idType.match(/IDCARD/i) ||
    idType.match(/IDENTITYCARD/i) ||
    idType === 'PROOF_OF_AGE'
  ) {
    fields.push({
      id: useIdNumber ? 'idNumber' : 'cardNumber',
      label: useIdNumber ? localizedString('idNumber') : localizedString('cardNumber'),
      confirm: true,
      required: true,
      halfWith: true
    });
    if (idType === 'PROOF_OF_AGE') {
      fields = fields.filter(({ id }) => {
        return id !== 'expiryDate';
      });
    }
  } else if (idType.match(/LICENCE/i)) {
    fields.push({
      id: useIdNumber ? 'idNumber' : 'licenceNumber',
      label: useIdNumber ? localizedString('idNumber') : localizedString('licenceNumber'),
      confirm: true,
      required: true,
      halfWith: true
    });

    if (isCardNumberFieldVisible({ idType, countryOfIssue })) {
      fields.push({
        id: 'cardNumber',
        label: localizedString('cardNumber')
      });
    }

    if (process.env.LICENCEVERSION && values.cardType && values.cardType === 'NZL_DRIVERLICENCE') {
      fields.push({
        id: 'licenceVersion',
        type: 'number',
        label: localizedString('licenceVersion'),
        confirm: true,
        required: true,
        halfWith: true
      });
    }
  }

  // Add IdNumber in Turkey Cards
  if (idType !== 'PASSPORT' && countryOfIssue && countryOfIssue.toLowerCase() === 'turkey') {
    fields.push({
      id: useIdNumber ? 'idNumber' : 'iDNumber',
      label: localizedString('idNumber'),
      confirm: true,
      required: true,
      halfWith: true
    });
  }

  // Set input default value
  return fields.map((field) => {
    let value = values[field.id] || '';

    if (field.id === 'addressData') {
      value = addressData;
    } else if (useIdNumber && ['licenceNumber', 'passportNumber'].includes(field.id)) {
      value = values.idNumber;
    }

    return {
      ...field,
      value
    };
  });
};
