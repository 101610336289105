import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DisclaimerContent } from '@components/Contents';
import APIs from '@services/APIs';

/**
 * This page wont display if `DISCLAIMER` env is false.
 */
export default class Disclaimer extends Component {
  static propTypes = {
    onAccept: PropTypes.func,
    onExit: PropTypes.func
  };

  static defaultProps = {
    onAccept: () => null,
    onExit: () => null
  };

  componentDidMount() {
    APIs.status('disclaimer');
  }

  /**
   * Render the component's markup
   * @return {ReactElement}
   */
  render() {
    const { onAccept, onExit } = this.props;
    return <DisclaimerContent onAccept={() => onAccept()} onExit={() => onExit()} />;
  }
}
