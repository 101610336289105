import React from 'react';
import classnames from 'classnames';
import Animation from '@components/Animation';
import classes from './Datacheck.style.module.scss';

let animationReview;
import('@FLOW_V2_FLOW/animations/anim_data.json').then((value) => {
  animationReview = value;
});

const DatacheckLoading = () => {
  return (
    <div style={{ height: '100%' }}>
      <div className={classes.wrapper}>
        <div className={classnames(classes.heading)} data-testid="id-heading">
          Processing your verification
        </div>
        <div className={classnames(classes.description)} data-testid="id-instruction">
          Matching your data to government database
        </div>
        <div className={classes['animation-container']} data-testid="id-animation">
          <Animation animationData={animationReview} />
        </div>
      </div>
    </div>
  );
};
export default DatacheckLoading;
