/* eslint-disable camelcase */
import axios from 'axios';
import { getCountryISO3 } from './country-iso-2-to-3';

/**
 * Addressfinder APIs
 * ?ad_mode=1
 Manual Address enabled.
 ADDRESS_VALIDATION: true,
 ENABLE_MANUAL_ADDRESS: true,

 ?ad_mode=2
 Address Validation On. (must enter valid address)
 ADDRESS_VALIDATION: true,
 ENABLE_MANUAL_ADDRESS: false,

 ?ad_mode=3
 Address validation off
 ADDRESS_VALIDATION: false,
 */
export default class Address {
  static API_URL = `https://api.addressfinder.io/api`;

  static API_KEY = process.env.ADDRESS_FINDER_KEY;

  static ADDY_API_URL = `https://api.addy.co.nz`;

  static ADDY_API_KEY = process.env.ADDY_KEY;

  static EXPERIAN_API_URL = `https://api.experianaperture.io/address`;

  static EXPERIAN_API_KEY = process.env.EXPERIAN_KEY;

  static ALLOWED_COUNTRIES = ['AU', 'NZ'];

  static apiReferenceId = `${window.location.host}_${document.body.dataset.id}`;

  /**
   * Make a request to verify address.
   * @param {String} address
   * @param country
   * @param dataProvider
   * @return {Promise}
   */
  static verify(address, country, dataProvider = null) {
    if (country && this.EXPERIAN_API_KEY) {
      return this.findExperian(address, true, country, true);
    }

    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });

    return new Promise((resolve) => {
      if (this.ALLOWED_COUNTRIES.includes(country) && (this.API_KEY || this.ADDY_API_KEY)) {
        const that = this;
        const useAddy = country === 'NZ' && that.ADDY_API_KEY;

        let url = `${that.API_URL}/${country.toLowerCase()}/address/cleanse?key=${
          that.API_KEY
        }&q=${address}&format=json`;

        if (useAddy) {
          url = `${that.ADDY_API_URL}/validation?key=${that.ADDY_API_KEY}&address=${address}&expostbox=True`;
        }

        axios
          .get(url, { cancelToken: source.token })
          .then(({ data }) => {
            const formatted = that.format(address, country, dataProvider, data);
            resolve(formatted);
          }, 'json')
          .catch((err) => {
            // reject(err)
            console.error('resolved error exception', err);
            const formatted = that.format(address, country, dataProvider);
            resolve(formatted);
          });
      } else {
        const formatted = this.format(address, country, dataProvider);
        resolve(formatted);
      }
    });
  }

  /**
   * Make a request to find addresses
   * @param {String} address
   * @param country
   * @param isExtracted
   * @return {Promise}
   */
  static find(address, country, isExtracted) {
    if (country && this.EXPERIAN_API_KEY) {
      return this.findExperian(address, isExtracted, country);
    }

    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });

    return new Promise((resolve) => {
      if (this.ALLOWED_COUNTRIES.includes(country) && (this.API_KEY || this.ADDY_API_KEY)) {
        const that = this;
        const useAddy = country === 'NZ' && that.ADDY_API_KEY;

        let url = `${that.API_URL}/${country.toLowerCase()}/address?key=${
          that.API_KEY
        }&q=${address}&format=json&strict=2&max=4`;

        if (useAddy) {
          url = `${that.ADDY_API_URL}/search?key=${that.ADDY_API_KEY}&s=${address}&max=4&expostbox=True`;
        }

        axios
          .get(url, { cancelToken: source.token })
          .then(({ data }) => {
            if (!useAddy) {
              resolve(data.completions);
            }

            resolve(data.addresses);
          }, 'json')
          .catch((err) => {
            console.error(err);
            // reject([])
            resolve([]);
          });
      } else {
        resolve([]);
      }
    });
  }

  static formatManualAddress(address) {
    const newData = {
      manual: true,
      fullAddress: '',
      addressLine1: address.addressLine1 || '',
      addressLine2: address.addressLine2 || '',
      unit_number: '',
      street_number: address.street_number || '',
      street_name: address.street_name || '',
      street_type: '',
      suburb: address.suburb || '',
      city: address.suburb || '',
      state_territory: address.state_territory || '',
      postcode: address.postcode || '',
      country: address.country || '',
      selectedManualCountryCode: address.selectedManualCountryCode || '',
      addressApiCalls: address.addressApiCalls || 0
    };

    if (newData.addressLine1 !== '') {
      newData.fullAddress += `${newData.addressLine1} `;
    }

    if (newData.addressLine2 !== '') {
      newData.fullAddress += `${newData.addressLine2} `;
    }

    if (newData.suburb !== '') {
      newData.fullAddress += `${newData.suburb} `;
    }

    if (newData.state_territory !== '') {
      newData.fullAddress += `${newData.state_territory} `;
    }

    if (newData.postcode !== '') {
      newData.fullAddress += newData.postcode;
    }

    return newData;
  }

  static format(address, country, dataProvider = null, cleanseData = null) {
    const isAddy = country === 'NZ' && this.ADDY_API_KEY;

    let countryCode = country;
    if (country === 'AU') {
      countryCode = 'AUS';
    } else if (country === 'NZ') {
      countryCode = 'NZL';
    }

    let newData = {
      fullAddress: address,
      addressLine1: '',
      addressLine2: '',
      unit_number: '',
      street_number: '',
      street_name: '',
      street_type: '',
      suburb: '',
      city: '',
      state_territory: '',
      postcode: '',
      country: countryCode
    };
    let matched = false;

    // If the API call had exception or It's different country other than AU, NZ
    if (!cleanseData) {
      return { matched, addressData: newData };
    }

    // If it is ADDY call and Address entered is not valid
    if (isAddy && (!cleanseData.address || !Object.keys(cleanseData.address))) {
      return { matched, addressData: newData };
    }

    // If it is Address Finder call and Address entered is not valid
    if (!isAddy && !cleanseData.matched) {
      return { matched, addressData: newData };
    }

    let { address: addrObject } = cleanseData;
    if (!addrObject) {
      addrObject = cleanseData;
    }

    if (dataProvider === 'centrix') {
      matched = true;
      if (isAddy) {
        newData = {
          fullAddress: address,
          addressLine1: addrObject.address1 || '',
          addressLine2: addrObject.address2 || '',
          city: addrObject.city ? addrObject.city : addrObject.mailtown,
          suburb: addrObject.suburb ? addrObject.suburb : '',
          postcode: addrObject.postcode || '',
          dpid: addrObject.dpid || '',
          address_api: 'addy'
        };

        if (!addrObject.address4 || addrObject.address2.includes('RD')) {
          newData.addressLine1 = addrObject.address1;
          newData.addressLine2 = addrObject.address2;
        } else {
          newData.addressLine1 = addrObject.displayline;
          newData.addressLine2 = '';
        }
      } else {
        newData = {
          fullAddress: address,
          addressLine1: addrObject.address_line_1 || '',
          addressLine2: addrObject.address_line_2 || '',
          city: addrObject.city || '',
          suburb: addrObject.suburb || '',
          postcode: addrObject.postcode || '',
          address_api: 'addressfinder'
        };

        if (country === 'AU') {
          newData.suburb = addrObject.locality_name;
        }
      }
    } else {
      matched = true;
      // green ID or IDMatrix

      if (isAddy) {
        newData = {
          fullAddress: address,
          addressLine1: addrObject.address1 || '',
          addressLine2: addrObject.address2 || '',
          unit_number: addrObject.unitnumber || '',
          street_number: addrObject.numberfull || '',
          street_name: addrObject.street || '',
          street_type: '',
          city: addrObject.city ? addrObject.city : addrObject.mailtown,
          suburb: addrObject.suburb ? addrObject.suburb : '',
          postcode: addrObject.postcode || '',
          dpid: addrObject.dpid || '',
          country: 'NZL',
          address_api: 'addy'
        };

        if (!addrObject.address4 || addrObject.address2.includes('RD')) {
          newData.addressLine1 = addrObject.address1;
          newData.addressLine2 = addrObject.address2;
        } else {
          newData.addressLine1 = addrObject.displayline;
          newData.addressLine2 = '';
        }
      } else {
        newData = {
          fullAddress: address,
          addressLine1: addrObject.address_line_1 || '',
          addressLine2: addrObject.address_line_2 || '',
          unit_number: addrObject.unit_identifier || '',
          street_number: addrObject.street_number_1 || '',
          street_name: addrObject.street_name || '',
          street_type: addrObject.street_type || '',
          suburb: addrObject.locality_name || '',
          city: addrObject.city || '',
          state_territory: addrObject.state_territory || '',
          postcode: addrObject.postcode || '',
          country: countryCode,
          address_api: 'addressfinder'
        };
      }
    }

    return { matched, addressData: newData };
  }

  static findExperian(address, isExtracted, country, detailed = false) {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });

    const countryIso = getCountryISO3(country) || 'GBR';

    const url = `${this.EXPERIAN_API_URL}/search/v1`;
    const headers = {
      'Auth-Token': this.EXPERIAN_API_KEY,
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Reference-ID': this.apiReferenceId
    };

    const body = {
      country_iso: countryIso,
      components: {
        unspecified: [address]
      },
      location: '',
      dataset: ''
    };

    if (countryIso === 'AUS') {
      body.dataset = 'GNAF';
    }

    return new Promise((resolve) => {
      if (!address || (address && address.length <= 3)) {
        console.warn('please enter at least 4 characters');
        resolve([]);
      } else {
        axios
          .post(url, body, { cancelToken: source.token, headers })
          .then(({ data }) => {
            if (data.result && data.result.suggestions) {
              if (!isExtracted) {
                resolve(data.result.suggestions);
              } else if (data.result.suggestions.length > 0) {
                const { format } = data.result.suggestions[0];
                this.verifyExperian(format).then((addressData) => {
                  const { fullAddress } = addressData;
                  if (detailed) {
                    resolve({
                      addressData,
                      matched: true,
                      provider: 'experian'
                    });
                  } else {
                    resolve([
                      {
                        text: fullAddress,
                        matched: true,
                        provider: 'experian'
                      }
                    ]);
                  }
                });
              } else {
                resolve([
                  {
                    text: ''
                  }
                ]);
              }
            } else {
              resolve([]);
            }
          }, 'json')
          .catch((err) => {
            console.error(err);
            resolve([]);
          });
      }
    });
  }

  static verifyExperian(url) {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });

    const headers = {
      'Auth-Token': this.EXPERIAN_API_KEY,
      Accept: 'application/json',
      'Add-Components': true,
      'Add-Metadata': true,
      'Reference-ID': this.apiReferenceId
    };

    return new Promise((resolve) => {
      axios
        .get(url, { cancelToken: source.token, headers })
        .then(({ data }) => {
          if (data.result && data.result.address) {
            resolve(this.formatExperian(data.result));
          } else {
            resolve({});
          }
        }, 'json')
        .catch((err) => {
          console.error('resolved error exception', err);
          resolve({});
        });
    });
  }

  static formatExperian(addressData) {
    const { address, components } = addressData;

    const formattedAddress = { ...address };

    let fullAddress = '';
    Object.keys(address).forEach((key) => {
      if (address[key] && key !== 'country') {
        fullAddress += `${address[key]} `;
      }
    });

    formattedAddress.fullAddress = fullAddress.trim();

    if ('building' in components) {
      const { building } = components;
      Object.keys(building).forEach((key) => {
        if (key.includes('building_')) {
          formattedAddress[key] = building[key];
        } else {
          formattedAddress[`building_${key}`] = building[key];
        }
      });
    }

    if ('sub_building' in components) {
      const { sub_building } = components;

      if ('door' in sub_building) {
        const { door } = sub_building;
        Object.keys(door).forEach((key) => {
          if (key === 'full_name') {
            formattedAddress.unit_door_full_number = door[key];
          } else {
            formattedAddress[`unit_door_${key}`] = door[key];
          }
        });
      }

      if ('floor' in sub_building) {
        const { floor } = sub_building;
        Object.keys(floor).forEach((key) => {
          if (key === 'full_name') {
            formattedAddress.unit_floor_full_number = floor[key];
          } else {
            formattedAddress[`unit_floor_${key}`] = floor[key];
          }
        });
      }

      if ('entrance' in sub_building) {
        const { entrance } = sub_building;
        Object.keys(entrance).forEach((key) => {
          if (key === 'full_name') {
            formattedAddress.unit_entrance_full_number = entrance[key];
          } else {
            formattedAddress[`unit_entrance_${key}`] = entrance[key];
          }
        });
      }
    }

    if ('street' in components) {
      const { street } = components;
      Object.keys(street).forEach((key) => {
        formattedAddress[`street_${key}`] = street[key];
      });
    }

    if ('locality' in components) {
      const { locality } = components;
      Object.keys(locality).forEach((key) => {
        formattedAddress[`locality_${key}_name`] = locality[key].name;
      });
    }

    formattedAddress.address_api = 'experian';
    return formattedAddress;
  }

  static isAddressAPIDisabled(country) {
    if (
      this.EXPERIAN_API_KEY ||
      (this.ALLOWED_COUNTRIES.includes(country) && (this.API_KEY || this.ADDY_API_KEY))
    ) {
      return false;
    }
    return true;
  }
}
