import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import { DocumentListScreen } from '@lib/pages/v2/DocumentListScreen';
import { localizedString } from '@languages';
import { DOCUMENTS } from '@lib/constants/documents';

const {
  FLOW_V2_AUS_AUTO_DRIVERLICENCE = 'Australian driver licence',
  FLOW_V2_AVAILABLE_DOCS1 = null,
  FLOW_V2_AVAILABLE_DOCS2 = null,
  FLOW_V2_DATA_CHECK_DIFF_FILTER_PREVIOUS_USED_DOC = false
} = process.env;

const availableDocumentListDefault1 = [
  {
    type: DOCUMENTS.DRIVER_LICENCE.documentType,
    cardType: DOCUMENTS.DRIVER_LICENCE.cardType,
    title: FLOW_V2_AUS_AUTO_DRIVERLICENCE,
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  },
  {
    type: DOCUMENTS.PASSPORT.documentType,
    cardType: DOCUMENTS.PASSPORT.cardType,
    title: 'Passport',
    icon: 'icon_passport.svg',
    checked: false,
    ocr: true
  }
];

const availableDocumentList1 = FLOW_V2_AVAILABLE_DOCS1
  ? [...FLOW_V2_AVAILABLE_DOCS1]
  : [...availableDocumentListDefault1];
const availableDocumentList2 = FLOW_V2_AVAILABLE_DOCS2 ? [...FLOW_V2_AVAILABLE_DOCS2] : [];

class ChooseAnotherID extends Component {
  static propTypes = {
    currentDoc: PropTypes.object,
    onNextStep: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      availableDocumentList: [],
      selectedDocument: null
    };

    this.handleNextStep = this.handleNextStep.bind(this);
  }

  componentDidMount() {
    const { currentDoc = {} } = this.props;

    const allDocs = [...availableDocumentList1, ...availableDocumentList2];
    const availableDocumentList = allDocs.filter((doc1) => {
      return !FLOW_V2_DATA_CHECK_DIFF_FILTER_PREVIOUS_USED_DOC || doc1.cardType !== currentDoc.type;
    });
    this.setState({ availableDocumentList });
  }

  handleNextStep(e) {
    const { onNextStep } = this.props;
    const { selectedDocument } = this.state;
    if (e) {
      e.preventDefault();
    }
    if (selectedDocument) {
      onNextStep(selectedDocument);
    }
  }

  onCardClick(item, index) {
    if (item.checked) {
      return;
    }

    this.setState(({ availableDocumentList }) => ({
      availableDocumentList: availableDocumentList.map((card, i) => {
        return {
          ...card,
          checked: i === index
        };
      }),
      selectedDocument: availableDocumentList[index]
    }));
  }

  /**
   * Render the component's markup
   * @return {ReactElement}
   */
  render() {
    const {
      CHOOSE_ANOTHER_ID_TITLE = localizedString('chooseDifferentId'),
      CHOOSE_ANOTHER_ID_DESCRIPTION,
      APP_NAME,
      FLOW_V2_HIDE_IMAGES_IN_DOC_LIST
    } = process.env;

    const { currentDoc = {} } = this.props;
    const { availableDocumentList, selectedDocument } = this.state;

    const footerButtons = [];
    footerButtons.push({
      label: localizedString('continue'),
      disabled: !selectedDocument,
      type: 'submit',
      onClick: this.handleNextStep,
      dataTestId: 'flow_v2_choose_another_id'
    });

    let description = `${localizedString('chooseDifferentIdInsteadOf')} ${currentDoc.type}.`;
    if (!isNil(CHOOSE_ANOTHER_ID_DESCRIPTION)) {
      description = CHOOSE_ANOTHER_ID_DESCRIPTION;
    }
    if (APP_NAME === 'RAMS') {
      description = `${localizedString('chooseDifferentIdInsteadOf')} 
        ${currentDoc.type}. ${localizedString('internationalPassportWithVisa')}`;
    }

    return (
      <DocumentListScreen
        title={CHOOSE_ANOTHER_ID_TITLE}
        description={description}
        documentList={availableDocumentList}
        onClickDocumentItem={(item, index) => this.onCardClick(item, index)}
        footerButtons={footerButtons}
        hideDocImages={FLOW_V2_HIDE_IMAGES_IN_DOC_LIST}
      />
    );
  }
}

export default ChooseAnotherID;
