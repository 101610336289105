import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InfoAction from '@store/actions/information';
import CaptureAction from '@store/actions/capture';

import { getCookie, setCookie } from '@lib/Utils';
import APIs from '@services/APIs';
import { envPatch } from '@languages/env_patch';
import { localizedString } from '@languages';
import { Error500 } from '../../errors';
import { IdSelectionContent, BackOfCard } from '../../components/Contents';
import { Page, Tips, LoadingSpinner } from '../../components';

class CaptureOther extends Component {
  static propTypes = {
    onNextStep: PropTypes.func,
    onGoBack: PropTypes.func,
    onIdSelect: PropTypes.func,
    onExit: PropTypes.func,
    country: PropTypes.string,
    nextCapture: PropTypes.string,
    capturedIds: PropTypes.object,
    frontParams: PropTypes.object,
    setFrontIDParams: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.element = null;
    this.isComponentMounted = false;

    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleCapture = this.handleCapture.bind(this);
  }

  /**
   * Return the component's initial state
   * @return {Object}
   */
  getInitialState() {
    return {
      idType: '',
      idTypes: [],
      progressBar: 0,
      backOfCard: false,
      isUploading: false,
      error: null,
      frontFile: null,
      tipStep: 0,
      auState: '',
      stateError: {}
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { frontParams, nextCapture } = this.props;

    if (frontParams.idType && nextCapture === 'back') {
      this.setState({
        backOfCard: true
      });
    }

    // Store all the remaining ids
    this.updateIdTypes();
  }

  componentDidMount() {
    this.isComponentMounted = true;
    APIs.status('selectId');
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  /**
   * Go to handle Select
   * @return {Void}
   */
  handleSelectIdType = (data) => {
    const oldIdType = this.state.idType;
    const { idType, backOfCard } = data;
    this.setState({ idType, backOfCard, tipStep: 0 });
    this.props.onIdSelect(idType, oldIdType, 'yes');
  };

  /**
   * Go to the next step
   *
   */
  handleNextStep() {
    this.element.click();
  }

  /**
   * Go back to the previous step
   * @param {ClickEvent} e
   * @return {Void}
   */
  handleGoBack(e) {
    e.preventDefault();
    const { onExit } = this.props;
    const { step } = this.state;
    if (step) {
      this.setState(({ step }) => ({ step: step - 1 }));
    } else {
      onExit();
    }
  }

  /**
   * When user happy with photo.
   * @param {ChangeEvent} e
   */
  handleCapture(e) {
    const { country, frontParams, setFrontIDParams, onNextStep, onGoBack } = this.props;
    const { frontFile, idType, backOfCard, auState } = this.state;
    let params;

    if (!e.target.files[0]) return;
    APIs.status('capturingId');
    const imagefile = e.target.files[0];
    e.target.value = '';

    let idCaptureAttempt = parseInt(getCookie('idCaptureAttempt'), 10);
    idCaptureAttempt = idCaptureAttempt ? idCaptureAttempt + 1 : 1;
    setCookie('idCaptureAttempt', idCaptureAttempt.toString(), 1);

    /* if (!frontFile && backOfCard) {
            this.setState({ backOfCard: true, frontFile: imagefile });
            return;
        } */

    const nCountry = country === 'OTHER' ? 'NZ' : country;

    if (backOfCard && nCountry === 'AU') {
      params = { ...frontParams, backFile: imagefile };
      setFrontIDParams({});
    } else {
      if (idType !== 'PASSPORT' && backOfCard) {
        params = { idType, frontFile, backFile: imagefile };
      } else {
        params = { idType, frontFile: imagefile };
      }

      if (nCountry === 'AU' || idType === 'PASSPORT') {
        setFrontIDParams(params);
      }
    }

    params.flowType = 'AUVOI_FLOW';
    params.cardType = 'other';
    params.state = auState;

    this.setState({ isUploading: true, backOfCard: false });
    APIs.uploadImage(params, {})
      .then(({ status, token, msg: error }) => {
        if (status === 'error') {
          if (this.isComponentMounted) {
            this.setState({
              error,
              isUploading: false
            });
          }
          return;
        }

        this.updateIdTypes(idType);
        onNextStep({ tokenId: token, idType, backOfCard });
      })
      .catch(({ message }) => {
        console.error('msg', message);
        const error = {
          component: Error500,
          props: {
            onTryAgain: onGoBack
          }
        };
        setFrontIDParams({});
        this.setState({ error, isUploading: false, backOfCard: false });
      });
  }

  updateIdTypes = (nidType = null) => {
    let { AUVOI_ID_TYPES } = process.env;
    const { APP_NAME } = process.env;
    if (!AUVOI_ID_TYPES && envPatch[APP_NAME]) {
      AUVOI_ID_TYPES = envPatch[APP_NAME].AUVOI_ID_TYPES;
    }

    let idTypes = AUVOI_ID_TYPES.map(({ label, id: value, backOfCard }) => {
      return {
        value: `${value}=${backOfCard}`,
        label
      };
    });

    const { capturedIds } = this.props;

    const noOfIdCaptured = Object.keys(capturedIds).length;
    if (noOfIdCaptured >= 1) {
      idTypes = idTypes.filter(({ value }) => {
        const parts = value.split('=');
        return !capturedIds[parts[0]];
      });
    }

    // Set IdType value to first of IDTypes
    let idType = idTypes[0].value;
    const parts = idType.split('=');
    [idType] = parts;

    if (nidType) {
      idTypes = idTypes.filter(({ value }) => {
        const parts = value.split('=');
        return parts[0] !== nidType;
      });
      this.props.onIdSelect(nidType);
    } else {
      this.props.onIdSelect(idType, null, 'yes');
    }

    this.setState({
      idType,
      idTypes,
      backOfCard: false,
      isUploading: false,
      error: null,
      frontFile: null,
      tipStep: 0
    });
  };

  /** Handle state change
   * @param obj
   */
  handleStateChange = (obj) => {
    this.setState({
      auState: obj.value
    });
  };

  render() {
    const {
      backOfCard,
      isUploading,
      error,
      idType,
      idTypes,
      tipStep = 0,
      auState,
      stateError
    } = this.state;
    const { component: Error, props: errorProps } = error || {};
    const { capturedIds } = this.props;
    const tips = {
      DL: localizedString('auvoiCaptureTipsDL'),
      PASSPORT: localizedString('auvoiCaptureTipsPassport'),
      AUS_MEDICARE: localizedString('auvoiAusMedicare'),
      AUS_AUTO_BIRTH: localizedString('auvoiAusAutoBirth')
    };

    const tipsStates = {
      idType,
      tips,
      onCapture: this.handleNextStep,
      lastButtonText: localizedString('captureID'),
      step: tipStep
    };

    const footerButtons = [
      {
        label: localizedString('back'),
        variant: 'transparent',
        onClick: this.handleGoBack
      },
      {
        label: localizedString('takePhoto'),
        type: 'submit',
        onClick: () => {
          if (idType === 'AUS_AUTO_BIRTH' && auState === '') {
            this.setState({
              stateError: {
                state: true
              }
            });
            return;
          }

          if (backOfCard) {
            this.element.click();
          } else {
            this.handleNextStep();
          }
        }
      }
    ];

    return (
      <Page buttons={footerButtons}>
        {Error && <Error {...errorProps} />}
        {isUploading && <LoadingSpinner heading={localizedString('viewingYourID')} showTitle />}
        {backOfCard && <BackOfCard idType={idType} onReady={() => this.element.click()} />}
        {!Error && !isUploading && !backOfCard && (
          <IdSelectionContent
            state={auState}
            stateError={stateError}
            onSelectState={this.handleStateChange}
            capturedIds={capturedIds}
            idType={idType}
            idTypes={idTypes}
            onSelect={this.handleSelectIdType}
          />
        )}
        {!backOfCard && <Tips {...tipsStates} />}
        <input
          type="file"
          name="image"
          accept="image/*"
          capture="environment"
          onChange={this.handleCapture}
          ref={(ref) => {
            this.element = ref;
          }}
          style={{ opacity: 0, zIindex: 99 }}
        />
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CaptureOther);

function mapStateToProps({ capture }) {
  return {
    frontParams: capture.frontParams
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    resetIdInfo: () => dispatch(InfoAction.resetIdInfo()),
    setFrontIDParams: (data) => dispatch(CaptureAction.setFrontIDParams(data))
  };
}
