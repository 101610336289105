import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Allow from '@DOC_ONLY_FLOW/components/Contents/FaceScan/Allow';
import { localizedString } from '@languages';
import position from './Animations/position.json';
import awesome from './Animations/awesome.json';
import smile from './Animations/smile.json';
import left from './Animations/left.json';
import right from './Animations/right.json';
import View from './View';

export default class Actions extends Component {
  static propTypes = {
    onComplete: PropTypes.func,
    audio: PropTypes.object,
    timeout: PropTypes.func,
    error: PropTypes.func,
    session: PropTypes.object,
    sessionId: PropTypes.func,
    onGoBack: PropTypes.func
  };

  static defaultProps = {
    onComplete: () => {},
    timeout: () => {},
    error: () => {},
    sessionId: () => {},
    onGoBack: () => {},
    audio: {
      play: () => {},
      pause: () => {}
    }
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.videoContainer = null;
    this.intervalId = null;
    this.intervalProcess = null;
    this.intervalTime = null;
    this.faceInFrameCheckTimeout = null;

    this.handleOnComplete = this.handleOnComplete.bind(this);
    this.handleMuteAudio = this.handleMuteAudio.bind(this);
    this.handleTimeout = this.handleTimeout.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.handleProcess = this.handleProcess.bind(this);
    this.handleRepeat = this.handleRepeat.bind(this);
    this.reset = this.reset.bind(this);
  }

  /**
   * Return the component's initial state
   * @return {Object}
   */
  getInitialState() {
    return {
      step: 0,
      muted: false,
      sessionId: null,
      completed: false,
      prompts: null,

      face: false,
      smile: false,
      smileEnd: false,
      left: false,
      right: false,

      repeat: false,

      animations: {
        smile: false,
        thanks: false,
        left: false,
        right: false
      },

      toPlay: null,
      dire: Math.floor(Math.random() * 2),
      isDetecting: false,
      isLoading: true,
      isRepeating: false,
      timeoutAttempt: false,
      firstTimeCheck: false
    };
  }

  componentDidMount() {
    const { session, sessionId, error } = this.props;

    // Timeout
    this.intervalId = setTimeout(() => {
      this.handleTimeout();
    }, 60 * 1000);

    if (session) {
      const options = {
        localVideo: this.videoContainer,
        mediaConstraints: {
          audio: false,
          video: {
            width: 640,
            framerate: 15,
            facingMode: 'user'
          }
        }
      };
      this.setState({ isLoading: true });
      session
        .start(options, (prompts) => {
          this.setState({ prompts });
          this.handleProcess();
        })
        .then((sessionID) => {
          this.handleRepeat();
          sessionId(sessionID);
        })
        .catch((err) => {
          console.error('Actions(Main): session start failed. Error:', err);
          error(err);
        });
      this.intervalProcess = setInterval(() => {
        this.handleProcess();
      }, 500);
    } else {
      console.warn('Actions(Main): no session found. do nothing');
    }
  }

  /**
   * Handle liveness proccess.
   *
   * @return {Void}
   */
  handleProcess() {
    const { step, dire, face, smile, left, right, animations, prompts, thanks } = this.state;

    if (!prompts) {
      console.warn('Actions(Main): No prompts. do nothing');
      return false;
    }

    // Check if face still in frame
    if (!prompts.f && step < 1) {
      this.reset();
      return false;
    }

    switch (step) {
      case 0:
        // Detect Face
        if (prompts.f && !face) {
          this.setState({
            step: 1,
            face: true,
            repeat: false
          });
          return true;
        }

        return false;
      case 1:
        // Detect Smile
        if (prompts.s && !smile) {
          this.setState({
            step: 2,
            smile: true,
            repeat: false
          });
          clearTimeout(this.intervalTime);
          clearTimeout(this.faceInFrameCheckTimeout);
          return true;
        }

        return false;
      case 2:
        if (!thanks && animations.thanks) {
          this.setState({
            step: 3,
            repeat: false
          });
          this.handleRepeat();
          return true;
        }

        return false;
      case 3:
        // Detect turn head
        if (dire) {
          if (prompts.l && !prompts.s && !left && animations.left) {
            this.setState({
              step: 4,
              left: true,
              repeat: false
            });
            clearTimeout(this.intervalTime);
            return true;
          }
        } else if (prompts.r && !prompts.s && !right && animations.right) {
          this.setState({
            step: 4,
            right: true,
            repeat: false
          });
          clearTimeout(this.intervalTime);
          return true;
        }

        return false;
      default:
        return false;
    }
  }

  handleRepeat(lastOne = false) {
    if (this.intervalTime) {
      clearTimeout(this.intervalTime);
    }
    this.intervalTime = setTimeout(() => {
      if (!this.handleProcess()) {
        if (lastOne) {
          this.handleTimeout();
        } else {
          this.setState({
            isRepeating: true,
            repeat: true
          });
          this.setState({
            isRepeating: false
          });
          this.handleRepeat(true);
        }
      }
    }, 10000);
  }

  handleStart() {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 600);
  }

  /**
   * Time out
   * @return {Void}
   */
  handleTimeout() {
    const { session, timeout } = this.props;
    const { completed, dire } = this.state;
    if (this.intervalTime) {
      clearTimeout(this.intervalTime);
    }
    if (!completed) {
      this.setState({ completed: true });
      clearInterval(this.intervalProcess);
      if (session) session.stop();
      timeout(dire);
    }
  }

  /**
   * To turn off/on the audio prompts.
   *
   * @return {Void}
   */
  handleMuteAudio() {
    const { muted } = this.state;
    const { audio } = this.props;
    if (audio) audio.muted = !muted;
    this.setState({ muted: !muted });
  }

  /**
   * Callback function for when the steps are completed.
   *
   * @return {Void}
   */
  handleOnComplete(type) {
    const { dire } = this.state;
    const { onComplete } = this.props;
    if (!type) {
      this.setState({ completed: true });
      clearInterval(this.intervalProcess);
      onComplete(dire);
      return;
    }

    this.setState(({ animations }) => ({
      animations: {
        ...animations,
        [type]: true
      }
    }));
  }

  handleGoBack() {
    const { audio, session, onGoBack } = this.props;

    clearTimeout(this.intervalId);
    clearTimeout(this.intervalTime);
    clearInterval(this.intervalProcess);
    clearInterval(this.faceInFrameCheckTimeout);

    if (session) {
      session.stop();
    }
    if (audio) {
      audio.pause();
    }
    onGoBack();
  }

  reset(fromTimeOut = false) {
    const resetVals = {
      step: 0,
      face: false,
      smile: false,
      smileEnd: false,
      left: false,
      right: false,
      animations: {
        smile: false,
        thanks1: false,
        left: false,
        right: false,
        thanks2: false
      }
    };

    this.setState(resetVals);
    if (fromTimeOut) {
      console.warn('Actions(Main): Returning false. Case1 From Timeout');
    } else {
      console.warn('Actions(Main): Returning false. Case1');
    }
    return false;
  }

  render() {
    const { audio } = this.props;
    const { step, muted, dire, repeat, isLoading, isRepeating } = this.state;
    const direction = dire ? 'left' : 'right';
    const turnHeadDesc = dire
      ? localizedString('turnHeadToTheLeftNewLine')
      : localizedString('turnHeadToTheRightNewLine');

    const globProps = {
      audio,
      muted,
      repeat,
      onGoBack: () => this.handleGoBack(),
      onMuteAudio: () => this.handleMuteAudio()
    };

    return (
      <div
        style={{
          background: '#000'
        }}
      >
        {isLoading && <Allow />}
        {isLoading && <View load />}
        {!isLoading && !isRepeating && (
          <div>
            {!step && (
              <View {...globProps} animation={position} audioType="position" delay={0} timeout={0}>
                {localizedString('positionFaceInFrameNewLine')}
              </View>
            )}
            {step === 1 && (
              <View
                {...globProps}
                animation={smile}
                audioType="smile"
                timeout={1.8}
                onComplete={() => this.handleOnComplete('smile')}
                delay={0}
              >
                {localizedString('giveABigSmile')}
              </View>
            )}
            {step === 2 && (
              <View
                {...globProps}
                animation={awesome}
                audioType="thanks"
                timeout={1.5}
                onComplete={() => this.handleOnComplete('thanks')}
                delay={0}
              >
                {localizedString('thanks')}
              </View>
            )}
            {step === 3 && (
              <View
                {...globProps}
                timeout={0}
                animation={dire ? left : right}
                audioType={direction}
                dire
                rightSide={!dire}
                onComplete={() => this.handleOnComplete(direction)}
              >
                {turnHeadDesc}
              </View>
            )}
            {step === 4 && (
              <View
                {...globProps}
                animation={awesome}
                audioType="thanks"
                timeout={2}
                onComplete={this.handleOnComplete}
                delay={0}
              >
                {localizedString('thanks')}
              </View>
            )}
          </div>
        )}
        <div className="b-video-container">
          <video
            ref={(ref) => {
              this.videoContainer = ref;
            }}
            onLoadStart={() => this.handleStart()}
            width="100%"
            height="100%"
            autoPlay
            muted
            playsInline
          />
        </div>
      </div>
    );
  }
}
