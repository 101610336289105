import { Message } from '@components';
import React from 'react';
import { isAndroidDevice } from '@js/lib/Utils';
import { localizedString } from '@languages';

const FailedV1 = (props) => {
  return (
    <Message
      title={localizedString('faceVerificationFail')}
      {...props}
      issue={localizedString('failedYourFaceVerification')}
    >
      {localizedString('weWillGiveYou')}
      <b>{localizedString('one')}</b>
      {localizedString('moreAttempt')}
      <div style={{ marginTop: 5, textAlign: 'left', fontSize: '15px' }}>
        - {localizedString('pleaseMakeSureYouGiveA')}
        <b>{localizedString('bigSmileForError')}</b>
        <br />- <b>{localizedString('turnYourHeadSlightly')}</b>
        {localizedString('whenPrompted')}
      </div>
      <div
        style={{
          padding: '20px 20px 0',
          maxWidth: 260,
          margin: 'auto'
        }}
      >
        <img width="100%" src="images/tips/facescan/prompts.png" />
      </div>
    </Message>
  );
};

const FailedV2 = (props) => {
  return (
    <Message
      title={localizedString('faceVerificationNewLine')}
      {...props}
      issue={localizedString('needYouToHaveOneMoreGo')}
    >
      {localizedString('weWillGiveYou')}
      <b>{localizedString('one')}</b>
      {localizedString('moreAttempt')}

      <div style={{ marginTop: 5, textAlign: 'left', fontSize: '15px' }}>
        - {localizedString('pleaseMakeSureYouGiveA')}
        <b>{localizedString('bigSmileForError')}</b>
        <br />- <b>{localizedString('turnYourHeadSlightly')}</b>
        {localizedString('whenPrompted')}
      </div>

      <div
        style={{
          padding: '20px 20px 0',
          maxWidth: 260,
          margin: 'auto'
        }}
      >
        <img alt="" width="100%" src="images/tips/facescan/prompts.png" />
      </div>
    </Message>
  );
};
/**
 * If card blurry or crop etc.
 */
export const Failed = (props) => {
  const { FACE_SCAN_FAILED_VERSION = 0 } = process.env;
  const Components = [FailedV1, FailedV2];

  const Error = Components[FACE_SCAN_FAILED_VERSION];
  return <Error {...props} />;
};

/**
 * If card blurry or crop etc.
 */
export const Permission = (props) => {
  const { SHOW_ENABLE_CAMERA_GRAPHIC = true } = process.env;

  return (
    <Message title={localizedString('enableCamera')} {...props} issue="">
      <div className="b-enable-exception">
        {SHOW_ENABLE_CAMERA_GRAPHIC && (
          <div className="image">
            <img
              alt=""
              width="100%"
              src={`images/exceptions/enable-camera-${isAndroidDevice() ? 'android' : 'ios'}.png`}
            />
          </div>
        )}
        <h3 className="b-title">{localizedString('cameraAccessIsRequired')}</h3>
      </div>
    </Message>
  );
};

export const Incompatible = () => {
  return (
    <Message
      title={localizedString('deviceIncompatible')}
      issue={localizedString('deviceIncompatibleContent')}
    >
      {localizedString('deviceIncompatibleDesc1')}
      <br />
      <br />
      {localizedString('deviceIncompatibleDesc2')}
    </Message>
  );
};

/**
 * Enable camera settings
 */
export const CameraSettings = (props) => {
  const Chrome = (
    <div>
      <p style={{ textAlign: 'left' }}>{localizedString('forMostAndroids')}</p>
      <ol>
        <li>
          {localizedString('gotoYour')}
          <b>{localizedString('settings')}</b>
          {localizedString('appsChromePermissions')}
          {localizedString('gotoYourEnd')}
        </li>
        <li>{localizedString('turnOnCamera')}</li>
        <li>{localizedString('comeBackAndPressTryAgain')}</li>
      </ol>
      <br />
      <p style={{ textAlign: 'left' }}>{localizedString('ifStillNotWorkPleaseFollow')}</p>
      <ol>
        <li>
          {localizedString('inYour')}
          <b>{localizedString('browserMenu')}</b>
          {localizedString('gotoSiteSettingsCameraBlocked')}
        </li>
        <li>{localizedString('tapOnBlockedSiteAndTapClearReset')}</li>
        <li>{localizedString('comeBackAndPressTryAgain')}</li>
      </ol>
    </div>
  );
  return (
    <Message
      title={localizedString('cameraSettings')}
      {...props}
      issue={localizedString('yourSettingsPreventUsFromAccessingYourCamera')}
    >
      {!isAndroidDevice() ? (
        <div>
          <ol>
            <li>
              {localizedString('goTo')}
              <b>{localizedString('settings')}</b>
              {localizedString('safari')}
              {localizedString('goToEnd')}
            </li>
            <li>{localizedString('turnOnCameraMicAccess')}</li>
            <li>{localizedString('comeBackAndPressTryAgain')}</li>
          </ol>
        </div>
      ) : (
        Chrome
      )}
    </Message>
  );
};

/**
 * Retry with alternative flow because of permission issue.
 */
export const PermissionIssueRequiresAlternativeFlow = (props) => {
  return (
    <Message
      title={localizedString('enableCamera')}
      {...props}
      issue={localizedString('unableToGetCameraAccessDueToYourSettings')}
    >
      <div>{localizedString('clickTryAgainBelowToProceed')}</div>
    </Message>
  );
};
