import React, { Component } from 'react';
import classNames from 'classnames';
import { testDevice } from '@lib/Utils';
import { Page } from '@DOC_ONLY_FLOW/components';
import { localizedString } from '@languages';
import classes from './DeviceInfo.style.module.scss';

class DeviceInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { BrowserName, OS, version } = testDevice();

    let Compatibility;
    let toDo;
    if (
      (OS === 'iOS' && BrowserName === 'Mobile Safari' && parseFloat(version) >= 11) ||
      (OS === 'Android' && (BrowserName === 'Samsung Browser' || BrowserName === 'Chrome'))
    ) {
      Compatibility = 'Success';
    } else if (OS === 'iOS' && BrowserName !== 'Mobile Safari') {
      Compatibility = 'Success';
      toDo = 'OPEN_SAFARI';
    } else if (OS === 'Android' && BrowserName !== 'Chrome') {
      Compatibility = 'Success';
      toDo = 'OPEN_CHROME';
    } else {
      toDo = 'ALT_FLOW';
    }

    const btns = [
      {
        label: localizedString('back'),
        onClick: () => {
          [window.location.href] = window.location.href.split('?');
        }
      }
    ];

    return (
      <Page buttons={btns}>
        <div className={classNames(classes.wrapper)}>
          <strong>
            {!toDo ? localizedString('yourDeviceIsFit') : localizedString('yourDeviceIsUnfit')}.{' '}
          </strong>

          <table>
            <tbody>
              <tr>
                <td>{localizedString('browserName')}</td>
                <td>{BrowserName}</td>
              </tr>
              <tr>
                <td>{localizedString('os')}</td>
                <td>{OS}</td>
              </tr>
              <tr>
                <td>{localizedString('version')}</td>
                <td>{version}</td>
              </tr>
              <tr>
                <td>{localizedString('compatibility')}</td>
                <td>{Compatibility}</td>
              </tr>
              <tr>
                <td>{localizedString('todo')}</td>
                <td>{toDo}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Page>
    );
  }
}

export default DeviceInfo;
