import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import classes from './Header.style.module.scss';

const Header = ({ title, onGoBack, fixed, onSelectLanguage }) => {
  const { MULTI_LANGUAGE, FEEDBACK_LINK } = process.env;

  return (
    <header className={classes.warpper} style={{ position: fixed && 'fixed' }}>
      <div className="b-container">
        {onGoBack && (
          <button
            type="button"
            className={classes.backButton}
            onClick={onGoBack}
            data-testid="header-go-back"
          />
        )}
        <h3 className={classes.title} data-testid="header-title">
          {title}
        </h3>

        {MULTI_LANGUAGE && onSelectLanguage && (
          <button
            type="button"
            className={classes['langButton-white']}
            onClick={onSelectLanguage}
            data-testid="header-language"
          />
        )}

        {FEEDBACK_LINK && (
          <a
            className={classes['feedback-link']}
            target="_blank"
            href={FEEDBACK_LINK}
            rel="noreferrer"
          >
            Feedback
          </a>
        )}
      </div>
    </header>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  onGoBack: PropTypes.func,
  /* If its `true` the header position will be fixed */
  fixed: PropTypes.bool,
  onSelectLanguage: PropTypes.func
};

Header.defaultProps = {
  fixed: false
};

export default Header;
