import React from 'react';
import parse from 'html-react-parser';
import { isAndroidDevice } from '@lib/Utils';
import { localizedString } from '@languages';
import { Message } from '../../components';

/**
 * If card blurry or crop etc.
 */
export const Failed = (props) => {
  return (
    <Message {...props} title={localizedString('weNeedYouToTryAgain')} issue>
      {localizedString('letsGiveIt')}
      <b>{localizedString('one')}</b>
      {localizedString('moreTry')}
      <br />
      <br />
      <ol className="oli-style-warn">
        <li>{parse(localizedString('makeSureYouGiveABigSmile'))}</li>
        <li>{parse(localizedString('turnYourHeadSlightlyWhenPrompted'))}</li>
      </ol>
    </Message>
  );
};

/**
 * If card blurry or crop etc.
 */
export const Permission = (props) => {
  return (
    <Message title={localizedString('cannotContinueWithoutYourPermission')} {...props} issue>
      <div>
        {localizedString('needToUserYourCameraAsPartOfTheVerificationProcess')}
        <br />
        <br />
        {localizedString('tappingGiveAccessWillTakeYouBackToThePermissionsPopup')}
      </div>
    </Message>
  );
};

export const Incompatible = (props) => {
  return (
    <Message {...props} issue title={localizedString('deviceIncompatibleContent')}>
      <div>
        {localizedString('deviceIncompatibleDesc1')}
        <br />
        <br />
        {localizedString('deviceIncompatibleDesc2')}
      </div>
    </Message>
  );
};

/**
 * Enable camera settings
 */
export const CameraSettings = (props) => {
  const Chrome = (
    <Message title={localizedString('needToAccessYourCameraToContinue')} {...props} issue={false}>
      <div>
        {localizedString('dueToPhoneSettingsAppRestrictsAccessingCamera')}
        <br />
        {localizedString('howToChangeThis')}
      </div>
      <ol className="oli-style-success">
        <li>{localizedString('gotoSettings')}</li>
        <li>
          {localizedString('choose')}
          <strong>{localizedString('appsNotificationsChromePermissions')}</strong>
          {localizedString('chooseEnd')}
        </li>
        <li>
          {localizedString('turnOn')}
          <strong>{localizedString('cameraInBrackets')}</strong>
          {localizedString('turnOnEnd')}
        </li>
      </ol>
      <br />
      <div>{localizedString('someAndroidsWorkDifferentlyPleaseTry')}</div>
      <ol className="oli-style-default">
        <li>
          {localizedString('inYourBrowserMenuGoTo')}
          <strong>{localizedString('inYourBrowserMenuSettings')}</strong>
        </li>
        <li>
          {localizedString('choose')}
          <strong>{localizedString('settingsCameraBlocked')}</strong>
          {localizedString('chooseEnd')}
        </li>
        <li>
          {localizedString('turnOn')}
          <strong>{localizedString('cameraInBrackets')}</strong>
          {localizedString('turnOnEnd')}
        </li>
      </ol>
      <div>{localizedString('onceYouDoneComeBackContinue')}</div>
    </Message>
  );

  const Apple = (
    <Message title={localizedString('needToAccessYourCameraToContinue')} {...props} issue={false}>
      <div>
        {localizedString('dueToPhoneSettingsAppRestrictsAccessingCamera')}
        <br />
        {localizedString('howToChangeThis')}
      </div>
      <ol className="oli-style-success">
        <li>{localizedString('gotoSettings')}</li>
        <li>{localizedString('chooseSafari')}</li>
        <li>
          {localizedString('turnOn')}
          <strong>{localizedString('cameraMicAccess')}</strong>
          {localizedString('turnOnEnd')}
        </li>
      </ol>
    </Message>
  );

  return !isAndroidDevice() ? Apple : Chrome;
};

/**
 * Retry with alternative flow because of permission issue.
 */
export const PermissionIssueRequiresAlternativeFlow = (props) => {
  return (
    <Message
      title={localizedString('enableCamera')}
      {...props}
      issue={localizedString('unableToGetCameraAccessDueToYourSettings')}
    >
      <div>{localizedString('clickTryAgainBelowToProceed')}</div>
    </Message>
  );
};
