import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { Input, Checkbox, Select } from '@DOC_ONLY_FLOW/components';
import classNames from 'classnames';

import Address from '@services/Address';
import countries from '@data/countries.json';
import anzData from '@data/anz-fields.json';
import { localizedString } from '@languages';
import { ADDRESS_SEARCH_DEBOUNCED_DELAY_IN_MS } from '@lib/constants/addressSearchDebouncedDelay';
import classes from './VerifyAddress.style.module.scss';

export default class VerifyAddress extends Component {
  static propTypes = {
    data: PropTypes.object,
    errors: PropTypes.object,
    onChange: PropTypes.func,
    countryCode: PropTypes.string,
    manually: PropTypes.bool
  };

  static defaultProps = {
    data: {},
    errors: {},
    onChange: () => null
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.handleChange = debounce(
      this.handleChange.bind(this),
      ADDRESS_SEARCH_DEBOUNCED_DELAY_IN_MS
    );
    this.handleSelect = this.handleSelect.bind(this);
  }

  /**
   * Return the component's initial state
   *
   */
  getInitialState() {
    const { ADDRESS_CUSTOM_FIELDS = [] } = process.env;
    const { data = {}, countryCode } = this.props;

    const {
      homeAddress,
      atAddress,
      prevAddress,
      addressLine1,
      addressLine2,
      city,
      suburb,
      postcode,
      country,
      isMatch
    } = data;

    const state = {
      homeAddress,
      atAddress,
      prevAddress,
      address: homeAddress,
      addresses: {
        address: [],
        prevAddress: []
      },
      isMatch: countryCode === 'OTHER' ? false : isMatch,
      detailedAddress: {
        addressLine1,
        addressLine2,
        city,
        suburb,
        postcode,
        country
      },
      filteredCountries: [],
      addressApiCalls: 0
    };

    if (ADDRESS_CUSTOM_FIELDS.length > 0) {
      ADDRESS_CUSTOM_FIELDS.forEach((field) => {
        if (field.id === 'citizenship') {
          state[field.id] = 'Australia';
        } else {
          state[field.id] = data[field.id] || '';
        }
      });
    }

    return state;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { countryCode } = this.props;
    const isMatch = countryCode === 'OTHER' ? false : nextProps.data.isMatch;
    this.setState({
      isMatch
    });
  }

  /**
   * Handle the change of detailed address in case match failed
   */
  handleChangeAddress = () => {
    const { atAddress, detailedAddress, prevAddress, isMatch, addressApiCalls } = this.state;
    const data = {
      ...detailedAddress,
      atAddress,
      homeAddress: '',
      prevAddress,
      isMatch,
      addressApiCalls: addressApiCalls + 1
    };

    this.props.onChange(data);
  };

  /**
   * Handle Custom  Field Changes
   * @return
   */
  handleCustomFieldChanges = (id, value) => {
    const { ADDRESS_CUSTOM_FIELDS = [] } = process.env;

    const state = { ...this.state };
    state[id] = value;

    this.setState(state);

    const { atAddress, homeAddress, detailedAddress, prevAddress, isMatch, addressApiCalls } =
      state;

    const data = {
      ...detailedAddress,
      atAddress,
      homeAddress,
      prevAddress,
      isMatch,
      addressApiCalls: addressApiCalls + 1
    };

    if (ADDRESS_CUSTOM_FIELDS.length > 0) {
      const temp = [];
      ADDRESS_CUSTOM_FIELDS.forEach((field) => {
        temp[field.id] = state[field.id];
      });
      data.customFields = temp;
    }

    this.props.onChange(data);
  };

  /**
   * Handle the change of the address
   * @param id
   * @param  {String} value
   * @return {Void}
   */
  handleChange(id, value) {
    const { countryCode, onChange } = this.props;
    const { addressApiCalls } = this.state;
    let addressApiCallsN = addressApiCalls;

    const increaseApiCalls =
      (Address.ALLOWED_COUNTRIES.includes(countryCode) &&
        (Address.API_KEY || Address.ADDY_API_KEY)) ||
      (countryCode && Address.EXPERIAN_API_KEY);
    if (increaseApiCalls) {
      ++addressApiCallsN;
      this.setState({
        addressApiCalls: addressApiCallsN
      });
    }

    Address.find(value, countryCode).then((addresses) => {
      const { atAddress, homeAddress, prevAddress, detailedAddress, isMatch } = this.state;
      const data = {
        ...detailedAddress,
        atAddress,
        homeAddress,
        prevAddress,
        isMatch,
        addressApiCalls: increaseApiCalls ? addressApiCallsN + 1 : addressApiCallsN
      };

      const detailedAddresss = { ...detailedAddress };
      data.isMatch = true;
      this.setState({
        addresses: { [id]: addresses, detailedAddress: detailedAddresss }
      });

      onChange(data);
    });
  }

  /**
   * Handle select the address
   * @param id
   * @param  {String} address
   * @param format
   * @return {Void}
   */
  handleSelect(id, address, format) {
    const { onChange } = this.props;
    const { atAddress, isMatch, homeAddress, prevAddress, addressApiCalls } = this.state;
    const data = {
      atAddress,
      homeAddress,
      prevAddress,
      isMatch,
      addressApiCalls: addressApiCalls + 1
    };

    if (id === 'address') {
      if (format) {
        Address.verifyExperian(format).then(({ fullAddress, ...detailedAddress }) => {
          this.setState(({ addresses }) => ({
            homeAddress: address,
            address,
            addresses: { ...addresses, address: [] },
            detailedAddress
          }));
          data.homeAddress = address;
          data.addressApiCalls += 1;

          Object.keys(detailedAddress).forEach((key) => {
            data[key] = detailedAddress[key];
          });
        });
      } else {
        this.setState(({ addresses }) => ({
          homeAddress: address,
          address,
          addresses: { ...addresses, address: [] }
        }));
        data.homeAddress = address;
      }
    } else {
      this.setState(({ addresses }) => ({
        prevAddress: address,
        addresses: { ...addresses, prevAddress: [] }
      }));
      data.prevAddress = address;
    }

    onChange(data);
  }

  /**
   * Generate custom fields
   * @return
   */
  generateCustomFields = () => {
    const { errors } = this.props;
    const { ADDRESS_CUSTOM_FIELDS = [] } = process.env;
    if (ADDRESS_CUSTOM_FIELDS.length > 0) {
      return ADDRESS_CUSTOM_FIELDS.map((field) => {
        const { id, label, isAutoComplete } = field;

        const options = anzData[id].map((item) => {
          return {
            name: item
          };
        });

        if (isAutoComplete) {
          return (
            <Select
              key={id}
              id={id}
              label={label}
              className={classNames(classes.input)}
              hasError={errors[id]}
              options={options}
              onChange={(value) => {
                this.handleCustomFieldChanges(id, value);
              }}
              value={this.state[id]}
            />
          );
        }
        return (
          <Input
            type="text"
            key={id}
            id={id}
            label={label}
            className={classNames(classes.input)}
            hasError={errors[field.id]}
            onChange={(value) => {
              this.handleCustomFieldChanges(id, value);
            }}
            value={this.state[id]}
          />
        );
      });
    }

    return null;
  };

  /**
   * Generate additional fields for detailed address
   * @return
   */
  generateAddressFields() {
    const { errors } = this.props;
    const { detailedAddress } = this.state;
    const fields = [
      { id: 'addressLine1', label: localizedString('addressLine1') },
      { id: 'addressLine2', label: localizedString('addressLine2') },
      { id: 'city', label: localizedString('city') },
      { id: 'suburb', label: localizedString('suburb') },
      { id: 'postcode', label: localizedString('postcode') },
      { id: 'country', label: localizedString('country'), isAutoComplete: true }
    ];

    return fields.map((field) => {
      const { id, label, isAutoComplete } = field;

      if (isAutoComplete) {
        return (
          <Select
            key={id}
            id={id}
            label={label}
            className={classNames(classes.input)}
            hasError={errors[field.id]}
            options={countries}
            onChange={async (countryValue) => {
              const { detailedAddress } = this.state;
              detailedAddress[id] = countryValue;
              await this.setState({
                detailedAddress
              });
              this.handleChangeAddress();
            }}
            value={detailedAddress[id]}
          />
        );
      }
      return (
        <Input
          type="text"
          key={id}
          id={id}
          label={label}
          className={classNames(classes.input)}
          hasError={errors[field.id]}
          onChange={(value) => {
            const detailedAddr = { ...detailedAddress };
            detailedAddr[id] = value;
            this.setState({
              detailedAddress: detailedAddr
            });

            this.handleChangeAddress();
          }}
          value={detailedAddress[id]}
        />
      );
    });
  }

  render() {
    const { errors, manually, onChange } = this.props;
    const { AD_ADDRESS } = process.env;
    const { homeAddress, prevAddress, addresses, atAddress } = this.state;

    const addressesList = (id, data) => {
      if (data && data.length) {
        return (
          <ul className={classes.addresses}>
            {data.map(({ a, full_address: fullAddress, text, format = null }, index) => (
              <li
                onClick={() => this.handleSelect(id, a || fullAddress || text, format)}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                {a || fullAddress || text}
              </li>
            ))}
          </ul>
        );
      }
      return null;
    };

    return (
      <div className={classNames(classes.wrapper, 'b-container')}>
        <div className={classes.title}>{localizedString('pleaseEnterOrConfirmAddress')}</div>
        {!manually && (
          <Input
            type="textarea"
            id="homeAddress"
            required
            label={localizedString('homeAddress')}
            hasError={errors.homeAddress}
            onChange={(value) => {
              this.setState({ homeAddress: value });
              if (value && value.trim() !== (homeAddress || '').trim()) {
                this.handleChange('address', value);
              }
            }}
            value={homeAddress}
          />
        )}
        {!manually && addressesList('address', addresses.address)}

        {manually && this.generateAddressFields()}
        {AD_ADDRESS && (
          <div className={classes.atAddress}>
            <Checkbox
              id="atAddress"
              onChange={(atAddress) => {
                this.setState({ atAddress });
                onChange(this.state);
              }}
              label={localizedString('haveYourBeenHereFor3Months')}
            />
          </div>
        )}
        {atAddress && (
          <Input
            type="textarea"
            id="prevAddress"
            label={localizedString('prevHomeAddress')}
            onChange={(value) => {
              this.setState({ prevAddress: value });
              this.handleChange('prevAddress', value);
            }}
            value={prevAddress}
          />
        )}
        {addressesList('prevAddress', addresses.prevAddress)}

        {/* ----Custom fields----*/}
        {this.generateCustomFields()}
      </div>
    );
  }
}
