import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Liveness from 'liveness-js';
import { Prepare, Actions, Allow, Animation } from '@DOC_ONLY_FLOW/components/Contents/FaceScan';
import APIs from '@js/services/APIs';
import { initAudio, setCookie, getCookie } from '@js/lib/Utils';
import { localizedString } from '@languages';
import { getVoicePromptInfo } from '@lib/Audio';
import {
  Failed,
  Permission,
  Incompatible,
  CameraSettings,
  PermissionIssueRequiresAlternativeFlow
} from './FaceScan.errors';
import { Message } from '../../components';

class FaceScan extends Component {
  static propTypes = {
    onNextStep: PropTypes.func,
    onGoBack: PropTypes.func,
    idType: PropTypes.string,
    tokenId: PropTypes.string,
    location: PropTypes.string,
    countryCode: PropTypes.string,
    appConfig: PropTypes.object
  };

  static defaultProps = {
    onNextStep: () => {},
    idType: '',
    countryCode: 'NZ'
  };

  constructor(props, context) {
    super(props, context);

    this.state = this.getInitialState();

    this.audioContainer = null;

    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleStartRecord = this.handleStartRecord.bind(this);
    this.handleFailed = this.handleFailed.bind(this);
    this.handlePromptError = this.handlePromptError.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
  }

  /**
   * Return the component's initial state
   * @return {Object}
   */
  getInitialState() {
    return {
      step: 0,
      totalSteps: 6,
      isLoading: false,
      isVerifying: false,
      videoId: null,
      error: null,
      message: null,
      audio: null,
      session: null,
      sessionId: null,
      completed: false,
      permissionDenied: false
    };
  }

  componentDidMount() {
    APIs.status('faceScanTips');
  }

  /**
   * Go to the next step
   * @return {Void}
   */
  handleNextStep() {
    const { totalSteps, step } = this.state;
    if (step < totalSteps - 1) {
      this.setState(({ step }) => ({ step: step + 1 }));
    }
  }

  /**
   * Go back to the previous step
   * @return {Void}
   */
  handleGoBack() {
    const { onGoBack } = this.props;
    const { step } = this.state;
    if (step) {
      this.setState(({ step }) => ({ step: step - 1 }));
    } else {
      onGoBack();
    }
  }

  /**
   * Trigger the events to start recording
   * @return {Void}
   */
  handleStartRecord() {
    const transToken = document.querySelector('body').getAttribute('data-id');
    const audio = initAudio(this.audioContainer);
    let { WSS_URL } = process.env;
    const { appConfig = {} } = this.props;

    WSS_URL = appConfig.WSS_URL ? appConfig.WSS_URL : WSS_URL;

    let wss;
    if (WSS_URL.includes('liveness.idkit.io')) {
      const token = transToken;
      wss = `${WSS_URL}/${Liveness.guuid(30)}/${token}`;
    } else {
      wss = `${WSS_URL}/${Liveness.guuid(30)}`;
    }

    APIs.status('faceCapture');
    this.setState({ isLoading: true, audio });
    Liveness.createSession(wss)
      .then((session) => {
        this.setState(({ step }) => ({
          step: step + 1,
          session,
          isLoading: false
        }));
      })
      .catch((error) => {
        console.error('liveness session creation failed', error);
        this.handlePromptError(error);
      });
  }

  /**
   * Face verification failed
   * @return {Void}
   */
  handlePromptError(error) {
    console.error('Error: ', { error });
    if (
      error.message &&
      String(error.message)
        .toLowerCase()
        .match(/permission|allowed/i)
    ) {
      let retry = parseInt(getCookie('retry'), 10);
      if (!retry) {
        retry = 0;
      }

      if (retry < 2) {
        retry += 1;
        setCookie('retry', retry.toString(), 1);

        const Error = retry > 1 ? CameraSettings : Permission;

        setCookie('_permission', 2, 1);
        this.setState({
          error: {
            component: Error,
            props: {
              buttons: [
                {
                  label: localizedString('tryAgain'),
                  large: true,
                  shadow: true,
                  onClick: () => this.setState({ error: null, step: 2 })
                }
              ]
            }
          },
          step: 0,
          permissionDenied: true
        });
      } else {
        // Should restart with alternate flow
        setCookie('retry', retry.toString(), 1);
        setCookie('_permission', 2, 1);
        this.setState({
          error: {
            component: PermissionIssueRequiresAlternativeFlow,
            props: {
              buttons: [
                {
                  label: localizedString('tryAgain'),
                  large: true,
                  shadow: true,
                  onClick: () => {
                    const url = `${window.location.href}?flow=alternate`;
                    window.location.replace(url);
                  }
                }
              ]
            }
          },
          step: 0,
          permissionDenied: true
        });
      }

      return;
    }

    if (error.message && String(error.message).match(/not supported/i)) {
      this.setState({
        error: {
          component: Incompatible,
          props: {}
        }
      });
      return;
    }

    this.setState({
      error: {
        component: Message,
        props: {
          issue: true,
          title: 'An error has occurred',
          children: 'Press "Try Again" to reattempt.',
          buttons: [
            {
              label: 'Try again',
              shadow: true,
              onClick: () => this.setState(this.getInitialState()),
              dataTestId: 'btn-try-again'
            }
          ]
        }
      }
    });
  }

  /**
   * Face verification failed
   * @return {Void}
   */
  handleFailed(lr) {
    const { session, sessionId, completed, permissionDenied } = this.state;
    if (permissionDenied) return;

    if (completed) {
      return;
    }
    console.error('liveness failed', { sessionId });
    APIs.status('livenessFail');

    const { onNextStep } = this.props;
    let attempt = parseInt(getCookie('retryAttempt'), 10) || 0;

    if (attempt) {
      session.stop();
      onNextStep({ sessionId, lr, liveness: false });
      setCookie('retryAttempt', null, -1);
      return;
    }

    const buttons = [
      {
        label: localizedString('tryAgain'),
        large: true,
        shadow: true,
        onClick: () => {
          attempt += 1;
          setCookie('retryAttempt', attempt.toString(), 1);

          this.setState({
            error: null,
            step: 0
          });
        }
      }
    ];

    this.setState({ error: { component: Failed, props: { buttons } } });
    /**
     * Upload face scan video.
     */
    APIs.uploadVideo(
      { id: sessionId, attempt: 1, actions: `Smile, Turn head ${lr ? 'left' : 'right'}` },
      null,
      '/api/v4'
    );
  }

  /**
   * Trigger the events to complete face scan
   * @return {Void}
   */
  handleComplete(lr) {
    const { onNextStep } = this.props;
    const { session, sessionId } = this.state;
    session.stop();
    onNextStep({ sessionId, lr, liveness: true });
  }

  render() {
    const { step, audio, isLoading, session, error, completed } = this.state;
    const { component: Error, props: errorProps } = error || {};

    const voiceInfo = getVoicePromptInfo();

    return (
      <div>
        {Error && <Error {...errorProps} />}
        {isLoading && <Allow />}
        {!Error && !completed && (
          <div>
            {!step && <Prepare onGoBack={this.handleGoBack} onNextStep={this.handleNextStep} />}
            {step === 1 && <Animation isLoading={isLoading} onStart={this.handleStartRecord} />}
            {step === 2 && (
              <Actions
                onComplete={this.handleComplete}
                timeout={this.handleFailed}
                session={session}
                onGoBack={this.handleGoBack}
                sessionId={(sessionId) => this.setState({ sessionId })}
                audio={audio}
                error={this.handlePromptError}
              />
            )}
          </div>
        )}
        <audio
          ref={(ref) => {
            this.audioContainer = ref;
          }}
        >
          {voiceInfo.ogg && <source src={voiceInfo.ogg} type="audio/ogg" />}
          {voiceInfo.mp3 && <source src={voiceInfo.mp3} type="audio/mpeg" />}
        </audio>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(FaceScan);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ appConfig }) {
  return {
    appConfig
  };
}
