export default class Edited {
  static SET_EDITED_FIELDS = 'SET_EDITED_FIELDS';

  static setEditedFields(data) {
    return {
      type: this.SET_EDITED_FIELDS,
      data
    };
  }
}
