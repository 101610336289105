import React from 'react';
import PropTypes from 'prop-types';
import { Page } from '@components';
import { localizedString } from '@languages';
import classes from './Prepare.style.module.scss';

const Prepare = ({ onNextStep, onGoBack }) => {
  const buttons = [
    {
      label: localizedString('next'),
      large: true,
      shadow: true,
      onClick: () => onNextStep(),
      dataTestId: 'face-next'
    }
  ];
  return (
    <Page buttons={buttons} onGoBack={onGoBack} title="Face verification">
      <div className={classes.wrapper}>
        <div className="b-title" data-testid="face-instruction-1">
          {localizedString('goingToPerformFaceVerify')}
        </div>
        <div className={classes.dontHave}>
          <div data-testid="face-instruction-2">
            {localizedString('dontHaveAnythingCoveringFacePart1')}
          </div>
          <img
            alt=""
            src="images/tips/facescan/no-hat-classes.png"
            data-testid="face-instruction-img"
          />
          <span data-testid="face-instruction-3">
            {localizedString('dontHaveAnythingCoveringFacePart2')}
          </span>
        </div>
      </div>
    </Page>
  );
};

Prepare.propTypes = {
  onNextStep: PropTypes.func,
  onGoBack: PropTypes.func
};

Prepare.defaultProps = {
  onNextStep: () => {}
};

export default Prepare;
