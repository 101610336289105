import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LANGUAGES, getDefaultLanguage } from '@languages';

import classes from './Language.style.module.scss';

/**
 * Identify flow guide - How many applicants
 */
export default class Language extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.selected
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selected } = this.state;
    if (nextProps.selected && selected !== nextProps.selected) {
      this.setState({
        selected: nextProps.selected
      });
    }
  }

  toggle = (event) => {
    const { onClose } = this.props;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    onClose(this.state.selected);
  };

  selectedLanguage = (event, language) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.setState({ selected: language });
  };

  render() {
    const defaultLanguage = getDefaultLanguage();

    let { selected } = this.state;

    if (!selected) {
      selected = defaultLanguage.name;
    }

    // 'selected' must not be NULL or Undefined
    selected = LANGUAGES.find((item) => item.name === selected);

    return (
      <div className={classes.wrapper} onClick={this.toggle}>
        <div className={classes['language-selector-content']}>
          <div className={classes.title}>
            Language
            <img src="images/icons/svg/icon_close.svg" alt="" onClick={this.toggle} />
          </div>

          {LANGUAGES.map((item, index) => {
            const isSelected = item.name === selected.name;
            const selectedImgUrl = isSelected
              ? 'images/icons/svg/icon_language_select.svg'
              : 'images/icons/svg/icon_language_deselect.svg';

            const itemClassName = `language-item-container${index === 0 ? '-first' : ''}`;

            return (
              <div
                className={classes[itemClassName]}
                key={item.name}
                onClick={(e) => this.selectedLanguage(e, item.name)}
              >
                <img src={selectedImgUrl} alt="" />
                <div className={classes['language-title']}>{item.title}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

Language.propTypes = {
  selected: PropTypes.any,
  onClose: PropTypes.func
};
