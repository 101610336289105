/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'calidation';
import uniq from 'lodash/uniq';

import InfoAction from '@store/actions/information';
import CaptureAction from '@store/actions/capture';
import EditedAction from '@store/actions/edited';
import Address from '@services/Address';

import { getAge, getCookie, setCookie, validateDate, getResAddressValue } from '@lib/Utils';
import { localizedString } from '@languages';
import APIs from '@services/APIs';

import { isAgeEligible } from '@lib/utils/ageEligibility';
import {
  isPassportExpiredBy2Years,
  isEligibleForPassportExpiredLogic,
  isDocumentExpired
} from '@lib/utils/checkExpiredDocument';
import {
  CouldNotRead,
  CouldNotRecognise,
  ExpiredID,
  ExpiredIDBy2Years,
  ConfirmAge,
  Under18,
  ConfirmConsent,
  ConfirmGoBack,
  FaceNotDetectedInId,
  Recapture,
  IdNumberNotEditable,
  TooManyRetryAttempts
} from './VerifyDetails.errors';
import { Error500 } from '../../errors';
import { VerifyDetailsContent } from '../../components/Contents';
import { Page, LoadingSpinner, Modal } from '../../components';

class VerifyDetails extends Component {
  static propTypes = {
    onNextStep: PropTypes.func,
    onExit: PropTypes.func,
    onSubmit: PropTypes.func,
    retake: PropTypes.func,
    idType: PropTypes.string,
    token: PropTypes.string,
    countryCode: PropTypes.string,
    location: PropTypes.string,
    verify: PropTypes.bool,
    flowType: PropTypes.string,
    idDetails: PropTypes.object,
    addresses: PropTypes.object,
    frontParams: PropTypes.object,
    captureBack: PropTypes.func,
    setFrontIDParams: PropTypes.func,
    appConfig: PropTypes.object,
    setAddress: PropTypes.func,
    setIdInfo: PropTypes.func,
    onGoBack: PropTypes.func,
    setEditedFields: PropTypes.func
  };

  static defaultProps = {
    onNextStep: () => null,
    onExit: () => null,
    retake: () => null,
    idType: 'NZL_DRIVERLICENCE',
    countryCode: 'NZ',
    token: '',
    verify: false
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState(props);

    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  /**
   * Return the component's initial state
   * @return {Object}
   */
  getInitialState(props) {
    const { editedFields } = props;
    return {
      data: {
        checkConfirm: false,
        dateOfBirth: '',
        expiryDate: ''
      },
      extracted: {},
      isLoading: true,
      isWaiting: false,
      /* disable primary button */
      disabled: true,
      confirmed: false,
      error: null,
      changes: editedFields,
      loading: false,
      countryOfIssue: '',
      readOnly: true,
      showDetailed: false,
      hasFormErrors: false,
      showConfirmLeaveEditing: false,
      showTooManyEditsModal: false,
      showNoMiddleNameModal: false,
      backup: null,
      addressApiCalls: 0,
      useIdNumber: false
    };
  }

  /**
   * Call get card information API
   * @return {Void}
   */
  componentDidMount() {
    const {
      token,
      idDetails,
      addresses,
      idType,
      verify,
      frontParams,
      countryCode,
      captureBack,
      setFrontIDParams,
      retake
    } = this.props;

    if (idDetails) {
      this.setState({
        countryOfIssue: idDetails.countryOfIssue,
        showDetailed: idDetails.showDetailed,
        extracted: idDetails
      });
    }

    const { data } = this.state;

    const { ENABLE_VISA_AFTER_PASSPORT = false, APP_VERSION } = process.env;
    const ENABLE_CONSENT_CHECKBOX = true;
    if (!ENABLE_CONSENT_CHECKBOX) {
      const newData = { ...data };
      data.checkConfirm = true;
      this.setState({
        data: newData
      });
    }

    if (!idDetails && token) {
      const noBack = idType === 'PASSPORT' || idType === 'ID_CARD';

      APIs.extractIdentifyInfo(token, noBack)
        .then(async ({ data: cardData, code, countryOfIssue, useIdNumber = false }) => {
          this.setState({
            countryOfIssue,
            extracted: cardData
          });

          if (frontParams.idType) {
            if (
              APP_VERSION >= 3 &&
              (cardData.cardType === 'AUS_QLD_DRIVERLICENCE' ||
                cardData.cardType === 'AUS_VIC_DRIVERLICENCE')
            ) {
              captureBack('back');
              return;
            }
            if (APP_VERSION < 3 && cardData.cardType === 'AUS_QLD_DRIVERLICENCE') {
              captureBack('back');
              return;
            }
            if (
              (cardData.cardType === 'PASSPORT' || cardData.cardType === 'MRZ') &&
              ENABLE_VISA_AFTER_PASSPORT &&
              cardData.countryOfIssue &&
              cardData.countryOfIssue.toUpperCase() !== 'NEW ZEALAND' &&
              cardData.countryOfIssue.toUpperCase() !== 'AUSTRALIA'
            ) {
              captureBack('visa');
              return;
            }
          }
          const buttons = [
            {
              label: localizedString('back'),
              variant: 'transparent',
              onClick: () => {
                setFrontIDParams({});
                retake();
              }
            },
            {
              label: localizedString('tryAgain'),
              onClick: () => {
                setFrontIDParams({});
                retake();
              }
            }
          ];

          if (code === '210') {
            const error = {
              component: CouldNotRecognise,
              props: { buttons }
            };
            this.setState({
              error,
              isLoading: false
            });
            return;
          }

          if (code === '230') {
            const error = {
              component: CouldNotRecognise,
              props: { buttons }
            };
            this.setState({
              error,
              isLoading: false
            });
            return;
          }

          if (code === '223') {
            const error = {
              component: FaceNotDetectedInId,
              props: { buttons }
            };
            this.setState({
              error,
              isLoading: false
            });
            return;
          }

          if (code !== '200') {
            const error = {
              component: CouldNotRead,
              props: { buttons }
            };
            this.setState({
              error,
              isLoading: false
            });
            return;
          }

          const temp = { ...data, ...cardData };
          // validate dates
          temp.dateOfBirth = validateDate(temp.dateOfBirth) ? temp.dateOfBirth : '';
          temp.expiryDate = validateDate(temp.expiryDate) ? temp.expiryDate : '';

          // format dates
          temp.dateOfBirth = this.formatDay(temp.dateOfBirth);
          temp.expiryDate = this.formatDay(temp.expiryDate);

          // Validate Address
          if (cardData.address) {
            const addresses = await Address.find(cardData.address, countryCode, true);
            const matched = addresses && addresses.length > 0;
            if (matched) {
              if (!matched) {
                // delete temp.address
                temp.addressData = {
                  homeAddress: '',
                  isMatch: false
                };
              } else {
                temp.addressData = {
                  homeAddress: addresses[0].full_address || addresses[0].a || addresses[0].text,
                  isMatch: true
                };
              }
            } else {
              temp.addressData = {
                homeAddress: temp.address,
                isMatch: false
              };
            }
          }

          // Validate Address
          if (!verify) {
            APIs.status('reviewInfo');
            this.setState({
              isLoading: false,
              data: temp,
              addressApiCalls: 1,
              useIdNumber
            });
          } else {
            this.setState({
              isLoading: false,
              isWaiting: true,
              data: temp,
              addressApiCalls: 1,
              useIdNumber
            });

            APIs.status('checkDocument').then(() => {
              APIs.checkApproval().then((status) => {
                this.setState({ isWaiting: false });
                if (status !== 'approved') {
                  const error = {
                    component: Recapture,
                    props: {
                      buttons: [
                        {
                          label: localizedString('recapture'),
                          onClick: () => {
                            setFrontIDParams({});
                            retake();
                          }
                        }
                      ]
                    }
                  };
                  this.setState({ error, isLoading: false });
                  return;
                }
                APIs.status('reviewInfo');
              });
            });
          }
        })
        .catch((e) => {
          console.error(e);
          const error = {
            component: Error500,
            props: {
              onTryAgain: () => {
                setFrontIDParams({});
                retake();
              }
            }
          };
          this.setState({ error, isLoading: false });
        });
    } else {
      this.setState({ isLoading: false, data: { ...idDetails, addressData: addresses } });
    }
  }

  /**
   * Handle the continue button
   * @param {ClickEvent} e Event
   * @return {Void}
   */
  async handleConfirm() {
    const {
      appConfig,
      setFrontIDParams,
      retake,
      setAddress,
      setIdInfo,
      onGoBack,
      onNextStep,
      onSubmit
    } = this.props;
    const { data: newData, changes, hasFormErrors, showDetailed, addressApiCalls = 0 } = this.state;
    const data = { ...newData, edited: changes };
    const { REMOVE_ADDRESS_REVIEW = false } = process.env;
    const { checkConfirm } = data;
    const btnOk = [
      {
        label: localizedString('ok'),
        onClick: () => this.setState({ error: null })
      }
    ];

    if (!checkConfirm) {
      const error = {
        component: ConfirmConsent,
        props: {
          buttons: btnOk
        }
      };
      this.setState({
        error
      });
      return;
    }
    if (hasFormErrors) {
      return;
    }
    const { dateOfBirth, expiryDate, cardType, countryCode } = data;

    const { ENABLE_CONFIRM_AGE = true } = process.env;
    const age = getAge(dateOfBirth, true);
    if (ENABLE_CONFIRM_AGE && !isAgeEligible(age)) {
      const error = {
        component: ConfirmAge,
        props: {
          buttons: [
            {
              label: localizedString('no'),
              variant: 'transparent',
              onClick: () => this.setState({ error: null })
            },
            {
              label: localizedString('yes'),
              onClick: () => {
                const error = {
                  component: Under18,
                  props: {}
                };
                this.setState({ error });
              }
            }
          ]
        }
      };
      this.setState({ error });
      return;
    }

    const isPassportExpiredBy2YearsFlag = isPassportExpiredBy2Years(expiryDate, {
      appConfig,
      cardType,
      countryCode
    });
    const isDocumentExpiredFlag = isDocumentExpired(expiryDate);
    const isExpired = isEligibleForPassportExpiredLogic(appConfig, cardType, countryCode)
      ? isPassportExpiredBy2YearsFlag
      : isDocumentExpiredFlag;

    const error = {
      component: isPassportExpiredBy2YearsFlag ? ExpiredIDBy2Years : ExpiredID,
      props: {
        buttons: [
          {
            label: localizedString('tryAgain'),
            variant: 'transparent',
            onClick: () => {
              setFrontIDParams({});
              retake();
            }
          },
          {
            label: localizedString('notExpired'),
            onClick: () => this.setState({ error: null })
          }
        ]
      }
    };

    if (isExpired) {
      this.setState({ error });
      return;
    }

    if (REMOVE_ADDRESS_REVIEW || data.address) {
      let { addressData } = data;
      delete data.addressData;
      // Store details
      const { location, countryCode, flowType, appConfig } = this.props;

      this.setState({ loading: true });

      const addressApiCalls1 = addressData.addressApiCalls || 0;
      addressData.addressApiCalls = addressApiCalls1 + addressApiCalls;

      addressData.fullAddress = showDetailed
        ? getResAddressValue(addressData)
        : addressData.homeAddress;

      if (!showDetailed) {
        // Validate the address
        const { addressData: addrDataValidated } = await Address.verify(
          addressData.homeAddress,
          countryCode,
          appConfig.dataProvider
        );
        addressData = {
          ...addressData,
          ...addrDataValidated,
          addressApiCalls: addressData.addressApiCalls + 1
        };
      } else {
        addressData = { ...addressData, manual: true };
      }

      setAddress({ ...addressData, showDetailed });
      setIdInfo({ ...data, ...addressData, showDetailed });

      const params = {
        ...data,
        addressData,
        location,
        flowType,
        countryCode: countryCode === 'OTHER' ? 'NZ' : countryCode
      };

      onSubmit(params)
        .then(({ status, type, msg }) => {
          this.setState({ loading: false });
          if (status !== 'success') {
            if (type === 'cards') {
              this.setState({
                error: {
                  issue: msg,
                  buttons: [
                    {
                      label: localizedString('cancel'),
                      onClick: () => onGoBack()
                    }
                  ]
                }
              });
            } else {
              this.setState({
                error: {
                  issue: msg,
                  buttons: [
                    {
                      label: localizedString('cancel'),
                      onClick: () =>
                        this.setState({
                          error: null
                        })
                    }
                  ]
                }
              });
            }
            return;
          }
          // this.props.setIdInfo(data);
          onNextStep(null, true);
        })
        .catch(({ message }) => {
          console.error(message);
          const error = {
            component: Error500,
            props: {
              onTryAgain: () => {
                retake();
              }
            }
          };
          this.setState({ error, isLoading: false });
        });
    } else {
      setIdInfo(data);
      onNextStep();
      // setCookie('UserData', JSON.stringify(data), 1/24);
    }
  }

  /**
   * handle the checkbox button
   * @param {String} id
   * @param {Object} value
   * @return {Void}
   */
  async handleChange(id, value) {
    const { setEditedFields, setFrontIDParams, retake } = this.props;
    const { data, extracted = {}, changes, showDetailed } = this.state;
    const { ID_NUMBER_EDITABLE = true, ADDITIONAL_RECAPTURE_ALLOWED = null } = process.env;

    const attempts = parseInt(getCookie('idCaptureAttempt'), 10) || 1;

    const onRecaptureClick = () => {
      setEditedFields([]);
      if (ADDITIONAL_RECAPTURE_ALLOWED && attempts > ADDITIONAL_RECAPTURE_ALLOWED) {
        const error = {
          component: TooManyRetryAttempts,
          props: {
            buttons: [
              {
                label: localizedString('back'),
                large: true,
                shadow: true,
                onClick: () => {
                  setCookie('idCaptureAttempt', 0, -7);
                  /* let changes = [...this.state.changes]
                                    let dlIndex = changes.indexOf('licenceNumber');
                                    let passportIndex = changes.indexOf('passportNumber');
                                    if(dlIndex > 0){
                                        changes.splice(dlIndex, 1);
                                    }
                                    if(passportIndex > 0){
                                        changes.splice(passportIndex, 1);
                                    } */
                  this.setState({ error: null, changes });
                }
              }
            ]
          }
        };

        this.setState({ error });
        return;
      }
      setFrontIDParams({});
      retake();
    };

    // Field Edit limit
    /* if (FIELD_EDIT_RESTRICTION && this.state.changes.length > FIELD_EDIT_LIMIT_COUNT) {
            this.props.setFrontIDParams({});
            document.activeElement.blur();//hide keyboard
            this.setState({ showTooManyEditsModal: true });
            return;

        } */

    // ID number not editable
    if (
      !ID_NUMBER_EDITABLE &&
      (id === 'idNumber' || id === 'licenceNumber' || id === 'passportNumber')
    ) {
      document.activeElement.blur(); // hide keyboard
      const error = {
        component: IdNumberNotEditable,
        props: {
          buttons: [
            {
              label: localizedString('back'),
              variant: 'outline',
              onClick: () => this.setState({ error: null })
            },
            {
              label: localizedString('recapture'),
              onClick: () => {
                onRecaptureClick();
              }
            }
          ]
        }
      };
      this.setState({ error });
      return;
    }

    if (!id.match(/^check/)) {
      let filtered;
      if (value !== extracted[id]) {
        filtered = uniq([...changes, id]);
      } else {
        filtered = changes.filter((key) => {
          return key !== id;
        });
      }
      this.setState({ changes: filtered });
      setEditedFields(filtered);
    }

    // eslint-disable-next-line camelcase
    const { street_number, street_name, suburb, postcode, state_territory } = value;
    // console.log({id, showDetailed, value})
    if (id === 'addressData' && !showDetailed && !value.isMatch) {
      this.setState({
        data: {
          ...data,
          [id]: value
        },
        hasFormErrors: true
      });
    } else if (
      (id === 'addressData' && !showDetailed && value.isMatch) ||
      (id === 'addressData' &&
        showDetailed &&
        street_number &&
        street_name &&
        suburb &&
        postcode &&
        state_territory)
    ) {
      this.setState({
        data: {
          ...data,
          [id]: value
        },
        hasFormErrors: false
      });
    } else {
      if (['licenceNumber', 'passportNumber'].includes(id) && data.idNumber) {
        data.idNumber = value;
      }

      this.setState({
        data: {
          ...data,
          [id]: value
        }
      });
    }
  }

  /**
   * Handle go back
   * @return {Void}
   */
  handleGoBack() {
    const { setFrontIDParams, retake } = this.props;
    const error = {
      component: ConfirmGoBack,
      props: {
        buttons: [
          {
            label: localizedString('cancel'),
            variant: 'outline',
            onClick: () => {
              this.setState({ error: null });
            }
          },
          {
            label: localizedString('yes'),
            onClick: () => {
              setFrontIDParams({});
              retake();
            }
          }
        ]
      }
    };
    this.setState({ error });
  }

  editValues = () => {
    this.setState((state) => ({
      readOnly: false,
      backup: { ...state }
    }));
  };

  handleDone = ({ isValid }) => {
    const { FIELD_EDIT_RESTRICTION, FIELD_EDIT_LIMIT_COUNT = 3 } = process.env;
    if (
      FIELD_EDIT_RESTRICTION &&
      this.state.changes &&
      this.state.changes.length > FIELD_EDIT_LIMIT_COUNT
    ) {
      this.props.setFrontIDParams({});
      document.activeElement.blur(); // hide keyboard
      this.setState({ showTooManyEditsModal: true });
      return;
    }

    const {
      data: { expiryDate, middleName }
    } = this.state;

    if (!middleName || middleName === '') {
      this.setState({ showNoMiddleNameModal: true });
      return;
    }

    const expiry = isDocumentExpired(expiryDate);
    if (expiry) {
      this.setState({
        error: {
          component: ExpiredID,
          props: {
            buttons: [
              {
                label: localizedString('tryAgain'),
                variant: 'transparent',
                onClick: () => {
                  this.props.setFrontIDParams({});
                  this.props.retake();
                }
              },
              {
                label: localizedString('notExpired'),
                onClick: () => this.setState({ error: null })
              }
            ]
          }
        }
      });
      return;
    }

    if (isValid) {
      this.setState({ readOnly: true });
    }
  };

  handleFormUpdate = ({ fields, isValid }) => {
    if (Object.keys(fields).length > 0) {
      this.setState({
        hasFormErrors: !isValid
      });
    }
  };

  // eslint-disable-next-line class-methods-use-this
  formatDay = (value) => {
    if (!value || value === '') {
      return '';
    }

    let parts;
    if (value.includes('-')) {
      parts = value.split('-');
    } else {
      parts = value.split('/');
    }

    const [day, month, year] = parts;
    return `${day}/${month}/${year}`;
  };

  renderConfirmLeaveChanges = () => {
    const { readOnly, showConfirmLeaveEditing } = this.state;
    if (readOnly) return '';

    const confirmBtns = [
      {
        label: localizedString('cancel'),
        onClick: () => this.setState({ showConfirmLeaveEditing: false }),
        variant: 'transparent'
      },
      {
        label: localizedString('yesImSure'),
        onClick: () => {
          const state = { ...this.state.backup };
          state.backup = null;
          this.setState({ ...state });
        }
      }
    ];

    return (
      <Modal
        isOpen={showConfirmLeaveEditing}
        heading={localizedString('leaveConfirmation')}
        buttons={confirmBtns}
      >
        {localizedString('willLoseChangesOnScreen')}
      </Modal>
    );
  };

  renderTooManyEditsModal = () => {
    const { showTooManyEditsModal } = this.state;
    const confirmBtns = [
      {
        label: localizedString('tryAgain'),
        full: true,
        onClick: () => {
          this.setState({
            showTooManyEditsModal: false
          });
          this.props.setFrontIDParams({});
          this.props.retake();
        }
      }
    ];

    return (
      <Modal
        isOpen={showTooManyEditsModal}
        heading={localizedString('takeAnotherPhoto')}
        buttons={confirmBtns}
      >
        {localizedString('takeAnotherPhotoDesc1')}
        <br />
        <br />
        {localizedString('takeAnotherPhotoDesc2')}
      </Modal>
    );
  };

  renderNoMiddleNameModal = () => {
    const { showNoMiddleNameModal } = this.state;
    const confirmBtns = [
      {
        label: localizedString('addNow'),
        variant: 'transparent',
        onClick: () => {
          this.setState({
            showNoMiddleNameModal: false
          });
        }
      },
      {
        label: localizedString('noMiddleName'),
        onClick: () => {
          this.setState({
            showNoMiddleNameModal: false,
            readOnly: true
          });
        }
      }
    ];

    return (
      <Modal
        isOpen={showNoMiddleNameModal}
        heading={localizedString('noMiddleNameQuestion')}
        buttons={confirmBtns}
      >
        {localizedString('noMiddleNameDesc1')}
        <br />
        <br />
        {localizedString('noMiddleNameDesc2')}
      </Modal>
    );
  };

  /**
   * Render the component's markup
   * @return {ReactElement}
   */
  render() {
    const {
      isLoading,
      isWaiting,
      error,
      data,
      loading,
      countryOfIssue,
      readOnly,
      showDetailed,
      hasFormErrors,
      useIdNumber,
      changes,
      backup
    } = this.state;
    const { component: Error, props: errorProps } = error || {};
    const { idType, onExit, countryCode: COUNTRY } = this.props;
    let footerButtons;
    footerButtons = [
      {
        label: localizedString('back'),
        variant: 'transparent',
        onClick: onExit
      },
      {
        label: localizedString('continue'),
        type: 'submit',
        disabled:
          !data.checkConfirm ||
          hasFormErrors ||
          (data.addressData && !showDetailed && !data.addressData.isMatch),
        loading
      }
    ];

    if (!readOnly) {
      footerButtons = [
        {
          label: localizedString('done'),
          type: 'submit',
          variant: 'outline',
          full: true,
          disabled: hasFormErrors
        }
      ];
    }
    return (
      <div>
        {Error && <Error {...errorProps} />}
        {isWaiting && <LoadingSpinner heading={localizedString('waitingForApproval')} />}
        {isLoading && <LoadingSpinner heading={localizedString('viewingYourID')} showTitle />}
        {!isLoading && (
          <Form
            onUpdate={this.handleFormUpdate}
            onSubmit={readOnly ? this.handleConfirm : this.handleDone}
          >
            <Page
              buttons={footerButtons}
              footerShadow
              hideLogo={!readOnly}
              showClose={!readOnly}
              onClose={() => {
                if (changes.length > 0) {
                  this.setState({ showConfirmLeaveEditing: true });
                } else {
                  const state = { ...backup };
                  state.backup = null;
                  this.setState({ ...state });
                }
              }}
            >
              <VerifyDetailsContent
                {...data}
                idType={idType}
                useIdNumber={useIdNumber}
                readOnly={readOnly}
                showDetailed={showDetailed}
                onShowDetailed={() => this.setState({ showDetailed: true })}
                country={COUNTRY}
                onPencilClick={this.editValues}
                countryOfIssue={countryOfIssue}
                onChange={(id, value) => {
                  this.handleChange(id, value);
                }}
              />
              {this.renderConfirmLeaveChanges()}
              {this.renderTooManyEditsModal()}
              {this.renderNoMiddleNameModal()}
            </Page>
          </Form>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyDetails);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ information, capture, appConfig, edited }) {
  return {
    addresses: information.addresses,
    idDetails: information.idDetails,
    frontParams: capture.frontParams,
    editedFields: edited.fields,
    appConfig
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    setIdInfo: (data) => dispatch(InfoAction.setIdInfo(data)),
    setAddress: (data) => dispatch(InfoAction.setAddress(data)),
    setFrontIDParams: (data) => dispatch(CaptureAction.setFrontIDParams(data)),
    setEditedFields: (data) => dispatch(EditedAction.setEditedFields(data))
  };
}
