import Edited from '../actions/edited';

const initialState = {
  fields: []
};

export default function editedFields(state = initialState, action = {}) {
  switch (action.type) {
    case Edited.SET_EDITED_FIELDS:
      return {
        ...state,
        fields: action.data
      };

    default:
      return state;
  }
}
