import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Page from '@lib/components/v2/Page';
import classNames from 'classnames';
import classes from './SelectState.style.module.scss';

class SelectState extends Component {
  static propTypes = {
    onStateSelect: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      optionList: [
        {
          title: 'ACT',
          checked: true
        },
        {
          title: 'NT',
          checked: false
        },
        {
          title: 'NSW',
          checked: false
        },
        {
          title: 'QLD',
          checked: false
        },
        {
          title: 'SA',
          checked: false
        },
        {
          title: 'TAS',
          checked: false
        },
        {
          title: 'VIC',
          checked: false
        },
        {
          title: 'WA',
          checked: false
        }
      ]
    };
    this.handleNextStep = this.handleNextStep.bind(this);
  }

  handleNextStep(e) {
    if (e) {
      e.preventDefault();
    }
    const { onStateSelect } = this.props;
    const { optionList } = this.state;

    const selectedItem = optionList.find((item) => item.checked);
    onStateSelect(selectedItem);
  }

  onOptionClick(item, index) {
    if (item.checked) {
      return;
    }

    const { optionList } = this.state;
    for (let i = 0; i < optionList.length; i++) {
      optionList[i].checked = index === i;
    }
    this.setState({ optionList });
  }

  /**
   * Render the component's markup
   * @return {ReactElement}
   */
  render() {
    const { optionList } = this.state;
    const footerButtons = [
      {
        label: 'Select',
        type: 'submit',
        onClick: () => this.handleNextStep(),
        dataTestId: 'state-select'
      }
    ];

    return (
      <Page buttons={footerButtons}>
        <div className={classes.wrapper}>
          <div className={classes.heading} data-testid="state-heading">
            What state is your certificate?
          </div>
          <div className={classes.option_list}>
            {optionList.map((item, index) => {
              const iconName = `images/icons/svg/${
                item.checked ? 'icon_radio_checked.svg' : 'icon_radio_unchecked.svg'
              }`;

              return (
                <div
                  key={item.title}
                  className={classNames(classes.option_item, item.checked && classes.checked)}
                  onClick={() => this.onOptionClick(item, index)}
                >
                  <div
                    className={classes.check_mark_wrapper}
                    data-testid={`state-radio-${item.title.toLowerCase()}`}
                  >
                    <img src={iconName} alt="" />
                  </div>
                  <div
                    className={classes.option_title}
                    data-testid={`state-txt-${item.title.toLowerCase()}`}
                  >
                    {item.title}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Page>
    );
  }
}

export default SelectState;
