/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'calidation';

import InfoAction from '@store/actions/information';
import CaptureAction from '@store/actions/capture';

import { reverseDateFormatSF, getCookie, setCookie, validateDate } from '@lib/Utils';
import APIs from '@services/APIs';
import { localizedString } from '@languages';
import { Page, LoadingSpinner, Modal } from '../../components';
import { VerifyOtherCardDetailsContent } from '../../components/Contents';
import { Error500 } from '../../errors';

import {
  CouldNotRead,
  CouldNotRecognise,
  ExpiredID,
  ConfirmConsent,
  ConfirmGoBack,
  FaceNotDetectedInId,
  IdNumberNotEditable,
  TooManyRetryAttempts
} from './VerifyOtherCardDetails.errors';

class VerifyOtherCardDetails extends Component {
  static propTypes = {
    onNextStep: PropTypes.func,
    onExit: PropTypes.func,
    retake: PropTypes.func,
    idType: PropTypes.string,
    token: PropTypes.string,
    countryCode: PropTypes.string,
    flowType: PropTypes.string,
    capturedIds: PropTypes.object,
    otherDetails: PropTypes.object,
    frontParams: PropTypes.object,
    captureBack: PropTypes.func,
    setFrontIDParams: PropTypes.func,
    setOtherIdInfo: PropTypes.func,
    onGoBack: PropTypes.func
  };

  static defaultProps = {
    onNextStep: () => null,
    onExit: () => null,
    retake: () => null,
    idType: '',
    countryCode: 'NZ',
    token: '',
    capturedIds: {}
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  /**
   * Return the component's initial state
   * @return {Object}
   */
  getInitialState() {
    return {
      data: {
        checkConfirm: false,
        dateOfBirth: '',
        expiryDate: ''
      },
      isLoading: true,
      /* disable primary button */
      disabled: true,
      error: null,
      changes: [],
      loading: false,
      countryOfIssue: '',
      readOnly: true,
      showDetailed: false,
      hasFormErrors: false,
      showConfirmLeaveEditing: false,
      backup: null,
      useIdNumber: false
    };
  }

  /**
   * Call get card information API
   * @return {Void}
   */
  componentDidMount() {
    APIs.status('reviewInfo');
    const { token, otherDetails, frontParams, captureBack, setFrontIDParams, retake } = this.props;

    const idDetails =
      otherDetails && otherDetails.length > 0 ? otherDetails[otherDetails.length - 1] : {};

    let idType = '';

    if (otherDetails && otherDetails.length > 0) {
      this.setState({
        countryOfIssue: idDetails.countryOfIssue,
        showDetailed: idDetails.showDetailed
      });
      idType = idDetails.idType;
    }

    const { data } = this.state;

    const { APP_VERSION } = process.env;
    const ENABLE_CONSENT_CHECKBOX = true;
    if (!ENABLE_CONSENT_CHECKBOX) {
      const newData = { ...data };
      data.checkConfirm = true;
      this.setState({
        data: newData
      });
    }

    if ((!otherDetails || otherDetails.length === 0) && token) {
      const noBack = idType === 'PASSPORT' || idType === 'ID_CARD';

      APIs.extractIdentifyInfo(token, noBack)
        .then(async ({ data: cardData, code, countryOfIssue, useIdNumber = false }) => {
          this.setState({
            countryOfIssue,
            useIdNumber
          });

          if (frontParams.idType) {
            if (
              APP_VERSION >= 3 &&
              (cardData.cardType === 'AUS_QLD_DRIVERLICENCE' ||
                cardData.cardType === 'AUS_VIC_DRIVERLICENCE')
            ) {
              captureBack('back');
              return;
            }
            if (APP_VERSION < 3 && cardData.cardType === 'AUS_QLD_DRIVERLICENCE') {
              captureBack('back');
              return;
            }
            if (
              (cardData.cardType === 'PASSPORT' || cardData.cardType === 'MRZ') &&
              cardData.countryOfIssue !== 'NEW ZEALAND' &&
              cardData.countryOfIssue !== 'AUSTRALIA'
            ) {
              captureBack('visa');
              return;
            }
          }
          const buttons = [
            {
              label: localizedString('back'),
              variant: 'transparent',
              onClick: () => {
                setFrontIDParams({});
                retake();
              }
            },
            {
              label: localizedString('tryAgain'),
              onClick: () => {
                setFrontIDParams({});
                retake();
              }
            }
          ];

          if (code === '210') {
            const error = {
              component: CouldNotRecognise,
              props: { buttons }
            };
            this.setState({
              error,
              isLoading: false
            });
            return;
          }

          if (code === '230') {
            const error = {
              component: CouldNotRecognise,
              props: { buttons }
            };
            this.setState({
              error,
              isLoading: false
            });
            return;
          }

          if (code === '223') {
            const error = {
              component: FaceNotDetectedInId,
              props: { buttons }
            };
            this.setState({
              error,
              isLoading: false
            });
            return;
          }

          if (code !== '200') {
            const error = {
              component: CouldNotRead,
              props: { buttons }
            };
            this.setState({
              error,
              isLoading: false
            });
            return;
          }

          const temp = { ...data, ...cardData };
          // validate dates
          temp.dateOfBirth = validateDate(temp.dateOfBirth) ? temp.dateOfBirth : '';
          temp.expiryDate = validateDate(temp.expiryDate) ? temp.expiryDate : '';

          // format dates
          temp.dateOfBirth = this.formatDay(temp.dateOfBirth);
          temp.expiryDate = this.formatDay(temp.expiryDate);

          this.setState({
            isLoading: false,
            data: temp
          });
        })
        .catch((e) => {
          console.error(e);
          const error = {
            component: Error500,
            props: {
              onTryAgain: () => {
                setFrontIDParams({});
                retake();
              }
            }
          };
          this.setState({ error, isLoading: false });
        });
    } else {
      this.setState({ isLoading: false, data: { ...idDetails } });
    }
  }

  /**
   * Handle the continue button
   * @param {ClickEvent} e Event
   * @return {Void}
   */
  handleConfirm() {
    const { AUVOI_REQUIRED_IDS } = process.env;
    const { setOtherIdInfo } = this.props;
    const { data: newData, changes, hasFormErrors, showDetailed } = this.state;
    const data = { ...newData, edited: changes };
    const { checkConfirm } = data;
    const btnOk = [
      {
        label: localizedString('ok'),
        onClick: () => this.setState({ error: null })
      }
    ];

    if (!checkConfirm) {
      const error = {
        component: ConfirmConsent,
        props: {
          buttons: btnOk
        }
      };
      this.setState({
        error
      });
      return;
    }
    if (hasFormErrors) {
      return;
    }

    // Store Other details
    const { flowType, onGoBack, capturedIds, onNextStep, retake } = this.props;

    this.setState({ loading: true });
    setOtherIdInfo({ ...data, showDetailed });

    const params = {
      ...data,
      flowType
    };
    // format date
    if (params.dateOfBirth) {
      params.dateOfBirth = params.dateOfBirth.replace(/[/]/g, '-');
    }
    if (params.expiryDate) {
      params.expiryDate = params.expiryDate.replace(/[/]/g, '-');
    }
    APIs.storeOther(params)
      .then(({ status, type, msg }) => {
        this.setState({ loading: false });
        if (status !== 'success') {
          if (type === 'cards') {
            this.setState({
              error: {
                issue: msg,
                buttons: [
                  {
                    label: localizedString('cancel'),
                    onClick: () => onGoBack()
                  }
                ]
              }
            });
          } else {
            this.setState({
              error: {
                issue: msg,
                buttons: [
                  {
                    label: localizedString('cancel'),
                    onClick: () =>
                      this.setState({
                        error: null
                      })
                  }
                ]
              }
            });
          }
          return;
        }
        const capturedIdCount = Object.keys(capturedIds).length;
        if (capturedIdCount >= AUVOI_REQUIRED_IDS) {
          onNextStep();
        } else {
          onNextStep(null, true);
        }
      })
      .catch(({ message }) => {
        console.error(message);
        const error = {
          component: Error500,
          props: {
            onTryAgain: () => {
              retake();
            }
          }
        };
        this.setState({ error, isLoading: false });
      });
  }

  /**
   * handle the checkbox button
   * @param {String} id
   * @param {Object} value
   * @return {Void}
   */
  async handleChange(id, value) {
    const { setFrontIDParams, retake } = this.props;
    const { data, changes, showDetailed } = this.state;
    const { ID_NUMBER_EDITABLE = true, ADDITIONAL_RECAPTURE_ALLOWED = null } = process.env;

    const attempts = parseInt(getCookie('idCaptureAttempt'), 10) || 1;

    const onRecaptureClick = () => {
      if (ADDITIONAL_RECAPTURE_ALLOWED && attempts > ADDITIONAL_RECAPTURE_ALLOWED) {
        const error = {
          component: TooManyRetryAttempts,
          props: {
            buttons: [
              {
                label: localizedString('back'),
                large: true,
                shadow: true,
                onClick: () => {
                  setCookie('idCaptureAttempt', 0, -7);
                  /* let changes = [...this.state.changes]
                                    let dlIndex = changes.indexOf('licenceNumber');
                                    let passportIndex = changes.indexOf('passportNumber');
                                    if(dlIndex > 0){
                                        changes.splice(dlIndex, 1);
                                    }
                                    if(passportIndex > 0){
                                        changes.splice(passportIndex, 1);
                                    } */
                  this.setState({ error: null, changes });
                }
              }
            ]
          }
        };

        this.setState({ error });
        return;
      }
      setFrontIDParams({});
      retake();
    };

    // Field Edit limit
    /* if (FIELD_EDIT_RESTRICTION && this.state.changes.length > FIELD_EDIT_LIMIT_COUNT) {
            this.props.setFrontIDParams({});
            document.activeElement.blur();//hide keyboard
            this.setState({ showTooManyEditsModal: true });
            return;

        } */

    // ID number not editable
    if (
      !ID_NUMBER_EDITABLE &&
      (id === 'idNumber' || id === 'licenceNumber' || id === 'passportNumber')
    ) {
      document.activeElement.blur(); // hide keyboard
      const error = {
        component: IdNumberNotEditable,
        props: {
          buttons: [
            {
              label: localizedString('back'),
              variant: 'outline',
              onClick: () => this.setState({ error: null })
            },
            {
              label: localizedString('recapture'),
              onClick: () => {
                onRecaptureClick();
              }
            }
          ]
        }
      };
      this.setState({ error });
      return;
    }

    if (changes.indexOf(id) < 0 && !id.match(/^check/)) {
      await this.setState({ changes: [...changes, id] });
    }

    const { street_number, street_name, suburb, postcode, state_territory } = value;
    // console.log({id, showDetailed, value})
    if (id === 'addressData' && !showDetailed && !value.isMatch) {
      this.setState({
        data: {
          ...data,
          [id]: value
        },
        hasFormErrors: true
      });
    } else if (
      (id === 'addressData' && !showDetailed && value.isMatch) ||
      (id === 'addressData' &&
        showDetailed &&
        street_number &&
        street_name &&
        suburb &&
        postcode &&
        state_territory)
    ) {
      this.setState({
        data: {
          ...data,
          [id]: value
        },
        hasFormErrors: false
      });
    } else {
      if (['licenceNumber', 'passportNumber'].includes(id) && data.idNumber) {
        data.idNumber = value;
      }

      this.setState({
        data: {
          ...data,
          [id]: value
        }
      });
    }
  }

  /**
   * Handle go back
   * @return {Void}
   */
  handleGoBack() {
    const { setFrontIDParams, retake } = this.props;
    const error = {
      component: ConfirmGoBack,
      props: {
        buttons: [
          {
            label: localizedString('cancel'),
            variant: 'outline',
            onClick: () => {
              this.setState({ error: null });
            }
          },
          {
            label: localizedString('yes'),
            onClick: () => {
              setFrontIDParams({});
              retake();
            }
          }
        ]
      }
    };
    this.setState({ error });
  }

  // eslint-disable-next-line class-methods-use-this
  formatDay = (value) => {
    if (!value || value === '') {
      return '';
    }

    let parts;
    if (value.includes('-')) {
      parts = value.split('-');
    } else {
      parts = value.split('/');
    }

    const [day, month, year] = parts;
    return `${day}/${month}/${year}`;
  };

  editValues = () => {
    this.setState((state) => ({
      readOnly: false,
      backup: { ...state }
    }));
  };

  handleDone = ({ isValid }) => {
    const now = new Date().getTime();
    const {
      data: { expiryDate }
    } = this.state;

    const expiry = now > reverseDateFormatSF(expiryDate, true).getTime();
    if (expiry) {
      this.setState({
        error: {
          component: ExpiredID,
          props: {
            buttons: [
              {
                label: localizedString('tryAgain'),
                variant: 'transparent',
                onClick: () => {
                  this.props.setFrontIDParams({});
                  this.props.retake();
                }
              },
              {
                label: localizedString('notExpired'),
                onClick: () => this.setState({ error: null })
              }
            ]
          }
        }
      });
      return;
    }

    if (isValid) {
      this.setState({ readOnly: true });
    }
  };

  handleFormUpdate = ({ fields, isValid }) => {
    if (Object.keys(fields).length > 0) {
      this.setState({
        hasFormErrors: !isValid
      });
    }
  };

  renderConfirmLeaveChanges = () => {
    const { readOnly, showConfirmLeaveEditing } = this.state;
    if (readOnly) return '';

    const confirmBtns = [
      {
        label: localizedString('cancel'),
        onClick: () => this.setState({ showConfirmLeaveEditing: false }),
        variant: 'transparent'
      },
      {
        label: localizedString('yesImSure'),
        onClick: () => {
          const state = { ...this.state.backup };
          state.backup = null;
          this.setState({ ...state });
        }
      }
    ];

    return (
      <Modal
        isOpen={showConfirmLeaveEditing}
        heading={localizedString('leaveConfirmation')}
        buttons={confirmBtns}
      >
        {localizedString('willLoseChangesOnScreen')}
      </Modal>
    );
  };

  /**
   * Render the component's markup
   * @return {ReactElement}
   */
  render() {
    const {
      isLoading,
      error,
      data,
      loading,
      countryOfIssue,
      readOnly,
      showDetailed,
      hasFormErrors,
      useIdNumber,
      changes,
      backup
    } = this.state;
    const { component: Error, props: errorProps } = error || {};
    const { idType, onExit, countryCode: COUNTRY, capturedIds } = this.props;
    let footerButtons;
    footerButtons = [
      {
        label: localizedString('back'),
        variant: 'transparent',
        onClick: onExit
      },
      {
        label: localizedString('continue'),
        type: 'submit',
        disabled: !data.checkConfirm || hasFormErrors,
        loading
      }
    ];

    if (!readOnly) {
      footerButtons = [
        {
          label: localizedString('done'),
          type: 'submit',
          variant: 'outline',
          full: true,
          disabled: hasFormErrors
        }
      ];
    }
    return (
      <div>
        {Error && <Error {...errorProps} />}
        {isLoading && <LoadingSpinner heading={localizedString('viewingYourID')} showTitle />}
        {!isLoading && (
          <Form
            onUpdate={this.handleFormUpdate}
            onSubmit={readOnly ? this.handleConfirm : this.handleDone}
          >
            <Page
              buttons={footerButtons}
              footerShadow
              hideLogo={!readOnly}
              showClose={!readOnly}
              onClose={() => {
                if (changes.length > 0) {
                  this.setState({ showConfirmLeaveEditing: true });
                } else {
                  const state = { ...backup };
                  state.backup = null;
                  this.setState({ ...state });
                }
              }}
            >
              <VerifyOtherCardDetailsContent
                data={data}
                idType={idType}
                useIdNumber={useIdNumber}
                readOnly={readOnly}
                capturedIds={capturedIds}
                showDetailed={showDetailed}
                onShowDetailed={() => this.setState({ showDetailed: true })}
                country={COUNTRY}
                onPencilClick={this.editValues}
                countryOfIssue={countryOfIssue}
                onChange={(id, value) => {
                  this.handleChange(id, value);
                }}
              />
              {this.renderConfirmLeaveChanges()}
            </Page>
          </Form>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOtherCardDetails);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ information, capture }) {
  return {
    otherDetails: information.otherDetails,
    frontParams: capture.frontParams
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    setOtherIdInfo: (data) => dispatch(InfoAction.setOtherIdInfo(data)),
    setFrontIDParams: (data) => dispatch(CaptureAction.setFrontIDParams(data))
  };
}
