import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { envPatch } from '@languages/env_patch';
import { localizedString } from '@languages';
import classes from './Country.style.module.scss';

const Country = ({ onSelect, country }) => {
  let { COUNTRIES } = process.env;
  const { APP_NAME } = process.env;
  if (!COUNTRIES && envPatch[APP_NAME]) {
    COUNTRIES = envPatch[APP_NAME].COUNTRIES;
  }

  const countries = COUNTRIES;

  // Sort countries by country code
  if (COUNTRIES) {
    countries.forEach((c, i) => {
      if (c.iso === country) {
        [countries[0], countries[i]] = [countries[i], countries[0]];
      }
    });
  }

  return (
    <div className={classNames(classes.wrapper, 'b-container')}>
      <div className={classNames(classes.title, 'b-title')}>
        {localizedString('whatCountryIsDocFrom')}
      </div>
      <div className={classes.blocks}>
        {countries &&
          countries.map(({ name, iso, flag }) => (
            <div
              key={name}
              className={classNames(classes.item, 'b-block')}
              onClick={() => onSelect({ country: iso })}
            >
              <div className={classes.icon}>
                <img src={`/images/icons/svg/countries/${flag}`} width="70" alt="" />
              </div>
              <div className={classes.content}>
                <h4>{iso === 'OTHER' ? localizedString('other') : name}</h4>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

Country.propTypes = {
  onSelect: PropTypes.func,
  country: PropTypes.string
};

Country.defaultProps = {
  onSelect: () => null
};

export default Country;
