import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { envPatch } from '@languages/env_patch';
import { localizedString } from '@languages';

import classes from './IdSelection.style.module.scss';

const IdSelection = ({ onSelect, country }) => {
  const { APP_NAME, SELECT_ID_HEADING = false } = process.env;
  let { ID_TYPES, COUNTRIES } = process.env;
  if (!ID_TYPES && envPatch[APP_NAME]) {
    ID_TYPES = envPatch[APP_NAME].ID_TYPES;
  }

  if (!COUNTRIES && envPatch[APP_NAME]) {
    COUNTRIES = envPatch[APP_NAME].COUNTRIES;
  }

  let countryName = '';
  if (COUNTRIES) {
    for (let i = 0; i < COUNTRIES.length; i++) {
      const element = COUNTRIES[i];
      if (element.iso === country) {
        countryName = element.name;
      }
    }
  }

  const headingText = SELECT_ID_HEADING || localizedString('selectIDTypeYouWantToUse');

  return (
    <div className={classNames(classes.wrapper, 'b-container')}>
      {COUNTRIES ? (
        <div>
          <div className={classNames(classes.countryTitle, 'b-title')}>{countryName}</div>
          <p className="b-title">{headingText}</p>
        </div>
      ) : (
        <div className="b-title">{headingText}</div>
      )}
      <div className={classes.blocks}>
        {ID_TYPES[country] &&
          ID_TYPES[country].map(({ id, label, icon, expired, backOfCard, description = '' }) => {
            let iconClasses = classes.icon;
            if (icon === 'au-dl-red.png' || icon === 'au-passport-red.png') {
              iconClasses = `${iconClasses} ${classes.iconSmall}`;
            }
            return (
              <div
                key={id}
                className={classNames(classes.item, 'b-block')}
                onClick={() => onSelect({ idType: id, backOfCard })}
              >
                <div
                  className={iconClasses}
                  style={{
                    backgroundImage: `url(images/icons/svg/id-types/${icon})`
                  }}
                />
                <div className={classes.content}>
                  <h4>{label}</h4>
                  {(description || !expired) && (
                    <span>{`(${description || localizedString('notExpired')})`}</span>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

IdSelection.propTypes = {
  onSelect: PropTypes.func,
  country: PropTypes.string
};

IdSelection.defaultProps = {
  onSelect: () => null,
  country: process.env.COUNTRY
};

export default IdSelection;
