import React from 'react';
import PropTypes from 'prop-types';
import { Page } from '@DOC_ONLY_FLOW/components';
import { OverviewContent } from '@DOC_ONLY_FLOW/components/Contents';
import { localizedString } from '@languages';

export default function Overview({ onExit, onStart }) {
  const {
    REMOVE_EXIT_FROM_OVERVIEW = false,
    OVERVIEW_PAGE_TITLE = localizedString('processOverview')
  } = process.env;
  let buttons = [
    {
      label: localizedString('exit'),
      variant: 'outline',
      onClick: () => onExit()
    },
    { label: localizedString('start'), onClick: () => onStart() }
  ];

  if (REMOVE_EXIT_FROM_OVERVIEW) {
    buttons = [{ label: localizedString('start'), onClick: () => onStart() }];
  }

  return (
    <Page title={OVERVIEW_PAGE_TITLE} buttons={buttons}>
      <OverviewContent onClick={() => onStart()} />
    </Page>
  );
}

Overview.propTypes = {
  onStart: PropTypes.func,
  onExit: PropTypes.func
};

Overview.defaultProps = {
  onStart: () => null,
  onExit: () => null
};
