import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { localizedString } from '@languages';
import Select from '../../Form/Select';

import classes from './IdSelection.style.module.scss';

const handleChange = (obj, onSelect) => {
  const { value } = obj;
  const parts = value.split('=');
  onSelect({
    idType: parts[0],
    backOfCard: parseInt(parts[1], 10) === 1
  });
};

const IdSelection = ({
  onSelect,
  onSelectState,
  state,
  idType,
  title,
  idTypes = [],
  capturedIds: ids,
  stateError = {},
  isCaptureName = false
}) => {
  const [stateL, setState] = useState({ stateChange: false });

  const capturedIds = Object.keys(ids).filter((key) => {
    return ids[key];
  });

  const noOfIdCaptured = Object.keys(capturedIds).length;
  let mainTitle = localizedString('idSelectionTitlePhoto');
  if (noOfIdCaptured === 1) {
    mainTitle = localizedString('idSelectionTitleSecond');
  } else if (noOfIdCaptured === 2) {
    mainTitle = localizedString('idSelectionTitleThird');
  } else if (noOfIdCaptured === 3) {
    mainTitle = localizedString('idSelectionTitleFourth');
  } else if (noOfIdCaptured === 4) {
    mainTitle = localizedString('idSelectionTitleFifth');
  } else if (noOfIdCaptured === 5) {
    mainTitle = localizedString('idSelectionTitleSixth');
  }

  const index = idTypes.findIndex(({ value }) => {
    return value.includes(idType);
  });

  const value = index > -1 ? idTypes[index] : idTypes[0];

  const auStates = [
    {
      value: 'NSW',
      label: localizedString('australiaStateNSW')
    },
    {
      value: 'QLD',
      label: localizedString('australiaStateQLD')
    },
    {
      value: 'TAS',
      label: localizedString('australiaStateTAS')
    },
    {
      value: 'VIC',
      label: localizedString('australiaStateVIC')
    },
    {
      value: 'NT',
      label: localizedString('australiaStateNT')
    },
    {
      value: 'SA',
      label: localizedString('australiaStateSA')
    },
    {
      value: 'WA',
      label: localizedString('australiaStateWA')
    },
    {
      value: 'ACT',
      label: localizedString('australiaStateACT')
    },
    {
      value: 'OTHER',
      label: 'OTHER'
    }
  ];
  const showState =
    value &&
    ['AUS_AUTO_NAME', 'AUS_AUTO_MARRIAGE', 'AUS_AUTO_BIRTH'].includes(value.value.split('=')[0]);

  const stateValue = auStates.find(({ value: val }) => {
    return val === state;
  });

  return (
    <div className={classes.wrapper}>
      <div className={classes.heading}>
        {title || mainTitle}
        {isCaptureName && <small>{localizedString('comparedToWhatIsOnYourIDDocument')}</small>}
      </div>
      <Select
        id="idType"
        placeholder={localizedString('idDocumentType')}
        // menuIsOpen={true}
        options={idTypes}
        value={value}
        hasError={!value ? localizedString('selectDocumentType') : null}
        onChange={(val) => handleChange(val, onSelect)}
      />

      <br />
      {showState && (
        <Select
          id="state"
          placeholder={localizedString('state')}
          options={auStates}
          value={stateValue}
          hasError={
            (stateL.stateChange || stateError.state) && !stateValue
              ? localizedString('pleaseSelectState')
              : null
          }
          onChange={(val) => {
            setState({
              stateChange: true
            });
            onSelectState(val);
          }}
        />
      )}
    </div>
  );
};

IdSelection.propTypes = {
  onSelect: PropTypes.func,
  onSelectState: PropTypes.func,
  idTypes: PropTypes.array,
  capturedIds: PropTypes.object,
  stateError: PropTypes.object,
  title: PropTypes.string,
  state: PropTypes.string,
  isCaptureName: PropTypes.bool,
  idType: PropTypes.string
};

IdSelection.defaultProps = {
  onSelect: () => null,
  onSelectState: () => null,
  capturedIds: {},
  idTypes: [],
  title: null,
  state: '',
  stateError: {},
  isCaptureName: false
};

export default IdSelection;
