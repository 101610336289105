import { localizedString } from '@languages';

export default {
  phone_number: {
    isRequired: localizedString('pleaseEnterMobileNumber'),
    isRegexMatch: {
      message: localizedString('pleaseEnterOnlyNumbers'),
      regex: /^([0-9 ]+)$/
    },
    isMaxLength: {
      message: localizedString('max12Chars'),
      length: 12
    }
  }
};
