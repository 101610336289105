/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import '../../../styles/main.scss';
import { Modal, Message } from '@DOC_ONLY_FLOW/components';
import Results from '@DOC_ONLY_FLOW/components/Contents/Results';
import {
  Privacy,
  Overview,
  IdSelection,
  Country,
  Capture,
  VerifyDetails,
  VerifyAddress,
  DetailMatch,
  Success,
  Questionnaire
} from '@DOC_ONLY_FLOW/containers';
import {
  isWebRTCSupported,
  copyOpenSafari,
  isAndroidDevice,
  isBrowserPermitted,
  setCookie,
  getCookie,
  isShownPrivacy
} from '@js/lib/Utils';
import { envPatch } from '@languages/env_patch';
import { localizedString, getLanguage } from '@languages';
import Language from '@DOC_ONLY_FLOW/components/Language';

import APIs from '@js/services/APIs';
import { InternetCut, Timeout, DeviceIncompatibleOpenChrome } from '../../errors';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      idType: 'PASSPORT',
      country: process.env.COUNTRY,
      backOfCard: true,
      tokenId: '',
      geolocation: '',
      questionnaireComplete: false,
      accepted: false,
      cancelled: false,
      confirm: false,
      confirmFR: false,
      error: null,
      tenMinsLeft: false,
      compatible: true,
      completed: false,
      result: null,
      isLandscape: false,

      modalError: null,

      webrtc: {
        todo: null,
        status: true
      },
      redirect: false,
      verify: false,
      allowedCountries: ['AU', 'NZ', 'CN', 'HK', 'MY', 'SG'],
      nextCapture: null,
      exitBtnLoading: false,

      showDetailMatch: false,
      showLanguageSelectionPrompt: false
    };

    this.input = null;

    this.handleComplete = this.handleComplete.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleExit = this.handleExit.bind(this);
  }

  async componentDidMount() {
    const { verify, isCompleted: completed } = this.props;
    this.setState({ verify, completed });

    const { allowedCountries } = this.state;
    const {
      APP_NAME,
      COUNTRY,
      DETAIL_MATCH = [],
      DETAIL_MATCH_ONLY_UNIVERSAL_URL = false
    } = process.env;
    let { COUNTRIES } = process.env;
    if (!COUNTRIES && envPatch[APP_NAME]) {
      COUNTRIES = envPatch[APP_NAME].COUNTRIES;
    }

    const { status: compatible, todo, os } = await isWebRTCSupported();

    // Init device compatible
    if (window.location.search === '?flow=alternate') {
      // for testing
      this.setState({ compatible, webrtc: { todo: 'ALT_FLOW', status: false } });
    } else {
      this.setState({ compatible, webrtc: { todo, status: compatible } });
    }

    if (os === 'iOS') {
      document.addEventListener(
        'touchmove',
        (event) => {
          if (event.scale !== 1) {
            event.preventDefault();
          }
        },
        { passive: false }
      );
    }

    // Detect if internet cut
    window.addEventListener('offline', () =>
      this.setState({
        error: {
          component: InternetCut,
          props: {}
        }
      })
    );

    // Detect if internet re connected
    window.addEventListener('online', () => {
      this.setState({
        error: {
          component: InternetCut,
          props: {
            isOnline: true,
            onGoBack: this.handleInternetReconnect
          }
        }
      });
    });

    // Set country
    if (COUNTRIES) {
      APIs.country().then(({ country_code }) => {
        const country = allowedCountries.includes(country_code) ? country_code : COUNTRY;
        this.setState({ country });
      });
    } else {
      this.setState({ country: COUNTRY });
    }

    const transToken = document.querySelector('body').getAttribute('data-id');
    const storedTransToken = getCookie('transToken');

    if (transToken === storedTransToken) {
      if (getCookie('_permission') === 1) {
        // Capturing ID
        this.setState({ step: 3, accepted: true });
        setCookie('_permission', 0, -7);
      } else if (getCookie('_permission') === 2) {
        // Liveness step
        this.setState({ step: process.env.COUNTRIES ? 6 : 5, accepted: true });
        setCookie('_permission', 0, -7);
      }

      const FRAttempt = parseInt(getCookie('retryAttempt'), 10) || parseInt(getCookie('retry'), 10);
      if (FRAttempt) {
        this.setState({
          step: 6,
          accepted: true
        });
      }
    } else {
      setCookie('transToken', transToken, -7);
    }

    if (getCookie('idCaptureAttempt')) {
      setCookie('idCaptureAttempt', 0, -7);
    }

    // Landscape
    const isLandscape = window.innerHeight < window.innerWidth;
    this.setState({ isLandscape });
    window.addEventListener('orientationchange', () => {
      this.setState(({ isLandscape }) => ({ isLandscape: !isLandscape }));
    });

    // Show detail match
    const path = window.location.pathname;
    const detailMatch = getCookie('detailMatch');
    if ((!detailMatch || detailMatch !== 'yes') && DETAIL_MATCH && DETAIL_MATCH.length > 0) {
      let showDetailMatch = true;
      const { UNIVERSAL_URL_PATH = '/verify' } = process.env;
      if (DETAIL_MATCH_ONLY_UNIVERSAL_URL && path !== UNIVERSAL_URL_PATH) {
        showDetailMatch = false;
      }
      this.setState({
        showDetailMatch
      });
    }

    // Start the timeout for 1 hour.
    let countdown = 60 * 60;
    const timerId = setInterval(() => {
      if (countdown === 600) {
        this.setState({
          tenMinsLeft: true
        });
      }

      if (countdown <= 0) {
        this.setState({
          tenMinsLeft: false,
          error: {
            component: Timeout,
            props: {}
          }
        });
        clearInterval(timerId);
      }
      countdown--;
    }, 1000);
  }

  /**
   * Unbind the event listener
   */
  componentWillUnmount() {
    window.removeEventListener('orientationchange', () => {
      this.setState(({ isLandscape }) => ({ isLandscape: !isLandscape }));
    });
  }

  /**
   * When all flows have been completed.
   *
   * @param {String} redirectTo
   * @return {Void}
   */
  handleComplete(redirectTo = null) {
    setCookie('retryAsf', 'no', -10);
    setCookie('retry', null, -7);
    setCookie('retryCaptureWithVideoStream', null, -7);
    setCookie('retryAttempt', null, -7);
    setCookie('idCaptureAttempt', 0, -7);
    setCookie('detailMatch', 'false', -1);
    setCookie('_permission', null, -1);

    if (redirectTo) {
      setTimeout(() => {
        APIs.return();
      }, 3000);
      this.setState({ redirect: true, completed: true });
    } else {
      this.setState({ completed: true });
    }
  }

  /**
   * Go to next step.
   *
   * @param {Object} state
   * @return {Void}
   */
  handleNextStep(state) {
    this.setState(({ step }) => ({ step: step + 1, ...state }));
  }

  /**
   * Go back to pev step.
   *
   * @return {Void}
   */
  handleGoBack() {
    this.setState(({ step }) => ({ step: step - 1 }));
  }

  /**
   * Handle internet reconnection
   *
   * @return {Void}
   */
  handleInternetReconnect = () => {
    this.setState(({ step }) => ({ step: step - 1, error: null }));
  };

  /**
   * Cancel the session.
   *
   * @return {Void}
   */
  handleExit() {
    const { NO_CANCEL_PRIVACY } = process.env;

    setCookie('detailMatch', null, -1);
    if (NO_CANCEL_PRIVACY) {
      this.setState({ cancelled: true, confirm: false });
    } else {
      this.setState({ exitBtnLoading: true });
      APIs.cancel();
    }
  }

  handleSelectCountry = (iso) => {
    this.setState({
      country: iso.country
    });

    this.handleNextStep(iso);
  };

  showQuestionnaire() {
    const { ENABLE_QUESTIONNAIRE = false } = process.env;

    const { questionnaire } = this.props;

    return ENABLE_QUESTIONNAIRE && questionnaire;
  }

  questionnairePosition() {
    const { questionnaire } = this.props;

    return questionnaire.position;
  }

  showQuestionnaireBefore() {
    return this.showQuestionnaire() && this.questionnairePosition() === 'BEFORE';
  }

  renderLanguageSelection() {
    const { showLanguageSelectionPrompt } = this.state;

    if (!showLanguageSelectionPrompt) {
      return null;
    }

    const selectedlanguage = getLanguage();

    return (
      <Language
        selected={selectedlanguage}
        onClose={(selected) => {
          this.setState({
            showLanguageSelectionPrompt: false
          });

          if (document.body.dataset.language === selected) {
            // Do nothing
            return;
          }

          APIs.language(selected).then(() => {
            let currentUrl = window.location.href;
            if (currentUrl.substring(currentUrl.length - 1) !== '/') {
              currentUrl += '/';
            }

            const newUrl = currentUrl + document.body.dataset.id;
            window.location.href = newUrl;
          });
        }}
      />
    );
  }

  /**
   * Render the component's.
   *
   * @return {ReactElement}
   */
  render() {
    const { questionnaire } = this.props;
    const {
      step,
      idType,
      tokenId,
      accepted,
      cancelled,
      confirm,
      confirmFR,
      error,
      tenMinsLeft,
      backOfCard,
      compatible,
      completed,
      result,
      isLandscape,
      geolocation,
      country,
      questionnaireComplete,
      verify,
      modalError,
      webrtc = {},
      nextCapture,
      exitBtnLoading,
      showDetailMatch,
      redirect
    } = this.state;
    const {
      APP_NAME,
      REMOVE_ADDRESS_REVIEW = false,
      COUNTRIES_ONLY_STAGING = false,
      MULTI_LANGUAGE
    } = process.env;
    let { COUNTRIES } = process.env;
    if (!COUNTRIES && envPatch[APP_NAME]) {
      COUNTRIES = envPatch[APP_NAME].COUNTRIES;
    }

    const { todo } = webrtc;
    const { component: Error, props: errorProps } = error || {};

    // only Staging
    if (COUNTRIES_ONLY_STAGING && !window.location.href.includes('staging')) {
      COUNTRIES = undefined;
    }
    /**
     * Button states
     */
    const largeShadow = { large: true, shadow: true };
    const confirmBtns = [
      {
        label: localizedString('back'),
        onClick: () => this.setState({ confirm: false }),
        variant: 'outline'
      },
      {
        label: localizedString('yes'),
        onClick: this.handleExit,
        loading: exitBtnLoading
      }
    ];
    const openChromeBtns = [
      {
        label: localizedString('proceed'),
        ...largeShadow,
        onClick: () => {
          const { id } = document.body.dataset;
          const url = `googlechrome://navigate?url=${document.location.href}${id}`;
          document.location = url;
          setTimeout(() => {
            this.setState({ compatible: true });
          }, 1000);
        }
      }
    ];
    const openSafariBtns = [
      {
        children: (
          <a className="link-inside-button" href="x-web-search://" target="_self">
            {localizedString('copyLinkOpenSafari')}
          </a>
        ),
        ...largeShadow,
        onClick: () => copyOpenSafari(this.input)
      }
    ];
    const tenMinsLeftBtns = [
      {
        label: localizedString('ok'),
        onClick: () => this.setState({ tenMinsLeft: false })
      }
    ];

    const confirmBtnsFR = [
      {
        label: localizedString('cancel'),
        onClick: () => this.setState({ confirmFR: false }),
        variant: 'outline'
      },
      {
        label: localizedString('yesImSure'),
        onClick: () => {
          setCookie('retry', null, -7);
          setCookie('retryAttempt', null, -7);
          this.setState({ step: 0, confirmFR: false });
        }
      }
    ];

    /**
     * Desktop flows
     */
    // if (!isMobileDevice()) {
    //     if (process.env.SMS) {
    //         if (!ipCountry) {
    //            return(<LoadingSpinner title={localizedString("pleaseWait")} />)
    //         }
    //         return <SendSMS resendCount={this.state.user_resend_count} ipCountry={ipCountry} countryCode={country} />;
    //     }

    //     return <div />;
    // }

    // Now it's a mobile device. Check if Browser is Firefox, as Firefox is not permitted for mobile flow
    if (isAndroidDevice() && !isBrowserPermitted()) {
      return <DeviceIncompatibleOpenChrome buttons={openChromeBtns} />;
    }

    /**
     * Exceptions for device compatible.
     */
    if (!compatible) {
      if (todo === 'OPEN_SAFARI') {
        return (
          <Message
            title={localizedString('deviceIncompatible')}
            buttons={openSafariBtns}
            issue={localizedString('useSafari')}
          >
            {localizedString('useSafariDesc2_Line1')}
            <br />
            <br />
            {localizedString('useSafariDesc2_Line2')}
            <input
              readOnly
              className="b-hidden-input"
              ref={(ref) => {
                this.input = ref;
              }}
            />
          </Message>
        );
      }
      if (todo === 'NEED_ALT_FLOW') {
        const url = `${window.location.href}?flow=alternate`;
        window.location.replace(url);
      }
    }

    let languageSelectionHandler;
    if (MULTI_LANGUAGE === 'true') {
      languageSelectionHandler = () => this.setState({ showLanguageSelectionPrompt: true });
    }

    return (
      <div>
        {modalError && <Modal isOpen {...modalError} />}

        {Error && <Error {...errorProps} />}

        {isLandscape && <Message landscope />}

        {this.showQuestionnaireBefore() && !questionnaireComplete && (
          <Questionnaire
            questionnaire={questionnaire}
            onComplete={() => this.setState({ questionnaireComplete: true })}
          />
        )}

        {/* Privacy */}
        {!showDetailMatch &&
          !accepted &&
          isShownPrivacy('DOC_ONLY') &&
          (!this.showQuestionnaireBefore() ||
            (this.showQuestionnaireBefore() && questionnaireComplete)) && (
            <Privacy
              onSelectLanguage={languageSelectionHandler}
              onExit={() => this.setState({ confirm: true })}
              onAccept={() => this.setState({ accepted: true })}
            />
          )}
        {/* End privacy */}

        {/* Pages */}
        {((isShownPrivacy('DOC_ONLY') && accepted) || !isShownPrivacy('DOC_ONLY')) &&
          !Error &&
          !completed && (
            <div>
              {!step && (
                <Overview
                  onExit={() => this.setState({ confirm: true })}
                  onStart={COUNTRIES ? this.handleNextStep : () => this.setState({ step: 2 })}
                />
              )}
              {COUNTRIES && step === 1 && (
                <Country
                  onGoBack={this.handleGoBack}
                  onSelect={this.handleSelectCountry}
                  country={country}
                />
              )}
              {step === 2 && (
                <IdSelection
                  onGoBack={COUNTRIES ? this.handleGoBack : () => this.setState({ step: 0 })}
                  onSelect={this.handleNextStep}
                  country={country}
                />
              )}
              {step === 3 && (
                <Capture
                  idType={idType}
                  backOfCard={backOfCard}
                  onGoBack={this.handleGoBack}
                  onNextStep={this.handleNextStep}
                  country={country}
                  frontTokenId={tokenId || null}
                  nextCapture={nextCapture}
                  onExit={() => this.setState({ confirm: true })}
                />
              )}
              {step === 4 && (
                <VerifyDetails
                  captureBack={(nextCapture = null) => {
                    if (nextCapture) {
                      this.setState({ step: 3, nextCapture });
                    } else {
                      this.setState({ step: 3 });
                    }
                  }}
                  idType={idType}
                  countryCode={country}
                  token={tokenId}
                  verify={verify}
                  location={geolocation}
                  onNextStep={
                    REMOVE_ADDRESS_REVIEW ? () => this.handleComplete : this.handleNextStep
                  }
                  retake={this.handleGoBack}
                  onExit={() => this.setState({ confirm: true })}
                  goToIdSelection={() => {
                    this.setState({ step: 2 });
                  }}
                />
              )}
              {step === 5 && !REMOVE_ADDRESS_REVIEW && (
                <VerifyAddress
                  onNextStep={this.handleComplete}
                  onGoBack={this.handleGoBack}
                  onExit={() => this.setState({ confirm: true })}
                  location={geolocation}
                  on500Error={() => this.setState({ step: 4 })}
                  countryCode={country}
                />
              )}
            </div>
          )}
        {/* Exceptions */}
        {cancelled && (
          <Message logoFooter cancelled title={localizedString('sessionCancelled')}>
            {localizedString('sessionCancelledDesc1')}
            <br />
            <br />
            {localizedString('sessionCancelledDesc2')}
          </Message>
        )}
        {tenMinsLeft && (
          <Modal isOpen issue={localizedString('tenMinsLeftDesc')} buttons={tenMinsLeftBtns} />
        )}
        {completed && <Success redirect={redirect} />}

        {showDetailMatch && <DetailMatch />}
        <Modal
          isOpen={confirm}
          issue={localizedString('cancelConfirmation')}
          buttons={confirmBtns}
        />
        <Modal
          isOpen={confirmFR}
          issue={localizedString('scanIDAgainPersonalDetails')}
          buttons={confirmBtnsFR}
        />
        {/* End exceptions */}
        {result && <Results {...result} />}

        {this.renderLanguageSelection()}
      </div>
    );
  }
}

App.propTypes = {
  verify: PropTypes.bool,
  isCompleted: PropTypes.bool,
  questionnaire: PropTypes.object
};

export default connect(mapStateToProps, null)(App);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ information, appConfig }) {
  return {
    idDetails: information.idDetails,
    addresses: information.addresses,
    appConfig
  };
}
