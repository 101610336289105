import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Validation } from 'calidation';
import classNames from 'classnames';
import { Select } from '@lib/components/v2/Form';
import { localizedString } from '@languages';
import classes from './MoreInfo.style.module.scss';

export default class MoreInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      citizenship: {
        value: 'Australia',
        label: 'Australia'
      },
      occupation: ''
    };
  }

  render() {
    const { optionsDict, onChange } = this.props;
    const { citizenship, occupation } = this.state;

    const config = {
      citizenship: {
        isRequired: localizedString('chooseCitizenship')
      },
      occupation: {
        isRequired: localizedString('chooseOccupation')
      }
    };

    const citizenshipOptions = optionsDict.citizenship.map((item) => {
      return {
        value: item,
        label: item
      };
    });

    const occupationOptions = optionsDict.occupation.map((item) => {
      return {
        value: item,
        label: item
      };
    });

    return (
      <div className={classNames(classes.wrapper)}>
        <div className={classNames(classes.heading)}>Please tell us more about you</div>
        <div className={classNames(classes.description)}>This also helps verify your identity.</div>
        <div className={classNames(classes.container)}>
          <div>
            <div className={classNames(classes.subheading)}>Citizenship</div>
            <span className={classNames(classes.selectDescription)}>
              Select your country of citizenship
            </span>
            <div className={classNames(classes.select)}>
              <Validation config={config}>
                {() => {
                  return (
                    <Select
                      id="citizenship"
                      name="citizenship"
                      label={localizedString('citizenship')}
                      className={classNames(classes.select)}
                      options={citizenshipOptions}
                      onChange={(value) => {
                        onChange('citizenship', value);
                        this.setState({ citizenship: value });
                      }}
                      value={citizenship}
                    />
                  );
                }}
              </Validation>
            </div>
          </div>
          <div>
            <div className={classNames(classes.subheading)}>Occupation</div>
            <span className={classNames(classes.selectDescription)}>
              {localizedString('chooseOccupation')}
            </span>
            <div className={classNames(classes.select)}>
              <Validation config={config}>
                {() => {
                  return (
                    <Select
                      id="occupation"
                      name="citizenship"
                      label={localizedString('occupation')}
                      className={classNames(classes.select)}
                      options={occupationOptions}
                      onChange={(value) => {
                        onChange('occupation', value);
                        this.setState({ occupation: value });
                      }}
                      value={occupation}
                    />
                  );
                }}
              </Validation>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MoreInfo.propTypes = {
  optionsDict: PropTypes.object,
  onChange: PropTypes.func
};
