import PropTypes from 'prop-types';
import { useState } from 'react';
import APIs from '@services/APIs';
import { ConfirmAgePrompt } from './ConfirmAgePrompt';
import { UnderEligibleAgeMessage } from './UnderEligibleAgeMessage';

const { CANCEL_TXN_FOR_NOT_ELIGIBLE_AGE = false } = process.env;

export const AgeReview = ({ eligibleAge, onTryAgain }) => {
  const [isNotEligibleAgeConfirmed, setIsNotEligibleAgeConfirmed] = useState(false);

  const handleOnConfirmNotEligibleAge = () => {
    if (CANCEL_TXN_FOR_NOT_ELIGIBLE_AGE) {
      APIs.cancelTransactionInBackground();
    }
    setIsNotEligibleAgeConfirmed(true);
  };

  return (
    <>
      {!isNotEligibleAgeConfirmed && (
        <ConfirmAgePrompt
          eligibleAge={eligibleAge}
          onConfirm={handleOnConfirmNotEligibleAge}
          onReject={onTryAgain}
        />
      )}

      {isNotEligibleAgeConfirmed && <UnderEligibleAgeMessage eligibleAge={eligibleAge} />}
    </>
  );
};

AgeReview.propTypes = {
  eligibleAge: PropTypes.number,
  onTryAgain: PropTypes.func
};
