import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './ButtonGroup.style.module.scss';

/**
 * A wrapper to handle buttons.
 */
const ButtonGroup = ({ children, btnCount }) => {
  return (
    <div className={classes.wrapper}>
      <div
        className={classNames(btnCount > 1 && classes.buttons, btnCount === 1 && classes.btnFull)}
      >
        {children}
      </div>
    </div>
  );
};

ButtonGroup.propTypes = {
  children: PropTypes.node,
  btnCount: PropTypes.number
};

ButtonGroup.defaultProps = {};

export default ButtonGroup;
