/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import '../../../styles/main.scss';
import Modal from '@lib/components/v2/Modal';

import { DetailMatch } from '@containers';

import {
  isWebRTCSupported,
  copyOpenSafari,
  isAndroidDevice,
  isBrowserPermitted,
  setCookie,
  getCookie,
  isShownPrivacy
} from '@lib/Utils';
import APIs from '@services/APIs';
import { envPatch } from '@languages/env_patch';
import { localizedString } from '@languages';
import parse from 'html-react-parser';
import draftToHtml from 'draftjs-to-html';
import {
  Error500,
  InternetCut,
  DeviceIncompatibleOpenChrome,
  Timeout,
  Error400
} from '../../errors';
import {
  Privacy,
  Capture,
  VerifyDetails,
  FaceScan,
  MoreInfo,
  Datacheck,
  AlternateFlow,
  Success,
  Questionnaire
} from '..';
import { LoadingSpinner, LoadingBar, Message, Language } from '../../components';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      idType: 'PASSPORT',
      tokenId: '',
      geolocation: '',
      questionnaireComplete: false,
      accepted: false,
      cancelled: false,
      confirm: false,
      confirmFR: false,
      error: null,
      tenMinsLeft: false,
      compatible: true,
      completed: false,
      isLandscape: false,
      isProcessing: false,
      isUploading: false,
      uploadBar: 0,
      webrtc: {
        todo: null,
        status: true
      },
      redirect: false,
      verify: false,
      verifyDetails: {},
      showLanguageSelectionPrompt: false,
      frRetryAttemptCount: 0,
      selfieFR: false,
      dataCheckOnConfirm: false,
      documentId: null,
      selectedDiffId: null,
      isFlowV2DiffId: false,
      showDetailMatch: false
    };

    this.input = null;

    this.handleComplete = this.handleComplete.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleExit = this.handleExit.bind(this);
  }

  async componentDidMount() {
    const { verify } = this.props;
    this.setState({ verify });

    const { APP_NAME, DETAIL_MATCH, DETAIL_MATCH_ONLY_UNIVERSAL_URL } = process.env;
    let { COUNTRIES } = process.env;
    if (!COUNTRIES && envPatch[APP_NAME]) {
      COUNTRIES = envPatch[APP_NAME].COUNTRIES;
    }

    const { status: compatible, todo, os } = await isWebRTCSupported();
    // Init device compatible
    if (window.location.search === '?flow=alternate') {
      // for testing
      this.setState({ compatible, webrtc: { todo: 'ALT_FLOW', status: false } });
    } else {
      this.setState({ compatible, webrtc: { todo, status: compatible } });
    }

    if (os === 'iOS') {
      document.addEventListener(
        'touchmove',
        (event) => {
          if (event.scale !== 1) {
            event.preventDefault();
          }
        },
        { passive: false }
      );
    }

    // Detect if internet cut
    window.addEventListener('offline', () =>
      this.setState({
        error: {
          component: InternetCut,
          props: {}
        }
      })
    );
    // Detect if internet re connected
    window.addEventListener('online', () => {
      this.setState({
        error: {
          component: InternetCut,
          props: {
            isOnline: true,
            onGoBack: this.handleInternetReconnect
          }
        }
      });
    });

    const transToken = document.querySelector('body').getAttribute('data-id');
    const storedTransToken = getCookie('transToken');

    if (transToken === storedTransToken) {
      if (getCookie('_permission') === 1) {
        // Capturing ID
        this.setState({ step: 0, accepted: true });
        setCookie('_permission', 0, -7);
      } else if (getCookie('_permission') === 2) {
        // Liveness step
        this.setState({ step: process.env.COUNTRIES ? 6 : 5, accepted: true });
        setCookie('_permission', 0, -7);
      }

      const FRAttempt = parseInt(getCookie('retryAttempt'), 10) || parseInt(getCookie('retry'), 10);
      if (FRAttempt) {
        this.setState({
          step: 3
        });
      }
    } else {
      setCookie('transToken', transToken, -7);
    }

    // Landscape
    const isLandscape = window.innerHeight < window.innerWidth;
    this.setState({ isLandscape });
    window.addEventListener('orientationchange', () => {
      this.setState(({ isLandscape }) => ({ isLandscape: !isLandscape }));
    });

    // Show detail match
    const path = window.location.pathname;
    const detailMatch = getCookie('detailMatch');
    if ((!detailMatch || detailMatch !== 'yes') && DETAIL_MATCH && DETAIL_MATCH.length > 0) {
      let showDetailMatch = true;
      const { UNIVERSAL_URL_PATH = '/verify' } = process.env;
      if (DETAIL_MATCH_ONLY_UNIVERSAL_URL && path !== UNIVERSAL_URL_PATH) {
        showDetailMatch = false;
      }
      this.setState({
        showDetailMatch
      });
    }

    // Start the timeout for 1 hour.
    let countdown = 60 * 60;
    const timerId = setInterval(() => {
      if (countdown === 600) {
        this.setState({
          tenMinsLeft: true
        });
      }

      if (countdown <= 0) {
        this.setState({
          tenMinsLeft: false,
          error: {
            component: Timeout,
            props: {}
          }
        });
        clearInterval(timerId);
      }
      countdown--;
    }, 1000);
  }

  /**
   * Unbind the event listener
   */
  componentWillUnmount() {
    window.removeEventListener('orientationchange', () => {
      this.setState(({ isLandscape }) => ({ isLandscape: !isLandscape }));
    });
  }

  /**
   * When all flows have been completed.
   */
  handleComplete({ sessionId: id, lr, liveness }) {
    const { frRetryAttemptCount } = this.state;
    const { REMOVE_ADDRESS_REVIEW = false, WSS_URL } = process.env;
    /**
     * Upload face scan video.
     */
    const data = { id, lr, actions: `Smile, Turn head ${lr ? 'left' : 'right'}`, isEngineV4: true };
    if (liveness) {
      data.success = 1;
    }

    let axiosParams;
    if (WSS_URL.includes('liveness.idkit.io')) {
      this.setState({ isProcessing: true });
    } else {
      this.setState({ uploadBar: 0, isUploading: true });
      axiosParams = {
        before: () => this.setState({ uploadBar: 0, isUploading: true }),
        onProgress: (width) => this.setState({ uploadBar: width })
      };
    }

    APIs.uploadVideo(data, axiosParams, '/api/v4')
      .then(({ status, token, msg: error, action = null }) => {
        if (status !== 'success') {
          if (action === 'ALTERNATE') {
            this.setState({
              webrtc: { todo: 'ALT_FLOW', status: false },
              step: 2,
              completed: false,
              isProcessing: false,
              isUploading: false
            });
            return;
          }
          console.error('video upload failed', { data, error });
          throw new Error(error);
        }

        /**
         * Get face scan results.
         */
        APIs.verifyScans(token)
          .then(({ status, redirectTo }) => {
            if (status !== 'success') {
              console.error('face scan data failed');
              throw new Error(error);
            }

            this.finishFlow({ completed: true, redirectTo });
          })
          .catch((err) => {
            console.warn('this.state.frRetryAttemptCount', frRetryAttemptCount);
            if (err && err.response && err.response.status === 400 && frRetryAttemptCount < 2) {
              const error = {
                component: Error400,
                props: {
                  onRetryAgain: () => {
                    if (REMOVE_ADDRESS_REVIEW) {
                      this.setState({
                        step: 4,
                        error: null,
                        frRetryAttemptCount: frRetryAttemptCount === 0 ? 1 : frRetryAttemptCount + 1
                      });
                    } else {
                      this.setState({
                        step: 6,
                        error: null,
                        frRetryAttemptCount: frRetryAttemptCount === 0 ? 1 : frRetryAttemptCount + 1
                      });
                    }
                  }
                }
              };
              this.setState({ error, isProcessing: false, isUploading: false });
            } else {
              const error = {
                component: Error500,
                props: {
                  onTryAgain: () => {
                    if (REMOVE_ADDRESS_REVIEW) {
                      this.setState({ step: 4, error: null });
                    } else {
                      this.setState({ step: 6, error: null });
                    }
                  }
                }
              };
              this.setState({ error, isProcessing: false, isUploading: false });
            }
          });
      })
      .catch((err) => {
        console.error(err);
        const error = {
          component: Error500,
          props: {
            onTryAgain: () => {
              if (REMOVE_ADDRESS_REVIEW) {
                this.setState({ step: 4, error: null });
              } else {
                this.setState({ step: 6, error: null });
              }
            }
          }
        };
        this.setState({ error, isProcessing: false, isUploading: false });
      });
  }

  /**
   * Handle complete without Liveness
   *
   * @param {Object} state
   * @return {Void}
   */
  handleCompleteDocOnly = () => {
    this.finishFlow({ completed: true });
  };

  /**
   * Go to next step.
   *
   * @param {Object} state
   * @return {Void}
   */
  handleNextStep(state) {
    const { SHOW_MORE_INFO_PAGE } = process.env;
    const { appConfig } = this.props;
    const { isFlowV2DiffId, step } = this.state;
    const showMoreInfoScreen = appConfig.showMoreInfoScreen || SHOW_MORE_INFO_PAGE;

    if (state && state.isDataOnlyCompleted) {
      this.handleCompleteDocOnly();
      return;
    }

    if (step === 1 && showMoreInfoScreen && isFlowV2DiffId) {
      this.setState(({ step }) => ({ step: step + 2, ...state }));
    } else {
      this.setState(({ step }) => ({ step: step + 1, ...state }));
    }
  }

  /**
   * Go back to pev step.
   *
   * @return {Void}
   */
  handleGoBack() {
    const { step } = this.state;
    if (step && step > 0) {
      if (step === 1) {
        this.setState(({ step }) => ({
          step: step - 1,
          verifyDetails: {},
          frRetryAttemptCount: 0,
          selfieFR: false,
          dataCheckOnConfirm: false,
          documentId: null,
          selectedDiffId: null,
          isFlowV2DiffId: false
        }));
      } else {
        this.setState(({ step }) => ({ step: step - 1 }));
      }
    } else {
      this.setState({ accepted: false });
    }
  }

  /**
   * Handle internet reconnection
   *
   * @return {Void}
   */
  handleInternetReconnect = () => {
    this.setState(({ step }) => ({ step: step - 1, error: null }));
  };

  /**
   * Cancel the session.
   *
   * @return {Void}
   */
  handleExit() {
    setCookie('detailMatch', null, -1);
    this.setState({ confirm: false });
    this.handleGoBack();
  }

  handleRedirection = ({ redirectTo = false, timeoutInSeconds = 3 }) => {
    if (redirectTo) {
      this.setState({ isProcessing: false, isUploading: false, redirect: true, completed: true });
      setTimeout(() => {
        APIs.return();
      }, timeoutInSeconds * 1000);
    } else {
      this.setState({ isProcessing: false, isUploading: false, completed: true });
    }
  };

  // eslint-disable-next-line class-methods-use-this
  cleanCookies = () => {
    setCookie('retryAsf', 'no', -10);
    setCookie('retry', null, -7);
    setCookie('retryCaptureWithVideoStream', null, -7);
    setCookie('retryAttempt', null, -7);
    setCookie('idCaptureAttempt', 0, -7);
    setCookie('detailMatch', 'false', -1);
    setCookie('_permission', null, -7);
  };

  finishFlow = ({ completed, redirectTo }) => {
    this.cleanCookies();

    if (completed) {
      APIs.markCompleted().then(({ redirectTo = null }) => {
        this.handleRedirection({ redirectTo });
      });
    } else {
      this.handleRedirection({ redirectTo });
    }
  };

  handleFRGoBack = () => {
    const { REMOVE_ADDRESS_REVIEW = false } = process.env;
    const { idDetails = {} } = this.props;

    const reloaded = parseInt(getCookie('retryAttempt'), 10) || parseInt(getCookie('retry'), 10);
    if (reloaded) {
      this.setState({ confirm: true, confirmFR: true });
      return;
    }

    if (REMOVE_ADDRESS_REVIEW || (idDetails && idDetails.address)) {
      this.setState({ step: 1 });
    } else {
      this.handleGoBack();
    }
  };

  updateDocId = (documentId) => {
    this.setState({
      documentId
    });
  };

  handleChooseDiffId = (newDoc) => {
    this.setState({
      step: 0,
      selectedDiffId: newDoc,
      isFlowV2DiffId: true
    });
  };

  clearDiffID = () => {
    this.setState({
      selectedDiffId: null
    });
  };

  showQuestionnaire() {
    const { ENABLE_QUESTIONNAIRE = false } = process.env;

    const { questionnaire } = this.props;

    return ENABLE_QUESTIONNAIRE && questionnaire;
  }

  questionnairePosition() {
    const { questionnaire } = this.props;

    return questionnaire.position;
  }

  showQuestionnaireBefore() {
    return this.showQuestionnaire() && this.questionnairePosition() === 'BEFORE';
  }

  /**
   * Render the component's.
   *
   * @return {ReactElement}
   */
  render() {
    const {
      step,
      idType,
      tokenId,
      cancelled,
      confirm,
      confirmFR,
      error,
      tenMinsLeft,
      accepted,
      compatible,
      completed,
      isProcessing,
      isUploading,
      uploadBar,
      isLandscape,
      questionnaireComplete,
      geolocation,
      verify,
      modalError,
      webrtc = {},
      nextCapture,
      showLanguageSelectionPrompt,
      verifyDetails,
      useIdNumber,
      isOldTurkishDL,
      selfieFR,
      dataCheckOnConfirm,
      documentId,
      selectedDiffId,
      isFlowV2DiffId,
      showDetailMatch,
      redirect
    } = this.state;
    const { SHOW_MORE_INFO_PAGE = false } = process.env;
    const { todo, status: isWebRTC } = webrtc;
    const { component: Error, props: errorProps } = error || {};

    const { flowType, questionnaire, appConfig = {}, hasLivenessScreen, transStatus } = this.props;

    const {
      FLOW_V2_EXIT_SCREEN_TITLE = localizedString('leaveConfirmation'),
      FLOW_V2_EXIT_SCREEN_DESCRIPTION_CAPTURE = localizedString('scanIDAgainInformationOnScreen'),
      FLOW_V2_EXIT_SCREEN_DESCRIPTION_DETAILS = localizedString('scanIDAgainPersonalDetails'),
      FLOW_V2_EXIT_SCREEN_CANCEL_BUTTON = localizedString('cancel'),
      FLOW_V2_EXIT_SCREEN_OK_BUTTON = localizedString('yesImSure'),
      FLOW_V2_INCORRECT_URL_ALERT_TITLE = localizedString('incorrectUrl'),
      FLOW_V2_INCORRECT_URL_ALERT_DESCRIPTION = localizedString('urlProvidedError'),
      FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_TITLE_IOS = localizedString('needToUseSafari'),
      FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_DESCRIPTION_IOS = `${localizedString(
        'useSafariDesc2_Line1'
      )}<br/><br/>${localizedString('useSafariDesc2_Line2')}`,
      FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_BUTTON_ANDROID = localizedString('proceed'),
      FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_BUTTON_IOS = localizedString('copyLinkOpenSafari'),
      FLOW_V2_VERIFICATION_COMPLETED_ALERT_TITLE = localizedString('verificationCompleted'),
      FLOW_V2_VERIFICATION_COMPLETED_ALERT_DESCRIPTION = localizedString(
        'verificationCompletedAlert'
      ),
      FLOW_V2_VERIFICATION_EXPIRED_ALERT_TITLE = localizedString('verificationExpired'),
      FLOW_V2_VERIFICATION_EXPIRED_ALERT_DESCRIPTION = localizedString('verificationExpiredAlert'),
      FLOW_V2_SESSION_CANCELLED_ALERT_TITLE = localizedString('sessionCancelled'),
      FLOW_V2_SESSION_CANCELLED_ALERT_DESCRIPTION = `${localizedString(
        'verificationCancelled'
      )}<br/><br/>${localizedString('youMayCloseThisWindow')}`,
      FLOW_V2_FORCE_ALTERNATE_FLOW = false
    } = process.env;

    let verificationExpiredContent = FLOW_V2_VERIFICATION_EXPIRED_ALERT_DESCRIPTION;
    if (appConfig.expiryPageContent) {
      verificationExpiredContent = draftToHtml(appConfig.expiryPageContent);
    }

    /**
     * Button states
     */
    const confirmBtns = [
      {
        label: FLOW_V2_EXIT_SCREEN_CANCEL_BUTTON,
        onClick: () => this.setState({ confirm: false, confirmFR: false }),
        variant: 'transparent'
      },
      {
        label: FLOW_V2_EXIT_SCREEN_OK_BUTTON,
        onClick: () => {
          setCookie('retry', null, -7);
          setCookie('retryAttempt', null, -7);
          this.setState({ step: 0, confirm: false, confirmFR: false });
          // this.handleGoBack();
        }
      }
    ];
    const openChromeBtns = [
      {
        label: FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_BUTTON_ANDROID,
        full: true,
        onClick: () => {
          const { id } = document.body.dataset;
          document.location = `googlechrome://navigate?url=${document.location.href}${id}`;
          setTimeout(() => {
            this.setState({ compatible: true });
          }, 1000);
        }
      }
    ];
    const openSafariBtns = [
      {
        children: (
          <a className="link-inside-button" href="x-web-search://" target="_self">
            {FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_BUTTON_IOS}
          </a>
        ),
        full: true,
        onClick: () => copyOpenSafari(this.input)
      }
    ];
    const tenMinsLeftBtns = [
      {
        label: localizedString('ok'),
        onClick: () => this.setState({ tenMinsLeft: false })
      }
    ];

    /**
     * Exceptions for device compatible.
     */
    if (!compatible) {
      if (todo === 'OPEN_SAFARI') {
        return (
          <Message
            title={FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_TITLE_IOS}
            buttons={openSafariBtns}
            issue={localizedString('useSafari')}
          >
            {parse(FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_DESCRIPTION_IOS)}
            <input
              readOnly
              className="b-hidden-input"
              ref={(ref) => {
                this.input = ref;
              }}
            />
          </Message>
        );
      }
      if (todo === 'NEED_ALT_FLOW') {
        const url = `${window.location.href}?flow=alternate`;
        window.location.replace(url);
      }
    }

    // Check if Browser is Firefox, as Firefox is not permitted for mobile flow
    if (isAndroidDevice() && !isBrowserPermitted()) {
      return <DeviceIncompatibleOpenChrome issue={false} buttons={openChromeBtns} />;
    }

    const newScreens = [];
    const showMoreInfoScreen = appConfig.showMoreInfoScreen || SHOW_MORE_INFO_PAGE;
    if (showMoreInfoScreen) {
      newScreens.push('SHOW_MORE_INFO_PAGE');
    }
    if (dataCheckOnConfirm) {
      newScreens.push('FLOW_V2_PROCESS_DATA');
    }

    if (showDetailMatch) {
      return <DetailMatch />;
    }

    return (
      <div>
        {modalError && <Modal isOpen {...modalError} />}
        {Error && <Error {...errorProps} />}
        {isLandscape && <Message landscape />}
        {showLanguageSelectionPrompt && (
          <Language onGoback={() => this.setState({ showLanguageSelectionPrompt: false })} />
        )}
        {/* Pages */}
        {this.showQuestionnaireBefore() && !questionnaireComplete && (
          <Questionnaire
            questionnaire={questionnaire}
            onComplete={() => this.setState({ questionnaireComplete: true })}
          />
        )}
        {!accepted &&
          !showLanguageSelectionPrompt &&
          isShownPrivacy('FLOW_V2') &&
          !completed &&
          (!this.showQuestionnaireBefore() ||
            (this.showQuestionnaireBefore() && questionnaireComplete)) &&
          !(
            transStatus === 'COMPLETED' ||
            transStatus === 'EXPIRED' ||
            transStatus === 'CANCELLED' ||
            transStatus === '404'
          ) && (
            <Privacy
              flowType={flowType}
              onAccept={() => this.setState({ accepted: true })}
              onSelectLanguage={() => this.setState({ showLanguageSelectionPrompt: true })}
            />
          )}
        {((isShownPrivacy('FLOW_V2') && accepted) || !isShownPrivacy('FLOW_V2')) &&
          !Error &&
          !showLanguageSelectionPrompt &&
          !completed &&
          !isProcessing &&
          !(
            transStatus === 'COMPLETED' ||
            transStatus === 'EXPIRED' ||
            transStatus === 'CANCELLED' ||
            transStatus === '404'
          ) && (
            <div>
              {!step && (
                <Capture
                  isFlowV2DiffId={isFlowV2DiffId}
                  selectedDiffId={selectedDiffId}
                  onGoBack={this.handleGoBack}
                  onNextStep={this.handleNextStep}
                  onCloseAcceptedDocs={this.clearDiffID}
                  onGeoLocation={(geolocation) => this.setState({ geolocation })}
                  nextCapture={nextCapture}
                  setDocId={this.updateDocId}
                  flowType={flowType}
                  verify={verify}
                  onExit={() => this.setState({ confirm: true })}
                />
              )}
              {step === 1 && (
                <VerifyDetails
                  captureBack={(nextCapture = null) => {
                    if (nextCapture) {
                      this.setState({ step: 0, nextCapture });
                    } else {
                      this.setState({ step: 0 });
                    }
                  }}
                  verifyDetails={verifyDetails}
                  useIdNumber={useIdNumber}
                  isOldTurkishDL={isOldTurkishDL}
                  isFlowV2DiffId={isFlowV2DiffId}
                  onChooseDiffId={this.handleChooseDiffId}
                  idType={idType}
                  token={tokenId}
                  verify={verify}
                  flowType={flowType}
                  location={geolocation}
                  onNextStep={(state) => {
                    if (!hasLivenessScreen && !state.dataCheckOnConfirm && !showMoreInfoScreen) {
                      this.handleCompleteDocOnly();
                    } else {
                      this.handleNextStep(state);
                    }
                  }}
                  retake={this.handleGoBack}
                  onExit={() => this.setState({ confirm: true })}
                />
              )}
              {step === 2 && showMoreInfoScreen && (
                <MoreInfo
                  token={tokenId}
                  onNextStep={
                    !hasLivenessScreen && !dataCheckOnConfirm
                      ? this.handleCompleteDocOnly
                      : this.handleNextStep
                  }
                  onExit={() => this.setState({ confirm: true })}
                />
              )}
              {((step === 2 && !showMoreInfoScreen) || (step === 3 && showMoreInfoScreen)) &&
                dataCheckOnConfirm && (
                  <Datacheck
                    isFlowV2DiffId={isFlowV2DiffId}
                    documentId={documentId}
                    onChooseDiffId={this.handleChooseDiffId}
                    onNextStep={
                      !hasLivenessScreen ? this.handleCompleteDocOnly : this.handleNextStep
                    }
                  />
                )}
              {step === 2 + newScreens.length &&
                hasLivenessScreen &&
                !selfieFR &&
                isWebRTC &&
                !FLOW_V2_FORCE_ALTERNATE_FLOW && (
                  <FaceScan
                    idType={idType}
                    tokenId={tokenId}
                    onNextStep={this.handleComplete}
                    onGoBack={this.handleFRGoBack}
                    onSelfie={() => this.setState({ selfieFR: true })}
                    location={geolocation}
                  />
                )}
              {step === 2 + newScreens.length &&
                hasLivenessScreen &&
                (FLOW_V2_FORCE_ALTERNATE_FLOW || selfieFR || !isWebRTC) && (
                  <AlternateFlow onNextStep={this.finishFlow} onGoBack={this.handleFRGoBack} />
                )}
            </div>
          )}
        {/* Exceptions */}
        {cancelled && (
          <Message title={FLOW_V2_SESSION_CANCELLED_ALERT_TITLE} issue>
            {parse(FLOW_V2_SESSION_CANCELLED_ALERT_DESCRIPTION)}
          </Message>
        )}
        {tenMinsLeft && (
          <Modal isOpen heading={localizedString('tenMinsLeftDesc')} buttons={tenMinsLeftBtns} />
        )}
        {transStatus === 'COMPLETED' && (
          <Message title={FLOW_V2_VERIFICATION_COMPLETED_ALERT_TITLE} completed>
            {parse(FLOW_V2_VERIFICATION_COMPLETED_ALERT_DESCRIPTION)}
          </Message>
        )}
        {transStatus === 'EXPIRED' && (
          <Message title={FLOW_V2_VERIFICATION_EXPIRED_ALERT_TITLE} issue>
            {parse(verificationExpiredContent)}
          </Message>
        )}
        {transStatus === 'CANCELLED' && (
          <Message title={FLOW_V2_SESSION_CANCELLED_ALERT_TITLE} issue>
            {parse(FLOW_V2_SESSION_CANCELLED_ALERT_DESCRIPTION)}
          </Message>
        )}
        {transStatus === '404' && (
          <Message title={FLOW_V2_INCORRECT_URL_ALERT_TITLE} issue>
            {parse(FLOW_V2_INCORRECT_URL_ALERT_DESCRIPTION)}
          </Message>
        )}
        {completed &&
          !(
            transStatus === 'COMPLETED' ||
            transStatus === 'EXPIRED' ||
            transStatus === 'CANCELLED' ||
            transStatus === '404'
          ) && <Success redirect={redirect} />}
        <Modal
          isOpen={confirm}
          heading={FLOW_V2_EXIT_SCREEN_TITLE}
          description=""
          buttons={confirmBtns}
        >
          {confirmFR
            ? FLOW_V2_EXIT_SCREEN_DESCRIPTION_DETAILS
            : FLOW_V2_EXIT_SCREEN_DESCRIPTION_CAPTURE}
        </Modal>
        {/* End exceptions */}
        {/* Loadings */}
        {isUploading && <LoadingBar heading={localizedString('uploading')} width={uploadBar} />}
        {isProcessing && (
          <LoadingSpinner subtitle="" heading={localizedString('verifyingYourIdentity')} />
        )}
      </div>
    );
  }
}

App.propTypes = {
  verify: PropTypes.bool,
  appConfig: PropTypes.object,
  idDetails: PropTypes.object,
  questionnaire: PropTypes.object,
  flowType: PropTypes.string,
  hasLivenessScreen: PropTypes.bool,
  transStatus: PropTypes.string
};

export default connect(mapStateToProps, null)(App);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ information, appConfig }) {
  return {
    appConfig,
    idDetails: information.idDetails,
    addresses: information.addresses
  };
}
