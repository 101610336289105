import React from 'react';
import PropTypes from 'prop-types';
import draftToHtml from 'draftjs-to-html';
import { connect } from 'react-redux';
import { SuccessContent } from '@components/Contents';

function Success({ appConfig, redirect }) {
  const props = {
    successMessage: null,
    redirect
  };
  if (appConfig.success_message) {
    props.successMessage = draftToHtml(appConfig.success_message);
  }
  return <SuccessContent {...props} />;
}

Success.propTypes = {
  appConfig: PropTypes.object,
  redirect: PropTypes.bool
};

export default connect(mapStateToProps, null)(Success);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ appConfig }) {
  return {
    appConfig
  };
}
