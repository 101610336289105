import React from 'react';
import PropTypes from 'prop-types';
import { Message } from '@components';
import APIs from '@services/APIs';

const Success = ({ redirect = false }) => {
  const { DONE_BUTTON } = process.env;
  const largeShadow = { large: true, shadow: true };
  const completeBtns = [
    {
      label: 'Redirect',
      ...largeShadow,
      onClick: () => APIs.return()
    }
  ];

  return (
    <Message completed title="Questionnaire complete" buttons={DONE_BUTTON ? completeBtns : []}>
      <h3 className="b-title">
        <b>Thank you for providing your personal information.</b>
        <br />
        <br />
        <b>Next steps</b>
        <br />
        <br />
        Your details have been received and will now be reviewed. If anything further is required,
        one of our staff will be in touch to advise and guide you accordingly.
        <br />
        <br />
        You can close this window
      </h3>
      <br />
      {!DONE_BUTTON && redirect && <div>You will now be redirected.</div>}
      {DONE_BUTTON && <div>Press redirect if you are not redirected within 10 seconds.</div>}
    </Message>
  );
};

Success.propTypes = {
  redirect: PropTypes.bool
};

export default Success;
