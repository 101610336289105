import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import Page from '@lib/components/v2/Page';
import { localizedString } from '@languages';
import Animation from '@components/Animation';
import classes from './Prepare.style.module.scss';

let animationFaceId;
import('@FLOW_V2_FLOW/animations/anim_face_id.json').then((value) => {
  animationFaceId = value;
});

const Prepare = ({ selfie = false, onStart, onGoBack }) => {
  const {
    FLOW_V2_FR_TIPS_HEADING = 'Face Verification',
    FLOW_V2_FR_TIPS_DESCRIPTION = 'We need a short selfie video to help us match you to your ID, it’s completely private and will only take a few seconds.',
    FLOW_V2_FR_TIPS_SELFIE_DESCRIPTION = 'We need you to take a selfie to help us match you to your ID. On the next screen, take a clear picture of your face to proceed.',
    REDUCE_FACE_ANIMATION_SIZE,
    FLOW_V2_EXTRA_FR_TIPS
  } = process.env;

  let size = {};
  if (REDUCE_FACE_ANIMATION_SIZE && REDUCE_FACE_ANIMATION_SIZE > 0) {
    size = {
      width: `${100 - REDUCE_FACE_ANIMATION_SIZE}%`,
      margin: '0 auto'
    };
  }

  const buttons = [
    {
      label: localizedString('back'),
      variant: 'transparent',
      onClick: onGoBack,
      dataTestId: 'face-back'
    },
    {
      label: localizedString('continue'),
      type: 'submit',
      onClick: onStart,
      dataTestId: 'face-continue'
    }
  ];
  return (
    <Page hideLogo={false} buttons={buttons} className={classes.wrapper}>
      <div className={classes.heading} data-testid="face-heading">
        {FLOW_V2_FR_TIPS_HEADING}
      </div>
      <div className={classes.content} data-testid="face-instruction">
        {parse(selfie ? FLOW_V2_FR_TIPS_SELFIE_DESCRIPTION : FLOW_V2_FR_TIPS_DESCRIPTION)}

        <div
          style={size}
          className={classes['animation-container']}
          data-testid="face-instruction-img"
        >
          <Animation animationData={animationFaceId} />
        </div>
        {FLOW_V2_EXTRA_FR_TIPS && <p>{FLOW_V2_EXTRA_FR_TIPS}</p>}
      </div>
    </Page>
  );
};

Prepare.propTypes = {
  onStart: PropTypes.func,
  onGoBack: PropTypes.func,
  selfie: PropTypes.bool
};

Prepare.defaultProps = {
  onStart: () => {}
};

export default Prepare;
