import startCase from 'lodash/startCase';
import { localizedString } from '@languages';
import { getResidentalAddressLabel } from '@lib/addressfieldLabel';
import { isIdCard, isProofOfAgeDocument } from '@lib/constants/documents';
import { isCardNumberFieldVisible } from '../../cardNumberField';

const {
  FLOW_V2_VERIFY_DETAILS_LASTNAME = localizedString('surname'),
  FLOW_V2_VERIFY_DETAILS_EXPIRY_DATE = localizedString('dateOfExpiry'),
  FLOW_V2_NAMES_TITLE_CASE = false,
  REMOVE_ADDRESS_REVIEW = false,
  FLOW_V2_DIGITAL_DL_HIDE_CARD_NUMBER_FIELD = false
} = process.env;

const COUNTRY_NAMES_FOR_VARIANTS = {
  AUSTRALIA: 'AUSTRALIA',
  BRAZIL: 'BRAZIL',
  NEW_ZEALAND: 'NEW ZEALAND',
  TURKEY: 'TURKEY'
};

export const getFields = ({
  idType,
  countryOfIssue = '',
  addressData,
  useIdNumber,
  disabledFields = [],
  values = {},
  isOldTurkishDL
}) => {
  const upperCasedCountryOfIssue = countryOfIssue.toUpperCase();

  const fields = [
    { id: 'firstName', label: localizedString('firstName') },
    { id: 'middleName', label: localizedString('middleName') },
    { id: 'lastName', label: FLOW_V2_VERIFY_DETAILS_LASTNAME }
  ];

  if (!idType.match(/MEDICARE/i)) {
    fields.push({
      id: 'dateOfBirth',
      label: localizedString('dateOfBirth'),
      date: true
    });
  }

  /**
   * if ID type is Passport, it should display the Passport Number field
   * instead of the Licence Number and Licence Version.
   */
  if (idType.match(/PASSPORT/i)) {
    fields.push({
      id: 'idNumber',
      label: localizedString('idNumber')
    });

    // If visa then add following field
    const { ENABLE_VISA_AFTER_PASSPORT } = process.env;
    if (
      ENABLE_VISA_AFTER_PASSPORT &&
      upperCasedCountryOfIssue !== COUNTRY_NAMES_FOR_VARIANTS.NEW_ZEALAND &&
      upperCasedCountryOfIssue !== COUNTRY_NAMES_FOR_VARIANTS.AUSTRALIA
    ) {
      fields.push({
        id: 'visaStartDate',
        label: localizedString('visaStartDate'),
        date: true
      });
      fields.push({
        id: 'visaEndDate',
        label: localizedString('visaEndDate'),
        date: true
      });
    }
  } else if (idType.match(/LICENCE/i)) {
    fields.push({
      id: 'idNumber',
      label: localizedString('idNumber')
    });

    if (
      isCardNumberFieldVisible({
        idType,
        countryOfIssue,
        skipDigitalDriverLicence: FLOW_V2_DIGITAL_DL_HIDE_CARD_NUMBER_FIELD
      })
    ) {
      fields.push({
        id: 'cardNumber',
        label: localizedString('cardNumber')
      });
    }

    if (
      process.env.LICENCEVERSION &&
      upperCasedCountryOfIssue === COUNTRY_NAMES_FOR_VARIANTS.NEW_ZEALAND
    ) {
      fields.push({
        id: 'licenceVersion',
        type: 'number',
        label: localizedString('licenceVersion')
      });
    }

    if (shouldShowAdditionalTurkishDLFields(idType, upperCasedCountryOfIssue)) {
      fields.push({
        id: 'documentNumber',
        label: localizedString('trDlDocumentNumber')
      });
      if (!isOldTurkishDL) {
        fields.push({
          id: 'dlclassCodeBFrom',
          label: localizedString('bClassIssueDate'),
          date: true
        });
      }
    }
  } else if (!useIdNumber) {
    fields.push({
      id: 'cardNumber',
      label: localizedString('cardNumber')
    });
  } else {
    fields.push({
      id: 'idNumber',
      label: localizedString('idNumber')
    });
  }

  if (
    (isIdCard(idType) || idType.match(/LICENCE/i)) &&
    upperCasedCountryOfIssue === COUNTRY_NAMES_FOR_VARIANTS.BRAZIL
  ) {
    fields.push(
      {
        id: 'taxNumber',
        label: localizedString('taxNumber')
      },
      {
        id: 'documentNumber',
        label: localizedString('documentNumber')
      }
    );
  }

  if (
    (idType.match(/LICENCE/i) && !isOldTurkishDL) ||
    idType.match(/PASSPORT/i) ||
    idType.match(/IMMI/i) ||
    (isProofOfAgeDocument(idType) &&
      upperCasedCountryOfIssue !== COUNTRY_NAMES_FOR_VARIANTS.BRAZIL) ||
    values.expiryDate
  ) {
    if (shouldShowAdditionalTurkishDLFields(idType, upperCasedCountryOfIssue)) {
      fields.push({
        id: 'expiryDate',
        label: localizedString('bClassExpiryDate'),
        date: true
      });
    } else {
      fields.push({
        id: 'expiryDate',
        label: FLOW_V2_VERIFY_DETAILS_EXPIRY_DATE,
        date: true
      });
    }
  }

  if (!REMOVE_ADDRESS_REVIEW) {
    fields.push({
      id: 'addressData',
      label: getResidentalAddressLabel(),
      addressFinder: true
    });
  }

  // Set input default value
  return fields.map((field) => {
    let value = values[field.id] || '';
    let disabled = false;

    if (field.id === 'addressData') {
      value = addressData;
    } else {
      disabled = disabledFields.includes(field.id);
      if (value) {
        if (
          FLOW_V2_NAMES_TITLE_CASE &&
          (field.id === 'firstName' || field.id === 'middleName' || field.id === 'lastName')
        ) {
          value = startCase(value);
        }
      }
    }

    return {
      ...field,
      value,
      disabled
    };
  });
};

function shouldShowAdditionalTurkishDLFields(idType = '', upperCasedCountryOfIssue = '') {
  const { TURKISH_LICENCE_B_CLASS_ENABLED = false } = process.env;

  return (
    TURKISH_LICENCE_B_CLASS_ENABLED &&
    idType.match(/LICENCE/i) &&
    upperCasedCountryOfIssue === COUNTRY_NAMES_FOR_VARIANTS.TURKEY
  );
}
