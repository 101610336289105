import { localizedString } from '@languages';
import { defaultLettersAndSymbolsRegexMatch } from '@lib/validations/vefifyDetailsFormValidations';

export const short = {
  homeAddress: {
    isRequired: {
      message: localizedString('pleaseEnterYourHomeAddress')
    }
  }
};

export const detailed = {
  addressLine1: {
    isRegexMatch: defaultLettersAndSymbolsRegexMatch
  },
  addressLine2: {
    isRegexMatch: defaultLettersAndSymbolsRegexMatch
  },
  suburb: {
    isRequired: {
      message: localizedString('suburbRequired')
    },
    isRegexMatch: defaultLettersAndSymbolsRegexMatch
  },
  postcode: {
    isMaxLength: {
      message: localizedString('postcodeLengthLimit'),
      length: 8
    },
    isRegexMatch: {
      message: localizedString('lettersAndNumbersOnly'),
      regex: /^([a-zA-Z0-9]+)$/
    }
  },
  state_territory: {
    isRequired: {
      message: localizedString('stateRequired'),
      validateIf: ({ fields }) => fields.country === 'Australia'
    }
  },
  country: {
    isRequired: localizedString('countryRequired')
  }
};
