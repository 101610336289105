import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactSelect from 'react-select';
import classes from './Select.style.module.scss';

export default class Select extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    hasError: PropTypes.string,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    required: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    options: PropTypes.array,
    paddingLeft30: PropTypes.bool,
    disabled: PropTypes.bool,
    borderBottomOnly: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  /**
   * Return the component's initial state
   * @return {Object}
   */
  getInitialState() {
    return {
      opened: false
    };
  }

  render() {
    const {
      id,
      className,
      disabled,
      readOnly,
      hasError,
      options = [],
      isLoading = false,
      borderBottomOnly = false,
      paddingLeft30,
      ...restProps
    } = this.props;
    const { value, placeholder } = restProps;
    const { opened, isFocused } = this.state;
    const focusedLabel = {
      position: 'absolute',
      top: '-15px',
      left: '-11px',
      transform: 'translateY(-50%) scale(0.9)',
      fontWeight: '400',
      padding: '0 0.3rem',
      margin: '0 0.5rem',
      transition: '.1s ease-out',
      transformOrigin: 'left top',
      pointerEvents: 'none',
      backgroundColor: '#fff',
      color: opened ? classes.secondary : '#6E7396'
    };

    const selectedLabel = {
      top: '1px',
      left: '0px',
      zIndex: '1',
      fontSize: '1rem', // 15
      fontWeight: '400'
    };

    let borderStyle = '1px solid rgba(25, 25, 25, 0.32)';

    if (borderBottomOnly) {
      borderStyle = 'none';
    } else if (isFocused) {
      borderStyle = '1px solid black';
    }

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color: '#000',
        borderBottom: state.isSelected ? 'none !important' : 'initial',
        backgroundColor: state.isSelected ? classes.primary50 : 'transparent',
        textAlign: 'left'
      }),
      control: (provided, state) => ({
        ...provided,
        width: '100%',
        fontSize: '1rem', // 15
        minHeight: '3.75rem',
        outline: 'none',
        boxShadow: 'none',
        backgroundColor: '#fff',
        border: borderStyle,
        borderBottom: borderBottomOnly ? '1px solid rgba(25, 25, 25, 0.32)' : null,
        borderRadius: borderBottomOnly ? '0px' : '5px',
        padding: '0.5rem 0.7rem',
        color: '#394448',
        '> div': {
          overflow: state.isFocused ? 'inherit' : 'hidden',
          padding: 0
        },
        paddingLeft: paddingLeft30 ? '35%' : 'auto'
      }),
      placeholder: (provided) => {
        let plCss = opened
          ? focusedLabel
          : { left: '-3px', transition: '0.1s ease-out', color: '#6E7396', fontWeight: '400' };
        plCss = borderBottomOnly ? {} : plCss;
        return {
          ...provided,
          ...plCss
        };
      },
      indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
      }),
      indicatorsContainer: (provided) => {
        const rotate = opened
          ? { transform: 'rotate(180deg)', transition: '0.1s ease-out' }
          : { transition: '0.1s ease-out' };
        return {
          ...provided,
          '> div': {
            color: '#A5ABC1'
          },
          ...rotate
        };
      },
      menu: (provided) => {
        return {
          ...provided,
          padding: '0',
          margin: '0',
          borderRadius: '0',
          boxShadow: '0 10px 20px rgba(0, 0, 0, 0), 0 6px 6px rgba(0, 0, 0, 0.11)',
          marginLeft: paddingLeft30 ? '35%' : 'auto',
          width: paddingLeft30 ? '65%' : 'auto'
        };
      },
      menuList: (provided) => {
        return {
          ...provided,
          padding: '0',
          div: {
            padding: '0.8125rem', // 13
            borderBottom: '1px solid #E8E8EC',
            fontSize: '1rem', // 15
            // color: classes.primary,
            fontWeight: '400'
          },
          'div:last-child': {
            borderBottom: 'none'
          }
        };
      },
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return {
          ...provided,
          // color:classes.primary,
          fontSize: '1rem',
          lineHeight: '1.2',
          opacity,
          transition
        };
      }
    };

    return (
      <div className={classNames(classes.Select)}>
        {value && !borderBottomOnly && (
          <div style={{ ...focusedLabel, ...selectedLabel }}>{placeholder}</div>
        )}
        <ReactSelect
          id={id}
          className={classNames(
            className,
            'minimal'
            // hasError && classes.hasError
          )}
          // menuIsOpen={true}
          options={options}
          isSearchable={false}
          isDisabled={disabled}
          isLoading={isLoading}
          readOnly={readOnly}
          styles={customStyles}
          onMenuOpen={() => {
            this.setState({
              opened: true
            });
            focusedLabel.color = classes.secondary;
          }}
          onMenuClose={() => {
            this.setState({
              opened: false
            });
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors
              // primary25: classes.primary25,
              // primary: classes.primary50,
              // neutral0: '#fff'
            }
          })}
          {...restProps}
        />
        {hasError && <div className={classes.hasError}>{hasError}</div>}
      </div>
    );
  }
}
