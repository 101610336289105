import React from 'react';
import PropTypes from 'prop-types';
import { Input, Checkbox, DatePicker } from '@DOC_ONLY_FLOW/components';
import classNames from 'classnames';
import { localizedString } from '@languages';
import { getFields } from '@lib/utils/v1/getFields';
import classes from './VerifyDetails.style.module.scss';

function formatDay(value) {
  if (!value || value === '') {
    return false;
  }
  const parts = value.split('-');
  if (parts[0] === '9') {
    return `0${value}`;
  }

  return value;
}

const VerifyDetails = ({
  idType,
  country,
  countryOfIssue,
  onChange,
  errors,
  checkConfirm,
  useIdNumber = false,
  ...restProps
}) => {
  const fields = getFields({ idType, countryOfIssue, useIdNumber, values: restProps });

  /**
   * Generate form fields.
   */
  const generateFields = fields.map((field) => {
    const { id, label, value, date, confirm, halfWith } = field;

    // if its date
    if (date) {
      const restProps = {
        id,
        label,
        value
      };
      return (
        <DatePicker
          key={id}
          hasError={errors[field.id]}
          className={classNames(classes.input, classes.halfWith)}
          onChange={(value) => onChange(id, formatDay(value))}
          {...restProps}
        />
      );
    }
    if (confirm) {
      // if input has confirmation

      const checkId = `check${id}`;
      const value = restProps[checkId];
      return (
        <div key={id} className={classes.isCorrect}>
          <Input
            className={classNames(classes.input, halfWith && classes.halfWith)}
            hasError={errors[field.id]}
            onChange={(value) => onChange(id, value)}
            {...field}
          />
          <Checkbox
            id={checkId}
            className={classes.checkbox}
            label={localizedString('correctQuestion')}
            value={value}
            onChange={(value) => onChange(checkId, value)}
          />
        </div>
      );
    }
    return (
      <Input
        key={id}
        className={classNames(classes.input, halfWith && classes.halfWith)}
        hasError={errors[field.id]}
        onChange={(value) => onChange(id, value)}
        {...field}
      />
    );
  });

  const {
    ENABLE_CONSENT_CHECKBOX = true,
    ENABLE_CONSENT_CHECKBOX_TEXT = localizedString('consentToAllowingDocumentDetails')
  } = process.env;

  return (
    <div className={classNames(classes.wrapper, 'b-container')}>
      <div className={classes.title}>
        {localizedString('makeSureExtractedInformationIs')}
        <b>{localizedString('correctLowercase')}</b>
        {localizedString('editIfNeeded')}
      </div>
      <div className={classes.container}>
        {generateFields}
        {ENABLE_CONSENT_CHECKBOX && (
          <Checkbox
            id="confirm"
            value={checkConfirm}
            onChange={(value) => onChange('checkConfirm', value)}
            label={ENABLE_CONSENT_CHECKBOX_TEXT}
          />
        )}
      </div>
    </div>
  );
};

VerifyDetails.propTypes = {
  idType: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  /* Form data */
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  dateOfBirth: PropTypes.string,
  expiryDate: PropTypes.string,
  licenceNumber: PropTypes.string,
  licenceVersion: PropTypes.string,
  passportNumber: PropTypes.string,
  /* Confirmation */
  checkConfirm: PropTypes.bool,
  checklicenceNumber: PropTypes.bool,
  checklicenceVersion: PropTypes.bool,
  checkpassportNumber: PropTypes.bool,

  country: PropTypes.string,
  countryOfIssue: PropTypes.string,
  useIdNumber: PropTypes.bool
};

VerifyDetails.defaultProps = {
  idType: 'NZL_DRIVERLICENCE',
  errors: {},
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',
  expiryDate: '',
  passportNumber: '',
  licenceNumber: '',
  licenceVersion: '',
  onChange: () => {}
};

export default VerifyDetails;
