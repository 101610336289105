import PropTypes from 'prop-types';
import Message from '@FLOW_V2_FLOW/components/Message';
import { localizedString } from '@languages';

export const UnderEligibleAgeMessage = ({ eligibleAge }) => {
  return (
    <Message issue title={localizedString('verifyDetails.UNDER_ELIGIBLE_AGE_TITLE', eligibleAge)}>
      {localizedString('sessionCancelledDesc1')}
      <br />
      <br />
      {localizedString('sessionCancelledDesc2')}
    </Message>
  );
};
UnderEligibleAgeMessage.propTypes = {
  eligibleAge: PropTypes.number
};
