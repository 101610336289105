import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import APIs from '@services/APIs';
import { localizedString } from '@languages';
// eslint-disable-next-line import/no-unresolved
import classes from './Success.style.module.scss';

const Success = ({ successMessage, redirect = false }) => {
  document.body.style.background = '#fff';
  const { APP_LOGO_SF = null, APP_LOGO } = process.env;
  const logo = localStorage.getItem(`${window.location.host}_app_logo`);
  const finalLogo = logo || `images/logos/${APP_LOGO_SF || APP_LOGO}`;

  const handleRedirect = () => {
    if (redirect) {
      APIs.return();
    }
  };

  let successMessageContent = 'Verifying your age';

  if (successMessage) {
    successMessageContent = parse(successMessage);
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes['img-container']} data-testid="cmplt-logo">
        <img alt="" src={finalLogo} />
      </div>
      <div className={classes.heading} data-testid="cmplt-heading">
        {successMessageContent}
      </div>
      <div className={classes.title} onClick={handleRedirect} data-testid="cmplt-instruction">
        {redirect
          ? parse(
              'Please do not close this browser.<br/><br/>After 10-15 seconds you’ll be directed back to the checkout.'
            )
          : localizedString('youCanCloseThisWindow')}
      </div>
    </div>
  );
};
Success.propTypes = {
  successMessage: PropTypes.any,
  redirect: PropTypes.bool
};
export default Success;
