import InforAction from '../actions/information';

const initialState = {
  idDetails: null,
  addresses: {
    home: '',
    atAddress: false,
    previous: '',

    addressLine1: '',
    addressLine2: '',
    city: '',
    suburb: '',
    postcode: '',
    country: 'New Zealand',
    isMatch: true
  },
  otherDetails: []
};

export default function information(state = initialState, action = {}) {
  switch (action.type) {
    case InforAction.SET_ID_INFO:
      return {
        ...state,
        idDetails: {
          ...state.idDetails,
          ...action.data
        }
      };

    case InforAction.RESET_ID_INFO:
      return {
        ...state,
        idDetails: null
      };

    case InforAction.RESET_OTHER_INFO:
      return {
        ...state,
        otherDetails: null
      };

    case InforAction.SET_ADDRESS:
      return {
        ...state,
        addresses: {
          ...state.addresses,
          ...action.data
        }
      };

    default:
      return state;
  }
}
