import countries from '../data/countries.json';

export const getCountryLabelFromIso2Code = (countryIso2) => {
  const country = countries.find((country) => country.code === countryIso2);

  if (country) {
    return country.name;
  }
  return null;
};

export const getStatesFromCountry = (countryIso2) => {
  const states = {
    AU: [
      {
        label: 'ACT',
        value: 'ACT'
      },
      {
        label: 'NSW',
        value: 'NSW'
      },
      {
        label: 'NT',
        value: 'NT'
      },
      {
        label: 'QLD',
        value: 'QLD'
      },
      {
        label: 'SA',
        value: 'SA'
      },
      {
        label: 'TAS',
        value: 'TAS'
      },
      {
        label: 'VIC',
        value: 'VIC'
      },
      {
        label: 'WA',
        value: 'WA'
      }
    ]
  };
  return states[countryIso2] || [];
};
