import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AppContainer } from '@containers';
import { LoadingSpinner, DeviceInfo } from '@components';
import { getQueryStringParams, generateId } from '@js/lib/Utils';
import { isMobile } from 'react-device-detect';
import AppConfigAction from '../store/actions/appConfig';
import { AppContainer as AppSFContainer } from '../_SHORT_FLOW/containers';
import AppVOIContainer from '../_AUVOI_FLOW/containers/App';
import { AppContainer as AppFlowV2Container } from '../_FLOW_V2_FLOW/containers';
import { AppContainer as AppVOIFlowV2Container } from '../_VOIFLOW_V2_FLOW/containers';
import { AppContainer as AppLiveOnlyContainer } from '../_LIVEONLY_FLOW/containers';
import { AppContainer as AppDOCONLYContainer } from '../_DOC_ONLY_FLOW/containers';
import { AppContainer as AppQOnlyContainer } from '../_Q_ONLY_FLOW/containers';
import APIs from '../services/APIs';
import { LockedTransactionUrl } from '../errors';

import '../styles/base.scss';

class AppView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verify: false,
      flowType: '',
      isLoading: true,
      isCompleted: false,
      transStatus: '',
      questionnaire: null,
      hasLivenessScreen: true,
      error: null
    };
  }

  componentDidMount() {
    const { setAppConfig } = this.props;

    let browserToken = sessionStorage.getItem('browser_session_token');
    sessionStorage.clear();

    if (browserToken) {
      sessionStorage.setItem('browser_session_token', browserToken);
    } else {
      sessionStorage.setItem('browser_session_token', generateId(20));
    }

    browserToken = sessionStorage.getItem('browser_session_token');

    APIs.sendBrowserToken(browserToken)
      .then((result) => {
        if (result.status === 'error') {
          this.setState({
            error: {
              component: LockedTransactionUrl,
              props: {
                message: result.message
              }
            }
          });
        }
      })
      .catch(() => {});

    if (document.body.dataset.primary) {
      document.documentElement.style.setProperty('--primary', document.body.dataset.primary);
      document.documentElement.style.setProperty('--loading-bg', document.body.dataset.primary);
      document.documentElement.style.setProperty(
        '--loading-progress-bar-incomplete',
        document.body.dataset.primary
      );
      document.documentElement.style.setProperty('--loading-progress-bar', '#fff');
      document.documentElement.style.setProperty('--loading-dot', '#fff');
      document.documentElement.style.setProperty('--loading-text-color', '#fff');
    }
    if (document.body.dataset.secondary) {
      document.documentElement.style.setProperty('--secondary', document.body.dataset.secondary);
    }

    const { UNIVERSAL_URL_PATH = '/verify' } = process.env;
    let config = {};

    if (!isMobile && process.env.SMS) {
      this.setState({
        flowType: 'MAIN',
        isLoading: false
      });
      return;
    }

    if (window.location.pathname === UNIVERSAL_URL_PATH) {
      const queryParams = getQueryStringParams();
      const state = {
        isLoading: false
      };
      if ('flowType' in queryParams && queryParams.flowType) {
        state.flowType = queryParams.flowType;
      }
      this.setState(state);
    } else {
      APIs.checkTransactionType()
        .then((result) => {
          // eslint-disable-next-line no-console
          console.log('TransactionCheckResult:', result);
          const {
            status,
            verify,
            flowType = 'FLOW_V2',
            isCompleted = false,
            questionnaire = null,
            transStatus,
            dataProvider = null,
            hasLivenessScreen = true,
            appConfig = {}
          } = result;

          if (
            status !== 'success' ||
            transStatus === 'COMPLETED' ||
            transStatus === 'EXPIRED' ||
            transStatus === 'CANCELLED' ||
            transStatus === '404'
          ) {
            this.setState({
              flowType: 'FLOW_V2',
              isLoading: false,
              transStatus: status !== 'success' ? '404' : transStatus
            });
          } else {
            this.setState({
              verify,
              flowType,
              isLoading: false,
              isCompleted,
              transStatus,
              questionnaire,
              hasLivenessScreen
            });
          }

          // Set Global CSS Vars
          if (appConfig.primary) {
            document.documentElement.style.setProperty('--primary', appConfig.primary);
            document.documentElement.style.setProperty('--loading-bg', appConfig.primary);
            document.documentElement.style.setProperty(
              '--loading-progress-bar-incomplete',
              appConfig.primary
            );
            document.documentElement.style.setProperty('--loading-progress-bar', '#fff');
            document.documentElement.style.setProperty('--loading-dot', '#fff');
            document.documentElement.style.setProperty('--loading-text-color', '#fff');
            document.documentElement.style.setProperty('--loading-spinner-dot1', appConfig.primary);
            document.documentElement.style.setProperty('--loading-spinner-dot3', appConfig.primary);
          }

          if (appConfig.secondary) {
            document.documentElement.style.setProperty('--secondary', appConfig.secondary);
            document.documentElement.style.setProperty(
              '--loading-spinner-dot2',
              appConfig.secondary
            );
            document.documentElement.style.setProperty(
              '--loading-spinner-dot4',
              appConfig.secondary
            );
          }
          if (appConfig.body) {
            document.documentElement.style.setProperty('--body-text-color', appConfig.body);
            document.documentElement.style.setProperty('--heading-text-color', appConfig.body);
          }
          if (appConfig.font) {
            document.documentElement.style.setProperty('--base-font-family', appConfig.font);
          }
          if (appConfig.heading_font) {
            document.documentElement.style.setProperty(
              '--heading-font-family',
              appConfig.heading_font
            );
          }
          const logoStorageKey = `${window.location.host}_app_logo`;
          if (appConfig.logo) {
            localStorage.setItem(logoStorageKey, appConfig.logo);
          } else {
            localStorage.removeItem(logoStorageKey);
          }

          // Store the APP settings to store
          config = { ...config, ...appConfig, dataProvider };
          setAppConfig(config);
        })
        .catch(() => {
          this.setState({
            flowType: 'FLOW_V2',
            isLoading: false,
            transStatus: '404'
          });
        });
    }
  }

  /**
   * Render the component's.
   *
   * @return {ReactElement}
   */
  render() {
    document.documentElement.className = isMobile ? 'mobile' : 'desktop';

    const {
      flowType,
      verify,
      isLoading,
      isCompleted,
      transStatus,
      questionnaire,
      hasLivenessScreen,
      error
    } = this.state;

    const { component: Error, props: errorProps } = error || {};

    if (error) {
      return <Error {...errorProps} />;
    }

    if (window.location.search === '?show=info') {
      return <DeviceInfo />;
    }

    if (isLoading) {
      return <LoadingSpinner title="" />;
    }

    if (flowType === 'SHORT_FLOW') {
      return <AppSFContainer isCompleted={isCompleted} flowType={flowType} verify={verify} />;
    }
    if (flowType === 'AUVOI_FLOW') {
      return <AppVOIContainer isCompleted={isCompleted} flowType={flowType} verify={verify} />;
    }
    if (flowType === 'LIVE_ONLY') {
      return <AppLiveOnlyContainer isCompleted={isCompleted} flowType={flowType} verify={verify} />;
    }
    if (flowType === 'DOC_ONLY') {
      return <AppDOCONLYContainer isCompleted={isCompleted} flowType={flowType} verify={verify} />;
    }
    if (flowType === 'FLOW_V2' || flowType === 'DATA_ONLY') {
      return (
        <AppFlowV2Container
          transStatus={transStatus}
          flowType={flowType}
          verify={verify}
          hasLivenessScreen={hasLivenessScreen}
          questionnaire={questionnaire}
        />
      );
    }
    if (flowType === 'VOI_FLOW_V2') {
      return (
        <AppVOIFlowV2Container transStatus={transStatus} flowType={flowType} verify={verify} />
      );
    }
    if (flowType === 'Q_ONLY') {
      return (
        <AppQOnlyContainer
          isCompleted={isCompleted}
          flowType={flowType}
          verify={verify}
          questionnaire={questionnaire}
        />
      );
    }
    return <AppContainer isCompleted={isCompleted} flowType={flowType} verify={verify} />;
  }
}

AppView.propTypes = {
  setAppConfig: PropTypes.func
};

export default connect(null, mapDispatchToProps)(AppView);

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    setAppConfig: (data) => dispatch(AppConfigAction.setAppConfig(data))
  };
}
