import Capture from '../actions/capture';

const initialState = {
  frontParams: {}
};

export default function information(state = initialState, action = {}) {
  switch (action.type) {
    case Capture.SET_FRONTID_PARAMS:
      return {
        ...state,
        frontParams: action.data
      };

    default:
      return state;
  }
}
