import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Message } from '@components';
import { localizedString } from '@languages';
import classes from './Results.style.module.scss';

const Results = ({ confidence, data }) => {
  const keyToText = (key) => {
    return key ? key.replace(/([a-z]+)([A-Z]+)/g, '$1 $2') : '';
  };
  return (
    <Message title={localizedString('captureProcessComplete')} className={classes.wrapper}>
      <h3 className="b-title">{localizedString('demoFaceMatchResults')}</h3>
      <ul>
        <li className={classes.title}>
          <div>{localizedString('overallConfidence')}</div>
          <span className={classes.textSuccess}>{localizedString('livenessDetected')}</span>
        </li>
        <li className={classNames(classes.status, !confidence && classes.rFailed)}>
          {!confidence ? localizedString('failed') : localizedString('passed')}
        </li>
        {Object.keys(data).map((key) => {
          const value = data[key];
          const intVal = parseInt(value, 10);
          let className = '';
          if (intVal >= 65) {
            className = classes.bgSuccess;
          } else if (intVal >= 50) {
            className = classes.bgOrange;
          } else {
            className = classes.bgDanger;
          }

          return (
            <li key={key} className={className}>
              <small>{value}%</small>
              <span className={classes.text}>{keyToText(key)}</span>
            </li>
          );
        })}
      </ul>
    </Message>
  );
};

Results.propTypes = {
  confidence: PropTypes.bool,
  data: PropTypes.object
};

Results.defaultProps = {
  confidence: false,
  data: {}
};

export default Results;
