import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Header from '@components/Header';
import { Button, ButtonGroup } from '@components';
import { wrapperClasses } from '../../lib/Utils';
import classes from './Page.style.module.scss';

/**
 * A layout for pages.
 */
const Page = ({
  children,
  footerContent,
  title,
  className,
  onGoBack,
  buttons,
  isPrivacy,
  buttonsStacked,
  onSelectLanguage,
  isMessage
}) => {
  const hasHeader = onGoBack || title;
  return (
    <div className={classNames(classes.wrapper, className && wrapperClasses(classes, className))}>
      {hasHeader && (
        <Header onSelectLanguage={onSelectLanguage} fixed onGoBack={onGoBack} title={title} />
      )}
      <div className={classes.body}>{children}</div>
      {(!isMessage || footerContent || (buttons && buttons.length > 0)) && (
        <div className={classNames(classes.footer, { [classes.privacyFooter]: isPrivacy })}>
          {footerContent}
          {buttons && (
            <ButtonGroup buttonsStacked={buttonsStacked} stacked>
              {buttons.map((button) => (
                <Button key={button.label} full {...button} />
              ))}
            </ButtonGroup>
          )}
        </div>
      )}
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.string,
  onGoBack: PropTypes.func,
  onSelectLanguage: PropTypes.func,
  footerContent: PropTypes.node,
  isPrivacy: PropTypes.bool,
  isMessage: PropTypes.bool,
  buttonsStacked: PropTypes.bool,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      shadow: PropTypes.bool,
      large: PropTypes.bool
    })
  )
};

Page.defaultProps = {
  title: '',
  className: '',
  isPrivacy: false,
  buttonsStacked: false,
  buttons: [],
  isMessage: false
};

export default Page;
