import isEmpty from 'lodash/isEmpty';

export const isDobUneditable = ({
  countryCodeIso2 = '',
  idType,
  forceRestriction,
  restrictedSecondDocsList = {}
}) => {
  if (forceRestriction) {
    return true;
  }

  const secondDocsConfig = restrictedSecondDocsList[idType];

  if (secondDocsConfig) {
    if (!isEmpty(secondDocsConfig.excludedCountries)) {
      return !secondDocsConfig.excludedCountries.includes(countryCodeIso2.toUpperCase());
    }

    if (!isEmpty(secondDocsConfig.includedCountries)) {
      return secondDocsConfig.includedCountries.includes(countryCodeIso2.toUpperCase());
    }
  }

  return false;
};
