import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactSelect from 'react-select';
import classes from './Select.style.module.scss';

export default class Select extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  /**
   * Return the component's initial state
   * @return {Object}
   */
  getInitialState() {
    return {
      opened: false
    };
  }

  render() {
    const {
      id,
      className,
      disabled,
      readOnly,
      hasError,
      options = [],
      isLoading = false,
      ...restProps
    } = this.props;
    const { value, placeholder } = restProps;
    const { opened } = this.state;
    const focusedLabel = {
      position: 'absolute',
      top: '-15px',
      left: '-11px',
      transform: 'translateY(-50%) scale(0.9)',
      fontWeight: '400',
      padding: '0 0.3rem',
      margin: '0 0.5rem',
      transition: '.1s ease-out',
      transformOrigin: 'left top',
      pointerEvents: 'none',
      backgroundColor: '#fff',
      color: opened ? classes.secondary : '#6E7396'
    };

    const selectedLabel = {
      top: '1px',
      left: '0px',
      zIndex: '1',
      fontSize: '16px',
      fontWeight: '400'
    };
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color: '#000',
        borderBottom: state.isSelected ? 'none !important' : 'initial',
        backgroundColor: state.isSelected ? classes.primary50 : 'transparent',
        textAlign: 'left'
      }),
      control: (provided, state) => ({
        ...provided,
        width: '100%',
        fontSize: '16px',
        minHeight: '51px',
        outline: 'none',
        boxShadow: 'none',
        backgroundColor: '#fff',
        border: state.isFocused
          ? `2px solid ${classes.secondary}`
          : '1px solid rgba(25, 25, 25, 0.32)',
        borderRadius: '5px',
        padding: '0.5rem 0.7rem 0.4rem',
        color: '#394448',
        '> div': {
          overflow: state.isFocused ? 'inherit' : 'hidden',
          padding: 0
        }
      }),
      placeholder: (provided) => {
        const plCss = opened
          ? focusedLabel
          : { left: '-3px', transition: '0.1s ease-out', color: '#6E7396', fontWeight: '400' };
        return {
          ...provided,
          ...plCss
        };
      },
      indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
      }),
      indicatorsContainer: (provided) => {
        const rotate = opened
          ? { transform: 'rotate(180deg)', transition: '0.1s ease-out' }
          : { transition: '0.1s ease-out' };
        return {
          ...provided,
          '> div': {
            color: '#A5ABC1'
          },
          ...rotate
        };
      },
      menu: (provided) => {
        return {
          ...provided,
          padding: '0',
          margin: '0',
          borderRadius: '0',
          boxShadow: '0 10px 20px rgba(0, 0, 0, 0), 0 6px 6px rgba(0, 0, 0, 0.11)',
          zIndex: 4
        };
      },
      menuList: (provided) => {
        return {
          ...provided,
          padding: '0',
          div: {
            padding: '13px',
            borderBottom: '1px solid #E8E8EC',
            fontSize: '16px',
            // color: classes.primary,
            fontWeight: '400'
          },
          'div:last-child': {
            borderBottom: 'none'
          }
        };
      },
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return {
          ...provided,
          // color:classes.primary,
          fontSize: '16px',
          lineHeight: '32px',
          opacity,
          transition
        };
      }
    };

    return (
      <div className={classNames(classes.Select)}>
        {value && <div style={{ ...focusedLabel, ...selectedLabel }}>{placeholder}</div>}
        <ReactSelect
          id={id}
          className={classNames(
            className,
            'minimal'
            // hasError && classes.hasError
          )}
          // menuIsOpen={true}
          options={options}
          isSearchable
          isDisabled={disabled}
          isLoading={isLoading}
          readOnly={readOnly}
          styles={customStyles}
          onMenuOpen={() => {
            this.setState({
              opened: true
            });
            focusedLabel.color = classes.secondary;
          }}
          onMenuClose={() => {
            this.setState({
              opened: false
            });
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors
              // primary25: classes.primary25,
              // primary: classes.primary50,
              // neutral0: '#fff'
            }
          })}
          {...restProps}
        />
        {hasError && <div className={classes.hasError}>{hasError}</div>}
      </div>
    );
  }
}

Select.propTypes = {
  id: PropTypes.string.isRequired,
  hasError: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  options: PropTypes.array
};
