const propertyErrorsMap = {
  '.occupationDetails.ABN': 'ABN should be 11 digits'
};

export const transformErrors = (errorList) => {
  const foundError = errorList.find((error) => {
    return propertyErrorsMap[error.property];
  });

  if (foundError) {
    return [];
  }

  return errorList;
};
