import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Page from '@lib/components/v2/Page';
import Animation from '@components/Animation';
import { localizedString } from '@languages';
import parse from 'html-react-parser';
import classes from './CaptureTips.style.module.scss';

let animationCloseFar;
import('@FLOW_V2_FLOW/animations/anim_close_far.json').then((value) => {
  animationCloseFar = value;
});

let animationLaptop;
import('@FLOW_V2_FLOW/animations/anim_laptop.json').then((value) => {
  animationLaptop = value;
});

let animationLamp;
import('@FLOW_V2_FLOW/animations/anim_lamp.json').then((value) => {
  animationLamp = value;
});

export default class CaptureTips extends Component {
  static propTypes = {
    type: PropTypes.string,
    onHide: PropTypes.func.isRequired,
    onCaptureAgain: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      step: 0
    };
  }

  render() {
    const {
      FLOW_V2_CAPTURE_TIP_TITLE = localizedString('captureTips'),
      FLOW_V2_CAPTURE_TIP_DESCRIPTION1 = '',
      FLOW_V2_CAPTURE_TIP_DESCRIPTION2 = '',
      FLOW_V2_CAPTURE_TIP_DESCRIPTION3 = ''
    } = process.env;

    let { step } = this.state;
    const { type, onHide, onCaptureAgain } = this.props;

    const tipTitle = FLOW_V2_CAPTURE_TIP_TITLE;
    const tipDescriptionKey = `captureTipsDescription${type}${step + 1}`;
    let tipDescription = localizedString(tipDescriptionKey);
    if (step === 0 && FLOW_V2_CAPTURE_TIP_DESCRIPTION1 !== '') {
      tipDescription = FLOW_V2_CAPTURE_TIP_DESCRIPTION1;
    }
    if (step === 1 && FLOW_V2_CAPTURE_TIP_DESCRIPTION2 !== '') {
      tipDescription = FLOW_V2_CAPTURE_TIP_DESCRIPTION2;
    }
    if (step === 2 && FLOW_V2_CAPTURE_TIP_DESCRIPTION3 !== '') {
      tipDescription = FLOW_V2_CAPTURE_TIP_DESCRIPTION3;
    }

    const buttons = [
      {
        label: localizedString('back'),
        variant: 'transparent',
        onClick: () => {
          if (step === 0) {
            onHide();
          } else {
            this.setState({ step: step - 1 });
          }
        },
        dataTestId: 'capturTips-baclBtn'
      },
      {
        label: step === 2 ? localizedString('captureMyID') : localizedString('next'),
        onClick: () => {
          if (step === 2) {
            step = 0;
            onCaptureAgain();
          } else {
            this.setState({ step: step + 1 });
          }
        },
        dataTestId: 'capturTips-nxtBtn'
      }
    ];

    return (
      <Page buttons={buttons}>
        <div className={classes.wrapper}>
          <div className={classes.heading} data-testid="capturTips-heading">
            {tipTitle}
          </div>
          <div className={classes.description} data-testid="capturTips-txt1">
            {parse(tipDescription)}
          </div>
          <div className={classes['animation-container']} data-testid="capturTips-anime">
            {step === 0 && <Animation animationData={animationCloseFar} />}
            {step === 1 && <Animation animationData={animationLaptop} />}
            {step === 2 && <Animation animationData={animationLamp} />}
          </div>
        </div>
      </Page>
    );
  }
}
