import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Page from '@DOC_ONLY_FLOW/components/Page';
import { localizedString } from '@languages';
import classes from './Message.style.module.scss';

const Message = ({
  children,
  landscope,
  dark,
  completed,
  cancelled,
  issue,
  logoFooter,
  className,
  ...resetProps
}) => {
  const { APP_VERSION } = process.env;

  /* const footerContent = logoFooter && (
        <img alt="" data-logo src="images/logo.png" />
    ); */
  return (
    <Page
      className={classNames(
        classes.wrapper,
        (dark || landscope) && 'dark',
        landscope && 'isLandscope'
      )}
      isDark={dark || landscope}
      isMessage
      // footerContent={footerContent}
      {...resetProps}
    >
      <div className={classes.content}>
        {landscope && (
          <div className={classes.landscope}>
            <div className={classNames(classes.content, { 'text-white': dark || landscope })}>
              {localizedString('pleaseRotateYourDeviceToPortrait')}
              <img alt="" src="images/icons/png/landscope.png" />
            </div>
          </div>
        )}
        {issue !== undefined && (
          <div className={classes.issue}>
            <div
              className={classNames('b-icon-warning', classes.warning, {
                'text-white': dark || landscope
              })}
            />
            <span className={classNames({ 'text-white': dark || landscope })}>{issue}</span>
          </div>
        )}
        {completed && (
          <div
            className={classNames(
              APP_VERSION < 3 && classes.completed,
              APP_VERSION >= 3 && classes.completedGreen
            )}
          />
        )}
        {cancelled && <div className={classes.cancelled} />}
        {!landscope && children}
      </div>
    </Page>
  );
};

Message.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
  onGoBack: PropTypes.func,
  logoFooter: PropTypes.bool,
  completed: PropTypes.bool,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      shadow: PropTypes.bool,
      large: PropTypes.bool
    })
  ),
  issue: PropTypes.string,
  dark: PropTypes.bool,
  landscope: PropTypes.bool,
  buttonsStacked: PropTypes.bool,
  cancelled: PropTypes.bool
};

Message.defaultProps = {
  className: '',
  buttonsStacked: false
};

export default Message;
