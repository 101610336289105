import appConfig from '../actions/appConfig';

const initialState = {};

export default function information(state = initialState, action = {}) {
  switch (action.type) {
    case appConfig.SET_APP_CONFIG:
      return {
        ...state,
        ...action.data
      };

    default:
      return state;
  }
}
