import React from 'react';
import PropTypes from 'prop-types';
import { localizedString } from '@languages';
import { Page } from '../../components';
import { OverviewContent } from '../../components/Contents';

export default function Overview({ onGoBack, onGoNext }) {
  const buttons = [
    {
      label: localizedString('back'),
      variant: 'transparent',
      onClick: () => onGoBack()
    },
    { label: localizedString('continue'), onClick: () => onGoNext() }
  ];
  return (
    <Page title={localizedString('processOverview')} buttons={buttons}>
      <OverviewContent />
    </Page>
  );
}

Overview.propTypes = {
  onGoNext: PropTypes.func,
  onGoBack: PropTypes.func
};

Overview.defaultProps = {
  onGoNext: () => null,
  onGoBack: () => null
};
