import React from 'react';
import PropTypes from 'prop-types';
import { Message, Modal } from '@components';
import { localizedString } from '@languages';

/**
 * If card blurry or crop etc.
 */
export const CouldNotRead = (props) => {
  const style = { color: '#fff' };
  return (
    <Message dark {...props} issue={localizedString('weCouldNotReadYourIDInPhoto')}>
      <ul>
        <li style={style}>{localizedString('makeSureNoPartsOfCardAreCovered')}</li>
        <li style={style}>{localizedString('makeSureCameraIsNotSmudgedOrDirty')}</li>
        <li style={style}>{localizedString('makeSureIDIsNotBadlyDamaged')}</li>
        <li style={style}>{localizedString('makeSurePhotoIsInFocusAndTextReadable')}</li>
      </ul>
    </Message>
  );
};

/**
 * Wrong document.
 */
export const CouldNotRecognise = (props) => {
  const style = { color: '#fff' };
  return (
    <Message dark {...props} issue={localizedString('weCouldNotRecogniseYourID')}>
      <ul>
        <li style={style}>{localizedString('makeSureYouSelectedCorrectID')}</li>
        <li style={style}>{localizedString('makeSurePhotoIsInFocusAndTextReadable')}</li>
        <li style={style}>{localizedString('makeSureAllEdgesCanBeSeenInPhoto')}</li>
      </ul>
    </Message>
  );
};

/**
 * If image has poor quality.
 */
export const PoorImage = (props) => {
  const style = { color: '#fff' };
  return (
    <Message dark {...props} issue={localizedString('poorImageQuality')}>
      <ul>
        <li style={style}>{localizedString('makeSureThereIsEnoughLight')}</li>
        <li style={style}>{localizedString('makeSureYourPhotoIsInFocusAndNotBlurry')}</li>
        <li style={style}>{localizedString('makeSureCameraIsNotSmudgedOrDirty')}</li>
      </ul>
    </Message>
  );
};

/**
 * If ID has been expired.
 */
export const ExpiredIDV1 = (props) => {
  const style = { textAlign: 'left', maxWidth: 270, margin: 'auto', color: 'white' };
  return (
    <Message dark {...props} issue={localizedString('weReadYourIDBeingExpired')}>
      <div style={style}>
        {localizedString('ifNotExpiredPress')}
        <b>{localizedString('notExpired3')}</b>
        {localizedString('belowAndEnterExpiry')}
        <br />
        <br />
        {localizedString('if')}
        <b>{localizedString('expired')}</b>
        {localizedString('commaPress')}
        <b>{localizedString('tryAgain')}</b>
        {localizedString('belowAndChooseValidID')}
      </div>
    </Message>
  );
};
export const ExpiredIDV2 = (props) => {
  const style = { textAlign: 'left', maxWidth: 270, margin: 'auto', color: 'white' };
  return (
    <Message dark {...props} issue={localizedString('yourIDDocumentExpired')}>
      <div style={style}>
        {localizedString('if')} {localizedString('itHas')}{' '}
        <strong style={{ fontFamily: 'arial' }}>{localizedString('notExpired2')}</strong>
        {localizedString('pressNotExpiredBelowAndEnterCorrectDate')}
        <br />
        <br />
        {localizedString('if')} {localizedString('itHas')}{' '}
        <strong style={{ fontFamily: 'arial' }}>{localizedString('expired')}</strong>
        {localizedString('pressTryAgainBelowAndChooseValidDocument')}
      </div>
    </Message>
  );
};
export const ExpiredID = (props) => {
  const { EXPIRED_ID_VERSION = 0 } = process.env;
  const Components = [ExpiredIDV1, ExpiredIDV2];

  const Exception = Components[EXPIRED_ID_VERSION];
  return <Exception {...props} />;
};

/**
 * If ID has been expired by more than 2 years.
 */
export const ExpiredIDBy2Years = (props) => {
  const {
    FLOW_V2_EXPIRED_ID_HEADING = localizedString('passportExpiredOverTowYearsAgo'),
    FLOW_V2_EXPIRED_ID_DESCRIPTION = localizedString('cannotAcceptExpiredId')
  } = process.env;

  return (
    <Message {...props} title={FLOW_V2_EXPIRED_ID_HEADING} issue>
      <div style={{ textAlign: 'center' }}>{FLOW_V2_EXPIRED_ID_DESCRIPTION}</div>
    </Message>
  );
};

/**
 * Confirm user age.
 */
export const ConfirmAge = (props) => {
  return <Modal isOpen {...props} issue={localizedString('areYouUnder18')} />;
};

/**
 * Confirm user age.
 */
export const Under18 = (props) => {
  return (
    <Message
      {...props}
      title={localizedString('userUnder18')}
      issue={localizedString('weCannotContinueBecauseYouAreUnder18')}
    >
      {localizedString('sessionCancelledDesc1')}
      <br />
      <br />
      {localizedString('sessionCancelledDesc2')}
    </Message>
  );
};

/**
 * If user did more than 3 changes.
 */
export const TooManyEdits = (props) => {
  const { FIELD_EDIT_LIMIT_COUNT = 3 } = process.env;
  const issue =
    localizedString('youOnlyAbleToModify') + FIELD_EDIT_LIMIT_COUNT + localizedString('fieldsDot');

  return (
    <Message {...props} title={localizedString('tooManyEdits')} issue={issue}>
      {localizedString('thisIsDoneToPreventIdentityTheft')}
      <br />
      <br />
      {localizedString('pleaseRecaptureMakeSureYouFollowTheInstructions')}
    </Message>
  );
};

/**
 * If image has poor quality.
 */
export const FaceNotDetectedInId = (props) => {
  const style = { color: '#fff' };
  return (
    <Message dark {...props} issue={localizedString('weCouldNotRecogniseYourID')}>
      <ul>
        <li style={style}>{localizedString('facePictureOnCardShouldBeClear')}</li>
        <li style={style}>{localizedString('makeSureCameraIsNotSmudgedOrDirty')}</li>
        <li style={style}>{localizedString('makeSureIDCleanFromAnyGrime')}</li>
        <li style={style}>{localizedString('makeSureIDIsNotBadlyDamaged')}</li>
        <li style={style}>{localizedString('makeSurePhotoIsInFocusAndTextReadable')}</li>
        <li style={style}>{localizedString('makeSureAllEdgesAreShown')}</li>
      </ul>
    </Message>
  );
};

/**
 * Confirm extracted details
 */
export const ConfirmExtracted = (props) => {
  return <Modal isOpen {...props} issue={localizedString('pleaseConfirmExtractedAreCorrect')} />;
};

/**
 * Confirm consent
 */
export const ConfirmConsent = (props) => {
  return <Modal isOpen {...props} issue={localizedString('pleaesConfirmConsentToCheckDetails')} />;
};

/**
 * Confirm going back
 */
export const ConfirmGoBack = (props) => {
  return <Modal isOpen {...props} issue={localizedString('sureToGoBackAndRecaptureYourID')} />;
};

/**
 *  Recapture
 */
export const Recapture = (props) => {
  return <Modal isOpen {...props} issue={localizedString('attendantRequestedYouToRecaptureID')} />;
};
/**
 * ID number not editable.
 */
export const IdNumberNotEditable = (props) => {
  const issue = localizedString('incorrectIDNumber');
  return (
    <Message {...props} title={localizedString('idNumberNotEditable')} issue={issue}>
      {localizedString('ifIDNumberNotCorrectPleaseTakeAnotherPhoto')}
    </Message>
  );
};

/**
 * ID number not editable.
 */
export const TooManyRetryAttempts = (props) => {
  const issue = localizedString('exceedNumberOfAttempts');
  return (
    <Message {...props} title={localizedString('tooManyAttempts')} issue={issue}>
      {localizedString('exceedNumberOfAttemptsToCaptureID')}
      <br />
      <br />
      {localizedString('ifStillIncorrectCanContinueAndWeWillContactYou')}
    </Message>
  );
};

/**
 * Poor Quality Image.
 */
export const PoorQuality = (props) => {
  const { cropped, ...restProps } = props;
  const img = <img data-testid="betterImg-img" alt="" src={cropped} style={{ width: '100%' }} />;
  return (
    <Message {...restProps} title="ID Verification">
      <div className="text-left">
        <h3 className="b-title">{localizedString('weNeedBetterPicture')}</h3>
        <p data-testid="betterImg-txt1">{localizedString('qualityImageNotSufficient')}</p>
        <br />
        <br />
        {img}
        <br />
        <br />
        <p data-testid="betterImg-txt2">{localizedString('carefullyViewTipsAndRecapture')}</p>
      </div>
    </Message>
  );
};

PoorQuality.propTypes = {
  cropped: PropTypes.string
};
