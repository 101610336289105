import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './ButtonGroup.style.module.scss';

/**
 * A wrapper to handle buttons.
 */
const ButtonGroup = ({ children, stacked, buttonsStacked }) => {
  return (
    <div style={{ padding: stacked && '20px 0' }} className={classes.wrapper}>
      <div className={classNames(classes.buttons, buttonsStacked && classes.buttonsStacked)}>
        {children}
      </div>
    </div>
  );
};

ButtonGroup.propTypes = {
  children: PropTypes.node,
  stacked: PropTypes.bool,
  buttonsStacked: PropTypes.bool
};

ButtonGroup.defaultProps = {
  stacked: false,
  buttonsStacked: false
};

export default ButtonGroup;
