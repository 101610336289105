import React from 'react';
import PropTypes from 'prop-types';
import classes from './TitlePage.style.module.scss';

export const TitlePage = ({ text = '' }) => {
  return <div className={classes.titlePage}>{text}</div>;
};

TitlePage.propTypes = {
  text: PropTypes.string
};
