import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, ButtonGroup } from '@DOC_ONLY_FLOW/components';
import { localizedString } from '@languages';
import classes from './Modal.style.module.scss';

/**
 * A sample Modal
 */
const Modal = ({ children, isOpen, small, issue, buttons, className }) => {
  return (
    <div
      className={classNames(classes.wrapper, isOpen && classes.open, className)}
      aria-modal="true"
    >
      <div
        className={classNames(classes.modalContent, isOpen && classes.open, small && classes.small)}
      >
        <div className={classNames(classes.dialog)}>
          {issue && (
            <div className={classes.issue}>
              <div className="b-icon-warning" />
              <span>{issue}</span>
            </div>
          )}
          {children}
          {buttons && (
            <ButtonGroup stacked>
              {buttons.map((button) => (
                <Button key={button.label} full {...button} />
              ))}
            </ButtonGroup>
          )}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  small: PropTypes.bool,
  issue: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      shadow: PropTypes.bool,
      large: PropTypes.bool
    })
  )
};

Modal.defaultProps = {
  isOpen: false,
  issue: localizedString('cancelConfirmation')
};

export default Modal;
